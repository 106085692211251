import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import SVG from 'react-inlinesvg';
import {readableLocation} from '../../Lib/readableLocation'
import Stars from '../../Components/Stars/Stars'
import {fixHtml} from '../../Lib/fixHtml'
import {deleteApplication} from '../../Services/Offers/offers'
import LazyImage from '../../Components/LazyImage/LazyImage'

class ChefApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false
        }
    }
    render() {
        const { lang } = this.props.match.params
        let {item, restaurant, offer} = this.props
        let image = item.offer.images.image1 ? item.offer.images.image1.sizes.showcase.url : 'http://via.placeholder.com/240x460/eee/fff'
        let offerUrl = `/${lang}/restaurant/view/offer/` + restaurant.id + '/' + restaurant.slug + '/' + offer.id + '/' + offer.slug
        return (
            <div className="component_ChefApplication">
                <div>
                    <div className="top">
                        {this.renderTop()}
                    </div>
                    <div className="wrap">
                        {this.props.deviceScreen !== 'mobile' && this.props.deleteButton && this.props.item && this.props.offer
                        && this.renderDeleteButton()}
                        <div className="graphic">
                            {this.renderPremiumDesktop()}
                            <div className="logo">
                                <LazyImage src={item.restaurant.images.logo ? item.restaurant.images.logo.sizes.square.url : '/static/images/logo.png'} alt={item.restaurant.name}/>
                            </div>
                            <div className="image" style={{backgroundImage: 'url(' + image + ')'}}></div>
                        </div>
                        <div className="info">
                            <h2>
                                <Link to={offerUrl}>{item.offer.name}</Link>
                            </h2>
                            {this.props.description && this.renderDescription()}
                            {this.props.extraContent}
                            {this.props.restaurantMessage
                            && <div className="message">
                            <p><strong>{translate(lang, 'message_from_restaurant').toUpperCase()}</strong></p>
                            {this.renderRestaurantMessage()}
                            </div>}
                        </div>
                        <div className="bottom">
                            <span>{translate(lang, 'applied_on').toUpperCase()} {moment(item.created).format('D MMM[.] YYYY')}</span>
                            {this.renderShareOptions()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderTop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let url = `/${lang}/restaurant/view/home/${item.restaurant.id}/${item.restaurant.slug}`
        if (this.props.deviceScreen === 'mobile') {
            return (
                <div>
                    <div className="restaurant">
                        <div className="name-text"><span><Link to={url}>{item.restaurant.name}</Link></span></div>
                        {/* {item.restaurant.premium === "1" ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null} */}
                    </div>
                    {this.props.deleteButton && this.props.item && this.props.offer
                    && this.renderDeleteButton()}
                </div>
            )
        } else {
            return (
                <div>
                    <div className="restaurant">
                        <div className="name-text"><span><Link to={url}>{item.restaurant.name}</Link></span></div>
                    </div>
                    <Stars type={item.restaurant.ratingType} number={parseInt(item.restaurant.rating)} />
                    <span className="location">{readableLocation(item.restaurant.location)}</span>
                </div>
            )
        }
    }
    renderDescription() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let {description} = item.offer
        let teaser = !this.state.showMore && description.length > 100 ? description.substring(0, 100) + '...' : description
        return (
            <span>
                <span dangerouslySetInnerHTML={{__html: fixHtml(teaser)}}></span>
                <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
            </span>
        )
    }
    renderRestaurantMessage() {
        const { lang } = this.props.match.params
        let {item} = this.props
        const message = item.stages[0].description
        if (!message){
            return (                
                <p dangerouslySetInnerHTML={{__html: translate(lang, 'no_message')}}></p>
            )
        } else if (message.length < 150){
            return (
                <p dangerouslySetInnerHTML={{__html: message}}></p>
            )        
        } else {
            return (
                <span>
                    <span dangerouslySetInnerHTML={{__html: !this.state.showMore && message.length > 150 ? message.substring(0, 150) + '...' : message}}></span>
                    <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
                </span>
            )
        }
    }
    renderPremiumDesktop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            let isPremium = null
            if (item.restaurant.premium === "1") {
                isPremium = (
                    <div><SVG className="isPremium" src="/static/images/premium_icn.svg" /><span className="text"><span>{translate (lang, 'premium').toUpperCase()}</span></span></div>
                )
            }
            return (
                <div className="premium">
                    {isPremium}
                </div>
            )
        }
    }
    renderShareOptions() {
        if (this.props.alternativeShare) {
            return this.props.alternativeShare
        } else {
            return (
                <div className="share">
                    {this.renderMessage()}
                </div>
            )
        }
        return null
    }
    renderMessage() {
        const { lang } = this.props.match.params
        let text = this.props.deviceScreen === 'mobile' ? null : translate(lang, 'message').toUpperCase()
        return (
            <Btn text={text} className="text" post="message_icn" callback={() => {
                this.props.showOverlay('message', {cid: this.props.user.profile.id, rid: this.props.restaurant.id})
            }} />
        )
    }
    renderDeleteButton() {
        const { lang } = this.props.match.params
        return(
            <a className="delete" onClick={() => {
                const result = window.confirm(translate(lang, 'delete_application'))
            if (result) {
                deleteApplication({id: this.props.item.id, oid: this.props.offer.id, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.deleteButtonCallback()
                    }
                })
            } else {
                return
            }
            }}>
                <SVG src="/static/images/close_btn_2.svg" />
            </a>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        userType: state.userType,
        user: state.user,
        isLogged: state.isLogged,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefApplication))
