import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class ErrorOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { lang } = this.props.match.params        
        let className = 'error'
        if (this.props.className) {
            className += ' '+this.props.className
        }
        let message = this.props.data.message ? this.props.data.message : translate(lang, 'error_request')
        return (
            <Overlay title={translate(lang, 'error')} className={className}>
                <div className="col-group">
                    <div className="col-mb-12">
                        <p dangerouslySetInnerHTML={{__html:message}}></p>
                    </div>
                    <div className="col-mb-12 actions">
                        <Btn text={translate(lang, 'ok').toUpperCase()} className="error" callback={() => {
                            this.props.showOverlay(null)
                        }} />
                    </div>
                </div>
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ErrorOverlay))
