import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'

const SwitchButton = ({ label, active, loading, callback, className }) => {
    const [position, setPosition] = useState('off')

    useEffect(() => {
        if (active) {
            setPosition('on');
        } else {
            setPosition('off')
        }
    }, [active])

    return (
        <div className={`component_SwitchButton ${className}`} onClick={() => callback()}>
            <div className={`switch ${position}`}>
                <div className={`button ${position}`}>
                    {loading && <div className="loader"><SVG src='/static/images/loading_icn.svg' /></div>}
                </div>
            </div>
            {label && <div className="label">{label}</div>}
        </div>
    )
}

export default SwitchButton
