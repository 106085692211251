import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'

export default class CallToActionBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <div className="component_CallToActionBox">
                <h2>{this.props.title}</h2>
                {this.props.text ? <p>{this.props.text}</p> : null}
                <Btn text={this.props.btnText} callback={this.props.callback} />
            </div>
        )
    }
};
