import React, { useState } from 'react'
import { Formik, Form, Field } from "formik"
import Cookies from 'js-cookie'

import { translate } from '../../Translations/translate'
import LoginSchema from '../../FormValidations/LoginSchema'
import {accessLogin} from '../../Services/Access/access'

import FormField from './FormField'
import Btn from '../Btn/Btn'
import FormCheckbox from './FormCheckbox'

const LoginForm = ({ props }) => {
  const { lang } = props.match.params
  const { showOverlay, setUser, setUserType, setToken, setIsLogged, history, data } = props

  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)

  const handleFormError = (error) => {
    if (error.includes(`The username/password combination doesn't exist`)) {
      setFormError(translate(lang, 'error_login_combination'))
    } else {
      setFormError(error)
    }
  }

  const handleLogin = (values) => {
    setLoading(true)
    accessLogin({
      remember: values.remember ? 1 : 0,
      username: values.username,
      password: values.password,
      lang
      }).then((res) => {
       setLoading(false)

        if (!res.data.success) {
            console.log('ERROR: ' + res.data.message)
            handleFormError(res.data.message)
            return
        }

        let data = res.data.data

        setUser(data.user)
        setUserType(data.user.type)
        setToken(data.user.session)
        setIsLogged(true)

        Cookies.set('token', data.user.session, {expires: 7})

        showOverlay(null)
        
        if (props.data.fromApplyCallback) {
            props.data.fromApplyCallback()
        } else {
          if (data.user.type === 'chef') {
              history.push(`/${lang}/chef/profile/home`)
          } else if (data.user.type === 'restaurant') {
              history.push(`/${lang}/restaurant/profile/home`)
          }
        }
    }).catch((e) => {
        console.log('ERROR: ' + e.message)
        handleFormError(e.message)
    })
  }

  return (
    <div>
      <Formik
        initialValues={{
          username: data.email || '',
          password: '',
          remember: false,
        }}
        validationSchema={LoginSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleLogin(values)}
      >{({ values, errors, handleSubmit, handleChange }) => (
          <Form>
            <FormField
              id="username"
              type="email"
              name="username"
              label={translate(lang, 'email')}
              placeholder={translate(lang, 'email_placeholder')}
              value={values.username}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <FormField
              id="password"
              type="password"
              name="password"
              label={translate(lang, 'password')}
              placeholder={translate(lang, 'password_placeholder')}
              values={values.password}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <div className="forgotPassword">
                <a onClick={() => {showOverlay('resetpassword')}}>
                  {translate(lang, 'forgot_password')}
                </a>
            </div>
            <div className="rememberMe">
              <FormCheckbox
                id="remember"
                name="remember"
                label={translate(lang, 'remember_me')}
                checked={values.remember}
                onChange={handleChange}
                errors={errors}
              />
            </div>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            <div className="loginButton">
              <Btn
              text={translate(lang, 'login').toUpperCase()}
              loading={loading}
              callback={() => handleSubmit()}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm