export const memberships_en = {
    m1: {
        title: 'METHOD 1',
        pricing: 'FREE',
        subtitle: 'Free Sign Up',
        items: [
            'Create your company profile and Ads for free',
            'LINK technology will quickly locate the most relevant Chefs for your role',
            'Easy to create and share your Ads on social ',
            'Advanced filters to optimise search results ',
            'Select your audience, and receive only Cvs that are relevant to your business. ',
            'Search for Chefs in our network'
        ],
        fees: '(3% recruitment fee will apply)'
    },
    m2: {
        title: 'METHOD 2',
        pricing: '£600 PER YEAR',
        subtitle: 'Premium',
        popular: 'MOST POPULAR',
        items: [
            'Premium advertising space',
            'Email and social media marketing',
            'Search for Chefs in our network',
            'Direct message with our Chefs',
            'Receive Chefs Cvs directly to your inbox',
            'Control all your Restaurants and Job Offers from one easy-to-use platform',
            'Add more Premium Restaurants'
        ],
        fees: '(+ £400 per restaurant)'
    },
}
