export const fr = {
        
    about: "Sur",
    about_chef: "À propos du chef",                
    about_restaurant: "A propos du restaurant",
    about_us: "À propos de nous",
    accept: "Acceptez",
    accepted: "Accepté",
    action_completed: 'Action terminée',
    active: "Actif",
    active_NUM: "Actif (%@)",
    active_offers: "Offres actives",
    active_offers_NUM: "Offres actives: %@",
    ad_manager: "Gestionnaire d'annonces",
    add: "Ajouter",
    add_address: "Ajoutez l'adresse",
    add_email: "Ajouter un email",
    add_media: "Ajouter des médias",
    add_phone_number: "Ajouter un numéro de téléphone",
    add_restaurant: "Ajouter un restaurant",
    add_social_network: "Ajouter un réseau social",
    add_to_job_description: "Ajouter à la description du poste",
    address: "Adresse",
    ads: "Les publicités",
    all_the_tools_you_need: "Tous les outils dont vous aurez besoin",
    already_member: "Déjà membre?",
    and: " et ",
    applic: 'Applic.',
    applicants: "Candidats",
    applicants_NUM: '(%@) Candidats',
    application_confirmation: "Confirmation de l'application",
    application_form: "Formulaire de demande",
    application_sent: "Demande envoyée",
    applications: "Applications",
    applications_NUM: "Applications (%@)",
    applied_on: "Appliqué sur",
    apply: "Appliquer",
    apply_jobs_content: "Les offres d'emploi affichées sur notre site Web ne sont qu'un résumé des informations les plus importantes et nécessaires. En postulant simplement à l’offre d’emploi, le restaurant sera informé de votre intérêt pour cet emploi et recevra une description complète de votre profil.",
    apply_jobs_subtitle: "Rechercher des offres d'emploi dans notre réseau",
    apply_jobs_title: 'Postuler à des emplois',
    attach_file: "Pièce jointe",
    
    back: 'Retour',
    back_to_active_chefs: "Retour aux chefs actifs",
    back_to_all_offers: "Retour à toutes les offres",
    back_to_lastest_offers: "Revenir aux dernières offres",
    back_to_restaurants_list: "Retourner à la liste des restaurants",
    block: 'Bloquer',
    block_page: 'Bloquer la page',
    block_this_page: 'Bloquer cette page pour que votre profil ne soit pas vu',
    blog: "Blog",
    book_stage: "Réservez une stage avec nous!",        
    browse: "cherche",
    
    calendar: "Calendrier",
    callback: "Rappeler",
    cancel: "Annuler",
    center: "Centre",
    chef_profile: "Profil du chef",
    chef_requirements: "Les exigences du chef",
    chef: "Chef",
    chef_faqs: "FAQ du chef",
    chef_services: 'Chef Services',
    chefs: "Chefs",
    chefs_active_NUM: "Chefs Actifs (%@)",
    choose_file: "Choisir le fichier",
    choose_image: "Choisir une image",
    choose_profile_type: "Il est essentiel que vous ne commettiez pas d'erreur dans le choix de votre type de profil,",
    click_date: 'Cliquez sur une date pour visualiser les détails de votre demande',
    closed: "Fermé",
    company: "Entreprise",     
    contact: "Contact",
    contact_email: "Email du contact",
    cookie_policy: "Politique de cookie",
    copy_link: "Copier le lien",
    copyright: "2018. CHEFS LINK. TOUS LES DROITS SONT RÉSERVÉS.",
    cover_picture: "Photo de couverture",
    covers: "Des couvertures",
    continue: "Continuer",
    continue_later: "Continuer plus tard",
    create_account: "Créer un compte",
    create_account_description_1: "Si vous n'avez pas encore de compte utilisateur créé par chefslink.com, utilisez cette option pour accéder au formulaire d'inscription.",
    create_account_description_2: "Saisissez votre adresse électronique et nous vous demanderons les informations essentielles pour créer un compte. Vous pourrez compléter votre profil plus tard.",
    create_page: 'Créer une page',
    create_page_content: 'Ces informations nous fourniront tous les détails nécessaires pour comprendre les besoins de votre entreprise. Cela nous permet de nous mettre directement au travail.',
    create_page_title: 'Aidez-nous à comprendre vos besoins',
    create_profile: 'Créer un profil',
    create_profile_content: 'Ces informations ne seront pas seulement affichées sur notre site Web pour que les Restaurants puissent les voir, elles fourniront également à notre équipe toutes les informations nécessaires pour vous aider à trouver le meilleur emploi possible.',
    create_profile_title: 'Annonce facile, pas à pas sur notre site Web',
    create_restaurant: "Créer un restaurant",
    create_your_profile: "Créez votre profil et faites-vous remarquer!",
    create_your_profile_text: "Établissez-vous dans les restaurants les plus exclusifs.",
    cuisine: "Cuisine",    
    cuisines: "Cuisines",
    currency: "Devise",
    currently: 'Actuellement',
    currently_studying: "Actuellement j'étudie",
    currently_work_here: "Je travaille actuellement ici",
    curriculum_vitae: "Curriculum vitae",
    
    date: "Date",
    date_of_birth: "Date de naissance",
    days_off: "Jours de congés:",
    default_email_message: "Message électronique par défaut pour les chefs",
    default_language: "Langage par défaut",
    delete: "Effacer",
    delete_application: 'Voulez-vous supprimer cette application?',
    delete_conversation: '¿Voulez-vous supprimer la conversation? Cette action est irréversible',
    delete_offer: "Voulez-vous supprimer l'offre?",
    description: "La description",
    details: "Détails",
    dinner: "Dîner",
    direct_message_content_chef: "Notre système de messagerie facilite la discussion ou le partage de fichiers directement avec Premium Restaurants sur notre site Web au cas où vous auriez besoin de plus d'informations sur les offres d'emploi qu'ils ont publiées.",
    direct_message_content_restaurant: "En tant que client Premium, vous pouvez discuter directement avec nos chefs au cas où vous auriez besoin de plus d’informations, planifiez une période d'éssai ou simplement voir si le chef pourrait être intéressé à vous rejoindre dans votre entreprise.",
    direct_message_subtitle_chef: "Besoin de plus d'informations",
    direct_message_subtitle_restaurant: "Pour plus d'informations",
    direct_message_title: 'Message direct',
    double_check: "Vérifiez ce qui va arriver!",
    drop_file_here: "Déposez votre fichier ici ou ",
    drop_us_a_line: "Écrivez-nous",
    
    edit: "Modifier",
    edit_about: "Modifier à propos de",
    edit_address: "Modifier l'adresse",
    edit_chef: "Modifier Chef",
    edit_cover: "Modifier la couverture",
    edit_education: "Modifier l'éducation",
    edit_email: "Editer Email",
    edit_language: "Modifier la langue",
    edit_more_information: "Modifier plus d'informations",
    edit_offer: "Modifier l'offre",
    edit_opening_hours: "Modifier les heures d'ouverture",
    edit_page: "Modifier la page",
    edit_phone_number: "Modifier le numéro de téléphone",
    edit_settings: "Modifier les paramètres",
    edit_social_network: "Modifier le réseau social",
    edit_work_experience: "Modifier l'expérience de travail",
    education: "Éducation",
    education_training: "Education et formation",
    email: "Email",
    email_exists: "Cet e-mail est déjà utilisé, veuillez cliquer sur \"Mot de passe oublié\" pour réinitialiser votre mot de passe. Vous recevrez un e-mail pour réinitialiser votre mot de passe. Vous pouvez également nous envoyer un courriel à info@chefslink.com",
    email_placeholder: 'Présentez votre email',
    email_reset_password: "Nous vous avons envoyé un email pour réinitialiser le mot de passe",
    enquiry_subject: "Sujet d'enquête",
    error: "Désolé, il y a eu une erreur",
    error_request: "Il y a eu une erreur lors du traitement de votre demande. Veuillez réessayer.",
    
    faq: "FAQ",
    faqs: "Questions Fréquentes",
    faq_chefs: "Questions Fréquentes du Chefs",
    faq_restaurants: "Questions Fréquentes du Restaurants",
    file_attached: "Fichier joint",
    filter_by: "Filtrer par",
    file_selected: "Vous avez sélectionné",
    find_chefs: "Trouver des chefs",
    find_chefs_now: "Trouvez des chefs maintenant:",
    find_jobs: "Trouver des emplois",
    find_jobs_now: "Trouver des emplois maintenant:",
    find_restaurants: 'Trouver des restaurants',
    find_restaurants_now: "Trouver des restaurants maintenant:",
    food_standards: "Normes alimentaires",
    forgot_password: "Mot de passe oublié?",
    from: "De",
    future_opportunities: "Opportunités futures",
    
    get_in_touch: "Entrer en contact",
    go: "Aller",
    go_back: "Retourner",
    guru_template: "Modèle de gourou",
    
    head_chef: "Chef de cuisine",
    header: 'Entête',
    headline: "Gros titre",
    health_safety: "Santé et sécurité",
    help_job_description: "Besoin d'aide pour rédiger une description de travail?",
    help_job_description_text: 'Cliquez sur les éléments qui décrivent le mieux vos objectifs de recrutement et modifiez ensuite les détails.',
    help_recruit: "Aidez-nous à recruter pour ce poste (des frais de recrutement de 3% seront appliqués)",
    hide: "Cacher",
    hide_menu: "Masquer le menu",
    hide_salary: "Masquer le salaire",
    holidays: "Vacances:",
    home: "Accueil",
    how_do_we_work: "Comment travaillons nous",
    how_do_we_work_chefs: "Comment travaillons-nous pour les chefs?",
    how_do_we_work_chefs_text: "Chefs Link est un service gratuit pour tous nos chefs à la recherche d'un emploi. Aucun risque et aucun paiement n'est nécessaire. Nous avons accès à un vaste réseau de restaurants offrant les meilleures opportunités de carrière possibles sur le marché de la restauration. Des opportunités qui promettent de dynamiser votre carrière de chef et vous permettront de travailler aux côtés de certains des meilleurs chefs du monde et d'être encadrés par ceux-ci. Nous nous engageons à vous aider à établir et à gérer votre carrière de chef.",
    how_do_we_work_restaurants: "Comment travaillons-nous pour les restaurants?",
    how_do_we_work_restaurants_text: "Nous consacrons notre temps à trouver les chefs les plus talentueux de nos restaurants par le biais de nos réseaux, de notre marketing et de bonnes relations avec certaines des meilleures écoles et universités culinaires du monde. Cela nous permet de trouver le talent qui répondra à vos besoins spécifiques. Notre société est conçue pour éliminer les tracas et les frustrations généralement associés au processus de recrutement en le rendant aussi simple que possible pour vous et votre entreprise.",
    
    import_template_NAME: 'Voulez-vous importer le modèle "%@"?',
    in: "en",
    in_review: "En revue",        
    inactive: "Inactif",
    inactive_NUM: "Inactif (%@)",
    info: "Info:",
    invalid_email: "Email non valide",
    
    job_description: "Description de l'emploi",
    job_offers: "Offres d'emplois",
    job_offers_NUM: "Offres d'emplois (%@)",
    job_offers_you_saved: "Ce sont les offres d'emploi que vous avez enregistrées!",
    join_our_network_today: "Rejoignez notre réseau aujourd'hui!",
    join_the_community: "Ou vous pouvez rejoindre la communauté et ",
    
    language: "La langue",    
    languages: "LangLes languesuages",
    last_job_offers_NUM: "Dernières offres d'emploi (%@)",
    last_login: "Dernière connexion",
    latest_job_offer_alerts_content: "Vous recevrez une alerte électronique hebdomadaire avec toutes les dernières offres d'emploi qui ont été automatiquement associées à votre profil en fonction des informations que vous avez fournies.",
    latest_job_offer_alerts_title: "Dernières alertes sur les offres d'emploi",
    latest_job_offer_alerts_subtitle: 'Offres liées à votre profil',
    launch_guru_template: "Lancer le template Guru",
    less: "- Moins",
    level: "Niveau",        
    link: "Lien",
    link_now: 'Lien maintenant',
    link_offer: "Lien pour l'offre",
    link_tech_content_chef: 'Le profilage intelligent de notre site Web vous mettra automatiquement en lien avec les restaurants qui vous intéressent. Il ne vous montrera que les offres qui correspondent à votre profil, vous évitant ainsi de perdre du temps ou de passer des heures à chercher des offres qui ne vous intéressent pas.',
    link_tech_content_restaurant: 'Le profilage intelligent de notre site Web vous mettra automatiquement en contact avec les chefs qui comptent le plus pour votre entreprise, vous évitant ainsi de perdre du temps à chercher des centaines de CV qui ne vous intéressent pas.',
    link_tech_subtitle_chef: 'Vous connecter avec les bonnes personnes',
    link_tech_subtitle_restaurant: 'Vous connecter avec les bons chefs',
    link_tech_title: 'Technologie de liaison',
    link_to_our_page: "Lien vers notre page pour voir nos dernières offres d'emploi en haut de votre fil d'actualités et recevoir une notification lorsque nous publions une nouvelle offre d'emploi.",
    link_with_us: 'Lien avec nous',
    links: "Liens",
    links_accepted_NUM: "Vous avez %@ lien%@ accepté!",
    links_for_you: "Des liens pour vous!",
    links_pending_NUM: "Vous avez %@ lien%@ en attente!",
    links_rejected_NUM: "Vous avez %@ lien%@ rejeté!",
    load_more_items: "Charger plus d'articles",
    loading: 'Chargement',
    location: "Emplacement",
    log_in: "S'identifier",
    login: "S'identifier",
    logout: "Se déconnecter",
    lunch: "Le déjeuner",
    
    manage: "Gérer",
    manage_pages: "Gérer les pages",
    manage_your_pages: "Gérer vos pages",
    management: "La gestion",
    me: "Moi",
    media: "Médias",
    member_login: "Identifiant",
    membership_requested: 'Adhésion demandée',
    menu: 'Menu',
    mess: "Mess.",
    message: "Message",
    message_from_restaurant: "Message du restaurant",
    message_sent: "Message envoyé",
    message_was_sent: "Le message a été envoyé",
    messages: "Messages",
    messages_from: 'Messages de',
    messages_overview: "Vue d'ensemble de vos messages",
    miniature: 'Miniature',
    month: "Mois",
    more: "+ Plus",
    moremore: "Plus",
    total_applications_NUM: "Total des applications (%@)",
    more_info: "Plus d'information",
    my_jobs_NUM: 'Mes emplois (%@)',
    my_profile: "Mon profil",
    
    name: "Prénom",
    name_placeholder: "Entrez votre nom",
    nationalities: "Nationalités",
    negotiable_salary: " - Salaire négociable",
    new_password: "Nouveau mot de passe",
    new_trial_date: "Nouvelle date:",
    no_accepted_applications: "Il n'y a pas d'applications acceptées",
    no_accepted_links: "Il n'y a pas de liens acceptés",
    no_active_offers: "Il n'y a pas d'offres actives",
    no_emails_allowed: ' -PAS DE COURRIELS AUTORESÉS- ',
    no_in_review_applications: "Il n'y a pas d'applications en cours de révision",
    no_information: "Aucune information",
    no_job_offers: "Il n'y a pas d'offres d'emploi",
    no_message: "Pas de message",
    no_messages: "Il n'y a pas de message",
    no_notifications: "Il n'y a pas de notifications",
    no_pending_applications: "Il n'y a pas d'applications en attente",
    no_pending_links: "Il n'y a pas de liens en attente",
    no_rejected_applications: "Il n'y a pas de demandes rejetées",
    no_rejected_links: "Il n'y a pas de liens rejetés",
    no_results: "Il n'y a pas de résultats pour votre recherche",
    no_saved_offers: "Il n'y a pas d'offres sauvegardées",
    no_sent_applications: "Il n'y a pas d'applications envoyées",
    no_stages_available: "Il n'y a pas de stages disponibles",
    no_time: "Aucune heure n'a été spécifiée",
    no_trials: "Il n'y a pas d'applications",
    no_urls_allowed: "-PAS D' URLS AUTORISE-",
    not_available: "indisponible",
    not_logged_in: "Vous devez être connecté pour continuer. Pas encore membre? <strong>Créez un compte gratuit</strong> et accédez à des offres illimitées, posez votre candidature et contactez directement les restaurants du monde entier.",
    notifications: "Notifications",
    
    offer_not_available: "Cette offre n'est pas disponible.",
    offer_saved: "L'offre a été enregistrée",
    offer_sent: 'Offre envoyée',
    offer_sent_to_client: "L'offre a été envoyée au client",
    offers: "Offres",
    ok: "D'accord",
    only_cv_subject: 'Demande d\'offre',
    only_cv_subject_plural: 'Demande d\'offres',
    only_cv_text: 'édité par le restaurant',
    only_cv_text_intro: 'Je souhaite envoyer mon CV pour solliciter l\'offre suivante:',
    only_cv_text_intro_plural: 'Je souhaite envoyer mon CV pour solliciter les offres suivantes:',
    opening_hours: "Heures d'ouverture",
    other_duties: "Autres devoirs",
    
    password: "Mot de passe",
    password_changed: "Votre mot de passe a été changé",
    password_placeholder: 'Introduire votre mot de passe',
    pause: "Pause",
    paused: "En pause",
    pending: "En attendant",
    pending_applicants_NUM: "%@ demandeurs en attente",
    person: "La personne",
    phone: "Téléphone",
    phone_code_number: "Code téléphonique",
    phone_number: "Numéro de téléphone",
    phone_placeholder: "Entrez votre numéro de téléphone",
    plan_that_works: "Un plan qui fonctionne",
    plan_that_works_business: "Un plan qui fonctionne pour votre entreprise",
    position: "Position",
    post_job_offers: "Publier des offres d'emploi",
    post_jobs: "Publier des emplois",
    post_jobs_content: "Créez autant d'offres que vous le souhaitez sur notre site Web et stockez même les annonces dans votre profil pour une utilisation future en recrutement. Grâce aux informations fournies dans les annonces, notre équipe de recrutement commence à rechercher des chefs et à vous envoyer des candidats éventuels.",
    post_jobs_subtitle: "Création rapide et facile d'annonces sur notre site Web",
    premium: 'Premium',
    preview: 'Aperçu',
    preview_as_restaurant: "Aperçu en tant que restaurant",
    previous_experience: "Expérience précédente",
    privacy_policy: "Politique de confidentialité",
    profile: "Profil",
    profile_type_placeholder: "Vous êtes un chef ou un restaurant?",
    profile_types: "Chef ou restaurant",
    propose_new_date: "Proposer une nouvelle date",
    proposed_offer: 'Offre proposée',
    published_on: "Publié le",

    questions: "Avez-vous des questions?",
    questions_text_1: "Nous avons préparé une liste des questions les plus fréquemment posées (FAQ) pour vous aider en cas de doute. Si vous ne trouvez pas la réponse",
    questions_text_2: "N'hésitez pas à nous contacter. Nous serons ravis de vous aider.",
    
    rating: "Évaluation",
    rating_type: "Type d'évaluation",
    reached_limit: "Vous avez atteint la limite de 10 chefs",
    read_less: "- Lire moins",
    read_more: "+ Lire plus",
    register_as: "S'inscrire en tant que",
    reject: "Rejeter",
    rejected: "Rejeté",
    rejected_trial_dates: "Malheureusement, le restaurant vous a refusé les dates proposées. Cependant, ils ont suggéré une autre date pour faire l'(%@). Veuillez accepter ou suggérer une autre date disponible.",        
    related_job_offers_NUM: "Offres d'emploi associées (%@)",
    remember_me: "Se souvenir de moi",
    remove_file: "Souhaitez-vous supprimer le fichier?",
    repeat_password: "Répéter le mot de passe",
    repeat_password_placeholder: "Répétez votre mot de passe",
    repeat_new_password: "Répété le nouveau mot de passe",
    report: "Rapport",
    report_page: "Signaler cette page à Chefs Link",
    request: "Demande",
    request_callback: "Demander un rappel",
    request_sent: "La demande a été envoyée",
    reset_password: "Réinitialiser le mot de passe",
    restaurant: 'Restaurant',
    restaurant_faqs: "FAQ du restaurant",
    restaurant_manager_email: 'E-mail pour recevoir des candidatures et des messages pour ce restaurant (facultatif)',
    restaurant_message: "Message du restaurant",
    restaurant_profile: "Profil du restaurant",
    restaurant_profiles: "Profils de restaurants",
    restaurant_proposed_offer: 'Le restaurant a proposé une offre',
    restaurant_services: 'Services de restaurant',
    restaurants: 'Restaurants',
    restaurants_list_NUM: "Liste de restaurants (%@)",
    
    salary: "Un salaire",
    salary_from: "Salaire de",
    salary_to: "Salaire à",
    save: "Sauvegarder",
    save_add_more: "Sauvegarder et ajouter plus",
    save_continue: "Sauvegarder et continuer",
    saved: "Enregistré",         
    search_chefs_content: "Tous les chefs de notre réseau ont un résumé de leur programme d’études avec uniquement les informations les plus pertinentes sur leur carrière en tant que chef, publiés sur notre site Web. Il vous suffit de sélectionner les profils de chefs qui correspondent à vos besoins et de leur envoyer une offre d'emploi.",
    search_chefs_subtitle: 'Besoin de chefs',
    search_chefs_title: 'Recherche de chefs',
    search_results: "Résultats de la recherche",
    search_thousands_offer: "Recherchez des milliers d'offres d'emploi pour les chefs",
    sector: "Secteur",
    select_audience_content: 'Lorsque vous vous inscrivez avec nous, nous vous donnerons la possibilité de sélectionner la nationalité et la langue de votre public. Cela permettra uniquement à certains chefs de voir et d’appliquer à vos offres d’emploi et d’afficher uniquement ces profils dans la section Trouver des chefs.',
    select_audience_subtitle: 'Qui voulez-vous embaucher',
    select_audience_title: 'Choisissez votre public',
    select_available_trial_dates: "Sélectionnez jusqu'à trois dates auxquelles vous êtes disponible",
    select_dates: "Sélectionnez les dates de début et de fin",
    select_file: "Sélectionnez un fichier dans votre médiathèque",
    select_new_trial_date: "Veuillez sélectionner une nouvelle date disponible",
    select_offer: "Sélectionnez une offre",
    select_status: "Sélectionnez un statut",
    select_type: "Sélectionnez le type",
    selected_chefs_NUM: "(%@) chefs sélectionnés pour cette offre",
    selected_chefs_NUM_mobile: "Chefs sélectionnés (%@)",
    selected_offers_NUM: "Vous avez sélectionné (%@) offres d'emploi",
    selected_offers_NUM_mobile: "Offres sélectionnées (%@)",
    selected_one_file: "Vous avez sélectionné un fichier",
    send: "Envoyer",
    send_cv: "Envoyer un CV",
    send_cv_only: "Envoyer CV uniquement",
    send_curriculum_vitae: "Envoyer un curriculum vitae",
    send_job_offer: "Envoyer une offre d'emploi",
    send_message: "Envoyer un message",
    send_message_to_restaurant: "Envoyer un message au restaurant",
    send_offer: "Envoyer une offre",
    sent: "Envoyé",
    sent_it: "Envoyé",
    sent_you_message: " vous a envoyé un message",
    services: "Prestations de service",
    services_chefs: "Services pour les chefs",
    services_restaurants: "Services pour les restaurants",
    settings: "Réglages",
    share: "Partager",
    share_on: "Partager sur",
    share_profile_content: "Vous pouvez partager ou télécharger votre profil en cliquant simplement sur le bouton Partager. Cela vous donnera quelques options pour télécharger au format Cv ou copier l’URL et l’envoyer en tant que pièce jointe dans une boîte de réception ou un e-mail de réseau social.",
    share_profile_subtitle: 'Ayez toujours votre profil" CV "avec vous.',
    share_profile_title: 'Partagez votre profil',
    show_menu: "Afficher le menu",
    showcase: 'Vitrine',
    sign_up: "S'inscrire",
    signup_terms: "La création d'un compte signifie que vous êtes d'accord avec notre ",
    smart_banner_apple: " - Sur l'App Store",
    smart_banner_free: "LIBRE",
    smart_banner_google: " - Dans Google Play",
    smart_banner_view: "VUE",
    social_network: "Réseau social",
    social_networks: "Réseaux sociaux",
    square: 'Case',
    staff_development: "Perfectionnement du personnel",
    stage: "Stage",
    stage_info: "Informations sur la stage",        
    start_adding_chefs: "Commencez à ajouter des chefs",
    start_adding_covers: "Commencez à ajouter des couvertures",
    start_date: "Date de début:",
    start_editing_opening_hours: "Commencez à modifier vos heures d'ouverture",
    status: "Statut",
    stay_up_to_date: "Tenez-vous à jour!",
    stay_up_to_date_profile: "Restez à jour avec votre profil!!",
    success: 'Succès',
    support: "Soutien",
    surname: "Nom de famille",
    switch: "Commutateur",
    switch_active_page: "Changer de page active",
    
    team: 'Équipe',
    template: "Modèle",
    term: "Terme:",
    text: "Texte",
    the_chef: "Le chef",
    them: "Eux",
    title: "Titre",
    to: "À",
    trial_date: "Date de l'(%@):",
    type: "Type",
    type_or_options: "Commencez à taper ou sélectionnez l'une de nos options",
    
    unblock: 'Débloquer',
    upgrade_premium: "Passer à la version premium",
    upload: "Télécharger",
    upload_cv: "Télécharger un CV",
    url: "URL",

    view_calendar: "Voir le calendrier",
    view_links: "Voir les liens",
    view_media: "Voir le média",
    
    w_h: "S/H:",
    w_hours: "S/Heures:",
    waiting_chef_response: "En attente de la réponse du chef",
    waiting_response: "En attente d'une réponse",
    waiting_restaurant_confirmation: "Nous attendons la confirmation du restaurant",
    waiting_restaurant_response: "En attente de la réponse du restaurant",
    weekly_days_off: "Jours de congé hebdomadaires",
    weekly_hours: "Heures hebdomadaires",
    when_available_to_start: "Si vous êtes accepté, quand êtes-vous disponible pour commencer?",
    work_experience: "L'expérience professionnelle",
    work_visas: "Visas de travail",
    write_new_message_here: "Écrivez votre nouveau message ici",
    
    year: "Année",
    your_conversation: "Votre conversation",
    your_enquiry: "Votre demande",
    your_message: "Votre message",
    your_job_offers_NUM: "Vos offres d'emploi (%@)",
     

    // Form error messages: (chef profile)
    error_email_required: 'L\'email est obligatoire',
    error_email_valid: 'L\'email doit être valide',
    error_password_required: 'Un mot de passe est requis',
    error_password_length: 'Le mot de passe doit comporter au moins 6 caractères',
    error_terms_required: 'Vous devez accepter nos conditions pour continuer',
    error_type_profile: 'Vous devez sélectionner un type de profil',
    error_name_letters: 'Le nom ne peut contenir que des lettres',
    error_name_length: 'Le nom doit comporter au moins 2 caractères',
    error_name_required: 'Le nom est obligatoire',
    error_phone_numbers: 'Le téléphone ne peut contenir que des chiffres',
    error_phone_length: 'Le numéro de téléphone doit comporter au moins 4 caractères',
    error_phone_required: 'Le téléphone est obligatoire',
    error_password_match: 'Les mots de passe ne correspondent pas',
    error_password_confirm: 'Confirmer le mot de passe',
    error_login_combination: 'La combinaison utilisateur/mot de passe n\'existe pas.',
    error_email_unique: 'L\'email est déjà utilisé',
    error_required_fields: 'Vous devez remplir les champs obligatoires',

    // Forms placeholders: (chef profile)
    placeholder_position: 'Sélectionnez votre position',
    placeholder_company_name: 'Ajouter le nom du centre',
    placeholder_company_url: 'Ajouter le site web du centre',
    placeholder_location: 'Ajouter un lieu',
    placeholder_select_type: 'Sélectionnez le type',
    placeholder_select_month: 'Sélectionnez le mois',
    placeholder_select_year: 'Sélectionnez l\'année',
    placeholder_education_title: 'Ajouter un titre d\'éducation',
    placeholder_center_name: 'Ajouter le nom du centre',
    placeholder_center_url: 'Ajouter le site web du centre',
    placeholder_select_language: 'Sélectionnez la langue',
    placeholder_select_level: 'Sélectionnez le niveau',
    placeholder_select_country: 'Sélectionnez le pays',

    // Chef profile literals:
    add_description: 'Ajoutez une description dans votre profil, afin que les gens vous connaissent un peu plus.',
    add_work_experience: 'Ajoutez votre expérience de travail.',
    add_education: 'Ajoutez votre formation.',
    add_languages: 'Ajoutez vos langues.',
    add_referrals: 'Ajouter plus d\'informations qui peuvent être d\'intérêt.',
    no_chef_info: 'Le chef n\'a pas encore ajouté d\'informations.',
    start_your_profile: 'Salut! Commencez à compléter votre profil.',
    start: 'Commencer',
    profile_status: 'Statut du profil',
    add_work_experience_extended: "Commencez à ajouter votre expérience de travail.<br>Vous pouvez en ajouter, modifier ou supprimer de votre page de profil.",
    add_education_extended: "Commencez à ajouter votre formation.<br>Vous pouvez en ajouter, modifier ou supprimer de votre page de profil.",
    add_languages_extended: "Commencez à ajouter vos langues.<br>Vous pouvez en ajouter, modifier ou supprimer de votre page de profil.",
    add_referrals_extended: "Ajouter plus d\'informations qui peuvent être d\'intérêt.<br>Vous pouvez en ajouter, modifier ou supprimer de votre page de profil.",

}