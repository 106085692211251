export function readableLocation(location) {
    if (location) {
        let name = [];
        location.address_components.forEach((component) => {
            component.types.forEach((type) => {
                if (type === 'locality') {
                    name.push(component.long_name)
                }
            })
            component.types.forEach((type) => {
                if (type === 'country') {
                    name.push(component.short_name)
                }
            })
        })
        return name.length > 0 ? name.join(', ') : location.formatted_address
    }
    return ''
}
