import React, {Component} from 'react'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg';
import SocialNetworks from '../SocialNetworks/SocialNetworks'
import LazyImage from '../../Components/LazyImage/LazyImage'
import {Helmet} from 'react-helmet';
import { translate } from '../../Translations/translate'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'


class Footer extends Component {
    componentDidMount () {
        const script = document.createElement("script");
        
        script.src = "https://cdn.iubenda.com/iubenda.js";
        script.async = true;
        
        document.body.appendChild(script);
    }
    render() {
        const { lang } = this.props.match.params

        return (
            <footer>
                <div className="container-fluid">
                    <div className="col-group">
                        <div className="col-mb-12 col-8 col-offset-2">
                            <div className="col-group">
                                <div className="col-mb-12 logo-wrapper">
                                    <div className="logo">
                                        <SVG src="/static/images/logo.svg" />
                                    </div>
                                </div>
                                <div className="col-mb-6 col-4">
                                    <div>
                                        <h5>{translate(lang, 'get_in_touch')}</h5>
                                        <ul>
                                            <li>
                                                (+34) 655 45 27 08
                                            </li>
                                            <li>
                                                <a href="mailto:info@chefslink.com">info@chefslink.com</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-mb-6 col-4">
                                    <div>
                                        <h5>{translate(lang, 'company')}</h5>
                                        <ul>
                                            <li>
                                                <NavLink to={`/${lang}/`}>{translate(lang, 'about_us')}</NavLink>
                                            </li>
                                            <li>
                                                <a href="https://www.iubenda.com/privacy-policy/35255572/cookie-policy" className="iubenda-nostyle iubenda-embed no-brand" title={translate(lang, 'cookie_policy')}>{translate(lang, 'cookie_policy')}</a>
                                            </li>

                                            <li>
                                                <a href="https://www.iubenda.com/privacy-policy/35255572" className="iubenda-nostyle iubenda-embed no-brand" title={translate(lang, 'privacy_policy')}>{translate(lang, 'privacy_policy')}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-mb-12  col-4">
                                    <div data-type="support">
                                        <h5>{translate(lang, 'support')}</h5>
                                        <ul>
                                            <li>
                                                <NavLink to={`/${lang}/faqs/restaurant`}>{translate(lang, 'faq_restaurants')}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/${lang}/faqs/chef`}>{translate(lang, 'faq_chefs')}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/${lang}/`}>{translate(lang, 'blog')}</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-group social-networks">
                                <div className="social-icons">
                                    <SocialNetworks />
                                    <span>&copy; {translate(lang, 'copyright')}</span>
                                </div>
                                <div className="store-buttons">
                                    <a target="_blank" href="https://apps.apple.com/us/app/chefslink/id1519459963"><LazyImage className="app-store" src={`/static/images/app-store-${lang}.png`} /></a>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.chefslinkapp"><LazyImage className="google-play" src={`/static/images/google-play-${lang}.png`} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
};


const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Footer))

// <NavLink to={`/${lang}/`}>Terms of Use</NavLink>
// <li>
//     <NavLink to={`/${lang}/`}>We're hiring</NavLink>
// </li>
