import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import SVG from 'react-inlinesvg';
import Geocode from "react-geocode";

let myTimer;

const FormSelectLocation = ({
  id,
  name,
  label,
  placeholder,
  errors,
  formError,
  showErrorMessage = true,
  reset,
}) => {
  const error = errors && errors.hasOwnProperty(name) ? errors[name] : null
  const [field, meta, helpers] = useField(name)
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  const handleChange = (value) => {
    helpers.setTouched(true)
    setInputValue(value)
  }

  const handleSelectLocation = (selected) => {
    helpers.setValue(selected)
    helpers.setTouched(false)
    setInputValue('')
    setOpen(false)
  }

  const loadOptions = (input) => {
    if (!input) {
        setOptions([])
        setOpen(false)
        return
    }
    clearTimeout(myTimer)

    myTimer = setTimeout(() => {
        Geocode.fromAddress(input).then(
          response => {
              let items = []
              response.results.forEach((r) => {
                  items.push({value: r, label: r.formatted_address})
              })
              setOptions(items)
              setOpen(true)
          },
          error => {
            setOptions([])
            setOpen(false)
          }
        );
    }, 200)
  }

  useEffect(() => {
    loadOptions(inputValue)
  }, [inputValue])
  
  useEffect(() => {
    if (reset) {
      const input = document.getElementById('inputLocation')
      input.value = ''
    }
  }, [reset])

  useEffect(() => {
    Geocode.setApiKey('AIzaSyDnMH5wbKMpJWpumoqFFSZy45Pp2nsW734')
  }, [])

  return (
    <div style={{ position: 'relative' }} className="component_FormSelect">
      <label className="inputLabel" htmlFor={id}>
        {label}
      </label>
      <div className='selectContainer'>
        <div className={`select ${error || formError ? 'error' : ''}`}>
          <input id="inputLocation" placeholder={placeholder} value={meta.touched ? inputValue : `${field.value.formatted_address || ''}`} onChange={e => handleChange(e.target.value)}/>
          <div className="arrowContainer">
            <div className={`arrowIcon ${open ? 'open' : ''}`}>
              <SVG src="/static/images/arrow_simple.svg" />
            </div>
          </div>
        </div>
        <ul className={`options ${open ? 'open' : ''}`}>
          {options.map(option => (
            <li key={option.value} className="option" onClick={() => handleSelectLocation(option.value)}>{option.name || option.label}</li>
          ))}
        </ul>
        {error && showErrorMessage && <div className="errorMessage">{error}</div>}
      </div>
    </div>
  )
}

export default FormSelectLocation