import React, {Component} from 'react'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'

class ShowcaseImages extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {

        let className = 'component_ShowcaseImages'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        return (
            <div className={className}>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        if (this.props.deviceScreen === 'mobile') {
            return (
                <div className="items">
                    {this.props.item1}
                    {this.props.item2}
                    {this.props.item3}
                </div>
            )
        } else {
            return (
                <div className="col-group">
                    <div className="col-mb-4">
                        {this.props.item1}
                    </div>
                    <div className="col-mb-4">
                        {this.props.item2}
                    </div>
                    <div className="col-mb-4">
                        {this.props.item3}
                    </div>
                </div>
            )
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ShowcaseImages))
