export const chefFaqs_en = [
    {
        q: 'Does your service cost any money?',
        t: 'Chefs Link is a free service for all our Chefs looking for Jobs. No risk and no payment is needed whatsoever. However, we do ask all our Chefs to respect the timing and attendance requirements of all the arranged interviews. '
    },
    {
        q: 'Why can I not see all the Jobs available? ',
        t: 'Our system will detect only the Restaurants that are relevant to your Profile. The two main features are Language and Nationality. This will enable you to send your application only to Restaurants that are interested in your Profile and will not waste your time when submitting your application to Restaurants that will never contact you back.'
    },
    {
        q: 'Do I have to create a profile to find a job?',
        t: 'It is not necessary. You can forward us your CV in the Contact Section or when you apply for jobs, and one of our team members will be more than happy to help you. However, we strongly recommend you create a Profile with us. It is a very simple step-by-step process and will only take a few minutes of your time. This information will provide us with all the necessary details we need to help you find the best possible Job. By doing so, it allows us to get straight to work and reduce the number of frustrating emails and calls going back and forward usually experienced during the recruitment process.'
    },
    {
        q: 'Can I post jobs on your website? ',
        t: 'You will need to create a Business page on our website, or if your company already has a Business page, you can ask the Website Admin to give you your login details. '
    },
    {
        q: 'What happens when I turn my profile off? ',
        t: 'We allow our Chefs to decide when they want their profiles to show on our website. You will still be able to apply for jobs, receive our weekly job offers and use your profile as usual, even though your profile has been turned off. The only thing turning your profile off does, is restricts restaurants being able to see your profile or send you job offers. '
    },
    {
        q: 'Why can I only message certain restaurants? ',
        t: 'Our system only allows you to direct message with our premium clients. '
    },
    {
        q: 'Why is my profile still in Review? ',
        t: 'It can be one of two reasons: <ul><li>We are still dealing with your application; After creating your profile, please leave at least 24 hours before contacting Chefs Link</li><li>Your application is incomplete; please complete your form fully and submit it again</li></ul>'
    },
    {
        q: 'I don’t want a particular restaurant to see my profile?',
        t: 'You can block a restaurant from seeing your profile by going to the restaurant’s profile and click on the more button in the function bar. A drop-down menu will give you a few options on blocking the restaurant.'
    },
    {
        q: 'What Restaurants do you work with?',
        t: 'You can find all the restaurants we work with on our homepage under Job Offers or Restaurant Profiles. '
    },
    {
        q: 'Do you help me with accommodation?',
        t: 'No, but some restaurants do provide accommodation with your contract. '
    },
    {
        q: 'How much will I get paid?',
        t: 'It will all depend on your experience working as a Chef. By joining our network, we dedicate our time to match your experience to the best possible Restaurants in our network. Our team will also stay in contact with you and manage your career as you gain more experience.'
    },
    {
        q: 'Can you send me some more information about the job?',
        t: 'We will be more than happy to do that. Please let us know what your concerns are or what other information you would like us to provide you with, and we will answer them as best we can. '
    },
    {
        q: 'I have no experience in the kitchen. Is this an Issue?',
        t: 'We are sorry to inform you, but unfortunately, we will not be able to help you at this point. Our Restaurants demand only experienced or qualified Chefs.'
    },
]
