export const pt = {
        
    about: "Sobre",
    about_chef: "Sobre o Chef",                
    about_restaurant: "Sobre o restaurante",
    about_us: "Sobre nós",
    accept: "Aceitar",
    accepted: "Aceitaram",
    action_completed: "Ação completada",
    active: "Ativo",
    active_NUM: "Ativo (%@)",
    active_offers: "Ofertas ativas",
    active_offers_NUM: "Ofertas Ativas: %@",
    ad_manager: "Gerente de anúncios",
    add: "Adicionar",
    add_address: "Adicione o endereço",
    add_email: "Adicionar email",
    add_media: "Adicionar mídia",
    add_phone_number: "Adicionar número de telefone",
    add_restaurant: "Adicionar restaurante",
    add_social_network: "Adicionar rede social",
    add_to_job_description: "Adicione a descrição do trabalho",
    address: "Endereço",
    ads: "Publicidades",
    all_the_tools_you_need: "Todas as ferramentas que você precisa",
    already_member: "já é um membro?",
    and: " e ",
    applic: 'Aplic.',
    applicants: "Candidatos",
    applicants_NUM: '(%@) Candidatos',
    application_confirmation: "Confirmação de aplicação",
    application_form: "Formulário de aplicação",
    application_sent: "Aplicativo enviado",
    applications: "Aplicações",
    applications_NUM: "Aplicações (%@)",
    applied_on: "Aplicado em",
    apply: "Aplique",
    apply_jobs_content: "As ofertas de emprego publicadas em nosso site são um resumo das informações mais importantes e necessárias. Ao se inscrever na Oferta de Emprego, o restaurante será notificado de que você está interessado nesse trabalho e receberá uma descrição completa do seu perfil.",
    apply_jobs_subtitle: 'Busca por ofertas de emprego em nossa rede',
    apply_jobs_title: 'Se inscrever em empregos',
    attach_file: "Anexar arquivo",
    
    back: 'De volta',
    back_to_active_chefs: "Volte para os chefs ativos",
    back_to_all_offers: "Voltar para todas as ofertas",
    back_to_lastest_offers: "Voltar às últimas ofertas",
    back_to_restaurants_list: "Volte para a lista de restaurantes",
    block: 'Bloquear',
    block_page: 'Bloquear página',
    block_this_page: "Bloquear esta página ao ver seu perfil",
    blog: "Blog",
    book_stage: "Reserve um estágio com a gente!",        
    browse: "procura",
    
    calendar: "Calendário",
    callback: "Ligue de volta",
    cancel: "Cancelar",
    center: "Centro",
    chef_profile: "Perfil do Chef",
    chef_requirements: "Requisitos do Chef",
    chef: "Chefe de cozinha",
    chef_faqs: "FAQS de chef",
    chef_services: 'Chef Services',
    chefs: "Chefs",
    chefs_active_NUM: "Chefs ativos (%@)",
    choose_file: "Escolher arquivo",
    choose_image: "Escolher imagem",
    choose_profile_type: "É essencial que não cometa um erro ao escolher o seu tipo de perfil,",
    click_date: 'Clique em uma data para ver os detalhes da sua avaliação',
    closed: "Fechado",
    company: "Empresa",     
    contact: "Contato",
    contact_email: "Email de contato",
    cookie_policy: "Política de Cookies",
    copy_link: "Link de cópia",
    copyright: "2018. CHEFS LINK. TODOS OS DIREITOS RESERVADOS.",
    cover_picture: "Foto de capa",
    covers: "Capas",
    continue: "Continuar",
    continue_later: 'Continuar mais tarde',
    create_account: "Criar conta",
    create_account_description_1: "Se ainda não tiver uma conta de utilizador criada pelo chefslink.com, utilize esta opção para aceder ao formulário de registo.",
    create_account_description_2: "Introduza o seu endereço de correio electrónico e pedir-lhe-emos as informações essenciais para criar uma conta. Poderá completar o seu perfil mais tarde.",
    create_page: 'Crie uma página',
    create_page_content: 'Essas informações nos fornecerão todos os detalhes necessários para entender os requisitos da sua empresa. Ao fazer isso, nos permite ir direto ao trabalho.',
    create_page_title: "Ajude-nos a entender suas necessidades",
    create_profile: 'Criar um perfil',
    create_profile_content: 'Essas informações não só serão publicadas em nosso website para os restaurantes, mas também fornecerão à nossa equipe todas as informações necessárias para ajudá-lo a encontrar o melhor emprego possível.',
    create_profile_title: 'Anúncio passo a passo fácil no nosso site',
    create_restaurant: "Criar restaurante",
    create_your_profile: "Crie seu perfil e seja notado!",
    create_your_profile_text: "Estabeleça-se nos restaurantes mais exclusivos.",
    cuisine: "Cozinha",    
    cuisines: "Cozinhas",
    currency: "Moeda",
    currently: 'Atualmente',
    currently_studying: "Atualmente, estou estudando",
    currently_work_here: "Eu trabalho aqui atualmente",
    curriculum_vitae: "Currículo",
    
    date: "Data",
    date_of_birth: "Data de nascimento",
    days_off: "Dias de folga:",
    default_email_message: "Mensagem de email padrão para chefs",
    default_language: "Idioma padrão",
    delete: "Excluir",
    delete_application: 'Deseja excluir este aplicativo?',
    delete_conversation: 'Deseja excluir a conversa? Esta ação é irreversível',
    delete_offer: "Você deseja excluir a oferta?",
    description: "Descrição",
    details: "Detalhes",
    dinner: "Jantar",
    direct_message_content_chef: 'Nosso sistema de mensagens facilita o bate-papo ou o compartilhamento de arquivos diretamente com os Premium Restaurants em nosso site, caso você precise de mais informações sobre as ofertas de emprego que eles postaram.',
    direct_message_content_restaurant: 'Como cliente Premium, você pode conversar diretamente com nossos Chefes, caso precise de mais informações, agende um período experimental ou simplesmente veja se o Chef pode estar interessado em se juntar a você em sua empresa.',
    direct_message_subtitle_chef: 'Precisa de mais informação',
    direct_message_subtitle_restaurant: 'Para maiores informações',
    direct_message_title: 'Mensagem direta',
    double_check: "Verifique o que está por vir!",
    drop_file_here: "Arraste aqui o seu ficheiro ou ",
    drop_us_a_line: "Deixe-nos uma mensagem",
    
    edit: "Editar",
    edit_about: "Editar sobre",
    edit_address: "Editar endereço",
    edit_chef: "Editar Chef",
    edit_cover: "Editar capa",
    edit_education: "Editar educação",
    edit_email: "Editar email",
    edit_language: "Editar idioma",
    edit_more_information: "Editar mais informações",
    edit_offer: "Editar oferta",
    edit_opening_hours: "Editar horário de funcionamento",
    edit_page: "Editar Página",
    edit_phone_number: "Editar número de telefone",
    edit_settings: "Editar configurações",
    edit_social_network: "Editar rede social",
    edit_work_experience: "Editar experiência de trabalho",
    education: "Educação",
    education_training: "Educação e Treinamento",
    email: "O email",
    email_exists: "Este e-mail já está em uso, por favor clique em esquecer a sua palavra-passe para redefinir a sua palavra-passe. Receberá um e-mail para redefinir a sua palavra-passe. Ou pode enviar-nos uma mensagem de correio electrónico para info@chefslink.com",
    email_placeholder: 'Introduza o seu email',
    email_reset_password: "Nós lhe enviamos um email para redefinir a senha",
    enquiry_subject: "Assunto de inquérito",
    error: "Desculpe, houve um erro",
    error_request: "Houve um erro ao processar seu pedido. Por favor, tente novamente.",
    
    faq: "FAQ",
    faqs: "Perguntas Frequentes",
    faq_chefs: "Perguntas Frequentes de Chefs",
    faq_restaurants: "Perguntas Frequentes de Restaurantes",
    file_attached: "Arquivo anexado",
    filter_by: "Filtrar por",
    file_selected: "Seleccionou",
    find_chefs: "Encontre Chefs",
    find_chefs_now: "Encontre chefs agora:",
    find_jobs: "Encontrar empregos",
    find_jobs_now: "Encontre empregos agora:",
    find_restaurants: 'Encontrar restaurantes',
    find_restaurants_now: "Encontre restaurantes agora:",
    food_standards: "Padrões alimentares",
    forgot_password: "Esqueceu sua senha?",
    from: "De",
    future_opportunities: "Oportunidades futuras",
    
    get_in_touch: "Entrar em contato",
    go: "Vai",
    go_back: "Voltar atrás",
    guru_template: "Modelo Guru",
    
    head_chef: "Chefe de cozinha",
    header: 'Cabeçalho',
    headline: "Título",
    health_safety: "Saúde e segurança",
    help_job_description: 'Precisa de ajuda para escrever uma descrição do trabalho?',
    help_job_description_text: 'Clique nos itens que melhor descrevem suas metas de recrutamento e edite os detalhes depois.',
    help_recruit: "Ajude-nos a recrutar para esta posição (taxa de recrutamento de 3% será aplicada)",
    hide: "Ocultar",
    hide_menu: "Ocultar menu",
    hide_salary: "Ocultar salário",
    holidays: "Feriados:",
    home: "Início",
    how_do_we_work: "Como trabalhamos",
    how_do_we_work_chefs: "Como trabalhamos para chefs?",
    how_do_we_work_chefs_text: "Chefs Link é um serviço gratuito para todos os nossos chefs à procura de emprego. Nenhum risco e nenhum pagamento é necessário. Temos acesso a uma extensa rede de restaurantes com as melhores oportunidades de carreira possíveis na cena do restaurante. Oportunidades que prometem impulsionar sua carreira como Chef e permitirão que você trabalhe e seja orientado por alguns dos melhores Chefs do mundo. Nós nos dedicamos a ajudá-lo a estabelecer e gerenciar sua carreira como Chef.",
    how_do_we_work_restaurants: "Como trabalhamos para restaurantes?",
    how_do_we_work_restaurants_text: "Nós dedicamos nosso tempo para encontrar os Chefs mais talentosos para os nossos restaurantes através de nossas redes, marketing e bom relacionamento com algumas das melhores escolas de culinária e universidades do mundo. Isso nos permite encontrar o talento que atenderá às suas necessidades específicas. Nossa empresa é projetada para eliminar o aborrecimento e a frustração geralmente associados ao processo de recrutamento, tornando-o o mais fácil possível para você e sua empresa.",
    
    import_template_NAME: 'Gostaria de importar o modelo "%@"?',
    in: "Em",
    in_review: "Em revisão",        
    inactive: "Inativo",
    inactive_NUM: "Inativo (%@)",
    info: "Informação:",
    invalid_email: "Email inválido",
    
    job_description: "Descrição do trabalho",
    job_offers: "Ofertas de trabalho",
    job_offers_NUM: "Ofertas de trabalho (%@)",
    job_offers_you_saved: "Estas são as ofertas de emprego que você salvou!",
    join_our_network_today: "Junte-se à nossa rede hoje!",
    join_the_community: "Ou pode juntar-se à comunidade e ",
    
    language: "Língua",    
    languages: "Línguas",
    last_job_offers_NUM: "Últimas ofertas de emprego (%@)",
    last_login: "Último login",
    latest_job_offer_alerts_content: 'Você receberá um alerta semanal por e-mail com as últimas ofertas de emprego vinculadas automaticamente ao seu perfil, de acordo com as informações que você forneceu.',
    latest_job_offer_alerts_title: 'Últimas ofertas de emprego alertas',
    latest_job_offer_alerts_subtitle: 'Oferece links para o seu perfil',
    launch_guru_template: "Lançar o modelo Guru",
    less: "- Menos",
    level: "Nível",        
    link: "Link",
    link_now: 'Link agora',
    link_offer: "Link para a oferta",
    link_tech_content_chef: 'O perfil inteligente do nosso site ligará você automaticamente aos restaurantes que são importantes para você. Ele mostrará somente as ofertas que correspondem ao seu perfil, para que você não precise perder tempo ou passar horas pesquisando ofertas de emprego irrelevantes para você.',
    link_tech_content_restaurant: 'O perfil inteligente do nosso site ligará você automaticamente aos Chefs mais importantes para sua empresa, para que você não perca tempo ou passe horas pesquisando centenas de currículos irrelevantes para você.',
    link_tech_subtitle_chef: 'Ligando você com as pessoas certas',
    link_tech_subtitle_restaurant: 'Ligando você com os Chefs certos',
    link_tech_title: 'Link Technology',
    link_to_our_page: 'Conecte-se à nossa página para ver nossas ofertas de emprego mais recentes na parte superior de seu Feed de notícias e receba uma notificação quando postarmos uma nova oferta de emprego',
    link_with_us: 'Link com a gente',
    links: "Links",
    links_accepted_NUM: "Você tem %@ link%@ aceito%@!",
    links_for_you: "Links para você!",
    links_pending_NUM: "Você tem %@ link%@ pendente%@!",
    links_rejected_NUM: "Você tem %@ link%@ rejeitado%@!",
    load_more_items: "Carregar mais itens",
    loading: 'Carregando',
    location: "Localização",
    log_in: "Entrar",
    login: "Entrar",
    logout: "Sair",
    lunch: "Almoço",
    
    manage: "Gerir",
    manage_pages: "Gerenciar Páginas",
    manage_your_pages: "Gerenciar suas páginas",
    management: "Gestão",
    me: "Eu",
    media: "Mídia",
    member_login: "Acesso de membro",
    membership_requested: 'Membro solicitado',
    menu: 'Menu',
    mess: "Mens.",
    message: "Mensagem",
    message_from_restaurant: "Mensagem do restaurante",
    message_sent: "Mensagem enviada",
    message_was_sent: "A mensagem foi enviada",
    messages: "Mensagens",
    messages_from: 'Mensagens de',
    messages_overview: "Sua visão geral de mensagens",
    miniature: 'Miniatura',
    month: "Mês",
    more: "+ Mais",
    moremore: "Mais",
    more_info: "Mais Informações",
    my_jobs_NUM: 'Meus trabalhos (%@)',
    my_profile: "Meu perfil",
    
    name: "Nome",
    name_placeholder: "Introduza o seu nome",
    nationalities: "Nacionalidades",
    negotiable_salary: " - Salário negociável",
    new_password: "Nova senha",
    new_trial_date: "Nova data:",
    no_accepted_applications: "Não há aplicativos aceitos",
    no_accepted_links: "Não há links aceitos",
    no_active_offers: "Não há ofertas ativas",
    no_emails_allowed: ' -NEM EMAILS PERMITIDOS- ',
    no_in_review_applications: "Não há aplicativos em revisão",
    no_information: "Sem informação",
    no_job_offers: 'Não há ofertas de emprego',
    no_message: "Nenhuma mensagem",
    no_messages: 'Não há mensagens',
    no_notifications: "Não há notificações",
    no_pending_applications: "Não há aplicativos pendentes",
    no_pending_links: "Não há links pendentes",
    no_rejected_applications: "Não há pedidos rejeitados",
    no_rejected_links: "Não há links rejeitados",
    no_results: "Não há resultados para a sua pesquisa",
    no_saved_offers: 'Não há ofertas salvas',
    no_sent_applications: "Não há aplicativos enviados",
    no_stages_available: "Não há estágios disponíveis",
    no_time: 'Nenhum tempo foi especificado',
    no_trials: "Não há aplicativos",
    no_urls_allowed: ' -NO URLS PERMITIDO- ',
    not_available: "não disponível",
    not_logged_in: "Você precisa estar logado para continuar. Não é membro ainda? <strong>Crie uma conta gratuita</strong> e tenha acesso a ofertas ilimitadas, inscreva-se e entre em contato diretamente com restaurantes de todo o mundo.",
    notifications: "Notificações",
    
    offer_not_available: 'Esta oferta não está disponível.',
    offer_saved: 'A oferta foi salva',
    offer_sent: 'Oferta enviada',
    offer_sent_to_client: "A oferta foi enviada ao cliente",
    offers: 'Ofertas',
    ok: "Está bem",
    only_cv_subject: 'Pedido de oferta',
    only_cv_subject_plural: 'Pedido de ofertas',
    only_cv_text: 'publicado por restaurante',
    only_cv_text_intro: 'Gostaria de enviar meu currículo para solicitar a seguinte oferta:',
    only_cv_text_intro_plural: 'Gostaria de enviar meu currículo para solicitar as seguintes ofertas:',
    opening_hours: "Horário de funcionamento",
    other_duties: "Outros deveres",
    
    password: "Senha",
    password_changed: "Sua senha foi mudada",
    password_placeholder: 'Introduza a sua palavra-passe',
    pause: "Pausa",
    paused: "Pausado",
    pending: "Pendente",
    pending_applicants_NUM: "%@ requerentes pendentes",
    person: "Pessoa",
    phone: "Telefone",
    phone_code_number: "Código do telefone",
    phone_number: "Número de telefone",
    phone_placeholder: "Introduza o seu número de telefone",
    plan_that_works: "Um plano que funciona",
    plan_that_works_business: "Um plano que funciona para o seu negócio",
    position: "Posição",
    post_job_offers: "Postar ofertas de emprego",
    post_jobs: "Postar empregos",
    post_jobs_content: 'Crie quantas Ofertas de emprego você quiser no nosso site e até mesmo armazene os anúncios em seu perfil para uso futuro de recrutamento. Com as informações fornecidas nos anúncios, nossa equipe de recrutamento começará a procurar chefs e enviará possíveis candidatos.',
    post_jobs_subtitle: 'Criando anúncios rápidos e fáceis em nosso site',
    premium: 'Premium',
    preview: 'Visualização',
    preview_as_restaurant: "Visualizar como restaurante",
    previous_experience: "Experiência anterior",
    privacy_policy: "Política de Privacidade",
    profile: "Perfil",
    profile_type_placeholder: "É chefe de cozinha ou restaurante?",
    profile_types: "Chef ou Restaurante",
    propose_new_date: "Propor uma nova data",
    proposed_offer: 'Oferta proposta',
    published_on: "Publicado em",

    questions: "Você tem alguma pergunta?",
    questions_text_1: "Preparamos uma lista com as perguntas mais frequentes (FAQ) para ajudá-lo com quaisquer dúvidas que você possa ter. No caso de você não encontrar a resposta",
    questions_text_2: "você precisa, não hesite em nos contatar. Nós estaremos por favor para ajudá-lo.",
    
    rating: "Avaliação",
    rating_type: "Tipo de Avaliação",
    reached_limit: "Você atingiu o limite de 10 chefs",
    read_less: "- Leia menos",
    read_more: "+ Leia mais",
    register_as: "Registre-se como",
    reject: "Rejeitar",
    rejected: "Rejeitado",
    rejected_trial_dates: "Infelizmente, o restaurante rejeitou as datas propostas. No entanto, eles sugeriram outra data para fazer a (%@). Por favor aceite ou sugira outra data disponível.",        
    related_job_offers_NUM: "Ofertas de emprego relacionadas (%@)",
    remember_me: "Lembre de mim",
    remove_file: "Você lke para remover o arquivo?",
    repeat_password: "Repita a senha",
    repeat_password_placeholder: "Repita a sua senha",
    repeat_nesw_pasword: "Repita a nova senha",
    report: "Reportar",
    report_page: "Reporte esta página para Chefs Link",
    request: "Solicitação",
    request_callback: "Solicite uma chamada de volta",
    request_sent: "A solicitação foi enviada",
    reset_password: "Redefinir senha",
    restaurant: 'Restaurante',
    restaurant_faqs: "FAQS de restaurante",
    restaurant_manager_email: 'E-mail para receber inscrições e mensagens para este restaurante (opcional)',
    restaurant_message: "Mensagem do restaurante",
    restaurant_profile: "Perfil do restaurante",
    restaurant_profiles: "Perfis de restaurante",
    restaurant_proposed_offer: 'O restaurante propôs uma oferta',
    restaurant_services: 'Serviços de restaurante',
    restaurants: 'Restaurantes',
    restaurants_list_NUM: "Lista de restaurantes (%@)",
    
    salary: "Salário",
    salary_from: "Salário de",
    salary_to: "Salário a",
    save: "Guardar",
    save_add_more: "Salve e adicione mais",
    save_continue: "Salve e continue",
    saved: "Guardado",         
    search_chefs_content: 'Todos os Chefes de nossa rede têm um resumo de seus currículos com apenas as informações mais relevantes sobre suas carreiras como um Chef publicado em nosso site. Basta selecionar os perfis dos chefs que interessam às suas necessidades e enviar-lhes uma oferta de emprego.',
    search_chefs_subtitle: 'Precisa de Chefs',
    search_chefs_title: 'Procurar por Chefs',
    search_results: "Procurar Resultados",
    search_thousands_offer: 'Busca milhares de oferta de emprego para chefs',
    sector: "Setor",
    select_audience_content: 'Quando você se inscrever, teremos a opção de selecionar a nacionalidade e o idioma do seu público. Ele permitirá que apenas alguns chefs vejam e apliquem suas ofertas de emprego, além de mostrar apenas esses perfis na seção Localizar Chefs.',
    select_audience_subtitle: 'Quem você quer contratar?',
    select_audience_title: 'Selecione seu público',
    select_available_trial_dates: "Selecione até três datas em que você está disponível",
    select_dates: "Selecione as datas inicial e final",
    select_file: "Selecione um arquivo da sua biblioteca de mídia",
    select_new_trial_date: "Por favor, selecione uma nova data disponível",
    select_offer: "Selecione uma oferta",
    select_status: "Selecione um status",
    select_type: "Selecione o tipo",
    selected_chefs_NUM: "(%@) chefs selecionados para esta oferta",
    selected_chefs_NUM_mobile: "Chefs selecionados (%@)",
    selected_offers_NUM: "Você selecionou (%@) ofertas de emprego",
    selected_offers_NUM_mobile: "Ofertas selecionadas (%@)",
    selected_one_file: "Você selecionou um arquivo",
    send: "Enviar",
    send_cv: "Enviar CV",
    send_cv_only: "Enviar apenas CV",
    send_curriculum_vitae: "Envie curriculum vitae",
    send_job_offer: "Enviar oferta de emprego",
    send_message: "Envie uma mensagem",
    send_message_to_restaurant: "Envie ao restaurante uma mensagem",
    send_offer: "Enviar oferta",
    sent: "Enviei",
    sent_it: "Enviou",
    sent_you_message: " enviou uma mensagem para você",
    services: "Serviços",
    services_chefs: "Serviços para Chefs",
    services_restaurants: "Serviços para Restaurantes",
    settings: "Configurações",
    share: "Compartilhar",
    share_on: "Compartilhar no",
    share_profile_content: 'Você pode compartilhar ou baixar o seu perfil, basta clicar no botão de compartilhamento. Ele lhe dará algumas opções para fazer o download em formato Cv ou copiar o URL e enviá-lo como anexo em qualquer caixa de entrada ou e-mail da mensagem de rede social.',
    share_profile_subtitle: 'Sempre tenha seu perfil "CV" com você.',
    share_profile_title: 'Compartilhar seu perfil',
    show_menu: "Mostrar menu",
    showcase: 'Mostruário',
    sign_up: "Inscrever-se",
    signup_terms: "Criar uma conta significa que está de acordo com o nosso ",
    smart_banner_apple: " - On the App Store",
    smart_banner_free: "FREE",
    smart_banner_google: " - In Google Play",
    smart_banner_view: "VIEW",
    // smart_banner_apple: " - Na App Store",
    // smart_banner_free: "GRATUITAMENTE",
    // smart_banner_google: " - No Google Play",
    // smart_banner_view: "VISUALIZAR",
    social_network: "Rede social",
    social_networks: "Redes sociais",
    square: 'Quadrado',
    staff_development: "Desenvolvimento de equipe",
    stage: "Estágio",
    stage_info: "Informação do estágio",        
    start_adding_chefs: "Comece a adicionar Chefs",
    start_adding_covers: "Começar a adicionar capas",
    start_date: "Data de início:",
    start_editing_opening_hours: "Comece a editar suas horas de abertura",
    status: "Status",
    stay_up_to_date: "Mantenha-se atualizado!",
    stay_up_to_date_profile: "Mantenha-se atualizado com o seu perfil!",
    success: 'Sucesso',
    support: "Suporte",
    surname: "Sobrenome",
    switch: "Trocar",
    switch_active_page: "Trocar página ativa",
    
    team: 'Equipe',
    template: "Modelo",
    term: "Prazo:",
    text: "Texto",
    the_chef: "O chefe",
    them: "Eles",
    title: "Título",
    to: "a",
    total_applications_NUM: "Total de aplicações (%@)",
    trial_date: 'Data da (%@):',
    type: "Tipo",
    type_or_options: "Comece a digitar ou selecione uma de nossas opções",
    
    unblock: 'Desbloquear',
    upgrade_premium: "Atualize para o Premium",
    upload: "Carregar",
    upload_cv: "Carregar CV",
    url: "URL",

    view_calendar: "Ver calendário",
    view_links: "Visualizar links",
    view_media: "Visualizar mídia",
    
    w_h: "S/H:",
    w_hours: "S/Horas:",
    waiting_chef_response: "Esperando pela resposta do chef",
    waiting_response: 'Aguardando resposta',
    waiting_restaurant_confirmation: "Estamos aguardando a confirmação do restaurante",
    waiting_restaurant_response: 'Aguardando resposta do restaurante',
    weekly_days_off: "Dias de folga semanais",
    weekly_hours: "Horas semanais",
    when_available_to_start: "Caso você seja aceito, quando você está disponível para começar?",
    work_experience: "Experiência de trabalho",
    work_visas: "Vistos de trabalho",
    write_new_message_here: "Escreva sua nova mensagem aqui",
    
    year: "Ano",
    your_conversation: "Sua conversa",
    your_enquiry: "Sua pergunta",
    your_message: "Sua mensagem",
    your_job_offers_NUM: "Suas ofertas de emprego (%@)",
     

    // Form error messages: (chef profile)
    error_email_required: 'O email é obrigatório',
    error_email_valid: 'O email deve ser válido',
    error_password_required: 'É necessária uma senha',
    error_password_length: 'A senha deve ter pelo menos 6 caracteres',
    error_terms_required: 'Deve aceitar os nossos termos para continuar',
    error_type_profile: 'Deve selecionar um tipo de perfil',
    error_name_letters: 'O nome só pode conter letras',
    error_name_length: 'O nome deve ter pelo menos 2 caracteres',
    error_name_required: 'O nome é obrigatório',
    error_phone_numbers: 'O telefone só pode conter números',
    error_phone_length: 'O número de telefone deve ter pelo menos 4 caracteres',
    error_phone_required: 'O telefone é necessário',
    error_password_match: 'As senhas não correspondem',
    error_password_confirm: 'Confirme a sua senha',
    error_login_combination: 'A combinação username/password não existe',
    error_email_unique: 'O email já está em uso',
    error_required_fields: 'Deve preencher os campos obrigatórios',

    // Forms placeholders: (chef profile)
    placeholder_position: 'Selecione a sua posição',
    placeholder_company_name: 'Adicionar nome do centro',
    placeholder_company_url: 'Adicionar site do centro',
    placeholder_location: 'Acrescentar uma localização',
    placeholder_select_type: 'Selecione o tipo',
    placeholder_select_month: 'Selecione o mês',
    placeholder_select_year: 'Selecionar ano',
    placeholder_education_title: 'Adicionar título educacional',
    placeholder_center_name: 'Adicionar nome do centro',
    placeholder_center_url: 'Adicionar site do centro',
    placeholder_select_language: 'Selecione o idioma',
    placeholder_select_level: 'Selecione o nível',
    placeholder_select_country: 'Selecione o pais',

    // Chef profile literals:
    add_description: 'Adicione uma descrição no seu perfil, para que as pessoas o conheçam um pouco mais.',
    add_work_experience: 'Adicione sua experiência de trabalho.',
    add_education: 'Adicione sua educação.',
    add_languages: 'Adicione seus idiomas.',
    add_referrals: 'Adicione mais informações que possam ser de seu interesse.',
    no_chef_info: 'O chef ainda não adicionou informações.',
    start_your_profile: 'Oi! Comece a completar o seu perfil.',
    start: 'Começar',
    profile_status: 'Status do perfil',
    add_work_experience_extended: "Comece a adicionar sua experiência de trabalho.<br>Você pode adicionar mais, editar ou excluir da sua página de perfil.",
    add_education_extended: "Comece a adicionar sua educação.<br>Você pode adicionar mais, editar ou excluir da sua página de perfil.",
    add_languages_extended: "Comece a adicionar seus idiomas.<br>Você pode adicionar mais, editar ou excluir da sua página de perfil.",
    add_referrals_extended: "Adicione mais informações que possam ser de seu interesse.<br>Você pode adicionar mais, editar ou excluir da sua página de perfil.",

}