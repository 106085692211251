import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { StickyContainer, Sticky } from 'react-sticky'
import { withRouter } from 'react-router'

class StickyElement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            relative: false,
            hasReachedHeight: false
        }
    }
    render() {
        return (
            <StickyContainer className="sticky_container">
                <Sticky
                    relative={this.state.relative}
                    >
                    {({
                        style,
                        calculatedHeight
                    }) => {

                        if (this.props.scroll) {
                            style = Object.assign({}, style)
                            if (calculatedHeight >= window.innerHeight && !this.state.hasReachedHeight) {
                                this.setState({hasReachedHeight: true})
                            }
                            if (style !== undefined && calculatedHeight !== undefined && style !== {}) {
                                style.maxHeight = window.innerHeight
                                if (this.state.hasReachedHeight) {
                                    style.overflowY = 'scroll'
                                }
                            }
                        }

                        return this.props.callback(style, calculatedHeight)
                    }}
                </Sticky>
            </StickyContainer>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps)(StickyElement))
