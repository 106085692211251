import * as types from "./types";
const find = require("lodash/find");

export function setUser(user) {
  if (user && user.type === "restaurant") {
    let main = find(user.profiles, (o) => {
      return parseInt(o.main) == 1;
    });
    if (!main) {
      main = user.profiles[0];
    }
    user.main = main;
  }

  if (user) {
    try {
      const userData = JSON.stringify(user);
      localStorage.setItem("user", userData);
    } catch (e) {
      console.log("Error saving user to localStorage: ", e);
    }
  } else {
    localStorage.removeItem("user");
  }

  return {
    type: types.SET_USER,
    user,
  };
}

export function setToken(token) {
  return {
    type: types.SET_TOKEN,
    token,
  };
}

export function setIsLogged(isLogged) {
  return {
    type: types.SET_IS_LOGGED,
    isLogged,
  };
}

export function setUserType(userType) {
  return {
    type: types.SET_USER_TYPE,
    userType,
  };
}
