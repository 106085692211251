import React, {Component} from 'react'
import moment from 'moment'
import LazyImage from '../../Components/LazyImage/LazyImage'

export default class ChatBubble extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const restaurantImg = this.props.data.restaurant.images.logo !== "" ? this.props.data.restaurant.images.logo.sizes.square.url : '/static/images/empty_600_600.png'
        const chefImg = this.props.data.chef.images.profile !== "" ? this.props.data.chef.images.profile.sizes.square.url : '/static/images/empty_600_600.png'

        const url = parseInt(this.props.sender) === parseInt(this.props.data.caccount) ? chefImg : restaurantImg
        const alt = parseInt(this.props.sender) === parseInt(this.props.data.caccount) ? this.props.data.chef.name : this.props.data.restaurant.name

        let className = `component_ChatBubble ${(this.props.deviceScreen === 'mobile' ? 'mobile' : '')}`
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        if (this.props.loading) {
            className += ' loading'
        }
        if (this.props.them === 'them') {
            className += ' them'
        } else {
            className += ' me'
        }

        return (
            <div className={className}>
                <div className='bubble-container'>
                    <LazyImage className='bubble-picture' src={url} alt={alt} />
                    <div className="text-content" dangerouslySetInnerHTML={{__html: this.props.text}}></div>
                </div>
                <div className="date">{moment.unix(this.props.date, 'x').format('MMMM D, YYYY [–] h:mma')}</div>
            </div>
        )
    }
};
