import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import Textarea from '../../Components/Input/Textarea'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefEditReferral} from '../../Services/Chef/chef'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { refreshUser } from '../../Services/Access/access'
import Cookies from 'js-cookie'
import ChefReferralForm from "../_NEW/ChefReferralForm"
var find = require('lodash/find')

class ChefReferralOverlay extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            errors: [],            
            loading: (Cookies.get('token') !== undefined),
            changedContents: false,
            lang: props.match.params.lang,
            isNew: true
        }

        this.referralTextarea = React.createRef()
    }

    parseDataToState(value = this.props.data.value, callback = () => {}) {
        this.setState({
            id: parseInt(value.id),
            description: value.description ? value.description : '',
        }, callback)
    }

    componentDidMount() {
        this.parseDataToState(this.props.data,        
            () => {
                this.shouldPerformRefreshUser(this.setState({loading: false}))
            }
        )}

    shouldPerformRefreshUser(callback = () => {}) {
        const { id } = this.state
        if (typeof id !== 'undefined' && id > 0) {
            this.setState({ isNew: false }, () => this.performRefreshUser())
        } 
        return callback
    }

    languageCallback(lang) {
        const {
            changedContents,
        } = this.state

        // if(!changedContents) {
            this.setState({ lang }, () => this.shouldPerformRefreshUser())
            return true
        // } else {
        //     const hasErrors = this.validateForm(false)
        //     this.save(false, () => {
        //         this.setState({ lang }, () => this.shouldPerformRefreshUser())
        //     })
        //     return hasErrors
        // }
    }

    performRefreshUser() {
        const { lang } = this.state
        const token = Cookies.get('token')
        refreshUser({ token: token, lang }).then((res) => {
            const { user } = res.data.data

            let description = ''

            if (typeof this.state.id !== 'undefined' && this.state.id > 0) {
                const referral = user.profile.referrals.find((referral) => 
                    parseInt(referral.id) === this.state.id ? referral : false)
                    description = referral.value.description
            }

            this.setState ({ loading: false, description: description }, () => {
                // this.referralTextarea.setContent(description)
            })
        })
    }

    validateForm(updateState = true){
        let items = [
            {field: 'description', minLength: 2},
        ];
        let errors = validate (items, this.state)
        if (updateState) this.setState({ errors })
        return errors.length === 0
    }

    handleChange(name, value) {
        this.setState({ [name]: value, changedContents: true })
    }

    save(closeModelAfterSave = false, callback = () => {}) {
        const { lang } = this.state
        
        const isValid = this.validateForm()

        if (isValid) {
            let updateData = {
                id: this.state.id,
                name: 'referral',
                order: 0,
                language: lang,
                value: JSON.stringify({
                    description: this.state.description,
                    lang: lang
                })
            }
            
            this.setState({ loading: true })
            
            chefEditReferral(updateData).then((res) => {
                this.setState({ loading: false, changedContents: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                } else {
                    const { data } = res.data

                    if (typeof updateData.id !== 'undefined' && updateData.id > 0) {
                        const referral = data.user.profile.referrals.find((referral) => 
                            parseInt(referral.id) === updateData.id ? referral : '')
                            referral.value.id = referral.id
                            this.parseDataToState(referral.value)
                    } else {
                        const referral = data.user.profile.referrals[data.user.profile.referrals.length - 1]
                        referral.value.id = referral.id
                        this.parseDataToState(referral.value)
                    }

                    this.props.setUser(data.user)
                    if (closeModelAfterSave){
                        this.props.showOverlay(null)
                    } else {
                        if (callback) callback()
                    }
                }
            }).catch((e) => {
                this.setState({ loading: false })
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }

    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        chefEditReferral({id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            let data = res.data.data
            this.props.setUser(data.user)
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }

    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay
                title={translate(lang, 'more_info')}
                className={this.props.className}
                languageTranslator={this.state.id === 0 ? false : true}
                languageCallback={this.languageCallback.bind(this)}
                isNew
                overlayName={this.props.overlayName}
                profileNav={this.props.data.profileNav}
                subtitle={translate(lang, 'add_referrals_extended')}
                >
                    <ChefReferralForm
                        props={this.props}
                        textTranslation={this.state.description}
                        textLang={this.state.lang}
                    />

                    {/* <div className="col-group form">
                        <div className="col-mb-12">
                            <Textarea
                                ref={(ref) => this.referralTextarea = ref}
                                lang={lang}
                                label={translate(lang, 'text')}
                                name="description"
                                error={this.state.errors}
                                type="text"
                                value={this.state.description}
                                minLength={4}
                                onChange={v => this.handleChange('description', v)}
                                />
                        </div>

                        <div className="col-mb-12 actions multiple">
                            {this.state.id !== 0 ? <div className="accessory">
                                <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                    this.delete()
                                }} />
                            </div> : null}
                            <div className="main">
                                <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                    this.props.showOverlay(null)
                                }} />
                                <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                    this.save(true)
                                }} />
                            </div>
                        </div>
                    </div> */}
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefReferralOverlay))
