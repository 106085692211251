import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import DateSectionTitle from '../../Components/DateSectionTitle/DateSectionTitle'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import InfoRow from '../../Components/InfoRow/InfoRow'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { chatListAccountConversations, blockConversation, unblockConversation, deleteConversation} from '../../Services/Chat/chat'
import {findById} from '../../Lib/findById'
import {listItems, listBtn2} from '../RestaurantProfile/RestaurantProfileTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import Loader from '../../Components/_NEW/Loader'
import { withRouter } from 'react-router'
var groupBy = require('lodash/groupBy')


class RestaurantProfileMessages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstLoad: true,
            loading: true,
            rawItems: [],
            items: [],
            page: 0,
            hasMorePages: true
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        this.setState({
            profile: profile,
        }, this.loadItems.bind(this))
    }
    loadItemsFromStart() {
        this.setState({page: 0, items: [], rawItems: [], hasMorePages: true, loading: true}, () => {
            this.loadItems()
        })
    }
    loadItems(done) {
        const qty = 10
        chatListAccountConversations({page: this.state.page, qty: qty}).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                const { list } = res.data.data
                delete list.messages
                let its = []
                Object.keys(list).forEach((k) => {
                    its.push(list[k])
                })
                let rawItems = this.state.rawItems
                rawItems.push(...its)
                let items = groupBy(rawItems, (o) => {
                    return moment(o.messages.length > 0 ? new Date(+o.messages[0].created*1000) : o.created).format('YYYY-MM-DD')
                })
                this.setState({ items: items, rawItems: rawItems, hasMorePages: (list.length >= qty), loading: false, firstLoad: false })
            }
        })
    }

    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            let profile = nextProps.match.params.id ? findById(nextProps.match.params.id, nextProps.user.profiles) : nextProps.user.profiles.find((profile) => parseInt(profile.main) === 1)
        this.setState({profile: profile});
        }
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant' && parseInt(this.props.user.subscription) === 1)
    }
    block(status, itemId, blockerId) {
        if (parseInt(status) !== 0 && blockerId !== this.props.user.id) {
            blockConversation({ conversation: itemId }).then((res) => {
                if (res.data.success) {
                    this.loadItemsFromStart()
                }
            })
        }
    }
    unblock(status, itemId, blockerId) {
        if (parseInt(status) === 0 && blockerId === this.props.user.id) {
            unblockConversation({ conversation: itemId }).then((res) => {
                if (res.data.success) {
                    this.loadItemsFromStart()
                }
            })
        }
    }
    delete(itemId) {
        const { lang } = this.props.match.params
        const result = window.confirm(translate(lang, 'delete_conversation'))
        if (result) {
            deleteConversation({ conversation: itemId }).then((res) => {
                if(res.data.success) {
                    this.loadItemsFromStart()
                }
            })
        } else {
            return
        }
    }

     

    render() {
        let className = 'scene_RestaurantProfileMessages'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params

        let items = listItems(this.state.profile, this.state, lang)
        let btn2 = listBtn2(lang, this.state.profile, this.state, this.showJobsOverlay.bind(this))

        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'restaurant_profile')}</span> / {translate(lang, 'messages')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        <Switch active={this.state.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        let items = []
        let k = 0
        Object.keys(this.state.items).forEach((date) => {
            const dateConversationArray = this.state.items[date]
            let subitems = []
            dateConversationArray.forEach((m) => {
                let url = `/${lang}/restaurant/profile/messages/` + this.state.profile.id + '/view/' + m.id
                let lastMessage = ''
                let newMessages = 0
                if (m.messages.length > 0) {
                    lastMessage = m.messages[0].message
                    if (this.props.deviceScreen !== 'mobile' && lastMessage.length > 90) {
                        lastMessage = m.messages[0].message.substring(0, 90) + "..."
                    }
                    if (this.props.deviceScreen === 'mobile' && lastMessage.length > 35) {
                        lastMessage = m.messages[0].message.substring(0, 35) + "..."
                    }
                    m.messages.forEach(mess => {
                        if (parseInt(mess.status) === 0 && mess.sender !== this.props.user.id) {
                            newMessages++
                        }
                    })
                } else {
                    return null
                }
                subitems.push(
                    <InfoRow
                        key={m.id}
                        newMessages={newMessages}
                        src={m.chef.images.profile ? m.chef.images.profile.sizes.square.url : ''}
                        title={'<strong>' + m.chef.name + '</strong>'}
                        subtitle={lastMessage}
                        className="marked"
                        url={url}
                        contentButton={true}
                        content={
                        <div className="buttons">
                            <Btn
                                className='no-line'
                                text={parseInt(m.status) === 0 && m.blocker === this.props.user.id ? translate(lang, 'unblock').toUpperCase() : translate(lang, 'block').toUpperCase()}
                                callback={parseInt(m.status) === 0 && m.blocker === this.props.user.id ? this.unblock.bind(this, m.status, m.id, m.blocker) : this.block.bind(this, m.status, m.id, m.blocker)}
                            />
                            <Btn
                                className='no-line red'
                                text={translate(lang, 'delete').toUpperCase()}
                                callback={this.delete.bind(this, m.id)}
                            />
                        </div>
                        }
                        blocked={parseInt(m.status) === 0}
                        />
                )
            })
            if (subitems.length > 0){
                items.push(
                    <div key={k}>
                        <DateSectionTitle title={moment(date).format('MMMM Do')} />
                        <div className="info-rows">{subitems}</div>
                    </div>
                )
                k++
            }
        })
        return (
            <div>
                <h4>{translate(lang, 'messages_overview')}</h4>
                <div className="items">
                    {items.length === 0 ? translate(lang, 'no_messages') : items}
                </div>
                {this.state.loading && <Loader />}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfileMessages))
