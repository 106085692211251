import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import MonthView from 'react-calendar/dist/entry.nostyle'
import Overlay from './Overlay'
import Textarea from '../../Components/Input/Textarea'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {acceptStaging, rescheduleStaging, rejectAcceptedStage} from '../../Services/Stages/stages'
import {findOption} from '../../Lib/findOption'
import {positions, disponibility, applicationTypes} from '../../Lists/lists'
import {readableLocation} from '../../Lib/readableLocation'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Select from 'react-select'
import LazyImage from '../../Components/LazyImage/LazyImage'

class AcceptApplicationOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            applicationId: this.props.data.id,
            chef: this.props.data.chef,
            item: this.props.data.item,
            stage: this.props.data.onlyCalendar ? -1 : null,
            start: this.props.data.item.stages[0].start || '',
            end: this.props.data.item.stages[0].end || '',
            type: parseInt(this.props.data.item.stages[0].typeid) || 0,
            message: this.props.data.restaurant.main.emailmessage || '',
            errors: [],
            value: null
        }
    }
    render() {
        const { lang } = this.props.match.params
        let item = this.state.item
        let title = translate(lang, 'application_confirmation')
        let chef = item.chef
        let image = chef.images.profile ? chef.images.profile.sizes.square.url : 'http://via.placeholder.com/240x240/eee/fff'
        return (
            <Overlay title={title} className="accept-application">
                <div className="col-group main-content">
                    <div className="col-mb-12">
                        <div className="col-group chef-info">
                            <div className="col-mb-12 col-3">
                                <LazyImage src={image} alt={chef.name}/>
                            </div>
                            <div className="col-mb-12 col-9">
                                <h5><Link to={`/${lang}/chef/view/` + chef.id + '/' + chef.slug}>{chef.name}</Link></h5>
                                <p>{this.renderName(chef)}</p>
                                {/* <p><strong>{translate(lang, 'start_date')}</strong> {disponibility[lang][item.disponibility-1].label}{parseInt(item.disponibility) === 2 ? ' ' + moment(item.disponibilitydate).format('DD/MM/YYYY') : null}</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12 stages">
                        {!this.props.data.onlyCalendar ? this.renderStages() : null}
                    </div>
                </div>
                <div className="col-group form">
                    <div className='col-mb-12 select-group'>
                        <div className='picker'>
                            <label htmlFor="type">{translate(lang, 'from')}</label>
                            <input type="time" value={this.state.start} onChange={(e) => { this.setState({ start: e.target.value })}}/>
                        </div>
                        <div className='picker'>
                            <label htmlFor="type">{translate(lang, 'to')}</label>
                            <input type="time" value={this.state.end} onChange={(e) => { this.setState({ end: e.target.value })}}/>
                        </div>
                        <div className="type-select">
                        <label htmlFor="type">{translate(lang, 'type')}</label>
                        <Select
                            placeholder={translate(lang, 'select_type')}
                            name="type"
                            value={this.state.type}
                            className={this.state.errors.indexOf('type') !== -1 ? 'error' : null}
                            onChange={option => this.handleChange('type', option ? option.value : -1 )}
                            options={applicationTypes[lang]}
                        />

                        </div>
                    </div>
                    {this.props.data.onlyCalendar || this.state.stage === -1 ? <div className='col-mb-12'>
                        <MonthView
                            minDate={new Date()}
                            activeStartDate={null}
                            value={this.state.value}
                            onClickDay={(date) => {
                                this.setState({value: date})
                            }}
                            locale="en-En"
                            view="month"
                            /></div> : null}
                    <div className="text-area col-mb-12">
                        <Textarea
                            lang={lang}
                            label={translate(lang, 'your_message')}
                            name="message"
                            error={this.state.errors}
                            type="text"
                            value={this.state.message}
                            minLength={4}

                            onChange={(v) => {
                                this.setState({message: v})
                            }}
                        />
                    </div>
                    {this.state.stage === null ?
                        <div key="new" className="col-mb-12 stage new-stage">
                            <Btn text={translate(lang, 'propose_new_date').toUpperCase()} className="no-line" post="_calendar" callback={() => {
                                this.setState({ stage: -1 })
                            }} />
                        </div>
                    : null}
                    <div className="col-mb-12 actions multiple">
                        <div className="btns">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            {(this.state.stage > 0 && this.state.start !== '' && this.state.end !== '' && this.state.type > 0 || (this.state.stage === -1 && this.state.value !== null && this.state.start !== '' && this.state.end !== '' && this.state.type > 0)) ?
                            <Btn text={translate(lang, 'send').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save()
                            }} /> : null}
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }

    handleChange(name, value) {
        this.setState({ [name]: value, changedContents: true })
    }

    renderStages() {
        const { lang } = this.props.match.params
        let item = this.state.item
        let content = []
        item.stages.forEach((stage) => {
            const stageType = applicationTypes[lang].find(i => i.value === parseInt(stage.typeid))
            const dateLiteral = stageType ? translate(lang, 'trial_date').replace('(%@)', stageType.label) : translate(lang, 'date') + ":"

            if (!['Accepted', 'Rejected'].includes(stage.status)) {
                let btn = this.state.stage !== null && this.state.stage == stage.id ?
                    <Btn text={translate(lang, 'accepted').toUpperCase()} pre="tick1" className="no-line disabled" callback={() => {
                    }} />
                    :
                    <Btn text={translate(lang, 'accept').toUpperCase()} pre="tick2" callback={() => {
                        this.setState({ stage: stage.id })
                    }} />
                content.push(
                    <div key={stage.id} className="stage">
                        <span>{dateLiteral} {moment(stage.date).format('DD/MM/YYYY')}</span>
                        {this.state.stage !== -1 ? btn : null}
                    </div>
                )
            }
        })
        return content
    }
    save() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'message' , minLength: 4},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (this.state.stage > 0 || (this.state.stage === -1 && this.state.value !== null)) {
            this.setState({ loading: true })
            if (this.state.stage > 0) {
                acceptStaging({
                    id: this.state.stage,
                    description: this.state.message,
                    start: this.state.start,
                    end: this.state.end,
                    type: this.state.type > 0 ? this.state.type : 0,
                    lang,
                }).then((res) => {
                    this.setState({ loading: false })
                    if (!res.data.success) {
                        this.props.showOverlay('error', {message: res.data.message})
                        return
                    }
                    this.props.showOverlay('success', {message: translate(lang, 'message_sent')})
                    this.props.data.callback()
                })
            } else {
                let id = this.state.item.stages.length > 0 ?  this.state.item.stages[0].id : -1
                if (this.state.item.stages[0].status === 'Accepted') {
                    rejectAcceptedStage({ id: this.state.item.stages[0].id})
                }
                rescheduleStaging({
                    id: id,
                    applicationid: this.state.item.id,
                    replacedate: moment(this.state.value).format('YYYY-MM-DD'),
                    description: this.state.message,
                    start: this.state.start,
                    end: this.state.end,
                    type: this.state.type > 0 ? this.state.type : 0,
                    lang,
                }).then((res) => {
                    this.setState({ loading: false })
                    if (!res.data.success) {
                        this.props.showOverlay('error', {message: res.data.message})
                        return
                    }
                    this.props.showOverlay('success', {message: translate(lang, 'message_sent')})
                    this.props.data.callback()
                })
            }
        }
    }
    renderName(item) {
        const { lang } = this.props.match.params
        let nameParts = []
        let position = findOption(item.position, positions[lang], false)
        if (position) {
            nameParts.push(position)
        }
        if (item.nationality !== '') {
            nameParts.push(item.nationality)
        }
        let location = readableLocation(item.location)
        if (location !== '') {
            nameParts.push(location)
        }
        return nameParts.join(' / ')
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(AcceptApplicationOverlay))
