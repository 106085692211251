import React, {Component} from 'react'
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Btn from '../Btn/Btn'
import { translate } from '../../Translations/translate'
import LazyImage from '../../Components/LazyImage/LazyImage'

class InfoRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openContent: false
        }
    }
    render() {
        let src = this.props.src ? this.props.src : 'http://via.placeholder.com/120x120/eee'
        let url = this.props.url && !this.props.blocked ? this.props.url : '#'
        let className = 'component_InfoRow'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        return (
            <div className={className}>
                <div className="fix-content">
                    <a href={url} className={`${this.props.blocked ? 'isBlocked' : ''}`}>
                        {!className.includes('simple') ? <LazyImage src={src} alt={this.props.title}/> : null}
                        <div className="info">
                            <div>
                                <div>
                                    {this.props.title ? <h6 dangerouslySetInnerHTML={{__html: this.props.title}}></h6> : null}
                                    {this.props.newMessages ? <div className='pending-dot'><span className='number'>{this.props.newMessages}</span></div> : null}
                                </div>
                            {this.props.subtitle ? <p dangerouslySetInnerHTML={{__html: this.props.subtitle}}></p> : null}
                            </div>
                        </div>
                    </a>
                    {this.props.contentButton && <div className="content-btn" onClick={() => this.setState({ openContent: !this.state.openContent })}>
                        <SVG src={this.state.openContent ? "/static/images/less_icn.svg" : "/static/images/_more.svg"} />
                    </div>}
                </div>
                {this.props.content && this.state.openContent ? this.props.content : null}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps)(InfoRow))
