import React from 'react'
import { Field } from 'formik'

const FormField = ({
  label,
  id,
  type = "text",
  name,
  placeholder = "",
  value,
  onChange,
  errors,
  className = "",
  disabled = false,
  formError,
  showErrorMessage = true,
}) => {
  const error = errors && errors.hasOwnProperty(name) ? errors[name] : null

  return (
    <div className="component_FormField">
      <label htmlFor={id} className="inputLabel">{label}</label>
      <div className="fieldContainer">
        <Field
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          className={`input ${error || formError ? 'error' : ''} ${className}`}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
      {error && showErrorMessage && <div className="errorMessage">{error}</div>}
    </div>
  )
}

export default FormField