import {chefFaqs_en} from './chefFaqs/chefFaqs_en'
import {chefFaqs_es} from './chefFaqs/chefFaqs_es'
import {chefFaqs_fr} from './chefFaqs/chefFaqs_fr'
import {chefFaqs_pt} from './chefFaqs/chefFaqs_pt'

export const chefFaqs = {
    en: chefFaqs_en,
    es: chefFaqs_es,
    fr: chefFaqs_fr,
    pt: chefFaqs_pt
}