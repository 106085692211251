import React, {Component} from 'react'
import FeaturedRestaurants from '../../Components/FeaturedRestaurants/FeaturedRestaurants'
import Filters from '../../Components/Filters/Filters'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Offer from '../../Components/Offer/Offer'
import SearchBar from '../../Components/SearchBar/SearchBar'
import smoothscroll from 'smoothscroll-polyfill'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {canUseDOM} from '../../Lib/canUseDOM'
import {searchOffers} from '../../Services/Offers/offers'
import {translate} from '../../Translations/translate'
import { withRouter } from 'react-router'
import {listSavedOffers} from '../../Services/Chef/chef'
import Loader from '../../Components/_NEW/Loader'
import {positions} from '../../Lists/lists'
import ShareButton from "../../Components/_NEW/ShareButton"

if (canUseDOM) {smoothscroll.polyfill();}
const findIndex = require('lodash/findIndex')


class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showFilters: false,
            showSelectedItems: false,
            filters: [],
            items: [],
            page: 0,
            hasMorePages: true,
            selectedFilters: this.props.selectedFilters ? this.props.selectedFilters : [],
            searching: false,
            position: false,
            location: false,
            firstLoad: true,
            isFetching: false,
            currentPathname: null,
            total: null,
            savedOffers: null,
            urlPosition: false,
            urlFilters: false,
            ready: false,
        }
    }
    
    componentDidMount() {
        this.setState({ currentPathname: window.location.pathname })
        this.loadSavedItems()
        this.handleStoredFilters(() => this.loadItems())
    }

    componentWillUnmount() {
        this.props.setSelectedFilters([])
    }
    _pageFromUrl() {
        let rQty = 10
        if (this.props.match.params.page !== undefined && this.state.page === 0 && this.state.firstLoad === true) {
            let page = parseInt(this.props.match.params.page)
            return {
                hasPage: true,
                qty: (page + 1) * rQty,
                urlPage: page
            }
        } else {
            return {
                hasPage: false,
                qty: rQty
            }
        }
    }
    resetSearch(newState, cb = ()=>{}) {
        const state = Object.assign(newState, {
            items: [],
            page: 0,
            hasMorePages: true
        })
        this.setState(state, cb)
    }

    handleStoredFilters = (callback) => {
        const urlSearch = window.location.search ? window.location.search.replace("?", "").split('&') : null
        const urlPosition = urlSearch ? urlSearch.find(i => !i.includes('=')) : false
        const urlFiltersGroups = urlSearch ? urlSearch.filter(i => i.includes("=")).map(f => {
            const split = f.split("=")
            const key = split[0]
            const values = split[1].split(',')
            const formattedValues = values.map(v => {return { id: v, type: key }})
            return formattedValues
        }) : false

        let urlFilters = []
        if (urlFiltersGroups) {
            urlFiltersGroups.forEach(g => g.forEach(i => urlFilters.push(i)))
        }
        if (urlFilters.length === 0) {
            urlFilters = false
        }

        const positionFilter = positions['en'].find(p => p.label.toLowerCase().replaceAll(" ", "-") === urlPosition)

        const storedLocation = sessionStorage.getItem("location")
        const locationFilter = JSON.parse(storedLocation)

        if (!urlPosition && !urlFilters && !locationFilter) {
            return callback()
        } else {
            this.setState({
                urlPosition: urlPosition,
                position: positionFilter ? positionFilter.value : false,
                location: locationFilter ? locationFilter : false,
                isFetching: this.state.isFetching,
                searching: (urlPosition || urlFilters || locationFilter) ? true : false
            }, () => {
                if (urlFilters) {
                    this.setState({ urlFilters: urlFilters })
                    const allFilters = this.state.selectedFilters ? [...this.state.selectedFilters] : []
                    urlFilters.map(i => {
                        if (allFilters.find(f => f.id === i.id)) {
                            const indexOf = allFilters.indexOf(i)
                            allFilters.splice(indexOf, 1)
                        } else {
                            allFilters.push(i)
                        }
                    })
                    this.props.setSelectedFilters(allFilters)
                } else {
                    callback()
                }
            })
        }
    }

    handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        const previousPage = sessionStorage.getItem("previousPage");
        if (scrollPosition && previousPage === this.state.currentPathname) {
            window.scrollTo(0, parseInt(scrollPosition));
        }
        sessionStorage.removeItem("scrollPosition");
        sessionStorage.removeItem("previousPage");
        sessionStorage.removeItem("location");
    };
    handleClick = e => {
        sessionStorage.setItem("scrollPosition", window.scrollY);
        sessionStorage.setItem("previousPage", this.state.currentPathname);
        sessionStorage.setItem("location", JSON.stringify(this.state.location));
    };

    loadSavedItems() {
        const { lang } = this.props.match.params
        listSavedOffers({page: this.state.page, qty: 0, lang}).then((res) => {
            if (res.data.success) {
                this.setState({ savedOffers: res.data.data})
            }
        })
    }
    loadItems(callback) {
        if (this.state.isFetching) {
            if (callback) callback()
            return
        }
        const { lang } = this.props.match.params
        this.setState({ isFetching: true, firstLoad: false })
        let pageInfo = this._pageFromUrl()

        let location = this.state.location ? JSON.stringify(this.state.location) : ''
        let position = this.state.position ? this.state.position : ''
        let filters = this.state.selectedFilters ? this.state.selectedFilters : []

        this.setState({loading: true})
        searchOffers({
            page: this.state.page,
            qty: pageInfo.qty,
            filters: JSON.stringify(filters),
            location: location,
            position: position,
            lang: lang
        }).then((res) => {
            this.setState({isFetching: false, ready: true})
            
            if (res.data.success) {
                this.setState({loading: false})
                let items = this.state.items
                let hasResults = (res.data.data.list && res.data.data.list.length > 0)
                let itemsAdded = 0
                if (hasResults) {
                    res.data.data.list.forEach((item) => {
                        let index = findIndex(items, (o) => {
                            return o.offer.id === item.offer.id
                        })
                        if (index < 0) {
                            items.push(item)
                            itemsAdded++
                        }
                    })
                }
                this.setState({ items: items, filters: res.data.data.filters, hasMorePages: (itemsAdded > 0), total: res.data.data.total })
                if (this.state.page > 0) {
                    this.props.history.replace(`/${lang}/home/${this.state.page}${window.location.search ? window.location.search : ""}`)
                    this.setState({ currentPathname: window.location.pathname })
                }
                if (pageInfo.hasPage) { //if there's a page in the URL
                    this.setState({ page: pageInfo.urlPage + 1 }, () => {
                        this.handleScrollPosition()
                    })
                } else {
                    this.handleScrollPosition()
                    this.setState({ page: this.state.page + 1 })
                    if (callback) callback()
                }
            } else {
                if (callback) callback()
            }
        })
    }

    setUrlFilters(selectedFilters) {
        const formattedFilters = {}
        selectedFilters.map(filter => {
            const keyAlreadyExists = Object.keys(formattedFilters).includes(filter.type)
            if (keyAlreadyExists) {
                formattedFilters[filter.type].push(filter.id)
            } else {
                Object.assign(formattedFilters, { [filter.type]: [filter.id] })
            }
        })

        const searchFiltersString = `${this.state.urlPosition ? `${this.state.urlPosition}&` : ''}${Object.keys(formattedFilters).map((f, index) => {
            return `${index !== 0 ? '&' : ''}${f}=${formattedFilters[f].map((i) => i)}`
        }).join('')}`

        this.setState({ urlFilters: searchFiltersString })
        this.props.history.replace({
            pathname: window.location.pathname,
            search: searchFiltersString ? searchFiltersString : `${this.state.urlPosition ? this.state.urlPosition : ''}`,
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedFilters && this.state.selectedFilters !== nextProps.selectedFilters) {
            let selectedFilters = nextProps.selectedFilters ? nextProps.selectedFilters : []
            let searching = selectedFilters.length > 0 || this.state.urlPosition || this.state.location ? true : false
            // commented: this line made "filters back button" not working on first try
            // let searching = (selectedFilters.length > 0 || this.state.location || this.state.position) ? true : false
            this.resetSearch({ searching: searching, selectedFilters: selectedFilters }, this.loadItems.bind(this))
            if (!this.state.firstLoad) {
                this.setUrlFilters(selectedFilters)
            }
        }
    }
    onBottomReached(done) {
        if (this.state.hasMorePages && !this.state.firstLoad) {
            this.setState({loading: true})
            this.loadItems(done)
        }
    }
    render() {
        const { lang } = this.props.match.params
        let className = 'scene_Home'
        if (this.state.showFilters) {
            className += ' open-filters'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        if (this.state.searching) {
            className += ' searching'
        }
        let meta = {
            title: translate(lang, 'find_jobs'),
            metas: [

            ]
        }

        return (
            <Main
                match={this.props.match}
                ref="main"
                hasInitialCallForBottomReached={true}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={this.onBottomReached.bind(this)}
                meta={meta}
                ready={this.state.ready}
                >
                <div className="content">
                    {this.renderOffers()}
                    {this.state.loading && <Loader />}
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        let items = []
        items.push(<SearchBar
            key="SearchBar"
            type="offers"
            filters={this.state.filters}
            callback={(position, location) => {
                const positionFilter = positions['en'].find(p => p.value === position) ? positions['en'].find(p => p.value === position).label.toLowerCase().replaceAll(" ", "-") : null
                this.resetSearch({ searching: true, position: position, location: location, urlPosition: positionFilter }, () => {
                    if (positionFilter) {
                        this.props.history.replace({
                            pathname: window.location.pathname,
                            search: `${positionFilter}${this.state.urlFilters ? `&${this.state.urlFilters}` : ''}`,
                        })
                    }
                    this.loadItems()
                })
        }} />)
        if (this.props.deviceScreen !== 'mobile') {
            items.push(<FeaturedRestaurants key="FeaturedRestaurants" />)
        }
        return items
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        return (
            <nav className="invisibleBackground">
                <div className="title-nav">
                    <h1>
                        {this.state.searching ? translate(lang, 'search_results') : translate(lang, 'last_job_offers_NUM', parseInt(this.state.total))}
                    </h1>
                    {this.state.searching && <div className="search-options">
                        <span onClick={() => {
                            this.resetSearch({
                                searching: false,
                                selectedFilters: [],
                                position: false,
                                location: false,
                                urlPosition: false,
                                urlFilters: false
                            }, () => {
                                this.props.setSelectedFilters([])
                                this.props.history.replace(`/${lang}`)
                                this.loadItems.bind(this)
                            })
                        }}>{translate(lang, 'back_to_lastest_offers')}</span>
                        {this.state.urlPosition && <div className="share">
                            <ShareButton
                            props={this.props}
                            urlSearchParam={this.state.urlPosition}
                            className="home-filter"
                            image={`https://www.chefslink.com/e/assets/${this.state.urlPosition}.jpg`}
                            />
                        </div>}
                    </div>}
                </div>
            </nav>
        )
    }
    renderLeft() {
        if (this.props.deviceScreen !== 'mobile') {
            return this.renderLeftContent()
        }
        return null
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderOffers() {
        const { lang } = this.props.match.params
        if (this.state.items.length === 0 && !this.state.loading) {
            return <p className="no-results">{translate(lang, 'no_results')}</p>
        } else {
            let items = []
            this.state.items.forEach((item) => {
                items.push(<Offer key={item.offer.id} item={item} handleClick={this.handleClick} savedOffers={this.state.savedOffers}/>)
            })
            return items
        }
    }
    renderLeftContent(style) {
        const { lang } = this.props.match.params
        return (
            <div style={style}>
                <h5>{translate(lang, 'filter_by') + ":"}</h5>
                <Filters filters={this.state.filters} selectedFilters={this.state.selectedFilters} callback={() => {
                    this.props.history.replace(`/${lang}`)
                    window.scrollTo(0, 0);
                    this.setState({ page: 0 })
                }} />
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        selectedFilters: state.selectedFilters,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Home))
