import React, { useState, useEffect } from 'react'
import { Formik, Form } from "formik"

import { translate } from '../../Translations/translate'
import ChefLanguageSchema from "../../FormValidations/ChefLanguageSchema"
import {chefEditLanguage} from '../../Services/Chef/chef'

import {languages, levels} from '../../Lists/lists'

import FormSelect from './FormSelect'
import Button from './Button'

const ChefLanguageForm = ({ props }) => {
  const { lang } = props.match.params
  const { data, setUser, showOverlay } = props
  
  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingAddMore, setLoadingAddMore] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [reset, setReset] = useState(false)
  const [formError, setFormError] = useState(null)

  const handleSave = (values, addMore = false) => {
    if (!addMore) {
      setLoadingSave(true)
    } else {
      setLoadingAddMore(true)
    }
    const formData = {
      id: values.id,
      name: values.language,
      value: values.level,
      lang: lang
    }
    chefEditLanguage(formData).then((res) => {
      setLoadingSave(false)
      if (!res.data.success) {
          setFormError(res.data.message)
          return
      }
      const response = res.data.data
      setUser(response.user)
      if (data.profileNav && !addMore) {
        showOverlay('chefreferral', { id: 0, value: {}, profileNav: true })
      } else if (addMore) {
        setLoadingAddMore(false)
        setReset(true)
      } else {
        showOverlay(null)
      }
    }).catch((e) => {
        setFormError(e.message)
    })
  }

  const handleDelete = () => {
    setLoadingDelete(true)
    chefEditLanguage({id: data.id, action: 'delete', lang}).then((res) => {
        setLoadingDelete(false)
        if (!res.data.success) {
            setFormError(res.data.message)
            return
        }
        const response = res.data.data
        setUser(response.user)
        showOverlay(null)
    }).catch((e) => {
      setFormError(e.message)
    })
  }

  return (
    <div className="component_chefLanguageForm">
      <Formik
        initialValues={{
            id: data.id,
            language: data.language,
            level: data.level,
        }}
        validationSchema={ChefLanguageSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSave(values)}
      >{({ values, errors, resetForm, validateForm }) => {
        
        useEffect(() => {
          if (reset) {
            resetForm()
            setReset(false)
          }
        }, [reset])

        return (
          <Form>
            <div className="fullWidth">
              <div className="halfWidth marginRight">
                <FormSelect
                  label={translate(lang, 'language')}
                  id="language"
                  name="language"
                  options={languages[lang]}
                  errors={errors}
                  formError={formError}
                  placeholder={translate(lang, 'placeholder_select_language')}
                  showErrorMessage={false}
                />
              </div>
              <div className="halfWidth marginLeft">
                <FormSelect
                  label={translate(lang, 'level')}
                  id="level"
                  name="level"
                  options={levels[lang]}
                  errors={errors}
                  formError={formError}
                  placeholder={translate(lang, 'placeholder_select_level')}
                  showErrorMessage={false}
                  disableSearch
                />
              </div>
            </div>
            <div className={`buttonRow ${formError || (errors && Object.keys(errors).length > 0) ? "hasError" : ""}`}>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            {errors && Object.keys(errors).length > 0 && <div className="errorMessage">{translate(lang, 'error_required_fields')}</div>}
              <div className='buttons'>
                {parseInt(data.id) !== 0 && <div className="nextTo">
                  <Button
                  label={translate(lang, 'delete').toUpperCase()}
                  className="red"
                  onClick={() => handleDelete()}
                  loading={loadingDelete}
                  /></div>}
                {parseInt(data.id) === 0 && <div className="nextTo">
                    <Button
                      label={translate(lang, 'save_add_more').toUpperCase()}
                      loading={loadingAddMore}
                      onClick={() => validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                        handleSave(values, true)
                      }})}
                    />
                  </div>}
                  <Button
                    label={data.profileNav? translate(lang, 'save_continue').toUpperCase() : translate(lang, 'save').toUpperCase()}
                    submit
                    loading={loadingSave}
                  />
              </div>
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default ChefLanguageForm