import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import MonthView from 'react-calendar/dist/entry.nostyle'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {listItems, listItemsMore, listLink, listBtnSendCV} from '../RestaurantView/RestaurantViewTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {report, block} from '../../Services/Util/util'
import {requestPractice} from '../../Services/Practice/practice'
import {viewRestaurant} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Loader from "../../Components/_NEW/Loader"
import RestaurantTopNavMobile from '../../Components/_NEW/RestaurantTopNavMobile'


class RestaurantViewStage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            loadingOffers: true,
            loadingSend: false,
            item: null,
            items: [],
            id: this.props.match.params.id
        }
    }

    _initState() {
        const { lang } = this.props.match.params
        viewRestaurant(this.state.id, lang).then((res) => {
            if (res.data.success) {
                this.setState({ item: res.data.data, slug: res.data.data.profile.slug, loading: false })
            }
        })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }
    render() {

        let className = 'scene_RestaurantViewStage'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.item) {
            let profile = this.state.item.profile
            meta = {
                title: profile.name,
                metas: [
                    {name: 'og:image', value: profile.images.image1 ? profile.images.image1.sizes.share.url : null}
                ]
            }
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                meta={meta}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if ( this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (!this.state.item) {
            return null
        }
        return (
            <ImageHeader
                left={this.state.item.profile.images.logo ? this.state.item.profile.images.logo.sizes.square.url : '/static/images/empty_600_600.png'}
                right={this.state.item.profile.images.header ? this.state.item.profile.images.header.sizes.header.url : null}
                alt={this.state.item.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (!this.state.item) {
            return null
        }

        let items = listItems(this.props.isLogged, this.props.userType, this.state, lang)
        let itemsMore = listItemsMore(this.props.isLogged, this.props.userType, this.state, {
            report: (type) => {
                report({offender: this.state.item.profile.acc, ischef: 0, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            },
            block: (type) => {
                block({offender: this.state.item.profile.acc, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            }
        }, lang
        )
        let itemsLink = listLink(this.props.isLogged, this.props.userType, this.state, lang) //TODO callback
        let btn2 = listBtnSendCV(this.props.isLogged, this.props.userType, this.state, () => {
            this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
        }, lang)
        if (this.props.deviceScreen === 'mobile') {
            return (
                <RestaurantTopNavMobile props={this.props} state={this.state} screen="stage" />
            )
        } else {
            return (
                <div>
                    <nav>
                        <TopNavMenu items={items} more={itemsMore} link={itemsLink} btn={btn2} />
                    </nav>
                    <h4><span>{this.state.item.profile.name}</span> / {translate(lang, 'stage')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        if (this.props.deviceScreen === 'mobile' || !this.state.item) {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.item.profile.name}</h3>
                        <span>{readableLocation(this.state.item.profile.location)}</span>
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading || !this.state.item) {
            return <Loader />
        }

        return (
            <div>
                <h4>{translate(lang, 'book_stage')}</h4>
                <div className="col-group">
                    <div className="col-mb-12 col-6">
                        <span dangerouslySetInnerHTML={{__html:this.state.item.profile.stageinfo}}></span>
                    </div>
                </div>
                <div className="col-group">
                    <div className="col-mb-12 col-6 calendar-view">
                        <MonthView
                            minDate={new Date()}
                            onChange={(date) => {this.setState({startDate: date})}}
                            value={this.state.startDate}
                            locale="en-En"
                            view="month"
                            tileClassName={({date, view }) => {
                                return date === this.state.startdate ? 'react-calendar__tile--active' : ''
                            }}
                            />
                    </div>
                    <div className="col-mb-12 col-6 calendar-view">
                        <MonthView
                            minDate={new Date()}
                            onChange={(date) => {this.setState({endDate: date})}}
                            value={this.state.endDate}
                            locale="en-En"
                            view="month"
                            tileClassName={({date, view }) => {
                                return date === this.state.startdate ? 'react-calendar__tile--active' : ''
                            }}
                            />
                    </div>
                </div>
                {this.renderBottom()}
            </div>
        )
    }
    renderBottom() {
        const { lang } = this.props.match.params
        if (this.state.startDate && this.state.startDate && (this.state.startDate < this.state.endDate)) {
            return (
                <div className="bottom col-group">
                    <div className="col-mb-12">
                        <span>
                        {translate(lang, 'from').toUpperCase()} {moment(this.state.startDate).format('Do [OF] MMMM YYYY')} {translate(lang, 'to').toUpperCase()} {moment(this.state.endDate).format('Do [OF] MMMM YYYY')}
                        <br/>
                        </span>
                        <Btn
                            text={translate(lang, 'send').toUpperCase()}
                            className={this.state.startDate ? '' : 'inverted disabled'}
                            loading={this.state.loadingRequest}
                            callback={() => {
                                this.setState({loadingRequest: true})
                                requestPractice({ restaurant: this.state.item.profile.id, date: moment(this.state.startDate).format('YYYY-MM-DD'), ends: moment(this.state.endDate).format('YYYY-MM-DD'), lang}).then((res) => {
                                    this.setState({loadingRequest: false})
                                    if (!res.data.success) {
                                        this.props.showOverlay('error', {message: res.data.message})
                                        return
                                    } else {
                                        this.props.showOverlay('success', {message: translate(lang, 'request_sent')})
                                    }
                                })
                            }}
                            />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="bottom">{translate(lang, 'select_dates')}</div>
            )
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantViewStage))
