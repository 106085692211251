import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import SVG from 'react-inlinesvg';
import WorkExperience from '../../Components/WorkExperience/WorkExperience'
import LazyImage from '../../Components/LazyImage/LazyImage'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findOption} from '../../Lib/findOption'
import {fixHtml} from '../../Lib/fixHtml'
import {positions} from '../../Lists/lists'
import {readableLocation} from '../../Lib/readableLocation'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import {deleteApplicant} from '../../Services/Offers/offers'
var find = require('lodash/find')
var sortBy = require('lodash/sortBy')
var reverse = require('lodash/reverse')


class Chef extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false,
            showMoreExperience: false
        }
    }
    render() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let url = `/${lang}/chef/view/` + item.profile.id + '/' + item.profile.slug

        return (
            <div className={`component_Chef ${this.props.isApplication ? "application" : "" }`}>
                <div>
                    <div className="top">
                        {this.renderTop()}
                    </div>
                    <div className="wrap">
                        {this.props.deviceScreen !== 'mobile' && this.props.deleteButton && this.props.application
                        && this.renderDeleteButton()}
                        <div className="inner-wrap">
                            {this.props.deviceScreen !== 'mobile' ? this.renderLogo() : null}
                            <div className="main-info">
                                <div className='main-info-top-mobile'>
                                    {this.props.deviceScreen === 'mobile' ? this.renderLogo() : null}
                                    <div className='title-description'>
                                        <h2 onClick={this.props.handleClick}>
                                            <Link to={url}>
                                            {this.renderName(item)}
                                            </Link>
                                        </h2>
                                        <p className="the-description">{this.renderDescription()}</p>
                                    </div>
                                </div>
                                {(this.props.isApplication && this.props.deviceScreen === "mobile")
                                    ? null
                                    : this.renderWorkExperience()}
                            </div>
                            {this.props.extraContent ? this.props.extraContent : null}
                            {this.props.deviceScreen === 'mobile' ? this.renderTopBtnButtons() : null}
                        </div>
                        <div className="bottom">
                            {this.props.alternativeDate ?
                                this.props.alternativeDate :
                                <span>{moment(item.profile.updated).format(this.props.deviceScreen === 'mobile' ? 'D MMM' : 'D MMM[.] YYYY')}</span>
                            }
                            {this.renderShareOptions()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderName(item) {
        const { lang } = this.props.match.params
        let nameParts = []
        let position = findOption(item.profile.position, positions[lang], false)
        if (position) {
            nameParts.push(position)
        }
        if (item.profile.nationality !== '') {
            nameParts.push(item.profile.nationality)
        }
        let location = readableLocation(item.profile.location)
        if (location !== '') {
            nameParts.push(location)
        }
        return nameParts.join(' / ')
    }
    renderDescription() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let {description} = item.profile
        let teaserDesktop = !this.state.showMore && description.length > 200 ? description.substring(0, 200) + '...' : description
        let teaserMobile = !this.state.showMore && description.length > 80 ? description.substring(0, 80) + '...' : description
        let teaser = this.props.deviceScreen === 'mobile' ? teaserMobile : teaserDesktop
        return (
            <span>
                <span dangerouslySetInnerHTML={{__html: fixHtml(teaser)}}></span>
                <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
            </span>
        )
    }
    renderShareOptions() {
        if (this.props.userType === 'restaurant') {
            if (this.props.alternativeShare) {
                return this.props.alternativeShare
            } else {
                return (
                    <div className="share">
                        {parseInt(this.props.user.subscription) === 1 && this.renderMessage()}
                        {this.renderShare()}
                    </div>
                )
            }
        }
        return null
    }
    renderLogo() {
        let {item} = this.props
        let image = item.profile.images.profile ? item.profile.images.profile.sizes.square.url : 'http://via.placeholder.com/240x240/eee/fff'
        return (
            <div className="logo">
                <LazyImage src={image} alt={item.profile.name}/>
                {this.props.deviceScreen !== 'mobile' ? this.renderTopBtnButtons() : null}
            </div>
        )
    }
    renderTopBtnButtons() {
        if (this.props.application && (this.props.topBtn || this.props.bottomBtn)) {
            return (
                <div className="top-bottom-buttons">
                    {this.props.topBtn ? this.props.topBtn : null}
                    {this.props.bottomBtn ? this.props.bottomBtn : null}
                </div>
            )
        }
        return null
    }
    renderTop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        //selectedChefs, only for restaurants
        let selected = find(this.props.selectedChefs, {id: item.profile.id}) ? true : false
        if (this.props.deviceScreen === 'mobile') {
            let select = (
                <div className="select" onClick={() => {
                    this.props.toggleSelectedChef({id: item.profile.id, item: item})
                }}>
                    <span className="text">{translate(lang, 'send').toUpperCase()}</span>
                    <SVG src={selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'} />
                </div>
            )
            return (
                <div>
                    <div className="name">
                        <span>{item.profile.name}</span>
                    </div>
                    {this.props.deviceScreen === 'mobile' && this.props.deleteButton && this.props.application
                    && this.renderDeleteButton()}
                    {item.profile.status && item.profile.status > 2 ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                    {this.props.isLogged && this.props.userType === 'restaurant' && this.props.hideSelect !== true && item.profile.inapply === 0 && item.profile.invited === 0 ? select : null}
                </div>
            )
        } else {
            let select = (
                <div className="select" onClick={() => {
                    this.props.toggleSelectedChef({id: item.profile.id, item: item})
                }}>
                    <SVG src={selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'} />
                    <span className="text">{translate(lang, 'send').toUpperCase()}</span>
                </div>
            )
            return (
                <div>
                    <div className="name">
                        <div className="name-text"><span>{item.profile.name}</span></div>
                        {this.props.isLogged && this.props.userType === 'restaurant' && this.props.hideSelect !== true && item.profile.inapply === 0 && item.profile.invited === 0 ? select : null}
                    </div>
                    <span className="location">{readableLocation(item.profile.location)}</span>
                </div>
            )
        }
    }

    renderMessage() {
        const { lang } = this.props.match.params
        if (this.props.userType !== 'restaurant') {
            return null
        }
        let text = this.props.deviceScreen === 'mobile' ? translate(lang, 'mess').toUpperCase() : translate(lang, 'message').toUpperCase()
        // let icon = this.props.deviceScreen === 'mobile' ? null : 'message_icn'
        let icon = 'message_icn'
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : text} className="text" post={icon} callback={() => {
                this.props.showOverlay('message', {cid: this.props.item.profile.id, rid: this.props.user.main.id})
            }} />
        )
    }
    renderShare() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let url = `/${lang}/chef/view/` + item.profile.id + '/' + item.profile.slug
        let image = item.profile.images.profile && item.profile.images.profile.sizes && item.profile.images.profile.sizes.share ? item.profile.images.profile.sizes.share.url : 'http://via.placeholder.com/240x240/eee/fff'
        // let icon = this.props.deviceScreen === 'mobile' ? null : 'share_icn'
        let icon = 'share_icn'
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : translate(lang, 'share').toUpperCase()} className="text" post={icon} callback={() => {
                this.props.showOverlay('share', {title: this.props.item.profile.name, url: url, image: image})
            }} />
        )
    }
    renderWorkExperience() {
        const { lang } = this.props.match.params
        if (!this.props.item.profile.experience) {
            return null
        }
        let readMoreBtn = <span key="readMoreBtn" className="read-more experience" onClick={() => {this.setState({showMoreExperience: !this.state.showMoreExperience})}}>{this.state.showMoreExperience ? translate(lang, 'less') : translate(lang, 'more')}</span>
        let items = []

        let experience = this.props.item.profile.experience
        experience = sortBy(experience, (a) => {
            if (a.value !== null) {
                return `${a.value.fromYear}-${a.value.fromMonth < 10 ? `0${a.value.fromMonth}` : a.value.fromMonth}-01`
            }
            }
        )
        experience = reverse(experience)
        experience.forEach((item) => {
            if (item.value !== null){
                items.push(this.renderWorkExperienceItem(item))
            }
        })
        if (items.length > 2) {
            items = this.state.showMoreExperience ? items : [items[0], items[1]]
            if (this.props.deviceScreen !== 'mobile') {
                items.push(readMoreBtn)
            }
        }

        if (this.props.deviceScreen === 'mobile') {
            let h = <div key="header" className="experience-title"><h5>{translate(lang, 'work_experience').toUpperCase()}</h5>{this.props.item.profile.experience.length > 2 ? readMoreBtn : null}</div>
            items = [h, ...items]
        }

        return items
    }
    renderWorkExperienceItem(item) {
        return (
            <WorkExperience
                key={item.id}
                item={item}
                hideDescription={true}
                />
        )
    }

    renderDeleteButton() {
        const { lang } = this.props.match.params
        return(
            <a className="delete" onClick={() => {
                const result = window.confirm(translate(lang, 'delete_application'))
                if (result) {
                    deleteApplicant({id: this.props.application.id, oid: this.props.offerId, lang}).then((res) => {
                        if (res.data.success) {
                            this.props.deleteButtonCallback()
                        }
                    })
                } else {
                    return
                }
            }}>
                <SVG src="/static/images/close_btn_2.svg" />
            </a>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        selectedChefs: state.selectedChefs,
        userType: state.userType,
        isLogged: state.isLogged,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Chef))
