import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Input from '../../Components/Input/Input'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {restaurantEditEmail} from '../../Services/Restaurant/restaurant'
import {validate} from '../../Components/Input/validate'
import {translate} from '../../Translations/translate'
import { withRouter } from 'react-router'

class RestaurantEmailOverlay extends Component {
    constructor(props) {
        super(props)
        let value = this.props.data.value
        this.state = {
            id: this.props.data.id,
            value: value.value ? value.value : '',
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'edit_email')} className={this.props.className}>
                <div className="col-group form">
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'email')}
                            name="value"
                            error={this.state.errors}
                            value={this.state.value}
                            onChange={(v) => {
                                this.setState({value: v})
                            }}
                            />
                    </div>

                    <div className="col-mb-12 actions multiple">
                        {parseInt(this.state.id) !== 0 ? <div className="accessory">
                            <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                this.delete()
                            }} />
                        </div> : null}
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    save() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'value'},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })
            //SAVE
            let data = {
                rid: this.props.data.rid,
                id: this.state.id,
                name: 'email',
                order: 0,
                value: JSON.stringify({
                    value: this.state.value,
                }),
                lang: lang
            }
            restaurantEditEmail(data).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                let data = res.data.data
                this.props.setUser(data.user)
                this.props.showOverlay(null)
            }).catch((e) => {
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }
    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        restaurantEditEmail({rid: this.props.data.rid, id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            let data = res.data.data
            this.props.setUser(data.user)
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantEmailOverlay))
