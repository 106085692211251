import React, {Component} from 'react'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ContactForm from '../../Components/ContactForm/ContactForm'
import SocialNetworks from '../../Components/SocialNetworks/SocialNetworks'
import Main from '../../Components/Layout/Main'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'


class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false,
            callback: false,
        }
    }

    render() {
        const { lang } = this.props.match.params

        let className = 'scene_Contact'
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta = {
            title: translate(lang, 'contact'),
            metas: [
            ]
        }
        return (
            <Main
                match={this.props.match}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                meta={meta}
                >
                <div className="content">
                    {this.renderContent()}
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.state.loading || this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        const { lang } = this.props.match.params
        return (
            <ImageHeader left="/static/images/logo.png" right="/static/images/header_default.png" alt={translate(lang, 'contact').toUpperCase()}/>
        )
    }
    renderLeft() {
        return <div></div>
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderContent() {
        const { lang } = this.props.match.params
        let title = this.state.callback ? translate(lang, 'request_callback') : translate(lang, 'drop_us_a_line')
        return (
            <div className="container-fluid">
                <h1>{title}</h1>
                <ul className="nav">
                    <li onClick={()=>{this.setState({callback:false})}} className={!this.state.callback ? 'active' : null}><a href="">{translate(lang, 'drop_us_a_line')}</a></li>
                    <li onClick={()=>{this.setState({callback:true})}} className={this.state.callback ? 'active' : null}><a href="">{translate(lang, 'request_callback')}</a></li>
                    <li className="social-networks"><SocialNetworks /></li>
                </ul>
                <div className="blocks">
                    <div className="block">
                        <ContactForm
                            isCallback={this.state.callback}
                        />
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Contact))
