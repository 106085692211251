import {apiFetch} from '../../Config/api'

export function chefEditAbout(data) {
    return apiFetch('profile/aboutchef', data) // previous About form -> name, description, position, location, dob, nationalities, visas, cuisine, lang
}
export function chefEditWorkExperience(data) {
    return apiFetch('profile/chefmeta/experience', data)
}
export function chefEditEducation(data) {
    return apiFetch('profile/chefmeta/education', data)
}
export function chefEditLanguage(data) {
    return apiFetch('profile/chefmeta/languages', data)
}
export function chefEditReferral(data) {
    return apiFetch('profile/chefmeta/referrals', data)
}
export function chefToggle(lang) {
    return apiFetch('profile/toggleChef', lang)
}
export function listSavedOffers(data) {
    return apiFetch('offer/chef/saved', data)
}
export function listSubscribedOffers(data) {
    return apiFetch('offer/chef/list', data)
}
export function listLinks(data) {
    return apiFetch('link/chefs/list', data)
}
export function acceptLink(data) {
    data.action = 'accept'
    return apiFetch('link/make', data)
}
export function rejectLink(data) {
    data.action = 'reject'
    return apiFetch('link/make', data)
}
export function chefEditDescription(data) {
    return apiFetch('profile/descriptionchef', data) // new About form --> description, lang
}
export function chefEditInfo(data) { // new Settings form for chefs -> name, position, dob, location, nationalities, visas, cuisine, lang, password, rpassword, phone, contactemail, language
    return apiFetch('profile/infochef', data)
}
