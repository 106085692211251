import React, {Component} from 'react'
import Select from 'react-select'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import { connect } from 'react-redux'

class LanguageTranslator extends Component {
    constructor(props){
        super(props)

        this.state = {
            lang: props.user.language,
            errors: []
        }

        this.options = [
            {value: 'en', label: 'English'},
            {value: 'es', label: 'Español'},
            {value: 'fr', label: 'Français'},
            {value: 'pt', label: 'Português'}
        ]
    }

    componentDidMount() {
        const { lang } = this.state
        this.updateLanguage(lang)
    }

    updateLanguage(lang) {
        const shouldUpdateLanguage = this.props.languageCallback(lang, true)
        if (shouldUpdateLanguage) {
            this.setState({ lang })
        }
    }

    render(){
        return (
            <div className="component_LanguageTranslator">
                <Select
                    placeholder={this.state.lang}
                    name="translator"
                    value={this.state.lang}
                    className={this.state.errors.indexOf('translator') !== -1 ? 'error' : null}
                    onChange={(option) => {
                        this.updateLanguage(option.value)
                    }}
                    options = {this.options}
                    clearable = {false}
                    deleteRemoves= {false}
                    />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(LanguageTranslator))