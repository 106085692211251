import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Input from '../../Components/Input/Input'
import LocationInput from '../../Components/Input/LocationInput'
import Overlay from './Overlay'
import Select from 'react-select'
import Textarea from '../../Components/Input/Textarea'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {ratingTypes, ratings, languages, cuisines, countries} from '../../Lists/lists'
import {restaurantEditAbout} from '../../Services/Restaurant/restaurant'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { refreshUser } from '../../Services/Access/access'
import Cookies from 'js-cookie'
var find = require('lodash/find')

class RestaurantAboutOverlay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            loading: (Cookies.get('token') !== undefined),
            changedContents: false,
            lang: props.match.params.lang,
        }

        this.descriptionTextarea = React.createRef()
        this.stageInfoTextarea = React.createRef()
        this.emailMessageTextarea = React.createRef()
    }



    parseDataToState(data, callback = () => {}) {

        const { lang } = this.state

        let nationalities = []
        if (data.nationalities) {
            data.nationalities.forEach((n) => {
                let item = find(countries[lang], {value: n})
                if (item) {
                    nationalities.push(item)
                }
            })
        }

        let workvisas = []
        if (data.workvisas) {
            data.workvisas.forEach((n) => {
                let item = find(countries[lang], {value: n})
                if (item) {
                    workvisas.push(item)
                }
            })
        }
        this.setState({
            id: parseInt(data.id),
            name: data.name ? data.name : '',
            description: data.description ? data.description : '',
            location: data.location ? data.location : null,
            rating: data.rating ? data.rating : '',
            ratingType: data.ratingType ? data.ratingType : '',
            url: data.url ? data.url : '',
            cuisine: data.cuisine ? data.cuisine : '',
            stageinfo: data.stageinfo ? data.stageinfo : '',
            language: data.language && data.language.length > 0 ? data.language : '',
            emailmessage: data.emailmessage ? data.emailmessage : '',
            contactemail: data.contactemail ? data.contactemail : '',
            nationalities: nationalities,
            workvisas: workvisas,
        }, callback)
    }

    componentDidMount() {
        this.parseDataToState(this.props.data,
            () => {
                this.shouldPerformRefreshUser(this.setState({loading: false}))
            })
        }

    shouldPerformRefreshUser(callback = () => {}) {
        const { id } = this.state
        if (typeof id !== 'undefined' && id > 0) {
            this.performRefreshUser()
        } 
        return callback
    }

    languageCallback(lang) {
        const {
            changedContents,
        } = this.state

        if (!changedContents) {
            this.setState({ lang }, () => this.shouldPerformRefreshUser())
            return true
        } else {
            const hasErrors = this.validateForm(false)
            this.save(false, () => {
                this.setState({ lang }, () => this.shouldPerformRefreshUser())
            })
            return hasErrors
        }
    }

    performRefreshUser() {
        const { lang } = this.state
        const token = Cookies.get('token')
        refreshUser({ token: token, lang }).then((res) => {
            const { user } = res.data.data

            let description = ''
            let stageinfo = ''
            let emailmessage = ''

            if (typeof this.state.id !== 'undefined' && this.state.id > 0) {
                const profile = user.profiles.find(profile => parseInt(profile.id) === this.state.id)
                description = profile.description
                stageinfo = profile.stageinfo
                emailmessage = profile.emailmessage
            }

            this.setState({ loading: false, description: description, stageinfo: stageinfo, emailmessage: emailmessage }, () => {
                this.descriptionTextarea.setContent(description)
                this.stageInfoTextarea.setContent(stageinfo)
                this.emailMessageTextarea.setContent(emailmessage)
            })
        })
    }

    validateForm(updateState = true){
        let items = [
            {field: 'name' , minLength: 4},
            {field: 'description', minLength: 4},
            {field: 'cuisine'},
        ];
        let errors = validate(items, this.state)
        if (updateState) this.setState({ errors })
        return errors.length === 0
    }

    handleChange(name, value) {
        this.setState({ [name]: value, changedContents: true })
    }

    save(closeModelAfterSave = false, callback = () => {}) {
        const { lang } = this.state

        const isValid = this.validateForm()

        if (isValid) {
            let nationalities = []
            this.state.nationalities.forEach((n) => {
                nationalities.push(n.value)
            })

            let workvisas = []
            this.state.workvisas.forEach((n) => {
                workvisas.push(n.value)
            })

            let updateData = {
                id: this.state.id || "",
                name: this.state.name,
                description: this.state.description,
                rating: this.state.rating,
                ratingtype: this.state.ratingType,
                url: this.state.url,
                cuisine: this.state.cuisine,
                worklanguage: this.state.language,
                stageinfo: this.state.stageinfo,
                emailmessage: this.state.emailmessage,
                contactemail: this.state.contactemail,
                location: JSON.stringify(this.state.location),
                nationalities: nationalities.join(','),
                visas: workvisas.join(','),
                lang: lang
            }

            this.setState({ loading: true })

            restaurantEditAbout(updateData).then((res) => {
                this.setState({ loading: false, changedContents: false })

                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                } else {
                    const { data } = res.data

                    if (typeof updateData.id !== 'undefined' && updateData.id > 0) {
                        const profile = data.user.profiles.find(profile => parseInt(profile.id) === updateData.id)
                        this.parseDataToState(profile)
                    } else {
                        this.parseDataToState(data.user.profiles[data.user.profiles.length - 1])
                    }
                    this.props.setUser(data.user)
                    if (closeModelAfterSave){
                        this.props.showOverlay(null)
                    } else {
                        if (callback) callback()
                    }
                }
            }).catch((e) => {
                this.setState({ loading: false, })
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }

    render() {
        const { lang } = this.props.match.params
        const pseudoEmpty = '<span><br></span>'
        const { description, stageinfo, emailmessage } = this.props.data 
        const textareasAreEmpty = (description === '' || description === pseudoEmpty)
                                    && (stageinfo === '' || stageinfo === pseudoEmpty)
                                    && (emailmessage === '' || emailmessage === pseudoEmpty)

        return (
            <Overlay
                title={this.state.id ? translate(lang, 'edit_about') : translate(lang, 'create_restaurant')}
                className={this.props.className}
                languageTranslator={!textareasAreEmpty}
                languageCallback={this.languageCallback.bind(this)}
                >
                <div className="col-group form">
                    <div className="col-mb-12">
                        <Input
                            label={translate(lang, 'name')}
                            name="name"
                            value={this.state.name}
                            error={this.state.errors}
                            onChange={v => this.handleChange('name', v)}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <LocationInput
                            label={translate(lang, 'location')}
                            name="location"
                            error={this.state.errors}
                            value={this.state.location}
                            onChange={v => this.handleChange('location', v)}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'url')}
                            name="url"
                            value={this.state.url}
                            error={this.state.errors}
                            onChange={v => this.handleChange('url', v)}
                            />
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <label htmlFor="cuisine">{translate(lang, 'cuisine')}</label>
                                <Select
                                    placeholder=""
                                    name="cuisine"
                                    value={this.state.cuisine}
                                    className={this.state.errors.indexOf('cuisine') !== -1 ? 'error' : null}
                                    onChange={option => this.handleChange('cuisine', option ? option.value : -1)}
                                    options={cuisines[lang]}
                                    />
                            </div>
                            <div className="col-mb-12 col-6">
                                <label htmlFor="language">{translate(lang, 'language')}</label>
                                <Select
                                    placeholder=""
                                    name="language"
                                    value={this.state.language}
                                    className={this.state.errors.indexOf('language') !== -1 ? 'error' : null}
                                    onChange={option => this.handleChange('language', option ? option.value : -1)}
                                    options={languages[lang]}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <label htmlFor="nationalities">{translate(lang, 'nationalities')}</label>
                                <Select
                                    multi
                                    placeholder=""
                                    name="nationalities"
                                    value={this.state.nationalities}
                                    className={this.state.errors.indexOf('nationalities') !== -1 ? 'error' : null}
                                    onChange={v => this.handleChange('nationalities', v)}
                                    options={countries[lang]}
                                    />
                            </div>
                            <div className="col-mb-12 col-6">
                                <label htmlFor="workvisas">{translate(lang, 'work_visas')}</label>
                                <Select
                                    multi
                                    placeholder=""
                                    name="workvisas"
                                    value={this.state.workvisas}
                                    className={this.state.errors.indexOf('workvisas') !== -1 ? 'error' : null}
                                    onChange={v => this.handleChange('workvisas', v)}
                                    options={countries[lang]}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-2">
                                <label htmlFor="type">{translate(lang, 'rating')}</label>
                                <Select
                                    placeholder=""
                                    name="rating"
                                    value={this.state.rating}
                                    className={this.state.errors.indexOf('rating') !== -1 ? 'error' : null}
                                    onChange={option => this.handleChange('rating', option ? option.value : '')}
                                    options={ratings[lang]}
                                    filterOptions={(options, currentValues) => {
                                        let max = find(ratingTypes, {'value': this.state.ratingType})
                                        return (max && max['max']) ? options.slice(0, max['max']) : options
                                    }}
                                    />
                            </div>
                            <div className="col-mb-12 col-4">
                                <label htmlFor="ratingType">{translate(lang, 'rating_type')}</label>
                                <Select
                                    placeholder=""
                                    name="ratingType"
                                    value={this.state.ratingType}
                                    className={this.state.errors.indexOf('ratingType') !== -1 ? 'error' : null}
                                    onChange={option => this.handleChange('ratingType', option ? option.value : '')}
                                    options={ratingTypes[lang]}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <Textarea
                            ref={(ref) => this.descriptionTextarea = ref}
                            lang={lang}
                            label={translate(lang, 'about')}
                            name="description"
                            error={this.state.errors}
                            type="text"
                            value={this.state.description}
                            minLength={4}
                            onChange={v => this.handleChange('description', v)}
                            />
                    </div>
                    <div className="col-mb-12">
                        <Textarea
                            ref={(ref) => this.stageInfoTextarea = ref}
                            lang={lang}
                            label={translate(lang, 'stage_info')}
                            name="stageinfo"
                            error={this.state.errors}
                            type="text"
                            value={this.state.stageinfo}
                            minLength={4}
                            onChange={v => this.handleChange('stageinfo', v)}
                            />
                    </div>
                    <div className="col-mb-12">
                        <Textarea
                            ref={(ref) => this.emailMessageTextarea = ref}
                            lang={lang}
                            label={translate(lang, 'default_email_message')}
                            name="emailmessage"
                            value={this.state.emailmessage}
                            error={this.state.errors}
                            type="text"
                            onChange={v => this.handleChange('emailmessage', v)}
                            />
                    </div>
                    <div className="col-mb-12">
                        <Input
                            label={translate(lang, 'restaurant_manager_email')}
                            name="contactemail"
                            value={this.state.contactemail}
                            error={this.state.errors}
                            onChange={v => this.handleChange('contactemail', v)}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save(true)
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantAboutOverlay))
