import React, {Component} from 'react'
import AuthorInfo from '../../Components/AuthorInfo/AuthorInfo'
import Education from '../../Components/Education/Education'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import SidebarMenu from '../../Components/SidebarMenu/SidebarMenu'
import WorkExperience from '../../Components/WorkExperience/WorkExperience'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {positions, languages, levels, countries} from '../../Lists/lists'
import {findOption} from '../../Lib/findOption'
import {readableLocation} from '../../Lib/readableLocation'
import {viewChef} from '../../Services/Chefs/chefs'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import ChefViewLeftColumn from '../../Components/_NEW/ChefViewLeftColumn'
import ProfileSection from '../../Components/_NEW/ProfileSection'
import ProfileTopMenu from '../../Components/_NEW/ProfileTopMenu'
import Loader from "../../Components/_NEW/Loader"

var chunk = require('lodash/chunk')
var sortBy = require('lodash/sortBy')
var reverse = require('lodash/reverse')


class ChefView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            item: null,
            id: this.props.match.params.id,
            slug: this.props.match.params.slug,
        }
    }



    componentDidMount() {
        const { lang } = this.props.match.params
        viewChef(this.props.match.params.id, lang).then((res) => {
            if (res.data.success) {
                this.setState({ item: res.data.data, loading: false })
            }
        })
    }
    render() {
        const { lang } = this.props.match.params

        let className = 'scene_ChefView'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.item) {
            let profile = this.state.item.profile
            meta = {
                title: profile.name,
                metas: [
                    {name: 'og:image', value: profile.images.profile ? profile.images.profile.sizes.share.url : null}
                ]
            }
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                darkBackground
                meta={meta}
                >
                <div className="content">
                    {this.props.deviceScreen === 'mobile' && this.state.item && <div>
                        <ChefViewLeftColumn props={this.props} item={this.state.item} />
                        <ProfileTopMenu
                            props={this.props}
                            items={[
                                {url: 'about', title: translate(lang, 'about')},
                                {url: 'work-experience', title: translate(lang, 'work_experience')},
                                {url: 'education', title: translate(lang, 'education')},
                                {url: 'languages', title: translate(lang, 'languages')},
                                {url: 'referrals', title: translate(lang, 'more_info')},
                            ]}
                        />
                    </div>}
                    {/* {this.props.deviceScreen === 'mobile' && this.state.item ? <ChefInfo profile={this.state.item.profile} /> : null} */}
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (!this.state.item) {
            return null
        }
        let {images} = this.state.item.profile
        return (
            <ImageHeader
                left={images.profile && images.profile.sizes ? images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                right={images.header && images.header.sizes ? images.header.sizes.header.url : null}
                alt={images.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile' || !this.state.item ) {
            return null
        }
            
            return (
                <ProfileTopMenu
                props={this.props}
                items={[
                    {url: 'about', title: translate(lang, 'about')},
                    {url: 'work-experience', title: translate(lang, 'work_experience')},
                    {url: 'education', title: translate(lang, 'education')},
                    {url: 'languages', title: translate(lang, 'languages')},
                    {url: 'referrals', title: translate(lang, 'more_info')},
                ]}
                share
                buttonLabel={this.props.isLogged && this.props.userType === 'restaurant' && this.state.item.profile.inapply === 0 && this.state.item.profile.invited === 0 ? translate(lang, 'send_job_offer') : null}
                buttonCallback={this.props.isLogged && this.props.userType === 'restaurant' && this.state.item.profile.inapply === 0 && this.state.item.profile.invited === 0 ? () => {
                    this.props.toggleSelectedChef({id: this.state.item.profile.id, item: this.state.item})} : null}
                buttonSecondaryLabel={(this.props.isLogged && this.props.userType === 'restaurant' && parseInt(this.props.user.main.premium) === 1) ? translate(lang, 'message') : null}
                buttonSecondaryCallback={(this.props.isLogged && this.props.userType === 'restaurant' && parseInt(this.props.user.main.premium) === 1) ? () => {
                    this.props.showOverlay('message', {rid: this.props.user.main.id, cid: this.state.item.profile.id, sender: 'restaurant' })
                } : null}
            />
            )
    }
    renderLeft() {
        if (!this.state.item || this.props.deviceScreen === 'mobile') {
            return null
        }
        return (
            <ChefViewLeftColumn props={this.props} item={this.state.item} />
        )
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        let items = [
            {url: 'about', title: translate(lang, 'about_chef')},
            {url: 'work-experience', title: translate(lang, 'work_experience')},
            {url: 'education', title: translate(lang, 'education_training')},
            {url: 'languages', title: translate(lang, 'languages')},
            {url: 'referrals', title: translate(lang, 'more_info')},
        ]
        if (this.props.deviceScreen === 'mobile' || !this.state.item) {
            return null
        } else {
            let nationalities = []
            this.state.item.profile.nationalities.forEach((n) => {
                if (findOption(n, countries[lang], false)) {
                    nationalities.push(findOption(n, countries[lang]))
                }
            })

            return (
                <div>
                    <div className="author">
                        <h1>{this.state.item.profile.name}</h1>
                        <span>{findOption(this.state.item.profile.position, positions[lang])}</span>
                        <span>{readableLocation(this.state.item.profile.location)}</span>
                        {nationalities.length > 0 ? <span>{nationalities.join(', ')}</span> : null}
                        <AuthorInfo forceView={(this.state.item.profile.related === 1 || this.state.item.profile.inapply === 1)} profile={this.state.item.profile} />
                    </div>
                    <h5 className="clear">{translate(lang, 'info')}</h5>
                    <SidebarMenu items={items} />
                </div>
            )
        }
    }
    renderWorkExperience() {
        let items = []
        let experience = this.state.item.profile.experience
        experience = sortBy(experience, (a) => {
            return `${a.value.fromYear}-${a.value.fromMonth < 10 ? '0'+a.value.fromMonth : a.value.fromMonth}-1`
        })
        experience = reverse(experience)
        experience.forEach((item, index) => {
            items.push(this.renderWorkExperienceItem(item, index))
        })
        return items
    }
    renderWorkExperienceItem(item) {
        return (
            <WorkExperience
                key={item.id}
                item={item}
                />
        )
    }
    renderEducation() {
        let items = []
        let key = 0
        let education = this.state.item.profile.education
        education = sortBy(education, (a) => {
            return `${a.value.fromYear}-${a.value.fromMonth < 10 ? '0'+a.value.fromMonth : a.value.fromMonth}-1`
        })
        education = reverse(education)
        chunk(education, 2).forEach((row) => {
            let rowItems = []
            row.forEach((item) => {
                rowItems.push(this.renderEducationItem(item))
            })
            items.push(<div key={key} className="col-group">{rowItems}</div>)
            key++
        })
        return items
    }
    renderEducationItem(item) {
        return (
            <Education
                key={item.id}
                item={item}
                />
        )
    }
    renderLanguages() {
        const { lang } = this.props.match.params
        let items = []
        let key = 0
        chunk(this.state.item.profile.languages, 3).forEach((row) => {
            let rowItems = []
            row.forEach((item) => {
                rowItems.push(this.renderLanguagesItem(item))
            })
            items.push(<div key={key} className="col-group">{rowItems}</div>)
            key++
        })
        return items
    }
    renderLanguagesItem(item) {
        const { lang } = this.props.match.params
        return (
            <div key={item.id} className="col-mb-6 col-4">
                <div className="item">
                    <h5><span>{findOption(item.language, languages[lang])}</span></h5>
                    <span>{findOption(item.level, levels[lang])}</span>
                </div>
            </div>
        )
    }
    renderReferrals() {
        let items = []
        let key = 0
        chunk(this.state.item.profile.referrals, 2).forEach((row) => {
            let rowItems = []
            row.forEach((item) => {
                rowItems.push(this.renderReferralsItem(item))
            })
            items.push(<div key={key} className="col-group">{rowItems}</div>)
            key++
        })
        return items
    }
    renderReferralsItem(item) {
        return (
            <div key={item.id} className="col-mb-12">
                <div className="item">
                    {item.value.description
                    ? <div className="the-description" dangerouslySetInnerHTML={{__html: item.value.description}}></div>
                    : <div className="the-description" dangerouslySetInnerHTML={{__html: item.value.text}}></div>
                    }
                </div>
            </div>
        )
    }

    handleShare(e) {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        this.props.showOverlay('share')
    }

    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return <Loader color="white" />
        }
        const { description, experience, education, languages, referrals } = this.state.item.profile
        const noContent = description.length === 0 && experience.length === 0 && education.length === 0 && languages.length === 0 && referrals.length === 0
        return (
            <div>
                {description.length > 0 && <ProfileSection
                    lang={lang}
                    section="about"
                    title={translate(lang, 'about_chef')}
                >
                    <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>
                </ProfileSection>}
                {experience.length > 0 && <ProfileSection
                    lang={lang}
                    section="work-experience"
                    title={translate(lang, 'work_experience')}
                >
                    {this.renderWorkExperience()}
                </ProfileSection>}
                {education.length > 0 && <ProfileSection
                    lang={lang}
                    section="education"
                    title={translate(lang, 'education')}
                >
                    {this.renderEducation()}
                </ProfileSection>}
                {languages.length > 0 && <ProfileSection
                    lang={lang}
                    section="languages"
                    title={translate(lang, 'languages')}
                >
                    {this.renderLanguages()}
                </ProfileSection>}
                {referrals.length > 0 && <ProfileSection
                    lang={lang}
                    section="referrals"
                    title={translate(lang, 'more_info')}
                >
                    {this.renderReferrals()}
                </ProfileSection>}
                {noContent && <div className="noChefInfo">{translate(lang, 'no_chef_info')}</div>}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefView))
