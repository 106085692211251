import moment from "moment"
import { currencies, positions } from "../../Lists/lists"

const stripHTML = (string) => {
  const html = string
  let div = document.createElement("div")
  div.innerHTML = html
  const text = div.textContent || div.innerText || ''
  return text
}

const JobPostingSchema = (data) => {
  if (!data) return null

  // FORMATTED VALUES
  const currency = currencies['en'].some(i => i.value === parseInt(data.offer.currency))
  ? currencies['en'].find(item => item.value === parseInt(data.offer.currency)).name
  : null
  const position = positions['en'].some(i => i.value === parseInt(data.offer.position))
  ? positions['en'].find(item => item.value === parseInt(data.offer.position)).label
  : null

  // jobLocation formatted data
  let region = undefined
  let country = undefined
  let postalCode = undefined
  let street = undefined
  let number = undefined
  let streetAddress = undefined

  if (data.restaurant.location.address_components) {
    region = data.restaurant.location.address_components.some(item => item.types.includes('postal_town') || item.types.includes('locality')) ? data.restaurant.location.address_components.find(item => item.types.includes('postal_town') || item.types.includes('locality')).short_name : ''
    country = data.restaurant.location.address_components.some(item => item.types.includes('country')) ? data.restaurant.location.address_components.find(item => item.types.includes('country')).short_name : ''
    postalCode = data.restaurant.location.address_components.some(item => item.types.includes('postal_code')) ? data.restaurant.location.address_components.find(item => item.types.includes('postal_code')).short_name : ''
    street = data.restaurant.location.address_components.some(item => item.types.includes('route')) ? data.restaurant.location.address_components.find(item => item.types.includes('route')).short_name : ''
    number = data.restaurant.location.address_components.some(item => item.types.includes('street_number')) ? data.restaurant.location.address_components.find(item => item.types.includes('street_number')).short_name : ''
    streetAddress = number ? `${number} ${street}` : street
  }

  // validThrough calculation
  const lastUpdate = new Date(data.offer.updated)
  const oneMonthFromLastUpdate = new Date(lastUpdate.getFullYear(), lastUpdate.getMonth(), lastUpdate.getDate() + 30);

  // JobPosting SCHEMA
  const schema = {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    "title": position || data.offer.name,
    "description": stripHTML(data.offer.description),
    "hiringOrganization": {
      "@type": "Organization",
      "name": data.restaurant.name,
      "sameAs": data.restaurant.url,
      "logo": data.restaurant.images.logo ? data.restaurant.images.logo.sizes.square.url : '',
    },
    "datePosted": moment(data.offer.updated).format('YYYY-MM-DDThh:mm:ssZ'),
    "validThrough": moment(oneMonthFromLastUpdate).format('YYYY-MM-DDThh:mm:ssZ'),
    "employmentType": data.offer.term,
    "jobLocation": {
      "@type": "Place",
      "address":
      {
        "@type": "PostalAddress",
        "addressRegion": region,
        "addressCountry": country,
        "streetAddress": streetAddress,
        "addressLocality": region,
        "postalCode": postalCode,
      }
    },
    "baseSalary": {
      "@type": "MonetaryAmount",
      "currency": currency || 'GBP',
      "value": {
        "@type": "QuantitativeValue",
        "value": parseInt(data.offer.salaryfrom.replace(',', '')),
        "minValue": parseInt(data.offer.salaryfrom.replace(',', '')),
        "maxValue": parseInt(data.offer.salaryto.replace(',', '')),
        "unitText": data.offer.salarytype.toUpperCase(),
      }
    },
    "responsibilities": data.offer.requirements ? stripHTML(data.offer.requirements) : '',
    "workHours": `${data.offer.hours} hours per week`,
    "industry": "Hospitality",
    "url": `www.chefslink.com/en/restaurant/view/offer/${data.restaurant.id}/${data.restaurant.slug}/${data.offer.id}/${data.offer.slug}`,
    "image": data.offer.images.image1 ? data.offer.images.image1.sizes.square.url : '',
  }

  return schema
}

export default JobPostingSchema