import createReducer from '../Services/General/createReducer.js'
import * as types from '../Actions/types.js'
var reject = require('lodash/reject')

export const selectedChefs = createReducer(null, {
    [types.SET_SELECTED_CHEFS](state, action) {
        state = action.selectedChefs
        return state
    },
    [types.ADD_SELECTED_CHEF](state, action) {
        if (state == null) {
            state = []
        }
        state.push(action.selectedChef)
        return state.slice(0)
    },
    [types.REMOVE_SELECTED_CHEF](state, action) {
        if (state == null) {
            state = []
        }
        state = reject(state, {id: action.selectedChef.id})
        return state.slice(0)
    }
})
