import React, {Component} from 'react'
import SVG from 'react-inlinesvg';

export default class File extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="component_Btn file-upload inverted">
                <label htmlFor={this.props.name} className="w">
                    <input
                        className={this.hasError() ? 'error' : ''}
                        type="file"
                        name={this.props.name}
                        value={this.props.value ? this.props.value : ''}
                        id={this.props.name}
                        onChange={(e) => {
                            const {target} = e
                            if (target.value.length > 0) {
                                let n = target.files[0].name
                                this.setState({name: n.length > 20 ? n.substr(0,20) + '...' : n })
                                this.props.onChange(target.files[0])
                            } else {
                                target.reset()
                            }
                        }}
                        />
                    <div className="pre"><SVG src='/static/images/more_icn.svg' /></div>
                    <div className="text">{this.state.name ? this.state.name : this.props.label}</div>
                </label>
            </div>
        )
    }
    hasError() {
        return (this.props.error.indexOf(this.props.name) !== -1)
    }
};
