import React, {Component} from 'react'

export default class FadingLine extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        let className = 'component_FadingLine'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        return (
            <div className={className}>
                <div className="line">
                    <div className="text">{this.props.title ? this.props.title : this.props.children}</div>
                </div>
            </div>
        )
    }
}
