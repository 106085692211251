import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Textarea from '../../Components/Input/Textarea'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chatStartConversation, chatSendMessage} from '../../Services/Chat/chat'
import {listItems, listItemsMore, listLink, listBtnSendCV} from '../RestaurantView/RestaurantViewTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {report, block} from '../../Services/Util/util'
import {viewRestaurant} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import RestaurantTopNavMobile from '../../Components/_NEW/RestaurantTopNavMobile'


class RestaurantViewMessage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            item: null,
            items: [],
            id: this.props.match.params.id,
            errors: [],
            loadingSend: false
        }
    }

    _initState() {
        const { lang } = this.props.match.params
        viewRestaurant(this.props.match.params.id, lang).then((res) => {
            if (res.data.success) {
                this.setState({ item: res.data.data, slug: res.data.data.profile.slug, loading: false })
            }
        })  
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }
    render() {

        let className = 'scene_RestaurantViewMessage'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.item) {
            let profile = this.state.item.profile
            meta = {
                title: profile.name,
                metas: [
                    {name: 'og:image', value: profile.images.image1 ? profile.images.image1.sizes.share.url : null}
                ]
            }
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                meta={meta}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.state.loading || this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (this.state.loading) {
            return null
        }
        return (
            <ImageHeader
                left={this.state.item.profile.images.logo ? this.state.item.profile.images.logo.sizes.square.url : '/static/images/empty_600_600.png'}
                right={this.state.item.profile.images.header ? this.state.item.profile.images.header.sizes.header.url : null}
                alt={this.state.item.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return null
        }

        let items = listItems(this.props.isLogged, this.props.userType, this.state, lang)
        let itemsMore = listItemsMore(this.props.isLogged, this.props.userType, this.state, {
            report: (type) => {
                report({offender: this.state.item.profile.acc, ischef: 0, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            },
            block: (type) => {
                block({offender: this.state.item.profile.acc, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            }
        }, lang
        )
        let itemsLink = listLink(this.props.isLogged, this.props.userType, this.state, lang) //TODO callback
        let btn2 = listBtnSendCV(this.props.isLogged, this.props.userType, this.state, () => {
            this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
        }, lang)
        if (this.props.deviceScreen === 'mobile') {
            return (
                <RestaurantTopNavMobile
                    props={this.props}
                    state={this.state}
                    screen="message"
                    sendCVCallback={() => {
                        this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
                    }} />
            )
        } else {
            return (
                <div>
                    <nav>
                        <TopNavMenu items={items} more={itemsMore} link={itemsLink} btn={btn2} />
                    </nav>
                    <h4><span>{this.state.item.profile.name}</span> / {translate(lang, 'message')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.state.loading) {
            return null
        }
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.state.loading) {
            return null
        }
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.item.profile.name}</h3>
                        <span>{readableLocation(this.state.item.profile.location)}</span>
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return null
        }
        return (
            <div>
                <h4>{translate(lang, 'send_message_to_restaurant')}</h4>
                <div className="form">
                    <Textarea
                        lang={lang}
                        label=""
                        name="text"
                        error={this.state.errors}
                        type="text"
                        value={this.state.text}
                        minLength={4}
                        onChange={(v) => {
                            this.setState({text: v})
                        }}
                        enableReceiveProps
                        reset={this.state.loadingSend === true}
                        />
                </div>
                <div className="bottom col-group">
                    <div className="col-mb-12 col-3 col-offset-9">
                        <Btn
                            text={translate(lang, 'send').toUpperCase()}
                            loading={this.state.loadingSend}
                            callback={() => {
                                this.setState({loadingSend: true})
                                chatStartConversation({cid: this.props.user.profile.id, rid: this.state.item.profile.id, lang}).then((res) => {
                                    if (res.data.success) {
                                        chatSendMessage({ conversation: res.data.data.id, message: this.state.text }).then((res) => {
                                            this.setState({loadingSend: false})
                                            if (res.data.success) {
                                                this.setState({text: ''})
                                                this.props.showOverlay('success', {message: translate(lang, 'message_sent')})
                                            }
                                        })
                                    } else {
                                        this.setState({loadingSend: false})
                                        this.props.showOverlay('error', {message: res.data.message})
                                    }
                                })
                            }}
                            />
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantViewMessage))
