import React, { Component } from 'react'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'
import { translate } from '../../Translations/translate'
import SVG from 'react-inlinesvg';
import LoginForm from '../_NEW/LoginForm'
import SignupEmailForm from '../_NEW/SignupEmailForm'

class LoginOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileShowRegister: false,
        }
    }

    componentDidMount(){
        document.querySelector("body").style.overflow = "hidden"

        const script = document.createElement("script");
        
        script.src = "https://cdn.iubenda.com/iubenda.js";
        script.async = true;
        
        document.body.appendChild(script);
    }

    componentWillUnmount(){
        document.querySelector("body").style.overflow = "auto"
    }

    render() {
        const isMobile = this.props.deviceScreen === 'mobile'
        const { lang } = this.props.match.params

        return (
            <div className='component_Login_Signup_Overlay'>
                <div className='container'>
                    <div className='buttonsRow'>
                        <div className='logo'>
                            <SVG src={'/static/images/logo.svg'} />
                        </div>
                        <div className='right'>
                            <a onClick={() => { this.props.showOverlay(null)}}>
                                <SVG src="/static/images/aspa.svg" />
                            </a>
                        </div>
                    </div>
                    <div className='formsContainer'>
                        {((isMobile && !this.state.mobileShowRegister) || !isMobile) && (
                            <div className='loginForm'>
                                <h4 className="title">{translate(lang, 'login')}</h4>
                                <LoginForm props={this.props} />
                                {isMobile && <div className="signupLinkMobile">
                                    <div className='center'>{translate(lang, 'join_the_community')}<a onClick={() => this.setState({ mobileShowRegister: true })}>{translate(lang, 'create_account')}</a></div>
                                </div>}
                            </div>
                        )}
                        {((isMobile && this.state.mobileShowRegister) || !isMobile) && (
                            <div className='signupEmailForm'>
                                <h4 className="title">{translate(lang, 'create_account')}</h4>
                                <div className="description">
                                    <p>{translate(lang, 'create_account_description_1')}</p>
                                    <p>{translate(lang, 'create_account_description_2')}</p>
                                </div>
                                <SignupEmailForm props={this.props} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(LoginOverlay))
