import {apiFetch} from '../../Config/api'

export function listChefs(data) {
    return apiFetch('api/chefs/list', data)
}
export function searchChefs(data) {
    return apiFetch('search/chefs', data)
}
export function viewChef(id, lang) {
    return apiFetch('api/chefs/show/', {id: id, lang})
}
export function sendOffers(data) {
    // return apiFetch('offer/subscribe/', data)
}
