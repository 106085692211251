import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';
import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import Slider from 'react-slick';
import {listPremium} from '../../Services/Restaurant/restaurant'
import Stars from '../../Components/Stars/Stars'
import {readableLocation} from '../../Lib/readableLocation'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'
import { translate } from '../../Translations/translate';

function Arrow(props) {
    const {className, onClick} = props
    return (
        <div
            className={className}
            onClick={onClick}
            >
            <SVG src="/static/images/arrow.svg" />
            </div>
    );
}

class FeaturedRestaurants extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: []
        }
        const { lang } = this.props.match.params
        listPremium(lang).then((res) => {
            if (res.data.success) {
                this.setState({ items: res.data.data.list })
            }
        })
    }
    render() {
        let numberOfSlides = 1
        if (this.props.deviceScreen === 'tablet') {
            numberOfSlides = 3
        } else if (this.props.deviceScreen === 'desktop') {
            numberOfSlides = 4
        } else if (this.props.deviceScreen === 'large') {
            numberOfSlides = 4
        }
        let settings = {
            inifine: true,
            slidesToShow: numberOfSlides,
            slidesToScroll: 1,
            dots: false,
            nextArrow: <Arrow/>,
            prevArrow: <Arrow/>
        }
        let height = window.innerWidth / numberOfSlides
        return (
            <div className="component_FeaturedRestaurants" style={{height: height}}>
                <Slider
                    {...settings}
                    >
                    {this.renderItems()}
                </Slider>
            </div>
        )
    }
    renderItems() {
        let items = []
        if (this.state.items) {
            this.state.items.forEach((item) => {
                items.push(this.renderItem(item))
            })
        }
        return items
    }
    renderItem(item) {
        const { lang } = this.props.match.params
        let image = item.profile.images.image1 ? item.profile.images.image1.sizes.square.url : 'http://via.placeholder.com/350x350'
        return (
            <div className="item" key={item.profile.id}>
                <div className="bg" style={{backgroundImage: 'url(' + image + ')'}}></div>
                <div className="content">
                    <SVG className="isPremium" src="/static/images/premium_icn.svg" />
                    <Stars type={item.profile.ratingType} number={parseInt(item.profile.rating)} />
                    <span className="location">{readableLocation(item.profile.location)}</span>
                    <h4>{item.profile.name}</h4>
                    <Btn text={translate(lang, 'restaurant_profile').toUpperCase()} callback={() => {
                        this.props.history.push(`/${lang}/restaurant/view/home/` + item.profile.id + '/' + item.profile.slug)
                    }} />
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(FeaturedRestaurants))
