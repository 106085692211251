import React, {Component} from 'react'
import SVG from 'react-inlinesvg';

export default class Checkbox extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let image = this.props.checked ? '/static/images/tick1.svg' : '/static/images/tick2.svg'
        let className = 'rc-checkbox'
        if (this.props.checked) {
            className += ' checked'
        }
        return (
            <div>
                <label
                    htmlFor=""
                    className={this.hasError() ? 'checkbox error' : 'checkbox'}
                    onClick={(e)=>{
                        this.props.onChange(e)
                    }}>
                    <div className={className}><SVG src={image} /></div>
                    <span className='checkboxLabel' dangerouslySetInnerHTML={{__html: this.props.label}}></span>
                </label>
            </div>
        )
    }
    hasError() {
        return (this.props.error.indexOf(this.props.name) !== -1)
    }
};
