import React, {Component} from 'react'
import SVG from 'react-inlinesvg'
import Stars from '../../Components/Stars/Stars'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {fixHtml} from '../../Lib/fixHtml'
import {readableLocation} from '../../Lib/readableLocation'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'

class Restaurant extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false
        }
    }
    render() {
        let {item} = this.props
        let image = item.profile.images.header ? item.profile.images.header.sizes.showcase.url : 'http://via.placeholder.com/240x460/eee/fff'
        return (
            <div className="component_Restaurant">
                <div>
                    <div className="top">
                        {this.renderTop()}
                    </div>
                    <div className="wrap">
                        <div className="graphic">
                            {this.renderPremiumDesktop()}
                            <div className="logo">
                                <LazyImage src={item.profile.images.logo ? item.profile.images.logo.sizes.square.url : 'http://via.placeholder.com/240x240'} alt={item.profile.name}/>
                            </div>
                            <div className="image" style={{backgroundImage: 'url(' + image + ')'}}></div>
                            {this.props.deviceScreen === "mobile" && <div className="row">     
                                <span className="location">{readableLocation(item.profile.location)}</span>    
                                <Stars type={item.profile.ratingType} number={parseInt(item.profile.rating)} />
                            </div>}
                        </div>
                        {this.renderInfo()}
                    </div>
                </div>
            </div>
        )
    }
    renderInfo() {
        const { lang } = this.props.match.params
        let {item} = this.props
        if (this.props.hideInfo && this.props.hideInfo === true) {
            return null
        }
        return (
            <div className="info">
                <p>{this.renderDescription()}</p>
                <div className="col-group">
                    <div className="col-mb-12">
                        <span>{translate(lang, 'active_offers_NUM', item.profile.offers.length)}</span>
                    </div>
                </div>
            </div>
        )
    }
    renderDescription() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let {description} = item.profile
        let teaser = !this.state.showMore && description.length > 260 ? description.substring(0, 260) + '...' : description
        return (
            <span>
                <span dangerouslySetInnerHTML={{__html: fixHtml(teaser)}}></span>
                <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
            </span>
        )
    }
    renderTop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let url = this.props.url ? this.props.url : `/${lang}/restaurant/view/home/` + item.profile.id + '/' + item.profile.slug
        if (this.props.editBtn) {
            return (
                <div>
                    <div className="restaurant">
                        <Link to={this.props.url}>{item.profile.name}</Link>
                    </div>
                    {this.props.editBtn}
                </div>
            )
        }
        if (this.props.deviceScreen === 'mobile') {
            return (
                <div>
                    <div className="restaurant" onClick={this.props.handleClick}>
                        <span><Link to={url}>{item.profile.name}</Link></span>
                    </div>
                    {item.profile.premium === "1" ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                    <div className="view-offers">
                        <Link to={`${url}/?job-offers`}>
                            {translate(lang, 'offers')}
                        </Link>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="restaurant" onClick={this.props.handleClick}>
                        <div className="name-text"><span><Link to={url}>{item.profile.name}</Link></span></div>
                    </div>
                    <Stars type={item.profile.ratingType} number={parseInt(item.profile.rating)} />
                    <span className="location">{readableLocation(item.profile.location)}</span>
                </div>
            )
        }
    }
    renderPremiumDesktop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            let isPremium = null
            if (item.profile.premium === "1") {
                isPremium = (
                    <div><SVG className="isPremium" src="/static/images/premium_icn.svg" /><span className="text"><span>{translate(lang, 'premium').toUpperCase()}</span></span></div>
                )
            }
            return (
                <div className="premium">
                    {isPremium}
                </div>
            )
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        userType: state.userType,
        isLogged: state.isLogged,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Restaurant))
