export const restaurantFaqs_es = [
    {
        q: '¿El servicio tiene algún coste?',
        t: 'Tienes dos opciones: <br/><br/><strong>Regístrate de forma gratuita:</strong><br/>Publicar ofertas de trabajo en nuestro sitio web es gratuito y no conlleva ningún pago. Solo cuando un restaurante decide que está interesado en uno de nuestros chefs, se considera un pago. <br/><br/><strong>Clientes Premium: </strong><br/>Una tarifa anual de £600 te permitirá acceder a todas las solicitudes de Chefs directamente a través de la información de contacto de Chefs en tu bandeja de entrada premium y mucho más. Para obtener información sobre el pago, ponte en contacto con nosotros y te enviaremos los detalles necesarios.'
    },
    {
        q: '¿Cómo me convierto en un cliente Premium?',
        t: 'Suscríbete o actualiza tu cuenta a Premium en "Ajustes". Una vez que recibamos tu solicitud, activaremos tu cuenta. Ten en cuenta que si eres parte de un grupo de restaurantes, te pedimos que todos los restaurantes del grupo se registren como clientes Premium. Para obtener más información sobre Premium, no dudes en ponerte en contacto con uno de nuestros asesores.'
    },
    {
        q: '¿Por qué no puedo ver a todos los Chefs disponibles?',
        t: 'Nuestro sistema detectará solo los chefs que sean relevantes para tu restaurante de acuerdo con las selecciones que hayas realizado para tu empresa en "Ajustes". Las dos características principales son el idioma y la nacionalidad. Solo te permitirá enviar ofertas de trabajo y recibir las solicitudes de Chef que sean relevantes para tu restaurante y no perder el tiempo filtrando a través de cientos de CV que son irrelevantes para tu restaurante.'
    },
    {
        q: '¿Por qué mi perfil aún está en revisión?',
        t: 'Puede ser por una de estas dos razones: <ul><li>Todavía estamos revisando tu solicitud: Después de crear tu perfil, espera por lo menos 24 horas antes de ponerte en contacto con Chefs Link. </li><li>Tu solicitud está incompleta: Por favor rellena el formulario completamente y envíalo nuevamente.</li></ul>'
    },
    {
        q: '¿Chefs Freelance?',
        t: 'Sólo nuestros clientes Premium tendrán acceso a estos perfiles.'
    },
    {
        q: 'Mensajes:',
        t: 'Sólo nuestros clientes Premium podrán enviar mensajes directamente a nuestros Chefs.'
    },
    {
        q: '¿Puede ayudarme a crear mis anuncios?',
        t: 'Puedes usar nuestra plantilla Gurú para ayudarte a crear anuncios de trabajo. Haz clic en "Publicar trabajos" y en la parte inferior encontrarás la plantilla Gurú, junto a la descripción del trabajo. <br/>PASO 1: <br/>Selecciona las descripciones que sean más relevantes para tu negocio. <br/>PASO 2: <br/>Edita el texto para que coincida con tus necesidades de contratación en la descripción del trabajo y envíalo.'
    },
    {
        q: '¿Necesitamos pagarles a los Chefs por su turno de prueba?',
        t: 'Es decisión de los Restaurantes decidir si quieren pagar el turno de prueba o no. Por lo general, los restaurantes solo pagarán el turno de prueba si el chef obtiene el trabajo y comienza a trabajar para la empresa.'
    },
    {
        q: '¿Qué pasa si el chef que envías para un turno de prueba no es el candidato adecuado para el trabajo?',
        t: 'Depende del restaurante decidir si le gusta el chef o si se siente cómodo con ellos en la cocina. Si decides que este no es el chef correcto, continuaremos buscando chefs hasta que encontremos al candidato adecuado para tu restaurante.'
    },
    {
        q: 'No quiero que un Chef determinado vea nuestras Ofertas de trabajo o Perfil.',
        t: 'Puedes bloquear a un Chef para que no vea tus Ofertas de trabajo o tu Perfil, yendo a tu perfil y haciendo clic en el botón "Más· en la barra de funciones. Un menú desplegable te dará algunas opciones para bloquear al Chef.'
    },
    {
        q: '¿Con qué chefs trabajan?',
        t: 'Trabajamos junto con algunas de las universidades privadas de chefs más prestigiosas, escuelas de chefs y chefs individuales. Si buscas un tipo específico de chef especializado en ciertas cocinas, háznoslo saber y nuestro equipo hará todo lo posible para encontrar al candidato adecuado.'
    },
    {
        q: '¿Puedo buscar chefs yo mismo?',
        t: 'Animamos a todos nuestros restaurantes a buscar sus propios chefs también. Puedes hacer clic en "Buscar chefs" en la barra de navegación y seleccionar los chefs que le interesen a tu empresa. Al hacerlo, tendrás una selección mucho más amplia de chefs a considerar.'
    },
    {
        q: '¿Puedes enviarme más información sobre el chef?',
        t: 'Lo haremos encantados. Haznos saber cuáles son tus inquietudes o qué otra información te gustaría que te brindemos y te responderemos lo mejor que podamos.'
    },
    {
        q: '¿Cuándo puede empezar a trabajar el chef?',
        t: 'Si tenemos el candidato adecuado para tu empresa y el chef está listo para comenzar a trabajar, organizaremos un turno de prueba para ese chef al día siguiente en tu restaurante. Aconsejamos a todos nuestros clientes que se pongan en contacto con nosotros lo antes posible para asegurarnos de que tenemos el tiempo suficiente para llenar esa vacante con el mejor candidato posible.'
    },
    {
        q: '¿Qué sucede si después de unas semanas decidimos que el chef no es el candidato adecuado para el puesto o decide dejar la empresa?',
        t: 'Solo facturaremos a la empresa después de que el chef haya completado sus primeros 30 días en el restaurante, para garantizar que tanto el candidato como el chef principal del restaurante estén satisfechos con el acuerdo. Si el chef decide irse o el Jefe de cocina del restaurante siente que no es el candidato adecuado para el trabajo dentro del primer período de prueba de 30 días, cancelaremos ese contrato sin necesidad de pago y comenzaremos a buscar un chef de reemplazo para esa posición.'
    },
]
