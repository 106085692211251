import React, {Component} from 'react'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findById} from '../../Lib/findById'
import {listItems, listBtn2} from '../RestaurantProfile/RestaurantProfileTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle, listOffersRestaurant} from '../../Services/Restaurant/restaurant'
import Select from 'react-select'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import OfferApplications from '../../Components/OfferApplications/OfferApplications'
import {toggleOffer} from '../../Services/Offers/offers'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';
import Loader from '../../Components/_NEW/Loader'


class RestaurantProfileOffers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstLoad: true,
            loading: true,
            items: [],
            page: 0,
            hasMorePages: true,
            inReview: 1,
            total: 0,
            active: 0,
            paused: 0,
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        
        this.setState({
            profile: profile,
        }, this.loadItems.bind(this))
    }

    loadItemsFromStart() {
        this.setState({page: 0, items: [], hasMorePages: true, active: [], paused: []}, () => {
            this.loadItems()
        })
    }

    loadItems(done) {
        const qty = 5
        const { lang } = this.props.match.params
        listOffersRestaurant({
            // reviewonly: 0,
            showpaused: 1,
            rid: this.state.profile.id,
            page: this.state.page,
            qty: qty,
            review: 1,
            lang: lang
        }).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                let items = this.state.items
                items.push(...res.data.data.list)
                
                this.setState({
                    items: items,
                    hasMorePages: (res.data.data.list.length >= qty),
                    total: parseInt(res.data.data.total),
                    active: parseInt(res.data.data.totalActive),
                    paused: parseInt(res.data.data.totalInactive),
                    loading: false,
                    firstLoad: false,
                })
            }
        })
    }

    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            const profile = nextProps.match.params.id ? findById(nextProps.match.params.id, nextProps.user.profiles) : nextProps.user.profiles.find((profile) => parseInt(profile.main) === 1)
            this.setState({profile: profile});
        }
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant')
    }



    render() {
        let className = 'scene_RestaurantProfileOffers'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.props.deviceScreen !== 'mobile' && this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        

        let items = listItems(this.state.profile, this.state, lang)
        let btn2 = listBtn2(lang, this.state.profile, this.state, this.showJobsOverlay.bind(this))

        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'restaurant_profile')}</span> / {translate(lang, 'job_offers')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div className='sidebar-left'>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        <Switch active={this.state.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                    <div className='offers-status'>
                        <div className='active'>
                            <SVG src='/static/images/_active-offer.svg' />
                            <span className='active_title'>{translate(lang, 'active_NUM', this.state.active)}</span>
                        </div>
                        <div className='inactive'>
                            <SVG src='/static/images/_inactive-offer.svg' />
                            <span className='inactive_title'>{translate(lang, 'inactive_NUM', this.state.paused)}</span>
                        </div>
                        <div className='calendar_link'>
                            <SVG src='/static/images/_calendar.svg' />
                            <Link className='calendar_title' to={`/${lang}/restaurant/profile/calendar/${this.state.profile.id}`}>{translate(lang, 'view_calendar')}</Link>
                        </div>
                    </div>
                </div>
            )
        }
    }

    
    
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div>
                <div className="title">
                    <h4>{translate(lang, 'your_job_offers_NUM', this.state.total)}</h4>
                </div>
                {this.renderOffers()}
                {this.state.loading && <Loader />}
            </div>
        )
    }
    renderOffers() {
        const { lang } = this.props.match.params
        let items = []
        this.state.items.forEach((item) => {
            let i = {
                restaurant: this.state.profile,
                offer: item
            }
            let url = `/${lang}/restaurant/profile/offers/` + this.state.profile.id + '/view/' + item.id
            items.push(<OfferApplications
                key={item.id}
                offerId={item.id}
                item={i}
                url={url}
                user={this.props.user}
                loadItemsCallback={() => this.loadItemsFromStart()}
                />)
            })
            if (items.length === 0){
                return translate(lang, 'no_job_offers')
            } else {
                return items
            }
        }
    
    renderSelector() {
        const { lang } = this.props.match.params
        const options = [
            {value: 0, label: translate(lang, 'accepted')},
            {value: 1, label: translate(lang, 'in_review')}
        ]
        if (options.length > 0) {
            return (
                <div className="form">
                    <Select
                        placeholder={translate(lang, 'select_status')}
                        name="inReview"
                        value={this.state.inReview}
                        onChange={(option) => {
                            this.setState({ inReview: option ? option.value : -1, hasMorePages: true, items: [], page: 0 }, () => {
                                this.loadItems()
                            });
                        }}
                        options={options}
                        />
                </div>
            )
        } else {
            return null
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfileOffers))
