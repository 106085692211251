import React, {Component} from 'react'
// import store from '../../Config/store'
import SVG from 'react-inlinesvg';

export default class NotificationsIcon extends Component {
    render() {
        // let src = store.getState().user == null ? '/static/images/login_icn.svg' : '/static/images/login_icn.svg'
        let src = this.props.open ? '/static/images/close_btn_top.svg' : '/static/images/notification_icn.svg'
        let badge = this.props.badge && !this.props.open ? <div className="badge">{this.props.badge}</div> : null
        return (
            <div className="component_NotificationsIcon" onClick={this.handleClick.bind(this)}>
                <SVG src={src}/>
                {badge}
            </div>
        )
    }
    handleClick() {
        if (this.props.callback !== undefined) {
            this.props.callback()
        }
    }
};
