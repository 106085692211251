import React, {Component} from 'react'
import SVG from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import { translate } from '../../../Translations/translate'
import { connect } from 'react-redux'
import {ActionCreators} from '../../../Actions'
import { withRouter } from 'react-router'


class ApplicationStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    pendingDot(number) {
        return (
            <div className='pending-dot'>
                <div className='number'>{number}</div>
            </div>
        )
    }
    
    render() {
        const { pending, review, accepted } = this.props.applicationsCounter
        const { lang } = this.props.match.params
        let baseUrl = `/${lang}/restaurant/profile/applicants/` + this.props.profile.id + '/' + this.props.oid + '/'
        return (
            <ul className="component_ApplicationStatus">
                <li>
                    {pending !== 0 && this.pendingDot(pending)}
                    <NavLink to={baseUrl + 'pending'}>{translate(lang, 'pending')}</NavLink>
                </li>
                <li>
                    <span><SVG src="/static/images/arrow.svg" /></span>
                </li>
                <li>
                    {review !== 0 && this.pendingDot(review)}
                    <NavLink to={baseUrl + 'in-review'}>{translate(lang, 'in_review')}</NavLink>
                </li>
                <li>
                    <span><SVG src="/static/images/arrow.svg" /></span>
                </li>
                <li>
                    {accepted !== 0 && this.pendingDot(accepted)}
                    <NavLink to={baseUrl + 'accepted'}>{translate(lang, 'accepted')}</NavLink>
                </li>
                <li><NavLink to={baseUrl + 'rejected'}>{translate(lang, 'rejected')}</NavLink></li>
            </ul>
        )
    }
};


const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ApplicationStatus))
