import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {canUseDOM} from '../../Lib/canUseDOM'
import smoothscroll from 'smoothscroll-polyfill'
if (canUseDOM) {smoothscroll.polyfill();}

class SidebarMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let items = []
        let rawItems = this.props.items ? this.props.items : []
        rawItems.forEach((i) => {
            items.push(this.renderItem(i))
        })
        return (
            <div className="component_SidebarMenu">
                <ul>{items}</ul>
            </div>
        )
    }
    renderItem(item) {
        return (
            <li key={item.url}>
                <a onClick={(e) => {
                    let el = document.querySelector('div[data-block="' + item.url + '"]')
                    if (el) {
                        el.scrollIntoView({
                            behavior: 'smooth'
                        })
                    }
                }}>{item.title}</a>
            </li>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps)(SidebarMenu))
