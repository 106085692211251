import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import SVG from 'react-inlinesvg';
import Stars from '../../Components/Stars/Stars'
import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import moment from 'moment'
import {saveOffers} from '../../Services/Offers/offers'
import {readableLocation} from '../../Lib/readableLocation'
import {fixHtml} from '../../Lib/fixHtml'
import {terms} from '../../Lists/lists'
import {findOption} from '../../Lib/findOption'
import { translate } from '../../Translations/translate';
import LazyImage from '../../Components/LazyImage/LazyImage'

var find = require('lodash/find')

class Offer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false,
        }
    }
    
    render() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let image = item.offer.images.image1 ? item.offer.images.image1.sizes.showcase.url : 'http://via.placeholder.com/240x460/eee/fff'
        let url = this.props.url ? this.props.url : `/${lang}/restaurant/view/offer/${item.restaurant.id}/${item.restaurant.slug}/${item.offer.id}/${item.offer.slug}`
        return (
            <div className="component_Offer">
                <div>
                    <div className="top">
                        {this.renderTop()}
                    </div>
                    <div className="wrap">
                        <div className="graphic">
                            {this.renderPremiumDesktop()}
                            <div className="logo">
                                <LazyImage src={item.restaurant.images.logo ? item.restaurant.images.logo.sizes.square.url : '/static/images/logo.png'} alt={item.restaurant.name}/>
                            </div>
                            <div className="image" style={{backgroundImage: 'url(' + image + ')'}}></div>
                            {this.props.deviceScreen === "mobile" && <div className="row">     
                                <span className="location">{readableLocation(item.restaurant.location)}</span>    
                                <Stars type={item.restaurant.ratingType} number={parseInt(item.restaurant.rating)} />
                            </div>}
                        </div>
                        <div className="info">
                            <h2 onClick={this.props.handleClick}>
                                <Link to={url}>{item.offer.name}</Link>
                            </h2>
                            <p>{this.renderDescription()}</p>
                            <div className="col-group">
                                <div className="col-mb-6 col-3">
                                    <span>{translate(lang, 'term')} {findOption(item.offer.term, terms[lang])}</span>
                                </div>
                                <div className="col-mb-6 col-3">
                                    <span>{translate(lang, 'w_hours')} {item.offer.hours}</span>
                                </div>
                                <div className="col-mb-6 col-3">
                                    <span>{translate(lang, 'holidays')} {item.offer.holidays}</span>
                                </div>
                                <div className="col-mb-6 col-3">
                                    <span>{translate(lang, 'days_off')} {item.offer.daysoff}</span>
                                </div>
                            </div>
                            {this.renderAcceptBnts()}
                        </div>
                        <div className="bottom">
                            <span>{moment(item.offer.updated).format(this.props.deviceScreen === 'mobile' ? 'D MMM' : 'D MMM[.] YYYY')}</span>
                            {this.renderShareOptions()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderDescription() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let {description} = item.offer
        let teaserDesktop = !this.state.showMore && description.length > 260 ? description.substring(0, 260) + '...' : description
        let teaserMobile = !this.state.showMore && description.length > 100 ? description.substring(0, 100) + '...' : description
        let teaser = this.props.deviceScreen === 'mobile' ? teaserMobile : teaserDesktop
        return (
            <span>
                <span dangerouslySetInnerHTML={{__html: fixHtml(teaser)}}></span>
                <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
            </span>
        )
    }
    renderShareOptions() {
        if (this.props.userType === 'chef') {
            return (
                <div className="share">
                    {this.renderMessage()}
                    {this.renderSave()}
                    {this.renderShare()}
                </div>
            )
        }
        // {this.renderMessage()}
        return null
    }
    renderTop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let selected = find(this.props.selectedOffers, {id: item.offer.id}) ? true : false
        let url = `/${lang}/restaurant/view/home/${item.restaurant.id}/${item.restaurant.slug}`
        let application = this.props.application ? true : false
        if (this.props.deviceScreen === 'mobile') {
            let select = (
                <div className="select" onClick={() => {
                    this.props.toggleSelectedOffer({id: item.offer.id, item: item})
                }}>
                    <span className="text">{translate (lang, 'apply')}</span>
                    <SVG src={selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'} />
                </div>
            )
            return (
                <div>
                    <div className="restaurant" onClick={this.props.handleClick}>
                        <span><Link to={url}>{item.restaurant.name}</Link></span>
                    </div>
                    {item.restaurant.premium === "1" ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                    {!this.props.isLogged || (this.props.userType === 'chef' && item.offer.inoffer === false) ? select : null}
                </div>
            )
        } else {
            let select = (
                <div className="select" onClick={() => {
                    this.props.toggleSelectedOffer({id: item.offer.id, item: item})
                }}>
                    <SVG src={selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'} />
                    <span className="text">{translate (lang, 'apply')}</span>
                </div>
            )
            return (
                <div>
                    <div className="restaurant" onClick={this.props.handleClick}>
                        <div className="name-text"><span><Link to={url}>{item.restaurant.name}</Link></span></div>
                        {(!this.props.isLogged || (this.props.userType === 'chef' && item.offer.inoffer === false)) && !application ? select : null}
                    </div>
                    <Stars type={item.restaurant.ratingType} number={parseInt(item.restaurant.rating)} />
                    <span className="location">{readableLocation(item.restaurant.location)}</span>
                </div>
            )
        }
    }
    renderPremiumDesktop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            let isPremium = null
            if (item.restaurant.premium === "1") {
                isPremium = (
                    <div><SVG className="isPremium" src="/static/images/premium_icn.svg" /><span className="text"><span>{translate (lang, 'premium').toUpperCase()}</span></span></div>
                )
            }
            return (
                <div className="premium">
                    {isPremium}
                </div>
            )
        }
    }
    renderSave() {
        const { lang } = this.props.match.params
        const isSaved = this.props.savedOffers ? this.props.savedOffers.find(offer => offer.offer.id === this.props.item.offer.id) : null
        let text = isSaved ? translate(lang, 'saved').toUpperCase() : translate(lang, 'save').toUpperCase()
        let icon = isSaved ? 'saved_icn' : 'save_icn'
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : text} loading={this.state.loadingSave} className={`${isSaved ? 'inactive' : 'text'}`} post={icon} callback={isSaved ? '#' : () => {
                this.setState({ loadingSave: true })
                saveOffers({offers: this.props.item.offer.id, lang}).then((res) => {
                    this.setState({ loadingSave: false })
                    if (!res.data.success) {
                        return
                    } else {
                        this.props.showOverlay('success', {message: translate (lang, 'offer_saved')})
                    }
                }).catch((e) => {
                    this.setState({ loadingSave: false })
                })
            }} />
        )
    }
    renderMessage() {
        const { lang } = this.props.match.params
        let item = this.props.item
        if (item.restaurant.premium === "0") {
            return null
        }
        let text = this.props.deviceScreen === 'mobile' ? translate (lang, 'mess').toUpperCase() : translate (lang, 'message').toUpperCase()
        // let icon = this.props.deviceScreen === 'mobile' ? null : 'message_icn'
        let icon = 'message_icn'
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : text} className="text" post={icon} callback={() => {
                this.props.history.push(`/${lang}/restaurant/view/message/${item.restaurant.id}/${item.restaurant.slug}`)
            }} />
        )
    }
    renderShare() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let url = `/${lang}/restaurant/view/offer/${item.restaurant.id}/${item.restaurant.slug}/${item.offer.id}/${item.offer.slug}`
        let image = item.offer.images.image1 && item.offer.images.image1.sizes && item.offer.images.image1.sizes.share ? item.offer.images.image1.sizes.share.url : 'http://via.placeholder.com/240x460/eee/fff'
        // let icon = this.props.deviceScreen === 'mobile' ? null : 'share_icn'
        let icon = 'share_icn'
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : translate(lang, 'share').toUpperCase()} className="text" post={icon} callback={() => {
                this.props.showOverlay('share', {title: this.props.item.offer.name, url: url, image: image})
            }} />
        )
    }
    renderAcceptBnts() {
        if (this.props.topBtn || this.props.bottomBtn) {
            return (
                <div className="top-bottom-buttons">
                    {this.props.topBtn ? this.props.topBtn : null}
                    {this.props.bottomBtn ? this.props.bottomBtn : null}
                </div>
            )
        } else {
            return null
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        selectedOffers: state.selectedOffers,
        userType: state.userType,
        isLogged: state.isLogged,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Offer))
