import React, {Component} from 'react'
import SVG from 'react-inlinesvg';

export default class Faq extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }
    render() {

        let className = 'component_Faq'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        if (this.state.open) {
            className += ' open'
        }

        return (
            <div className={className}>
                <div className="heading" onClick={()=>{this.setState({open:!this.state.open})}}>
                    <h4>{this.props.title}</h4>
                    <SVG src="/static/images/arrow.svg" />
                </div>
                <p dangerouslySetInnerHTML={{__html: this.props.question}}></p>
            </div>
        )
    }
};
