import React, {Component, useRef, useState, useEffect} from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import FadingLine from '../FadingLine/FadingLine'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

const LazyImage = ({ src, alt, className }) => {
    const observerRef = useRef()
    const imageRef = useRef()
    const [visible, setVisible] = useState(false)

    const callback = (e) => {
        if (e.isIntersecting) {
            setVisible(true)
            observerRef.current.disconnect() 
        }
    }

    const options = {
        rootMargin: '0px 0px 50%'
    }

    useEffect(() => {
        observerRef.current = new IntersectionObserver(([e]) => callback(e), options)
        observerRef.current.observe(imageRef.current)
        return () => {
            observerRef.current.disconnect()
        }
    }, [])

    
    return (
        <img ref={imageRef} src={visible ? src : ''} alt={alt} className={className || ''} />
    )
}

export default LazyImage
