import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Input from '../../Components/Input/Input'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import {resetPassword} from '../../Services/Access/access'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate';

class ResetPasswordOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            code: this.props.data.code,
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'reset_password')} className={this.props.className}>
                <div className="col-group form">
                    {!this.state.code ?
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'email')}
                            name="username"
                            error={this.state.errors}
                            type="email"
                            value={this.state.username}
                            minLength={4}
                            onChange={(v) => {
                                this.setState({username: v})
                            }}
                            />
                    </div> : null}
                    {this.state.code ?
                        <div className="col-mb-12">
                            <div className="col-group no-margin">
                                <div className="col-mb-12 col-6">
                                    <Input
                                        label={translate(lang, 'password')}
                                        name="password"
                                        error={this.state.errors}
                                        type="password"
                                        value={this.state.password}
                                        minLength={6}
                                        onChange={(v) => {
                                            this.setState({password: v})
                                        }}
                                        />
                                </div>
                                <div className="col-mb-12 col-6">
                                    <Input
                                        label={translate(lang, 'repeat_password')}
                                        name="rpassword"
                                        error={this.state.errors}
                                        type="password"
                                        minLength={6}
                                        value={this.state.rpassword}
                                        onChange={(v) => {
                                            this.setState({rpassword: v})
                                        }}
                                        />
                                </div>
                            </div>
                        </div>
                    : null }
                    <div className="col-mb-12 actions multiple">
                        <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                            this.props.showOverlay(null)
                        }} />
                        <Btn text={translate(lang, 'reset_password').toUpperCase()} loading={this.props.loading} callback={() => {
                            this.login()
                        }} />
                    </div>
                </div>
            </Overlay>
        )
    }
    login() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.code) {
            items.push({field: 'password' , minLength: 6})
            items.push({field: 'rpassword' , minLength: 6, equals: 'password'})
        } else {
            items.push({field: 'username', minLength: 4})
        }
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            let data = {
                lang: lang
            }
            if (this.state.code) {
                data.password = this.state.password
                data.rpassword = this.state.rpassword
                data.code = this.state.code                
            } else {
                data.email = this.state.username
            }
            this.setState({ loading: true })
            resetPassword(data).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                if (this.state.code) {
                    this.props.showOverlay('success', {message: translate(lang, 'password_changed')})
                } else {
                    this.props.showOverlay('success', {message: translate(lang, 'email_reset_password')})
                }

            }).catch((e) => {
            })
        }

    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ResetPasswordOverlay))
