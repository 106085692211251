import React, {Component} from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import FadingLine from '../FadingLine/FadingLine'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class AuthorInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    
    render() {
        const { lang } = this.props.match.params
        const isMobile = this.props.deviceScreen === 'mobile'
        if (!this.props.forceView || !this.props.isLogged) {
            return null
        }
        let contactemail = this.props.userType === 'chef' ? this.props.user.contactemail : this.props.profile.contactemail
        let phone = this.props.userType === 'chef' ? this.props.user.phone : this.props.profile.phone

        return (
            <div className="author-info">
            {isMobile && <br/>}
                <span>
                    <strong>{translate(lang, 'last_login')}{isMobile ? ': ' : ''}</strong>{!isMobile && <br/>}
                    {moment(this.props.profile.updated).format('DD/MM/YYYY')}
                </span>
                {isMobile && <br/>}
                {isMobile && <br/>}
                <span>
                    <strong>{translate(lang, 'email')}{isMobile ? ': ' : ''}</strong>{!isMobile && <br/>}
                    {contactemail ? <span>
                        {isMobile
                        ? <a href={"mailto:"+contactemail}>{contactemail}</a>
                        : <FadingLine className={`sidebar ${this.props.fadeColor || ''}`}><a href={"mailto:"+contactemail}>{contactemail}</a></FadingLine>
                        }
                    </span>
                    : <div>({translate(lang, 'no_information')})</div>
                    }
                </span>
                {isMobile && <br/>}
                {isMobile && <br/>}
                <span>
                    <strong>{translate(lang, 'phone')}{isMobile ? ': ' : ''}</strong>{!isMobile && <br/>}
                    {phone ? phone
                    : <div>({translate(lang, 'no_information')})</div>
                    }
                </span>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(AuthorInfo))
