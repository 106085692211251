import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Filter from './Filter'
import { withRouter } from 'react-router'

class Filters extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        let maxHeight = this.props.deviceHeight - 62
        let styles = {maxHeight: maxHeight, overflowY: 'scroll'}
        return (
            <div className="component_Filters" style={styles}>
                {this.renderFilters()}
            </div>
        )
    }
    renderFilters() {
        let filters = []
        if (this.props.filters) {
            this.props.filters.forEach((filter) => {
                let f = this._renderFilter(filter.name, filter.label, filter.options)
                if (f) {
                    filters.push(f)
                }
            })
        }
        return filters
    }
    _renderFilter(type, title, items) {
        return <Filter key={type} type={type} title={title} items={items} callback={this.props.callback}/>
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        deviceHeight: state.deviceHeight,
    }
}
export default withRouter(connect(mapStateToProps)(Filters))
