
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { translate } from "../../Translations/translate"
import { cuisines, countries, positions } from "../../Lists/lists"
import {findOption} from '../../Lib/findOption'
import {readableLocation} from '../../Lib/readableLocation'
import {chefToggle} from '../../Services/Chef/chef'
import IconButton from "./IconButton"
import SwitchButton from './SwitchButton'

const ChefProfileLeftColumn = ({ props }) => {
  const { lang } = props.match.params
  const { user, userType } = props

  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleToggleChef = () => {
    if (!loading) {
      setLoading(true)
      chefToggle(lang).then((res) => {
          setLoading(false)
          setActive(!active)
          let data = res.data.data
          props.setUser(data.user)
      }).catch((e) => {
          console.log(e)
          setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (userType) {
      switch(userType) {
        case "chef":
          setActive(Number(user.profile.active))
          break;
        default:
          break;
          
        }
    }
  }, [])

  return (
    <div className='component_ChefProfileLeftColumn'>
      <div className="top">
        <div>
            <SwitchButton
              label={active ? translate(lang, 'active') : translate(lang, 'inactive')}
              active={active}
              loading={loading}
              callback={() => handleToggleChef()}
            />
            {user.profile.approved === "0" && <div className='reviewLabel'>
              <span>{translate(lang, 'in_review')}</span>
            </div>}
        </div>
        <div>
          <IconButton label={translate(lang, 'edit')} callback={() => {props.showOverlay('chefinfo', user)}} icon="edit" />
        </div>
      </div>
      <div className="userName">
        <h3>{user.profile.name}</h3>
        <div className="basicInfo">
          <div>{findOption(user.profile.position, positions[lang])}</div>
          {user.profile.location && typeof user.profile.location === "object" ? <div>{readableLocation(user.profile.location)}</div> : null}
        </div>
      </div>
      <div className="userInfo">
        <div>
          {/* TODO */}
          {/* <div className="infoBlock">
            <h4>{translate(lang, 'last_login')}</h4>
            <div className="text">{moment(user.profile.updated).format('DD/MM/YYYY')}</div>
          </div> */}
          <div className="infoBlock">
            <h4>{translate(lang, 'email')}</h4>
            <div className="text">{user.contactemail}</div>
          </div>
          <div className="infoBlock">
            <h4>{translate(lang, 'phone')}</h4>
            <div className="text">{user.phone}</div>
          </div>
          <div className="infoBlock">
            <h4>{translate(lang, 'work_visas')}</h4>
            <div className="wrap">
              {user.profile.visas.map((vis) => <div className="item" key={vis}>
                <span className="text">{findOption(vis, countries[lang])}</span>
              </div>)}
            </div>
          </div>
          <div className="infoBlock">
            <h4>{translate(lang, 'nationalities')}</h4>
            <div className="wrap">
              {user.profile.nationalities.map((nat) => <div className="item" key={nat}>
              <span className="text">{findOption(nat, countries[lang])}</span>
              </div>)}
            </div>
          </div>
          <div className="infoBlock">
            <h4>{translate(lang, 'cuisine')}</h4>
            <div className="wrap">
              {user.profile.cuisine.map((cui) => <div className="item" key={cui}>
              <span className="text">{findOption(cui, cuisines[lang])}</span>
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChefProfileLeftColumn