export const chefFaqs_es = [
    {
        q: '¿El servicio tiene algún coste?',
        t: 'Chefs Link es un servicio gratuito para los chefs que buscan trabajo. Ningún riesgo y ningún pago es necesario en absoluto. Sin embargo, le pedimos a todos nuestros chefs que respeten los requisitos de tiempo y asistencia de todas las entrevistas concertadas.'
    },
    {
        q: '¿Por qué no puedo ver todos los trabajos disponibles?',
        t: 'Nuestro sistema detectará solo los restaurantes que sean relevantes para tu perfil. Las dos características principales son el idioma y la nacionalidad. Esto te permitirá enviar tu solicitud solo a los restaurantes que estén interesados.'
    },
    {
        q: '¿Tengo que dar de alta mi perfil para encontrar un trabajo?',
        t: 'No es necesario. Puedes enviarnos tu CV en la sección de contacto o cuando solicites un empleo y uno de los miembros de nuestro equipo estará encantado de ayudarte. Sin embargo, te recomendamos encarecidamente que crees un perfil con nosotros. Es un proceso muy simple paso a paso y solo te tomará unos minutos de tu tiempo. Esta información nos proporcionará todos los detalles necesarios que necesitamos para ayudarte a encontrar el mejor trabajo posible. Al hacerlo, nos permite ir directamente al trabajo y reducir la cantidad de correos electrónicos frustrados y las llamadas que se devuelven y reenvían normalmente durante el proceso de contratación.'
    },
    {
        q: '¿Puedo publicar ofertas en su sitio web?',
        t: 'Deberás crear una página de negocios en nuestro sitio web o, si tu empresa ya tiene una página de negocios, puedes solicitar al administrador del sitio web que te brinde sus datos de inicio de sesión.'
    },
    {
        q: '¿Qué pasa cuando desactivo mi perfil?',
        t: 'Permitimos que nuestros chefs decidan cuándo quieren que sus perfiles se muestren en nuestro sitio web. Aún podrás solicitar trabajos. Recibe nuestras ofertas de trabajo semanales y usa tu perfil como de costumbre aunque haya sido desactivado. Lo único que puede desactivar tu perfil es restringir que los restaurantes puedan verlo o enviarte ofertas de trabajo.'
    },
    {
        q: '¿Por qué solo puedo enviarle un mensaje a ciertos restaurantes?',
        t: 'Nuestro sistema solo te permite enviar mensajes directamente a nuestros clientes premium.'
    },
    {
        q: '¿Por qué mi perfil aún está en revisión?',
        t: 'Puede ser una de dos razones: <ul><li>Todavía estamos revisando tu solicitud: Después de crear tu perfil, espera por lo menos 24 horas antes de ponerte en contacto con Chefs Link. </li><li>Tu solicitud está incompleta: Por favor rellena tu formulario completamente y envíalo de nuevo.</li></ul>'
    },
    {
        q: 'No quiero que un restaurante en particular vea mi perfil.',
        t: 'Puedes bloquear un restaurante para que no vea tu perfil yendo al perfil del restaurante y haciendo clic en el botón "Más", en la barra de funciones. Un menú desplegable te dará algunas opciones para bloquear el restaurante.'
    },
    {
        q: '¿Con qué restaurantes trabajan?',
        t: 'Puedes encontrar todos los restaurantes con los que trabajamos en nuestra página de inicio, en "Ofertas de trabajo" o "Perfiles de restaurantes".'
    },
    {
        q: '¿Me ayudarán con el alojamiento?',
        t: 'No, pero algunos restaurantes ofrecen alojamiento en su contrato.'
    },
    {
        q: '¿Cuánto me pagarán?',
        t: 'Todo dependerá de tu experiencia trabajando como chef. Al unirte a nuestra red, dedicamos tiempo a hacer coincidir tu experiencia con los mejores restaurantes posibles de nuestra red. Nuestro equipo también se mantendrá en contacto contigo y administrará tu carrera a medida que adquieras más experiencia.'
    },
    {
        q: '¿Me pueden enviar más información sobre el trabajo?',
        t: 'Estaremos encantados. Haznos saber cuáles son tus inquietudes o qué otra información te gustaría que te proporcionemos y te responderemos lo mejor que podamos.'
    },
    {
        q: 'No tengo experiencia en la cocina. ¿Es esto un problema?',
        t: 'Lamentamos informarte pero desafortunadamente no podremos ayudarte en este punto. Nuestros restaurantes solo requieren chefs experimentados o calificados.'
    },
]
