import createReducer from '../Services/General/createReducer.js'
import * as types from '../Actions/types.js'

export const user = createReducer(null, {
    [types.SET_USER](state, action) {
        state = action.user
        return state
    }
})
export const token = createReducer(null, {
    [types.SET_TOKEN](state, action) {
        state = action.token
        return state
    }
})
export const isLogged = createReducer(null, {
    [types.SET_IS_LOGGED](state, action) {
        state = action.isLogged
        return state
    }
})
export const userType = createReducer(null, {
    [types.SET_USER_TYPE](state, action) {
        state = action.userType
        return state
    }
})
