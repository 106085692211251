import React, { useState } from 'react'
import SVG from 'react-inlinesvg';

const OverlayLanguageTranslator = ({
  props,
  languageCallback,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState({value: 'en', label: 'English'})
  const options = [
    {value: 'en', label: 'English'},
    {value: 'es', label: 'Español'},
    {value: 'fr', label: 'Français'},
    {value: 'pt', label: 'Português'}
  ]
  const userLanguage = options.find(o => o.value === props.user.language)

const handleSelectValue = (selected) => {
  const shouldUpdateLanguage = languageCallback(selected.value, true)
  if (shouldUpdateLanguage) {
    setSelectedValue(selected)
  }
}

  return (
    <div style={{ position: 'relative' }} className="component_OverlayLanguageTranslator">
      <div className='selectContainer' onClick={() => setOpen(!open)}>
        <div className="select">
          <span className="placeholder">{selectedValue ? selectedValue.label : userLanguage.label}</span>
          <div className="arrowContainer">
            <div className={`arrowIcon ${open ? 'open' : ''}`}>
              <SVG src="/static/images/arrow_simple.svg" />
            </div>
          </div>
        </div>
        <ul className={`options ${open ? 'open' : ''}`}>
          {options.map(option => (
            <li key={option.value} className="option" onClick={() => handleSelectValue(option)}>{option.label}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default OverlayLanguageTranslator