import React, {Component} from 'react'
import Offer from '../../Components/Offer/Offer'
import SVG from 'react-inlinesvg'
import SmallOffer from '../../Components/SmallOffer/SmallOffer'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class RelatedOffers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            activeIndex: 0,
            numberOfPages: 0,
        }
    }

    componentDidMount() {
        this.numberOfPages()
    }

    componentDidUpdate(prevProps) {
        if (this.props.items !== prevProps.items){
            this.numberOfPages()
        }
    }

    numberOfPages(){
        let { items } = this.props
        let number = Math.ceil(items.length/4)
        this.setState({ numberOfPages: number })
    }
    
    render() {
        const { lang } = this.props.match.params
        if (this.props.items.length === 0) {
            return null
        }
        let className = 'component_RelatedOffers'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        return (
            <div className={className}>
                <div className="top">
                    <h4>
                        {translate(lang, 'related_job_offers_NUM', this.props.items.length)}
                        <div className="arrows">
                            <div onClick={() => {
                                if (this.state.page > 0) {
                                    this.setState({page: this.state.page-1})
                                }
                                if (this.state.activeIndex > 0) {
                                    this.setState({activeIndex: this.state.activeIndex-1})
                                }
                            }}>
                                <SVG src='/static/images/arrow.svg' />
                            </div>
                            <div onClick={() => {
                                if (this.state.page < this.state.numberOfPages-1) {
                                    this.setState({page: this.state.page+1})
                                }
                                if (this.state.activeIndex < this.props.items.length-1) {
                                    this.setState({activeIndex: this.state.activeIndex+1})
                                }
                            }}>
                                <SVG src='/static/images/arrow.svg' />
                            </div>
                        </div>
                    </h4>
                </div>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        if (this.props.deviceScreen === 'mobile') {
            if (this.props.items[this.state.activeIndex]) {
                let item = this.props.items[this.state.activeIndex]
                return (
                    <div className="items">
                        <Offer item={item} />
                    </div>
                )
            } else {
                this.setState({page: 0})
                return null
            }

        } else {
            let itemsR = this.props.items.slice(this.state.page*4, this.state.page*4+4)
            let items = []
            itemsR.forEach((i) => {
                items.push(
                    <div key={i.offer.id} className="col-mb-12 col-3">
                        <SmallOffer
                            item={i}
                            />
                    </div>
                )
            })
            return (
                <div className="container-fluid">
                    <div className="col-group">{items}</div>
                </div>
            )
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RelatedOffers))
