import React, {Component} from 'react'
import Faq from '../../Components/Faq/Faq'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefFaqs} from '../../Lists/chefFaqs'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'


class ChefFaqs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false
        }
    }

    render() {
        const { lang } = this.props.match.params
        let className = 'scene_ChefFaqs'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta = {
            title: translate(lang, 'chef_faqs'),
            metas: [

            ]
        }
        return (
            <Main
                match={this.props.match}
                preTopArea={this.renderPreTopArea.bind(this)}
                className={className}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                meta={meta}
                >
                <div className="content">
                    {this.renderContent()}
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.state.loading || this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        const { lang } = this.props.match.params
        return (
            <ImageHeader left="/static/images/logo.png" right="/static/images/header_default.png" alt={translate(lang, 'faqs')} />
        )
    }
    renderTopNav() {
        if (this.props.deviceScreen === 'mobile') {
            return (
                <nav>
                    <div className="btns">
                        <div className="main-btns">
                        </div>
                    </div>
                </nav>
            )
        } else {
            return null
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return this.renderLeftContent()
        }
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        return (
            <div>
            </div>
        )
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div className="container-fluid">
                <h5>{translate(lang, 'faqs')}</h5>
                <div className="blocks">
                    <div className="block">
                        {chefFaqs[lang].map((faq, index) => {
                            return (
                                <Faq key={index} title={faq.q} question={faq.t} />
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefFaqs))
