import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Input from '../../Components/Input/Input'
import Overlay from './Overlay'
import Select from 'react-select'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {socialNetworks} from '../../Lists/lists'
import {restaurantEditSocial} from '../../Services/Restaurant/restaurant'
import {validate} from '../../Components/Input/validate'
import {translate} from '../../Translations/translate'
import { withRouter } from 'react-router'

class RestaurantSocialOverlay extends Component {
    constructor(props) {
        super(props)
        let value = this.props.data.value
        this.state = {
            id: this.props.data.id,
            url: value.url ? value.url : '',
            type: value.type ? value.type : '',
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'edit_social_network')} className={this.props.className}>
                <div className="col-group form">
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'url')}
                            name="url"
                            error={this.state.errors}
                            value={this.state.url}
                            onChange={(v) => {
                                this.setState({url: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <label htmlFor="type">{translate(lang, 'social_network')}</label>
                        <Select
                            placeholder=""
                            name="type"
                            value={this.state.type}
                            className={this.state.errors.indexOf('type') !== -1 ? 'error' : null}
                            onChange={(option) => { this.setState({ type: option ? option.value : -1 }) }}
                            options={socialNetworks[lang]}
                            />
                    </div>

                    <div className="col-mb-12 actions multiple">
                        {parseInt(this.state.id) !== 0 ? <div className="accessory">
                            <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                this.delete()
                            }} />
                        </div> : null}
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    save() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'url'},
            {field: 'type'},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })
            //SAVE
            let data = {
                rid: this.props.data.rid,
                id: this.state.id,
                name: 'social-network',
                order: 0,
                value: JSON.stringify({
                    url: this.state.url,
                    type: this.state.type,
                }),
                lang: lang
            }
            restaurantEditSocial(data).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                let data = res.data.data
                this.props.setUser(data.user)
                this.props.showOverlay(null)
            }).catch((e) => {
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }
    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        restaurantEditSocial({rid: this.props.data.rid, id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            let data = res.data.data
            this.props.setUser(data.user)
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantSocialOverlay))
