import {translations} from './translations'

export function translate(lang, string, value) {

    if (!translations[lang]) {
        return `_${lang}_${string}`
    }
    
    if (!translations[lang][string]) {
        return `_${lang}_${string}_`
    }

    let translatedString = translations[lang][string]
    let newString = "";
    if (value) {
        if (value.length > 1){
            for (var i = 0; i < value.length; i++){
                newString = translatedString.replace('%@', value[i])
                translatedString = newString
            }
            return (
                newString
            )
        } else if (typeof value === "number"){
            newString = translatedString.replace('%@', value)
            return (
                newString
            )
        }
    } else if (value === 0) {
        return (
            newString = translatedString.replace('%@', 0)
        )
    } else {
        return (
            translatedString
        )
    }
}

