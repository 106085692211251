import React, {Component} from 'react'
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {readableLocation} from '../../Lib/readableLocation'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import Stars from "../Stars/Stars";

class RestaurantInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <div className="component_RestaurantInfo">
                <div className="container-fluid">
                    {this.props.profile.status > 2 ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                    <div className="row">
                        <h1>{this.props.profile.name}</h1>
                        {this.props.deviceScreen === "mobile" && <Stars type={this.props.profile.ratingType} number={parseInt(this.props.profile.rating)} />}
                    </div>
                    <span className="location">{readableLocation(this.props.profile.location)}</span>
                    <span><a target="_blank" href={this.props.profile.url}>{this.props.profile.url}</a></span>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantInfo))
