import * as types from './types'
var find = require('lodash/find')

export function setSelectedFilters(selectedFilters) {
    return {
        type: types.SET_SELECTED_FILTERS,
        selectedFilters
    }
}
export function addSelectedFilter(selectedFilter) {
    return {
        type: types.ADD_SELECTED_FILTER,
        selectedFilter
    }
}
export function removeSelectedFilter(selectedFilter) {
    return {
        type: types.REMOVE_SELECTED_FILTER,
        selectedFilter
    }
}
export function toggleSelectedFilter(selectedFilter) {
    return (dispatch, getState) => {
        if (find(getState().selectedFilters, {id: selectedFilter.id, type: selectedFilter.type})) {
            dispatch(removeSelectedFilter(selectedFilter))
        } else {
            dispatch(addSelectedFilter(selectedFilter))
        }
    }
}
