import React, {Component} from 'react'
import SVG from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import { translate } from '../../../Translations/translate'
import { ActionCreators } from '../../../Actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'


class ChefApplicationStatus extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    pendingDot(number) {
        return (
            <div className='pending-dot'>
                <div className='number'>{number}</div>
            </div>
        )
    }

    render() {
        const { pending, review, accepted } = this.props.profile.profile.pendingnotifications
        const { lang } = this.props.match.params
        let baseUrl = `/${lang}/chef/profile/applications/`
        return (
            <ul className="component_ApplicationStatus">
                <li>
                    {pending !== 0 && this.pendingDot(pending)}
                    <NavLink className={this.props.match.url === baseUrl || this.props.match.url === `/${lang}/chef/profile/applications` ? '' : 'not-pending'} to={baseUrl}
                    >{translate(lang, 'pending')}</NavLink>
                </li>
                <li>
                    <span><SVG src="/static/images/arrow.svg" /></span>
                </li>
                <li>
                    {review !== 0 && this.pendingDot(review)}
                    <NavLink to={baseUrl + 'in-review'}>{translate(lang, 'in_review')}</NavLink>
                </li>
                <li>
                    <span><SVG src="/static/images/arrow.svg" /></span>
                </li>
                <li>
                    {accepted !== 0 && this.pendingDot(accepted)}
                    <NavLink to={baseUrl + 'accepted'}>{translate(lang, 'accepted')}</NavLink>
                </li>
                <li><NavLink to={baseUrl + 'rejected'}>{translate(lang, 'rejected')}</NavLink></li>
            </ul>
        )
    }
};


const mapStateToProps = state => {
    return {
    }
}

export default withRouter(connect(mapStateToProps, ActionCreators)(ChefApplicationStatus))