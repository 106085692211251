import React, {Component} from 'react'
import AuthorInfo from '../../Components/AuthorInfo/AuthorInfo'
import Btn from '../../Components/Btn/Btn'
import ChefApplication from '../../Components/ChefApplication/ChefApplication'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Moment from 'moment'
import MonthView from 'react-calendar/dist/entry.nostyle'
import Switch from '../../Components/Switch/Switch'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefToggle, listSubscribedOffers} from '../../Services/Chef/chef'
import {findOption} from '../../Lib/findOption'
import {applicationTypes, positions} from '../../Lists/lists'
import { extendMoment } from 'moment-range'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Loader from '../../Components/_NEW/Loader'
const moment = extendMoment(Moment);
var findIndex = require('lodash/findIndex')


class ChefProfileCalendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false,
            loadingSwitch: false,
            rawItems: [],
            items: [],
            page: 0,
            hasMorePages: true,
            loading: true,
            dates: [],
            selectedDayEvents: [],
            firstLoad: true,
        }
    }
    _initState() {
        this.loadItems()
    }
    loadItemsFromStart() {
        this.setState({page: 0, items: [], hasMorePages: true}, () => {
            this.loadItems()
        })
    }
    loadItems(done) {
        const qty = 10
        const { lang } = this.props.match.params
        listSubscribedOffers({page: this.state.page, qty: qty, showpaused: 1, status: 4, lang}).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                let items = [...this.state.items]
                items.push(...res.data.data)
                let dates = []
                items.forEach((i) => {
                    i.stages.forEach((s) => {
                        dates.push(s.date)
                    })
                })

                this.setState({ items: items, dates:dates, hasMorePages: items.length >= qty, loading: false, firstLoad: false })
            }
        })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }

    printHour(hour) {
        const splitHour = hour.split(':')
        if (splitHour[1] === '0'){
            return `${splitHour[0]}:00`
        }
        return `${splitHour[0]}:${splitHour[1]}`
    }
     

    render() {
        let className = 'scene_ChefProfileCalendar'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.props.deviceScreen !== 'mobile' && this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        return (
            <ImageHeader
                left={this.props.user.profile.images.profile ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="image" />}
                right={this.props.user.profile.images.header ? this.props.user.profile.images.header.sizes.header.url : null}
                rightBtn={<FakeUploadBtn type="profile" name="header" />}
                alt={this.props.user.profile.name}
                />
        )
    }
    _showShare() {
        const { lang } = this.props.match.params
        let profile = this.props.user.profile
        let url = `/${lang}/chef/view/` + profile.id + '/' + profile.slug
        let image = this.props.user.profile.images.header && this.props.user.profile.images.header.sizes && this.props.user.profile.images.header.sizes.share ? this.props.user.profile.images.header.sizes.share.url : null
        this.props.showOverlay('share', {title: profile.name, url: url, image:image})
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'my_profile')}</span> / {translate(lang, 'calendar')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.props.user.profile.name}</h3>
                        <span>{findOption(this.props.user.profile.position, positions[lang])}</span>
                        <Switch active={this.props.user.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                chefToggle(lang).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                        <AuthorInfo forceView={true} profile={this.props.user.profile} />
                    </div>
                </div>
            )
        }
    }
    dateClassName(date) {
        const day = date.getDate()
        if (parseInt(day) > 9 && parseInt(day) < 32) {
            return 'stage-big'
        }
        return 'stage'
    }
    handleClick(value) {
        const date = moment(value).format('YYYY-MM-DD')
        let stages = []
        if (this.state.dates.includes(date)) {
            this.state.items.forEach(i => {
                i.stages.forEach(s => {
                    if (s.date === date) {
                        stages.push(i)
                    }
                })
            })
            this.setState({ selectedDayEvents: stages })
        } else {
            let stages = []
            this.setState({ selectedDayEvents: stages })
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return <Loader />
        }
        return (
            <div>
                {this.props.deviceScreen === 'mobile' && <h4>{translate(lang, 'calendar')}</h4>}
                <div className="col-group">
                    <div className="col-mb-12 col-6">
                        <MonthView
                            activeStartDate={this.state.activeStartDate}
                            onActiveDateChange={(info) => {
                                let date = info.activeStartDate
                                this.setState({
                                    activeStartDate: date,
                                }, () => {
                                    this.loadItems()
                                })
                            }}
                            locale="en-En"
                            view="month"
                            tileClassName={({date}) => 
                                this.state.dates.includes(moment(date).format('YYYY-MM-DD'))
                                ? this.dateClassName(date)
                                : ''
                            }
                            // tileDisabled={({date, view }) => {
                            //     return (findIndex(this.state.dates, (o) => {
                            //         return o === moment(date).format('YYYY-MM-DD')
                            //     }) > -1)
                            // }}
                            onClickDay={(value) => {this.handleClick(value)}}
                            />
                    </div>
                </div>
                {this.state.selectedDayEvents.length === 0 ? <div className='click-message'>({translate(lang, 'click_date')})</div> : null}
                {this.renderItems(<p>{translate(lang, 'no_trials')}</p>)}
            </div>
        )
    }
    sortDates = () => {
        const formattedItems = (
            this.state.selectedDayEvents.map((item) => {
              const dateItems = item.stages[0].date.split('-')
              const intDateItems = dateItems.map(number => parseInt(number))
              const year = intDateItems[0]
              const month = intDateItems[1]
              const day = intDateItems[2]
              const start = parseInt(item.stages[0].start)
              const end = parseInt(item.stages[0].end)
      
              return ({
                id: item.id, year, month, day, start, end,
              })
            })
          )
          const sortedItems = formattedItems.sort((a, b) => {
            if (a.year - b.year !== 0) {
                return a.year - b.year
              }
              if (a.month - b.month !== 0) {
                return a.month - b.month
              }
              if (a.day - b.day !== 0) {
                return a.day - b.day
              }
              if (a.start - b.start !== 0) {
                return a.start - b.start
              }
              if (a.end - b.end !== 0) {
                return a.end - b.end
              }
              return (a, b)
            })

          const order = sortedItems.map(i => i.id)
            this.state.selectedDayEvents.sort((a, b) => {
          const A = a.id
          const B = b.id
          if (order.indexOf(A) > order.indexOf(B)) {
            return 1
          }
          return -1
        })
        return this.state.selectedDayEvents
    }

    renderItems(message) {
        const { lang } = this.props.match.params
        let items = []
        this.sortDates().forEach((item) => {
            items.push(
                <ChefApplication
                    key={item.id}
                    item={item}
                    restaurant={item.restaurant}
                    offer={item.offer}
                    restaurantMessage={true}
                    extraContent={
                        <div className="extra-content">
                            {item.stages.map((i, index) => {
                            const stageType = applicationTypes[lang].find(a => a.value === parseInt(i.typeid))
                            const dateLiteral = stageType ? translate(lang, 'trial_date').replace('(%@)', stageType.label) : translate(lang, 'date') + ":"
                            return (
                                <div key={i.id}>
                                    <div className="stage no-border">
                                        <span className='accepted-stage'>
                                            <strong className='pre-date'>{dateLiteral.toUpperCase()}</strong>
                                            {i.start !== "" && i.end !== ""
                                            ? <span className='accepted-stage'><strong>{moment(i.date).format('DD MMM YYYY')}</strong> ({translate(lang, 'from').toLowerCase()} {this.printHour(i.start)} {translate(lang, 'to').toLowerCase()} {this.printHour(i.end)})</span>
                                            : <span className='accepted-stage'><strong>{moment(i.date).format('DD MMM YYYY')}</strong> ({translate(lang, 'no_time')})</span>
                                            }
                                        </span>
                                        <Btn text={translate(lang, 'accepted').toUpperCase()} pre="tick1" className="no-line disabled" callback={() => {
                                        }} />
                                        {this.props.deviceScreen === "mobile" && <Btn text={translate(lang, 'propose_new_date').toUpperCase()} pre="_calendar" className="text" callback={() => {
                                            if (item.stages.length > 0) {
                                                this.props.showOverlay('acceptstage', {id: item.stages[0].id, item: item.stages[0]}, this.loadItemsFromStart.bind(this))
                                            }
                                        }} />}
                                    </div>
                                </div>
                            )
                            })}
                        </div>
                    }
                    alternativeShare={
                        this.props.deviceScreen !== "mobile" && <div className="share alternative-share">
                            <Btn text={translate(lang, 'propose_new_date').toUpperCase()} post="_calendar" className="text" callback={() => {
                                if (item.stages.length > 0) {
                                    this.props.showOverlay('acceptstage', {id: item.stages[0].id, item: item.stages[0]}, this.loadItemsFromStart.bind(this))
                                }
                            }} />
                            {this.renderMessageButton(item.restaurant.id)}
                        </div>
                    }
                    />
            )
        })
        return items.length ? items : message
    }
    renderMessageButton(restaurantId) {
        const { lang } = this.props.match.params
        let text = this.props.deviceScreen === 'mobile' ? translate(lang, 'mess').toUpperCase() : translate(lang, 'message').toUpperCase()
        return (
            <Btn text={text} className="text" post="message_icn" callback={() => {
                this.props.showOverlay('message', {cid: this.props.user.profile.id, rid: restaurantId})
            }} />
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefProfileCalendar))
