import * as Yup from 'yup'
import { translate } from '../Translations/translate'

const LoginSchema = (lang) => Yup.object().shape({
  username: Yup.string()
    .email(translate(lang, 'error_email_valid'))
    .required(translate(lang, 'error_email_required')),
  password: Yup.string()
    .min(6, translate(lang, 'error_password_length'))
    .required(translate(lang, 'error_password_required')),
})

export default LoginSchema