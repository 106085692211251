export function validate(items, state) {
    let errors = []
    items.forEach((c) => {

        let val = state[c.field]
        let minLength = c.minLength ? c.minLength : 1
        let onlyIfNotEmpty = c.onlyIfNotEmpty ? c.onlyIfNotEmpty : false
        let checkEquals = c.equals ? true : false
        let onlyNumbers = c.onlyNumbers ? true : false
        let onlyLetters = c.onlyLetters ? true : false
        let oneOf = c.oneOf ? true : false
        let required = c.required ? true : false

        if (onlyIfNotEmpty && val.length > 0 && val.length < minLength) {
            errors.push(c.field)
        } else if (!onlyIfNotEmpty && (!val || val.length < minLength)) {
            errors.push(c.field)
        }

        if (checkEquals && val !== state[c.equals]) {
            errors.push(c.field)
        }
        if (onlyNumbers && /[^0-9 ]+/.test(val)) {
            errors.push(c.field)
        }
        if (onlyLetters && /[^A-Za-z ]+/.test(val)) {
            errors.push(c.field)
        }
        if (oneOf && !c.oneOf.includes(val)) {
            errors.push(c.field)
        }
        if (required && (val === null || typeof val === 'unefined')) {
            errors.push(c.field)
        }
    })
    return errors
}
export function validateArr(items, state) {
    let errors = []
    items.forEach((c) => {

        let val = state[c.field]
        if (!val || val.length == 0) {
            errors.push(c.field)
        }
    })
    return errors
}
