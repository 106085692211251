import {en} from './en'
import {es} from './es'
import {fr} from './fr'
import {pt} from './pt'

export const translations = {
    en: en,
    es: es,
    fr: fr,
    pt: pt,
}