import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import MediaOverlay from './MediaOverlay'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import {sendCV} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate';

class SendCVOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }
    render() {
        const { lang } = this.props.match.params
        if (this.state.media) {
            return <MediaOverlay key="media" data={{ rid: this.state.rid, oid: this.state.id, type: 'curriculum', callback: (id, item) => {
                this.setState({selectedItemId: id})
                this.setState({media: false})
            } }} />
        }
        return (
            <Overlay callback={this.props.data.callback} title={translate(lang, 'send_curriculum_vitae')} className="send-cv">
                <div className="col-group">
                    <div className="col-mb-12 q">
                        <p>{translate(lang, 'select_file')}</p>
                        <FakeUploadBtn type="cv" className="outlined" click={() => {
                            this.setState({media: true, activeImage: 1})
                        }} />
                    </div>
                    <div className="col-mb-12">
                        {this.state.selectedItemId ? <span>{translate(lang, 'selected_one_file')}</span> : null}
                    </div>
                    <div className="col-mb-12 actions multiple">
                        <div className="btns">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'send').toUpperCase()} loading={this.state.loading} className={!this.state.selectedItemId ? 'disabled inverted' : null} callback={() => {
                                this.action()
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    action() {
        const { lang } = this.props.match.params
        if (!this.state.selectedItemId) {
            return
        }
        this.setState({loading: true})
        sendCV({ rid: this.props.data.id, mid: this.state.selectedItemId, lang}).then((res) => {
            this.setState({ loading: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            this.props.showOverlay(null)
        }).catch((e) => {
            this.setState({ loading: false })
            this.props.showOverlay('error', {message: e.message})
        })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(SendCVOverlay))
