import {apiFetch} from '../../Config/api'

export function listPremium() {
    return apiFetch('api/restaurants/featured', {})
}
export function searchRestaurants(data) {
    return apiFetch('search/restaurants', data)
}
export function viewRestaurant(id, lang) {
    return apiFetch('api/restaurants/show/', {id: id, lang})
}
export function restaurantEditAbout(data) {
    return apiFetch('profile/aboutrestaurant', data)
}
export function restaurantEditOpeningHours(data) {
    return apiFetch('profile/restaurantmeta/schedule', data)
}
export function restaurantEditChef(data) {
    return apiFetch('profile/restaurantmeta/chefs', data)
}
export function restaurantEditCover(data) {
    return apiFetch('profile/restaurantmeta/covers', data)
}
export function restaurantEditSocial(data) {
    return apiFetch('profile/restaurantmeta/social', data)
}
export function restaurantEditPhone(data) {
    return apiFetch('profile/restaurantmeta/phone', data)
}
export function restaurantEditEmail(data) {
    return apiFetch('profile/restaurantmeta/email', data)
}
export function restaurantEditAddress(data) {
    return apiFetch('profile/restaurantmeta/address', data)
}
export function restaurantToggle(data) {
    return apiFetch('profile/togglerestaurant', data)
}
export function listOffers(data) {
    return apiFetch('offer/manage/list', data)
}
export function listOffersRestaurant(data) {
    return apiFetch('offer/restaurant', data)
}
export function listLinks(data) {
    return apiFetch('link/offers/list', data)
}
export function acceptLink(data) {
    data.action = 'accept'
    return apiFetch('link/make', data)
}
export function rejectLink(data) {
    data.action = 'reject'
    return apiFetch('link/make', data)
}
export function listApplicants(data) {
    return apiFetch('offer/applicants/list', data)
}
export function listApplicantsCounter(data) {
    return apiFetch('offer/counters', data)
}
export function acceptApplicant(data) {
    data.action = 'accept'
    //message optional
    return apiFetch('offer/applicant', data)
}
export function rejectApplicant(data) {
    data.action = 'reject'
    return apiFetch('offer/applicant', data)
}
export function sendCV(data) {
    return apiFetch('offer/sendcv', data)
}
export function setMainProfile(data) {
    return apiFetch('profile/setmainprofile', data)
}
