import React, {Component} from 'react'
import AuthorInfo from '../../Components/AuthorInfo/AuthorInfo'
import Btn from '../../Components/Btn/Btn'
import DateSectionTitle from '../../Components/DateSectionTitle/DateSectionTitle'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import InfoRow from '../../Components/InfoRow/InfoRow'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefToggle, listSavedOffers} from '../../Services/Chef/chef'
import {findOption} from '../../Lib/findOption'
import {positions, cuisines} from '../../Lists/lists'
import {readableLocation} from '../../Lib/readableLocation'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Loader from '../../Components/_NEW/Loader'
var groupBy = require('lodash/groupBy')


class ChefProfileSaved extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false,
            loadingSwitch: false,
            rawItems: [],
            items: [],
            page: 0,
            hasMorePages: true,
            loading: true,
            firstLoad: true,
        }
    }
    _initState() {
        this.loadItems()
    }
    loadItems(done) {
        const qty = 10
        const { lang } = this.props.match.params
        listSavedOffers({page: this.state.page, qty: qty, lang}).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                let rawItems = this.state.rawItems
                rawItems.push(...res.data.data)
                let items = groupBy(rawItems, (o) => {
                    return moment(o.created).format('YYYY-MM-DD')
                })
                this.setState({ items: items, rawItems: rawItems, hasMorePages: res.data.data.length >= qty, loading: false, firstLoad: false })
            }
        })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }
    
    render() {
        let className = 'scene_ChefProfileSaved'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        return (
            <ImageHeader
                left={this.props.user.profile.images.profile ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="image" />}
                right={this.props.user.profile.images.header ? this.props.user.profile.images.header.sizes.header.url : null}
                rightBtn={<FakeUploadBtn type="profile" name="header" />}
                alt={this.props.user.profile.name}
                />
        )
    }
    _showShare() {
        const { lang } = this.props.match.params
        let profile = this.props.user.profile
        let url = `/${lang}/chef/view/` + profile.id + '/' + profile.slug
        let image = this.props.user.profile.images.header && this.props.user.profile.images.header.sizes && this.props.user.profile.images.header.sizes.share ? this.props.user.profile.images.header.sizes.share.url : null
        this.props.showOverlay('share', {title: profile.name, url: url, image:image})
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return (
               <nav></nav>
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'my_profile')}</span> / {translate(lang, 'saved')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.props.user.profile.name}</h3>
                        <span>{findOption(this.props.user.profile.position, positions[lang])}</span>
                        <Switch active={this.props.user.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                chefToggle(lang).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                        <AuthorInfo forceView={true} profile={this.props.user.profile} />
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div>
                <h4>{translate(lang, 'job_offers_you_saved')}</h4>
                <div className="items">
                    {this.renderItems()}
                </div>
                {this.state.loading && <Loader />}
            </div>
        )
    }
    renderItems() {
        const { lang } = this.props.match.params
        let items = []
        for (let k in this.state.items) {
            items.push(<DateSectionTitle key={k} title={moment(k).format('MMMM Do')} />)
            if (this.state.items[k]) {
                let infoRows = []
                this.state.items[k].forEach((o) => {
                    let subtitleParts = []
                    subtitleParts.push(findOption(o.offer.position, positions[lang]))
                    if (o.restaurant.location) {
                        subtitleParts.push(readableLocation(o.restaurant.location))
                    }
                    if (o.restaurant.cuisine) {
                        subtitleParts.push(findOption(o.restaurant.cuisine, cuisines[lang]))
                    }
                    let url = `/${lang}/restaurant/view/offer/` + o.restaurant.id + '/' + o.restaurant.slug + '/' + o.offer.id + '/' + o.offer.slug
                    infoRows.push(
                        <InfoRow
                            key={o.id}
                            url={url}
                            src={o.restaurant.images.logo.sizes ? o.restaurant.images.logo.sizes.square.url : null}
                            title={'<strong>' + o.restaurant.name + '</strong>'}
                            subtitle={subtitleParts.join(' / ')}
                            />
                    )
                })
                items.push(<div key={'items'+k} className="info-rows">{infoRows}</div>)
            }
        }
        if (items.length <= 0){
            items = translate(lang, 'no_saved_offers')
        }
        return items
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefProfileSaved))
