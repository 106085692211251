import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import Select from 'react-select'
import {ActionCreators} from '../../Actions'
import Input from '../../Components/Input/Input'
import Checkbox from '../../Components/Input/Checkbox'
import {validate} from '../../Components/Input/validate'
import Textarea from '../../Components/Input/Textarea'
import LocationInput from '../../Components/Input/LocationInput'
import {sectors, positions, cuisines, ratingTypes, ratings} from '../../Lists/lists'
import moment from 'moment'
import {chefEditWorkExperience} from '../../Services/Chef/chef'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { refreshUser } from '../../Services/Access/access'
import Cookies from 'js-cookie'
import ChefWorkExperienceForm from "../../Components/_NEW/ChefWorkExperienceForm"
var find = require('lodash/find')

class ChefWorkExperienceOverlay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            loading: (Cookies.get('token') !== undefined),
            changedContents: false,
            lang: props.match.params.lang
        }

        this.experienceTextarea = React.createRef()
    }

    parseDataToState(value = this.props.data.value, callback = () => {}) {

        if (value.value) {
            let id = value.id ? value.id : value.value.id
            if (typeof id === 'undefined') id = 0
            value = value.value
            value.id = id
        }

        const months = [
            {value: 1, label: 'January'},
            {value: 2, label: 'February'},
            {value: 3, label: 'March'},
            {value: 4, label: 'April'},
            {value: 5, label: 'May'},
            {value: 6, label: 'June'},
            {value: 7, label: 'July'},
            {value: 8, label: 'August'},
            {value: 9, label: 'September'},
            {value: 10, label: 'October'},
            {value: 11, label: 'November'},
            {value: 12, label: 'December'},
        ]

        let currentYear = moment().year()
        var years = []
        for (var y = 0; y < 60; y++) {
            years.push({value: (currentYear-y), label: (currentYear-y)})
        }

        this.setState({
            id: parseInt(value.id),
            position: value.position ? value.position : '',
            company: value.company ? value.company : '',
            url: value.url ? value.url : '',
            rating: value.rating ? value.rating : -1,
            ratingType: value.ratingType ? value.ratingType : '',
            location: value.location ? value.location : '',
            cuisine: value.cuisine ? value.cuisine : '',
            sector: value.sector ? value.sector : '',
            fromMonth: value.fromMonth ? value.fromMonth : null,
            fromYear: value.fromYear ? value.fromYear : null,
            toMonth: value.toMonth ? value.toMonth : null,
            toYear: value.toYear ? value.toYear : null,
            currently: value.currently ? value.currently : false,
            description: value.description ? value.description : '',
            months: months,
            currentYear: currentYear,
            years: years
        }, callback)
    }

    componentDidMount() {
        this.parseDataToState(this.props.data,
            () => {
                this.shouldPerformRefreshUser(this.setState({loading: false}))
            }
        )}

    shouldPerformRefreshUser(callback = () => {}) {
        const { id } = this.state
        if (typeof id !== 'undefined' && id > 0) {
            this.performRefreshUser()
        }
        return callback
    }

    languageCallback(lang) {
        const {
            changedContents,
        } = this.state

        // if (!changedContents) {
            this.setState({ lang }, () => this.shouldPerformRefreshUser())
            return true
        // } else {
        //     const hasErrors = this.validateForm(false)
        //     this.save(false, () => {
        //         this.setState({ lang }, () => this.shouldPerformRefreshUser())
        //     })
        //     return hasErrors
        // }
    }

    performRefreshUser() {
        const { lang } = this.state
        const token = Cookies.get('token')
        refreshUser({ token: token, lang }).then((res) => {
            const { user } = res.data.data

            let description = ''

            if (typeof this.state.id !== 'undefined' && this.state.id > 0) {
                const experience = user.profile.experience.find((experience) =>
                    parseInt(experience.id) === this.state.id ? experience : false)
                    description = experience.value.description
            }
            this.setState ({ loading: false, description: description }, () => {
                // this.experienceTextarea.setContent(description)
            })
        })
    }

    // validateForm(updateState = true){
    //     let items = [
    //         {field: 'position'},
    //         {field: 'company', minLength: 2},
    //         {field: 'cuisine'},
    //         {field: 'sector'},
    //         {field: 'fromMonth'},
    //         {field: 'fromYear'},
    //     ];
    //     let errors = validate(items, this.state)
    //     if (updateState) this.setState({ errors })
    //     return errors.length === 0
    // }

    handleChange(name, value) {
        this.setState({ [name]: value, changedContents: true })
    }

    save(closeModalAfterSave = false, callback = () => {}, formData) {
        const { lang } = this.state

        // const isValid = this.validateForm()

        // if (isValid) {

            let months = []
            this.state.months.forEach((n) => {
                months.push(n.value)
            })

            let years = []
            this.state.years.forEach((n) => {
                years.push(n.value)
            })

            let updateData = {
                id: this.state.id,
                name: 'work',
                order: 0,
                language: lang,
                value: JSON.stringify({
                    position: this.state.position,
                    company: this.state.company,
                    url: this.state.url,
                    rating: this.state.rating,
                    ratingType: this.state.ratingType,
                    location: this.state.location,
                    cuisine: this.state.cuisine,
                    sector: this.state.sector,
                    fromMonth: this.state.fromMonth,
                    fromYear: this.state.fromYear,
                    toMonth: this.state.toMonth,
                    toYear: this.state.toYear,
                    currently: this.state.currently,
                    description: this.state.description,
                    lang: lang,
                    location: this.state.location,
                    months: months.join(','),
                    currentYear: this.state.currentYear,
                    years: years.join(','),
                })
            }

            this.setState({ loading: true })

            chefEditWorkExperience(updateData).then((res) => {
                this.setState({ loading: false, changedContents: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                } else {
                    const { data } = res.data

                    if (typeof updateData.id !== 'undefined' && updateData.id > 0) {
                        const experience = data.user.profile.experience.find((experience) =>
                            parseInt(experience.id) === updateData.id ? experience : '')
                            experience.value.id = experience.id
                            this.parseDataToState(experience.value)
                    } else {
                        const experience = data.user.profile.experience[data.user.profile.experience.length - 1]
                        experience.value.id = experience.id
                        this.parseDataToState(experience.value)
                    }

                    this.props.setUser(data.user)
                    if (closeModalAfterSave){
                        this.props.showOverlay(null)
                    } else {
                        if (callback) callback()
                    }
                }
            }).catch((e) => {
                this.setState({ loading: false })
                this.props.showOverlay('error', {message: e.message})
            })
        // }
    }

    renderWorkTo() {
        const { lang } = this.props.match.params
        if (this.state.currently) {
            return null
        } else {
            return (
                <div className="col-mb-12 col-8 group-container">
                    <div className="col-group">
                        <div className="col-mb-12 col-6">
                            <label htmlFor="type">{translate(lang, 'to')}</label>
                            <Select
                                placeholder={translate(lang, 'month').toUpperCase()}
                                name="toMonth"
                                value={this.state.toMonth}
                                className={this.state.errors.indexOf('toMonth') !== -1 ? 'error' : null}
                                onChange={option => this.handleChange('toMonth', option ? option.value : -1 )}
                                options={this.state.months}
                                />
                        </div>
                        <div className="col-mb-12 col-6">
                            <label htmlFor="type">&nbsp;</label>
                            <Select
                                placeholder={translate(lang, 'year').toUpperCase()}
                                name="toYear"
                                value={this.state.toYear}
                                className={this.state.errors.indexOf('toYear') !== -1 ? 'error' : null}
                                onChange={option => this.handleChange('toYear', option ? option.value : -1 )}
                                options={this.state.years}
                                />
                        </div>
                    </div>
                </div>
            )
        }
    }

    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        chefEditWorkExperience({id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            let data = res.data.data
            this.props.setUser(data.user)
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }

    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay
                title={translate(lang, 'work_experience')}
                className={this.props.className}
                languageTranslator={this.state.id === 0 ? false : true}
                languageCallback={this.languageCallback.bind(this)}
                isNew
                overlayName={this.props.overlayName}
                profileNav={this.props.data.profileNav}
                subtitle={translate(lang, 'add_work_experience_extended')}
                >
                  <ChefWorkExperienceForm
                    props={this.props}
                    textTranslation={this.state.description}
                    textLang={this.state.lang}
                />
                    
                {/* <div className="col-group form">
                    <div className="col-mb-12">
                        <label htmlFor="type">{translate(lang, 'position')}</label>
                        <Select
                          placeholder=""
                          name="position"
                          value={this.state.position}
                          className={this.state.errors.indexOf('position') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('position', option ? option.value : -1 )}
                          options={positions[lang]}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <Input
                          label={translate(lang, 'company')}
                          name="company"
                          error={this.state.errors}
                          value={this.state.company}
                          onChange={v => this.handleChange('company', v)}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <Input
                          label={translate(lang, 'url')}
                          name="url"
                          error={this.state.errors}
                          value={this.state.url}
                          onChange={v => this.handleChange('url', v)}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <LocationInput
                          label={translate(lang, 'location')}
                          name="location"
                          error={this.state.errors}
                          value={this.state.location}
                          onChange={v => this.handleChange('location', v)}
                          />
                    </div>
                    <div className="col-mb-12 col-3">
                        <label htmlFor="type">{translate(lang, 'cuisine')}</label>
                        <Select
                          placeholder=""
                          name="cuisine"
                          value={this.state.cuisine}
                          className={this.state.errors.indexOf('cuisine') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('cuisine', option ? option.value : -1 )}
                          options={cuisines[lang]}
                          />
                    </div>
                    <div className="col-mb-12 col-3">
                        <label htmlFor="type">{translate(lang, 'sector')}</label>
                        <Select
                          placeholder=""
                          name="sector"
                          value={this.state.sector}
                          className={this.state.errors.indexOf('sector') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('sector', option ? option.value : -1 )}
                          options={sectors[lang]}
                          />
                    </div>
                    <div className="col-mb-12 col-2">
                        <label htmlFor="type">{translate(lang, 'rating')}</label>
                        <Select
                          placeholder=""
                          name="rating"
                          value={this.state.rating}
                          className={this.state.errors.indexOf('rating') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('rating', option ? option.value : -1 )}
                          options={ratings[lang]}
                          filterOptions={(options, currentValues) => {
                              let max = find(ratingTypes[lang], {'value': this.state.ratingType})
                              return (max && max['max']) ? options.slice(0, max['max']) : options
                          }}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">{translate(lang, 'rating_type')}</label>
                        <Select
                          placeholder=""
                          name="ratingType"
                          value={this.state.ratingType}
                          className={this.state.errors.indexOf('ratingType') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('ratingType', option ? option.value : -1 )}
                          options={ratingTypes[lang]}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">{translate(lang, 'from')}</label>
                        <Select
                          placeholder={translate(lang, 'month').toUpperCase()}
                          name="fromMonth"
                          value={this.state.fromMonth}
                          className={this.state.errors.indexOf('fromMonth') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('fromMonth', option ? option.value : -1 )}
                          options={this.state.months}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">&nbsp;</label>
                        <Select
                          placeholder={translate(lang, 'year').toUpperCase()}
                          name="fromYear"
                          value={this.state.fromYear}
                          className={this.state.errors.indexOf('fromYear') !== -1 ? 'error' : null}
                          onChange={option => this.handleChange('fromYear', option ? option.value : -1 )}
                          options={this.state.years}
                          />
                    </div>
                    <div className="col-mb-12 col-4">
                        <label htmlFor="type">&nbsp;</label>
                        <Checkbox
                          label={translate(lang, 'currently_work_here')}
                          name="currently"
                          error={this.state.errors}
                          checked={this.state.currently}
                          onChange={v => this.handleChange('currently', !this.state.currently)}
                          />
                    </div>

                    {this.renderWorkTo()}

                    <div className="col-mb-12">
                        <Textarea
                            ref={(ref) => this.experienceTextarea = ref}
                            lang={lang}
                            label={translate(lang, 'description')}
                            name="about"
                            error={this.state.errors}
                            type="text"
                            value={this.state.description}
                            minLength={4}
                            onChange={v => this.handleChange('description', v)}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        {this.state.id !== 0 ? <div className="accessory">
                            <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                this.delete()
                            }} />
                        </div> : null}
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save(true)
                            }} />
                        </div>
                    </div>
                </div> */}
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefWorkExperienceOverlay))
