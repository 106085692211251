import * as Yup from 'yup'
import { translate } from '../Translations/translate'

const ChefEducationSchema = (lang) => Yup.object().shape({
  title: Yup.string().min(2).required(),
  center: Yup.string().min(2).required(),
  fromMonth: Yup.number().required(),
  fromYear: Yup.number().required(),
  toMonth: Yup.number().when('currently', {
    is: false,
    then: Yup.number().required(),
  }),
  toYear: Yup.number().when('currently', {
    is: false,
    then: Yup.number().required(),
  }),
})

export default ChefEducationSchema