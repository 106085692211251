import React, {Component} from 'react'
import Btn from '../Btn/Btn'
import Checkbox from '../Input/Checkbox'
import FakeUploadBtn from '../FakeUploadBtn/FakeUploadBtn'
import Input from '../Input/Input'
import JobOfferGuruOverlay from './JobOfferGuruOverlay'
import MediaOverlay from './MediaOverlay'
import Overlay from './Overlay'
import Select from 'react-select'
import Switch from '../Switch/Switch'
import Textarea from '../Input/Textarea'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {positions, salaryTypes, terms, currencies} from '../../Lists/lists'
import {selectOptionsForProfiles, profilesForUser} from '../../Lib/selectOptionsForOffers'
import {updateOffer} from '../../Services/Offers/offers'
import {listOffersRestaurant} from '../../Services/Restaurant/restaurant'
import LazyImage from '../../Components/LazyImage/LazyImage'
import {validate} from '../Input/validate'
import {valueByKey} from '../../Lib/valueByKey'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Cookies from 'js-cookie'
import { format } from 'url';
var find = require('lodash/find')
var _filter = require('lodash/filter')

class JobOfferOverlay extends Component {
    constructor(props) {
        super(props)
        let restaurants = selectOptionsForProfiles(profilesForUser(this.props.user))

        this.state = {
            restaurants: restaurants,
            templatesRaw: [],
            templates: [],
            rid: this.props.data.rid === -1 ? this.props.user.main.id : this.props.data.rid,
            errors: [],
            loading: (Cookies.get('token') !== undefined),
            changedContents: false,
            lang: props.match.params.lang,
        }
        this.descriptionTextarea = React.createRef()
        this.requirementsTextarea = React.createRef()
    }

    parseDataToState(offer, callback = () => {}) {
        this.setState({
            id: valueByKey(offer, 'id', 0),
            name: valueByKey(offer, 'name', ''),
            position: valueByKey(offer, 'position'),
            description: valueByKey(offer, 'description'),
            status: offer && offer.status ? parseInt(offer.status) : 1,
            salarytype: valueByKey(offer, 'salarytype'),
            salaryfrom: valueByKey(offer, 'salaryfrom'),
            salaryto: valueByKey(offer, 'salaryto'),
            hidesalary: valueByKey(offer, 'hidesalary'),
            currency: valueByKey(offer, 'currency'),
            term: valueByKey(offer, 'term'),
            hours: valueByKey(offer, 'hours'),
            holidays: valueByKey(offer, 'holidays'),
            daysoff: valueByKey(offer, 'daysoff'),
            recruit: valueByKey(offer, 'recruit'),
            requirements: valueByKey(offer, 'requirements'),
            image1: offer && offer.images && offer.images.image1 ? offer.images.image1.id : null,
            previewimage1: offer && offer.images && offer.images.image1 ? offer.images.image1.sizes : null,
            manualHeadline: valueByKey(offer, 'name', '') === '' ? false : true
        }, () => {
            this.descriptionTextarea.setContent(this.state.description)
            this.requirementsTextarea.setContent(this.state.requirements)
            callback()
        })

    }

    loadItems(callback = () => {}) {
        const { lang } = this.state.lang
            listOffersRestaurant({
                showpaused: 1,
                reviewonly: 0,
                rid: this.state.rid,
                page: 0,
                qty: 999,
                review: 1,
                lang: lang
            }).then((res) => {
                let items = res.data.data.list.map((item) => {
                    return {value: item.id, label: item.name, rid: this.state.rid}
                })
                this.setState({templates: items, templatesRaw: res.data.data.list}, () => {
                    callback()
                })
            })
    }

    componentDidMount() {
        this.loadItems(() => {
            this.parseDataToState(this.props.data.offer)
            this.setState({ loading: false })
        })
    }

    shouldPerformUpdateOffer() {
        const { id } = this.state
        if (typeof id !== 'undefined' && id > 0) {
            this.performUpdateOffer()
        }
    }

    languageCallback(lang) {
        const { changedContents } = this.state
        if (!changedContents) {
            this.setState({ lang }, () => this.shouldPerformUpdateOffer())
            return true
        } else {
            const hasErrors = this.validateForm(false)
            this.save(false, () => {
                this.setState({ lang }, () => this.shouldPerformUpdateOffer())
            })
            return hasErrors
        }
    }

    performUpdateOffer() {
        const { lang } = this.state

        listOffersRestaurant({
            showpaused: 1,
            reviewonly: 0,
            rid: this.state.rid,
            page: 0,
            qty: 999,
            review: 1,
            lang: lang
        }).then((res) => {
            const { data } = res.data
            if (typeof this.state.id !== 'undefined' && this.state.id > 0) {
                const offer = data.list.find((o) => parseInt(o.id) === parseInt(this.state.id))
                this.parseDataToState(offer)
            }
        })
    }

    validateForm(updateState = true){
        let items = [
            {field: 'name' , minLength: 4},
            {field: 'description' , minLength: 4},
            {field: 'salaryfrom', minLength: 1},
            {field: 'salaryto', minLength: 1},
            {field: 'position'},
            {field: 'term'},
            {field: 'salarytype'},
            {field: 'hours'},
            {field: 'daysoff', minLength: 1},
            {field: 'holidays', minLength: 1},
        ];
        let errors = validate(items, this.state)
        if (updateState) this.setState({ errors })
        return errors.length === 0
    }

    handleChange(name, value){
        this.setState({ [name]: value, changedContents: true },
        () => this.generateHeadline())
        
    }

    save(closeModelAfterSave = false, callback = () => {}) {
        const { lang } = this.state

        const isValid = this.validateForm()

        if (isValid) {
            let updateData = {
                rid: this.state.rid,
                id: this.state.id,
                name: this.state.name,
                position: this.state.position,
                description: this.state.description,
                salarytype: this.state.salarytype,
                salaryfrom: this.state.salaryfrom,
                salaryto: this.state.salaryto,
                hidesalary: this.state.hidesalary ? 1 : 0,
                term: this.state.term,
                hours: this.state.hours,
                holidays: this.state.holidays,
                daysoff: this.state.daysoff,
                recruit: this.state.recruit ? 1 : 0,
                status: this.state.status ? 1 : 0,
                requirements: this.state.requirements,
                image1: this.state.image1,
                lang: lang
            }

            this.setState({ loading: true })

            updateOffer(updateData).then((res) => {
                this.setState({ loading: false, changedContents: false })

                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                } else {
                    const { offer } = res.data.data
                    this.setState({ id: offer.id })
                    if (closeModelAfterSave){
                        this.props.showOverlay(null)
                        if (offer.status === 1) {
                            const offerRestaurant = this.props.user.profiles.find(r => r.id === this.state.rid)
                            const url = `/${lang}/restaurant/view/offer/${offerRestaurant.id}/${offerRestaurant.slug}/${offer.id}/${offer.slug}`
                            const image = offer.images.image1 ? offer.images.image1.sizes.showcase.url : 'http://via.placeholder.com/240x460/eee/fff'
                            this.props.showOverlay('share', {title: offer.name, url: url, image: image})
                        }
                    } else {
                        if (callback) callback()
                    }
                }
            }).catch((e) => {
                this.setState({ loading: false })
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }

    renderActiveSwitch() {
        return (
            <Switch active={this.state.status} inLeft={true} callback={() => {
                this.setState({ status: !this.state.status })
            }} />
        )
    }

    returnGuru(data) {
        this.setState({guru: false})
        if (data) {
            this.setState({
                description: '<p>' + [data.about,data.position,data.headchef,data.opportunities].join('<br/><br/>') + '</p>',
                requirements: '<p>' + [data.thechef,data.foodstandards,data.staffdevelopment,data.healthsafety,data.management,data.other].join('<br/><br/>') + '</p>',
            })
        }
    }

    render() {
        const { lang } = this.props.match.params
        if (this.state.guru) {
            return <JobOfferGuruOverlay key="jobofferguru" data={{ callback: this.returnGuru.bind(this) }} />
        } else if (this.state.media) {
            return <MediaOverlay key="media" data={{ rid: this.state.rid, oid: this.state.id, type: 'offer', callback: (id, item) => {
                this.setState({ ['image' + this.state.activeImage]: id, ['previewimage' + this.state.activeImage]: item })
                this.setState({media: false})
            } }} />
        } else {
            return (
                <Overlay
                    title={translate(lang, 'post_job_offers')}
                    className={this.props.className}
                    rightTop={this.renderActiveSwitch()}
                    languageTranslator={this.state.id === 0 ? false : true}
                    languageCallback = {this.languageCallback.bind(this)}
                    >
                    <form autoComplete='off' className="col-group form">
                        {this.state.id === 0 || this.state.restaurants.length > 0 ? <div className="col-mb-12 col-6">
                            <label htmlFor="type">{translate(lang, 'restaurants')}</label>
                            <Select
                                placeholder=""
                                name="restaurants"
                                value={this.state.rid}
                                className={null}
                                onChange={option => {
                                    if (!option) return (
                                        this.handleChange('rid', this.state.restaurants[0].value)
                                    )
                                    this.handleChange('rid', option.value, () => this.loadItems.bind(this))
                                }}
                                options={this.state.restaurants}
                                />
                        </div> : null}
                        {this.state.id === 0 ? <div className="col-mb-12 col-6">
                            <label htmlFor="type">{translate(lang, 'template')}</label>
                            <Select
                                placeholder=""
                                name="template"
                                value={this.state.template}
                                className={null}
                                onChange={option => {
                                    let template = find(this.state.templatesRaw, {id: option.value})
                                    if (window.confirm(translate(lang, 'import_template_NAME', template.name))) {
                                        this.setState({
                                            changedContents: true,
                                            description: template.description,
                                            requirements: template.requirements,
                                            name: template.name,
                                            position: template.position,
                                            description: template.description,
                                            status: template.status,
                                            salarytype: template.salarytype,
                                            salaryfrom: template.salaryfrom,
                                            salaryto: template.salaryto,
                                            hidesalary: template.hidesalary,
                                            term: template.term,
                                            hours: template.hours,
                                            holidays: template.holidays,
                                            daysoff: template.daysoff,
                                            recruit: template.recruit,
                                            requirements: template.requirements,
                                            image1: template.images && template.images.image1 ? template.images.image1.id : null,
                                            previewimage1: template.images && template.images.image1 ? template.images.image1.sizes : null
                                        })

                                        this.descriptionTextarea.setContent(template.description)
                                        this.requirementsTextarea.setContent(template.requirements)

                                    }
                                }}
                                options={this.state.templates}
                                filterOptions={(options, filter, currentValues) => {
                                    return _filter(options, {'rid': this.state.rid})
                                }}
                                />
                        </div> : null}
                        <div className="col-mb-12">
                            <div className="col-group" style={{marginTop:0}}>
                                <div className="col-mb-6">
                                    <label htmlFor="type">{translate(lang, 'position')}</label>
                                    <Select
                                        placeholder=""
                                        name="position"
                                        value={this.state.position}
                                        className={this.state.errors.indexOf('position') !== -1 ? 'error' : null}
                                        onChange={option => {
                                            if (!option) return (
                                                this.handleChange('position', ''),
                                                this.handleChange('positionLabel', '')
                                            )
                                            return (
                                                this.handleChange('position', option.value),
                                                this.handleChange('positionLabel', option.label)
                                            )
                                        }}
                                        options={positions[lang]}
                                        />
                                </div>
                                <div className="col-mb-6">
                                    <label htmlFor="type">{translate(lang, 'cover_picture')}</label>
                                    <FakeUploadBtn className="outlined" click={() => {
                                        return (
                                            this.handleChange('media', true),
                                            this.handleChange('activeImage', 1)
                                        )
                                        }} />
                                    <br/>
                                    {this.state.previewimage1 ? <LazyImage alt={translate(lang, 'preview')} src={this.state.previewimage1.showcase.url} /> : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12">
                            <div className="col-group no-margin">
                                <div className="col-mb-12 col-3">
                                    <label htmlFor="type">{translate(lang, 'salary')}</label>
                                    <Select
                                        placeholder=""
                                        name="salarytype"
                                        value={this.state.salarytype}
                                        className={this.state.errors.indexOf('salarytype') !== -1 ? 'error' : null}
                                        onChange={option => {
                                            if (!option) return (
                                                this.handleChange('salarytype', '')
                                            )
                                            this.handleChange('salarytype', option.value)
                                        }}
                                        options={salaryTypes[lang]}
                                        />
                                </div>
                                <div className="col-mb-12 col-2">
                                    <label htmlFor="type">{translate(lang, 'currency')}</label>
                                    <Select
                                        placeholder=""
                                        name="currency"
                                        value={this.state.currency}
                                        className={this.state.errors.indexOf('currency') !== -1 ? 'error' : null}
                                        onChange={option => {
                                            if (!option) return (
                                                this.handleChange('currency', ''),
                                                this.handleChange('currencyLabel', '')
                                            )
                                            return (
                                                this.handleChange('currency', option.value),
                                                this.handleChange('currencyLabel', option.label)
                                            )
                                        }}
                                        options={currencies[lang]}
                                        />
                                </div>
                                <div className="col-mb-12 col-2">
                                    <Input
                                        label={translate(lang, 'salary_from')}
                                        name="salaryfrom"
                                        value={this.state.salaryfrom}
                                        error={this.state.errors}
                                        onChange={v => {this.handleChange('salaryfrom', v)}}
                                        />
                                </div>
                                <div className="col-mb-12 col-2">
                                    <Input
                                        label={translate(lang, 'salary_to')}
                                        name="salaryto"
                                        value={this.state.salaryto}
                                        error={this.state.errors}
                                        onChange={v => {this.handleChange('salaryto', v)}}
                                        />
                                </div>
                                <div className="col-mb-12 col-3">
                                    <label htmlFor="type">&nbsp;</label>
                                    <Checkbox
                                        label={translate(lang, 'hide_salary')}
                                        name="hidesalary"
                                        error={this.state.errors}
                                        checked={this.state.hidesalary}
                                        onChange={v => {this.handleChange('hidesalary', !this.state.hidesalary)}}
                                        />
                                </div>
                            </div>
                        </div>
                        <div className="col-mb-12">
                            <Input
                                label={translate(lang, 'headline')}
                                name="name"
                                value={this.state.name}
                                error={this.state.errors}
                                onChange={v => {
                                    return (
                                        this.handleChange('name', v),
                                        this.handleChange('manualHeadline', true)
                                    )
                                }}
                                />
                        </div>
                        <div className="col-mb-6 col-3">
                            <label htmlFor="type">{translate(lang, 'term')}</label>
                            <Select
                                placeholder=""
                                name="term"
                                value={this.state.term}
                                className={this.state.errors.indexOf('term') !== -1 ? 'error' : null}
                                onChange={option => {
                                    if (!option) return (
                                        this.handleChange('term', '')
                                    )
                                    this.handleChange('term', option.value)
                                }}
                                options={terms[lang]}
                                />
                        </div>
                        <div className="col-mb-6 col-3">
                            <Input
                                label={translate(lang, 'weekly_hours')}
                                name="hours"
                                value={this.state.hours}
                                error={this.state.errors}
                                // type="number"
                                onChange={v => this.handleChange('hours', v)}
                                />
                        </div>
                        <div className="col-mb-6 col-3">
                            <Input
                                label={translate(lang, 'weekly_days_off')}
                                name="daysoff"
                                value={this.state.daysoff}
                                error={this.state.errors}
                                // type="number"
                                onChange={v => this.handleChange('daysoff', v)}
                                />
                        </div>
                        <div className="col-mb-6 col-3">
                            <Input
                                label={translate(lang, 'holidays')}
                                name="holidays"
                                value={this.state.holidays}
                                error={this.state.errors}
                                // type="number"
                                onChange={v => this.handleChange('holidays', v)}
                                />
                        </div>
                        <div className="col-mb-12">
                            <Textarea
                                ref={(ref) => this.descriptionTextarea = ref}
                                lang={lang}
                                label={translate(lang, 'description')}
                                name="description"
                                endLabel={<span>{translate(lang, 'help_job_description')} <span onClick={() => { this.setState({guru: true}) }}>+ {translate(lang, 'launch_guru_template')}</span></span>}
                                error={this.state.errors}
                                type="text"
                                value={this.state.description || ''}
                                minLength={4}
                                onChange={v => this.handleChange('description', v)}
                                />
                        </div>
                        <div className="col-mb-12">
                            <Textarea
                                ref={(ref) => this.requirementsTextarea = ref}
                                lang={lang}
                                label={translate(lang, 'chef_requirements')}
                                name="requirements"
                                error={this.state.errors}
                                type="text"
                                value={this.state.requirements || ''}
                                minLength={4}
                                onChange={v => this.handleChange('requirements', v)}
                                />
                        </div>
                        <div className="col-mb-12">
                            <Checkbox
                                label={translate(lang, 'help_recruit')}
                                name="recruit"
                                error={this.state.errors}
                                checked={(parseInt(this.props.user.main.premium) === 1) ? this.state.recruit : 1}
                                onChange={v => this.handleChange('recruit', parseInt(this.props.user.main.premium) === 1 ? !this.state.recruit : '')}
                                />
                        </div>
                        <div className="col-mb-12 actions multiple">
                            {this.state.id !== 0 ? <div className="accessory">
                                <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                    if (window.confirm(translate(lang, 'delete_offer'))) {
                                        this.delete()
                                    }
                                }} />
                            </div> : null}
                            <div className="main">
                                <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                    this.props.showOverlay(null)
                                }} />
                                <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                    this.save(true)
                                }} />
                            </div>
                        </div>
                    </form>
                </Overlay>
            )
        }
    }
    generateHeadline() {
        const { lang } = this.props.match.params
        if (!this.state.manualHeadline) {
            let headline = ''
            if (this.state.positionLabel) {
                headline += this.state.positionLabel
            }
            if (this.state.hidesalary) {
                headline += translate(lang, 'negotiable_salary')
            } else {
                let currency = this.state.currencyLabel ? this.state.currencyLabel : ''
                if (this.state.salaryfrom) {
                    headline += ' / ' + currency + this.state.salaryfrom
                }
                if (this.state.salaryto) {
                    this.state.salaryfrom
                    ? headline += ' - ' + currency + this.state.salaryto
                    : headline += ' / ' + currency + this.state.salaryto
                }
            }
            this.setState({name: headline})
        }
    }

    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        updateOffer({id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                window.location = `/${lang}/restaurant/profile/home` + this.state.rid
                return
            }
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }
};

const mapStateToProps = state => {
    return {
        user: state.user,
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(JobOfferOverlay))
