import * as Yup from 'yup'
import { translate } from '../Translations/translate'

const ChefWorkExperienceSchema = (lang) => Yup.object().shape({
  position: Yup.number().required(),
  company: Yup.string().min(2).required(),
  cuisine: Yup.number().required(),
  sector: Yup.number().required(),
  fromMonth: Yup.number().required(),
  fromYear: Yup.number().required(),
  toMonth: Yup.number().when('currently', {
    is: false,
    then: Yup.number().required(),
  }),
  toYear: Yup.number().when('currently', {
    is: false,
    then: Yup.number().required(),
  }),
})

export default ChefWorkExperienceSchema