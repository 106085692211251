import React, {Component} from 'react'
import SVG from 'react-inlinesvg';
import isFunction from 'lodash/isFunction'

export default class Btn extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        let className = 'component_Btn'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        if (this.props.loading) {
            className += ' loading'
        }

        return (
            <div className={className} onClick={this.handleClick.bind(this)}>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        if (this.props.loading) {
            return (
                <div className="w">
                    <div className="text"><SVG src='/static/images/loading_icn.svg' /></div>
                </div>
            )
        } else {
            let preSrc = this.props.pre ? '/static/images/' + this.props.pre + '.svg' : null
            let preIcon = preSrc ? <div className="pre"><SVG src={preSrc} /></div> : null

            let postSrc = this.props.post ? '/static/images/' + this.props.post + '.svg' : null
            let postIcon = postSrc ? <div className="post"><SVG src={postSrc} /></div> : null

            return (
                <div className="w">
                    {preIcon}
                    {this.props.text ? <div className="text">{this.props.text}</div> : null}
                    {postIcon}
                </div>
            )
        }
    }
    //algo
    handleClick() {
        if (this.props.callback !== undefined && isFunction(this.props.callback)) {
            this.props.callback()
        }
    }
};
