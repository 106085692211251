import React, {Component} from 'react'
import Select from 'react-select'
import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import Filters from '../../Components/Filters/Filters'
import {ActionCreators} from '../../Actions'
import LocationInput from '../../Components/Input/LocationInput'
import {positions, cuisines} from '../../Lists/lists'
import {validate} from '../../Components/Input/validate'
import {translate} from '../../Translations/translate'
import { withRouter } from 'react-router'

class SearchBar extends Component {
    constructor(props) {
        super(props)
        let state = {
            position: '',
            cuisine: '',
            location: '',
            menuIsOpen: false,
            errors: [],
        }

        this.state = state
    }

    render() {
        const { lang } = this.props.match.params
        let title = ''
        switch (this.props.type) {
            case 'chefs':
                title = translate(lang, 'find_chefs_now').toUpperCase()
                break;
            case 'offers':
                title = translate(lang, 'find_jobs_now').toUpperCase()
                break;
            case 'restaurant':
                title = translate(lang, 'find_restaurants_now').toUpperCase()
                break;
            default:
                break;
        }

        let findJobsClass = 'find-jobs-now'
        if (this.state.menuIsOpen) {
            findJobsClass += ' active inverted'
        }

        let findjobs = this.props.deviceScreen === 'mobile' ? <Btn
            text= {translate(lang, 'filter_by').toUpperCase()}
            pre="filters"
            className={findJobsClass}
            callback={() => {this.setState({ menuIsOpen: !this.state.menuIsOpen })}}
            /> : <span>{title}</span>

        let className = 'component_SearchBar'
        if (this.state.menuIsOpen) {
            className += ' find-jobs'
        }
        let numberOfSelectedItems = 0
        if ((!this.props.isLogged || this.props.userType === 'chef') && this.props.selectedOffers) {
            numberOfSelectedItems = this.props.selectedOffers.length
        } else if (this.props.userType === 'restaurant' && this.props.selectedChefs) {
            numberOfSelectedItems = this.props.selectedChefs.length
        }
        if (numberOfSelectedItems > 0 && this.props.deviceScreen === 'mobile'){
            className += ' has-selected-items'
        }

        let hasFilter1 = (this.props.type !== 'restaurant')

        return (
            <div className={className}>
                <div className="container-fluid">
                    <div className="col-group">
                        <div className="col-mb-12">
                            <div className="find-jobs">
                                {findjobs}
                                <div className="filters">
                                    {hasFilter1 ? <div className="select-wrapper">
                                        {this.renderFilter1()}
                                    </div> : null}
                                    {hasFilter1 ? <span className="in">{translate(lang, 'in').toUpperCase()}</span> : null}
                                    <div className="select-wrapper">
                                        <LocationInput
                                            placeholder={translate(lang, 'location')}
                                            name="location"
                                            className={this.state.errors.indexOf('location') !== -1 ? 'error' : null}
                                            value={this.state.location}
                                            onChange={(v) => {
                                                this.setState({location: v})
                                            }}
                                            />
                                    </div>
                                    {this.props.deviceScreen === 'mobile' ? <Filters filters={this.props.filters} /> : null}
                                    <Btn text={translate(lang, 'go').toUpperCase()} callback={() => {

                                        this.goAction()
                                    }} />
                                </div>
                            </div>
                            {/* {this.renderJobOfferBtn()} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    goAction() {
        let items = [
            {field: 'location'},
        ];
        if (this.props.type !== 'restaurant') {
            items.push(
                {field: 'position'}
            )
        }
        let errors = validate(items, this.state)
        
        if (errors.length <= 1) {
            this.setState({ errors: [] })
            this.props.callback(this.state.position, this.state.location)
        } else {
            this.setState({ errors: errors })
        }
    }
    renderFilter1() {
        const { lang } = this.props.match.params
        let content = null
        switch(this.props.type) {
            case 'restaurant':
                content = (
                    <Select
                        placeholder={translate(lang, 'cuisine')}
                        name="cuisine"
                        value={this.state.cuisine}
                        onChange={(value) => {
                            if (value) {
                                this.setState({ cuisine: value.value });
                            } else {
                                this.setState({ cuisine: null });
                            }
                        }}
                        options={cuisines[lang]}
                        className={this.state.errors.indexOf('cuisine') !== -1 ? 'error' : null}
                        />
                )
                break;
            default:
                content = (
                    <Select
                        placeholder={translate(lang, 'position')}
                        name="position"
                        value={this.state.position}
                        onChange={(value) => {
                            if (value) {
                                this.setState({ position: value.value });
                            } else {
                                this.setState({ position: null });
                            }
                        }}
                        options={positions[lang]}
                        className={this.state.errors.indexOf('position') !== -1 ? 'error' : null}
                        />
                )
                break;
        }
        return content
    }
    renderJobOfferBtn() {
        const { lang } = this.props.match.params
        let content = null
        if (this.props.isLogged && this.props.userType === 'restaurant') {
            content = (
                <div className="create-job-offer">
                    <Btn
                        text={translate(lang, 'post_jobs').toUpperCase()}
                        pre="edit_icn"
                        className={this.props.deviceScreen === 'mobile' ? 'outlined' : null}
                        callback={() => {
                            if (this.props.isLogged) {
                                if (this.props.userType === 'restaurant') {
                                    this.props.showOverlay('joboffers', {rid: -1})
                                }
                            } else {
                                this.props.showOverlay('login')
                            }
                        }}
                        />
                </div>
            )
        }
        return content
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        selectedOffers: state.selectedOffers,        
        selectedChefs: state.selectedChefs,        
        isLogged: state.isLogged,
        userType: state.userType,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(SearchBar))
