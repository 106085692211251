import React, {Component} from 'react'
import ApplicationStatus from './Components/ApplicationStatus'
import Btn from '../../Components/Btn/Btn'
import Chef from '../../Components/Chef/Chef'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import OfferSummary from './Components/OfferSummary'
import Switch from '../../Components/Switch/Switch'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findById} from '../../Lib/findById'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle, listApplicants, listApplicantsCounter} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';
import Loader from '../../Components/_NEW/Loader'
var find = require('lodash/find')


class RestaurantProfileApplicantsReview extends Component {
    constructor(props) {
        super(props)

        this.state = {
            firstLoad: true,
            loading: true,
            items: [],
            page: 0,
            hasMorePages: true,
            applicationsCounter: {
                pending: 0,
                review: 0,
                accepted: 0,
                total: 0
            }
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        let offer = find(profile.offers, {id: this.props.match.params.oid})
        this.setState({
            profile: profile,
            offerId: this.props.match.params.oid,
            offer: offer
        }, this.loadItems.bind(this))
    }
    loadItemsFromStart() {
        this.setState({page: 0, items: [], hasMorePages: true}, () => {
            this.loadItems()
        })
    }
    loadItems(done) {
        const qty = 10
        if (!this.state.profile) {
            this._initState()
        } else {
            const { lang } = this.props.match.params
            //status (-1 rejected, 2 pending, 3 in review, 4 accepted)
            listApplicantsCounter({oid: this.state.offerId, rid: this.state.profile.id }).then((res) => {
                if (res.data.success) {
                    const { pending, review, accepted, rejected } = res.data.data 
                    const total = pending + review + accepted
    
                    this.setState({
                        applicationsCounter: {
                            pending,
                            review,
                            accepted,
                            total
                        }
                    })
                }
            })
            listApplicants({oid: this.state.offerId, page: this.state.page, qty: qty, status: 3, lang}).then((res) => {
                if (done !== undefined) {
                    done()
                }
                if (res.data.success) {
                    const { data } = res.data
                    const reviewItems = [
                        ...this.state.items,
                        ...data
                    ]
                    this.setState({ items: reviewItems, hasMorePages: res.data.data.length >= qty, loading: false, firstLoad: false })
                }
            })
        }
    }
    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.user && nextProps.user !== this.props.user) {
            const profile = nextProps.match.params.id ? findById(nextProps.match.params.id, nextProps.user.profiles) : nextProps.user.profiles.find((profile) => parseInt(profile.main) === 1)
            this.setState({profile: profile});
        }
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant' && parseInt(this.props.user.subscription) === 1)
    }



    render() {
        let className = 'scene_RestaurantProfileApplicants'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={null}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                topLeft={this.renderTopLeftContent.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({ page: this.state.page+1, loading: true }, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        let back=`/${lang}/restaurant/profile/offers/${this.state.profile.id}`

        if (this.props.deviceScreen === 'mobile') {
            return (
                <nav className='mobile'>
                    <div className="btns">
                         <Link className='back-button' to={back}>
                            <SVG src="/static/images/backarrow.svg" />
                            <span>
                                {translate(lang, 'back')}
                            </span>
                        </Link>
                    </div>
                </nav>
            )
        } else {
            return (
                <div className='top-content'>
                    <h4><span>{translate(lang, 'restaurant_profile')}</span> / {translate(lang, 'applicants')}</h4>
                    <Link className='back-button' to={back}>
                        <SVG src="/static/images/backarrow.svg" />
                        <span>
                            {translate(lang, 'back')}
                        </span>
                    </Link>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderTopLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <ImageHeader
                noCover={true}
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
            )
        }
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        <Switch active={this.state.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                    <div className='total_applications'>
                        <SVG src='/static/images/edit_icn.svg' />
                        <span className='applications_title'>{translate(lang, 'total_applications_NUM', this.state.applicationsCounter.total)}</span>
                    </div>
                    <div className='calendar_link'>
                        <SVG src='/static/images/_calendar.svg' />
                        <Link className='calendar_title' to={`/${lang}/restaurant/profile/calendar/${this.state.profile.id}`}>{translate(lang, 'view_calendar')}</Link>
                    </div>
                </div>
            )
        }
    }
    countApplicationsByStatus(status) {
        let count = 0
        this.state.items.forEach((i) => {
            if (parseInt(i.statusid) === parseInt(status)) {
                count++
            }
        })
        return count
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div>
                {this.props.deviceScreen === 'mobile' && <h4>{translate(lang, 'applicants')}</h4>}
                <OfferSummary profile={this.state.profile} offer={this.state.offer} />
                <ApplicationStatus profile={this.state.profile} oid={this.state.offerId} applicationsCounter={this.state.applicationsCounter}/>
                {this.renderChefs(<p>{translate(lang, 'no_in_review_applications')}</p>)}
                {this.state.loading && <Loader />}
            </div>
        )
    }
    renderChefs(message) {
        const { lang } = this.props.match.params
        let items = []
        this.state.items.forEach((item) => {
            const wasAccepted = item.stages.find(stage => stage.status === 'Accepted')
            const wasRejected = item.stages.find(stage => stage.status === 'Rejected')
            let waitingForChefResponse = false
            if (item.stages.length) {
                waitingForChefResponse = (parseInt(item.stages[0].proposes) === parseInt(this.props.user.id))
            }
            let chef = item.chef
            items.push(
                <Chef
                    deleteButton={true}
                    deleteButtonCallback={() => this.loadItemsFromStart()}
                    offerId={this.state.offerId}
                    application={item}
                    key={item.id}
                    item={{profile: chef}}
                    hideSelect={true}
                    topBtn={null}
                    bottomBtn={null}
                    isApplication={true}
                    extraContent={
                        <div className="main-info no-padding-top">
                            <div className="extra-content no-border">
                                {parseInt(item.disponibility) === 0
                                ? <div key={item.id} className="stage">
                                    <span>{translate(lang, 'proposed_offer')}</span>
                                    <Btn text={this.props.deviceScreen === 'mobile' ? translate(lang, 'waiting_response').toUpperCase() : translate(lang, 'waiting_chef_response').toUpperCase()} pre="tick1" className={`no-line disabled ${this.props.deviceScreen === 'mobile' && 'long-text'}`} callback={() => {}} />
                                </div>
                                : <div>{item.stages.length > 1 && !wasAccepted && !wasRejected
                                ? <div key={item.id} className="stage">
                                    <span>{item.stages.map((stage, index) => index === 0 ? moment(stage.date).format('DD MMM YYYY') : ` / ${moment(stage.date).format('DD MMM YYYY')}`)}</span>
                                    <Btn text={this.props.deviceScreen === 'mobile' ? translate(lang, 'waiting_response').toUpperCase() : translate(lang, 'waiting_chef_response').toUpperCase()} pre="tick1" className={`no-line disabled ${this.props.deviceScreen === 'mobile' && 'long-text'}`} callback={() => {}} />
                                </div>
                                : <div key={item.stages[0].id} className="stage">
                                    <span>{moment(item.stages[0].date).format('DD MMM YYYY')}</span>
                                    <Btn text={this.props.deviceScreen === 'mobile' ? translate(lang, 'waiting_response').toUpperCase() : translate(lang, 'waiting_chef_response').toUpperCase()} pre="tick1" className={`no-line disabled ${this.props.deviceScreen === 'mobile' && 'long-text'}`} callback={() => {}} />
                                </div>
                                }
                                </div>
                                }
                            </div>
                        </div>
                    }
                    alternativeShare={
                        waitingForChefResponse || parseInt(item.disponibility) === 0 || item.stages.length > 1 ? null :
                        <div className="share alternative-share">
                            <Btn text={translate(lang, 'propose_new_date').toUpperCase()} post="_calendar" className="text" callback={() => {
                                this.props.showOverlay('acceptapplication', {id: item.id, chef: item.chef, restaurant: this.props.user, item: item, onlyCalendar: true}, this.loadItemsFromStart.bind(this))
                            }} />
                        </div>
                    }
                    alternativeDate={
                        <span className="alternative-date">
                            {translate(lang, 'applied_on').toUpperCase()} {moment(item.created).format('MMM DD, YYYY')}
                        </span>
                    }
                    />
            )
        })
        if (items.length > 0) {
            return items
        } else {
            return message
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfileApplicantsReview))
