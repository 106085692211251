import React, {Component} from 'react'
import Btn from '../Btn/Btn'
import ReactCrop from 'react-image-crop'
import SVG from 'react-inlinesvg'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {uploadImage} from '../../Services/Media/media'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class UploadBtn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: this.props.loading,
            dataUrl: false,
            croppingSizes: {
                header: {
                    w: 1200,
                    h: 600,
                    a: 2,
                    title: 'header'
                },
                square: {
                    w: 600,
                    h: 600,
                    a: 1,
                    title: 'square'
                },
                showcase: {
                    w: 400,
                    h: 300,
                    a: 1.3333,
                    title: 'showcase'
                },
                miniature: {
                    w: 460,
                    h: 240,
                    a: 1.9166,
                    title: 'miniature'
                }
            }
        }
    }
    render() {

        let className = 'component_Btn file-upload inverted'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        if (this.state.loading) {
            className += ' loading'
        }

        return (
            <div className={className}>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        const { lang } = this.props.match.params
        let editView = null
        if (this.state.dataUrl !== false) {
            editView = (
                <div className="ReactCropWrapper">
                    <ReactCrop
                        src={this.state.dataUrl}
                        crop={this.state.crop}
                        onChange={(crop, pixelCrop) => {
                            this.setState({crop: crop, pixelCrop: pixelCrop})
                        }}
                        onImageLoaded={(image) => {
                            this.setState({imageWidth: image.width, imageHeight: image.height})
                        }}
                        />
                    <ul className="sizes">
                    {Object.keys(this.state.croppingSizes).map((k) => {
                        return <li key={k} onClick={()=>{
                            let w = 100
                            let h = 100 / this.state.croppingSizes[k].a
                            let crop = {x: 0, y: 0, width: w/2, height: h/2}
                            this.setState({crop: crop})
                        }}>{translate(lang, this.state.croppingSizes[k].title)}</li>
                    })}
                    </ul>
                    <Btn
                        text={translate(lang, 'upload')}
                        callback={() => {
                            this.uploadImage()
                        }}
                        />
                </div>
            )
        }

        if (this.state.loading) {
            return (
                <div className="w">
                    <div className="text"><SVG src='/static/images/loading_icn.svg' /></div>
                    {editView}
                </div>
            )
        } else {
            let key = this.props.name + 'file'
            return (
                <label htmlFor={key} className="w">
                    <input id={key} accept="image/*, application/pdf" type="file" onChange={(e) => {
                        const {target} = e
                        if (target.value.length > 0) {
                            this.handleFileChanged(target.files)
                        } else {
                            target.reset()
                        }
                    }}/>
                    <div className="pre"><SVG src='/static/images/more_icn.svg' /></div>
                    <div className="text">{translate(lang, 'upload')}</div>
                </label>
            )
        }
    }
    handleFileChanged(files) {
        const file = files[0]
        this.setState({ loading: true })
        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({file: file})
            if (file.type === 'application/pdf') {
                this.uploadFile({image: file, type: 'curriculum'})
            } else {
                this.loadEditView(e.target.result)
            }
        }
        reader.readAsDataURL(file)
    }
    loadEditView(dataUrl) {
        this.setState({dataUrl})
    }
    async uploadImage() {
        const { lang } = this.props.match.params
        let croppedImg = await this.getCroppedImg()
        let data = {
            image: croppedImg,
            lang
        }
        await this.uploadFile(data)
    }
    async uploadFile(data) {
        uploadImage(data).then((res) => {
            this.setState({ loading: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            this.props.refresh()
        }).catch((e) => {
            this.setState({ loading: false })
            this.props.refresh()
        })
    }
    getCroppedImg()
    {

        if (!this.state.pixelCrop) {
            return this.state.file
        }

        let image = new Image()
        image.src = this.state.dataUrl
        let pixelCrop = this.state.pixelCrop
        let fileName = this.state.file.name

        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );

        // As a blob
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                var file = new File([blob], fileName);
                resolve(file);
            }, 'image/jpeg');
        });
    }
};


const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(UploadBtn))
