import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'

class EditableImage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="component_EditableImage">
                <LazyImage src={this.props.src} alt={this.props.alt}/>
                {this.props.btn ? this.props.btn : null}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps)(EditableImage))
