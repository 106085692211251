import React, {Component} from 'react'
import SVG from 'react-inlinesvg';

export default class SocialNetworks extends Component {
    render() {
        return (
            <ul>
                <li><a href="https://www.facebook.com/ChefsLink.Job/" target="_blank"><SVG src="/static/images/fb_icn.svg" /></a></li>
                <li><a href="https://twitter.com/chefslink" target="_blank"><SVG src="/static/images/tw_icn.svg" /></a></li>
                <li><a href="https://www.linkedin.com/company/chefs-for-london/" target="_blank"><SVG src="/static/images/in_icn.svg" /></a></li>
                <li><a href="https://www.instagram.com/chefslink.job" target="_blank"><SVG src="/static/images/ig_icn.svg" /></a></li>
            </ul>
        )
    }
};
