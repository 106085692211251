import React, {Component} from 'react'
import { Link, NavLink } from 'react-router-dom'
import UserIcon from '../UserIcon/UserIcon'
import NotificationsIcon from '../NotificationsIcon/NotificationsIcon'
import SVG from 'react-inlinesvg'
import SocialNetworks from '../SocialNetworks/SocialNetworks'
import Notifications from '../Notifications/Notifications'
import LazyImage from '../../Components/LazyImage/LazyImage'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'
import Cookies from 'js-cookie'
import {accessLogout} from '../../Services/Access/access'
import { translate } from '../../Translations/translate'
import Btn from '../Btn/Btn'
import {setMainProfile} from '../../Services/Restaurant/restaurant'


class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            primaryMenuIsOpen: false,
            secondaryMenuIsOpen: false,
            primaryMenuSubmenuServicesOpen: false,
            primaryMenuSubmenuFindJobsOpen: false,
            notificationsIsOpen: false,
            languageSelectorIsOpen: false,
            languagesMenuIsOpen: false,
            profilesMenuIsOpen: false,
        }
        this.props.history.listen( location =>  {
            this.closeAllMenus()
        });

        this.langOptions = {
            en: 'English',
            es: 'Español',
            fr: 'Français',
            pt: 'Português'
        }
    }

    closeAllMenus() {
        this.setState({
            primaryMenuIsOpen: false, 
            secondaryMenuIsOpen: false, 
            primaryMenuSubmenuFindJobsOpen: false, 
            primaryMenuSubmenuServicesOpen: false, 
            notificationsIsOpen: false, 
            languageSelectorIsOpen: false})
    }

    render() {
        const { lang } = this.props.match.params

        let className = ''
        if (this.state.primaryMenuIsOpen) {
            className += ' primary-open'
        }
        if (this.state.secondaryMenuIsOpen) {
            className += ' secondary-open'
        }
        if (this.state.primaryMenuSubmenuServicesOpen) {
            className += ' services-open'
        }
        if (this.state.primaryMenuSubmenuFindJobsOpen) {
            className += ' find-open'
        }
        if (this.state.notificationsIsOpen) {
            className += ' notifications-open'
        }
        if (this.state.languageSelectorIsOpen) {
            className += ' languageSelector-open'
        }

        const pendingMobileRestaurant = this.props.isLogged && this.props.user && this.props.user.type === "restaurant" && parseInt(this.props.user.main.premium) === 1 ? this.props.user.main.pendingnotifications.pending + this.props.user.main.pendingnotifications.messages : null
        const pendingMobileRestaurantLimited = typeof pendingMobileRestaurant === "number" && pendingMobileRestaurant > 99 ? '+99' : pendingMobileRestaurant
        const pendingMobileChef = this.props.isLogged && this.props.user && this.props.user.type === "chef" ? this.props.user.profile.pendingnotifications.pending + this.props.user.profile.pendingnotifications.messages : null
        const pendingMobileChefLimited = typeof pendingMobileChef === "number" && pendingMobileChef > 99 ? '+99' : pendingMobileChef

        return (
            <header className={className}>
                <div className="container-fluid">
                    <div className="col-group">
                        <div className="col-mb-12">
                            <nav>
                                <div className="logo">
                                    <Link to={`/${lang}/`}>
                                        <SVG src={this.props.deviceScreen === 'mobile' ? '/static/images/logo_mobile.svg' : '/static/images/logo.svg'} />
                                    </Link>
                                </div>
                                <div className="subnav">
                                    {this.props.deviceScreen === "mobile" 
                                        ? <>
                                            <div className="mobile-burger" onClick={() => this.togglePrimaryMenu()}>
                                            {typeof pendingMobileRestaurantLimited !== null && pendingMobileRestaurantLimited > 0 && this.pendingDot(pendingMobileRestaurantLimited)}
                                            {typeof pendingMobileChefLimited !== null && pendingMobileChefLimited > 0 && this.pendingDot(pendingMobileChefLimited)}
                                                <div className={`line top ${this.state.primaryMenuIsOpen || (!this.props.isLogged && (this.state.languageSelectorIsOpen || this.state.primaryMenuSubmenuServicesOpen)) ? 'open' : ''}`} />
                                                <div className={`line middle ${this.state.primaryMenuIsOpen || (!this.props.isLogged && (this.state.languageSelectorIsOpen || this.state.primaryMenuSubmenuServicesOpen)) ? 'open' : ''}`} />
                                                <div className={`line bottom ${this.state.primaryMenuIsOpen || (!this.props.isLogged && (this.state.languageSelectorIsOpen || this.state.primaryMenuSubmenuServicesOpen)) ? 'open' : ''}`} />
                                            </div>
                                            {this.state.primaryMenuIsOpen && this.renderPrimaryMenu()}
                                        </>
                                        : this.renderPrimaryMenu()
                                    }
                                    <div className="user-actions">
                                        <UserIcon
                                        open={this.state.secondaryMenuIsOpen}
                                        callback={this.openUserMenu.bind(this)}
                                        />
                                        {this.renderSecondaryMenu()}
                                    </div>
                                    <div className="social-networks view-tablet">
                                        <SocialNetworks />
                                    </div>
                                </div>
                            </nav>
                            {this.props.deviceScreen === 'mobile' && !this.props.isLogged ? this.renderServicesMenu() : null}
                            {this.props.deviceScreen === 'mobile' && !this.props.isLogged ? this.renderLanguagesMenu() : null}
                        </div>
                    </div>
                </div>
            </header>
        )
    }
    updateLanguage(language) {
        let currentUrl = this.props.location.pathname
                
        Object.keys(this.langOptions).forEach((key) => {
            if (currentUrl === `/${key}`){
                currentUrl = currentUrl.replace(`/${key}`, `/${language}/`)
            } else {
                currentUrl = currentUrl.replace(`/${key}/`, `/${language}/`)
            }
        })
        return currentUrl
    }

    pendingDot(number) {
        return (
            <div className='pending-dot'>
                <div className='number'>{number}</div>
            </div>
        )
    }

    renderPrimaryMenu() {
        const { lang } = this.props.match.params
        const langList = []
        Object.keys(this.langOptions).forEach((key) => {
            const url = this.updateLanguage(key)
            langList.push(
                <li key={key}><a onClick={() => window.location.replace(url)}>{this.langOptions[key]}</a></li>
                )
        })
        if (!this.props.isLogged) { // not logged in
            return (
                <div className={`primary-menu ${this.props.deviceScreen === 'mobile' ? 'mobile' : ''}`}>
                    <ul>
                        <li>
                            <NavLink className={this.props.match.url === `/${lang}/` ? 'active' : 'not-home'} to={`/${lang}/`}>
                                <SVG src="/static/images/_home.svg" />
                                {translate(lang, 'home')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/${lang}/find-chefs`}>
                                <SVG src="/static/images/_chefs.svg" />
                                {translate(lang, 'find_chefs')}
                            </NavLink>
                        </li>

                        {this.props.deviceScreen === 'mobile'
                        ? <li data-section="services" className={`${this.state.primaryMenuSubmenuServicesOpen ? 'open' : ''}`}
                        >
                        <a to={`/${lang}/services`} onClick={(e) => {
                            e.preventDefault()
                            this.handlePrimaryMenuSubmenuServicesOpen(true)
                        }}>
                            <SVG src="/static/images/_services.svg" />
                            {translate(lang, 'services')}
                        </a>
                    </li>
                        : <li data-section="services" className={`${this.state.primaryMenuSubmenuServicesOpen ? 'open' : ''}`}
                            onMouseEnter={this.handlePrimaryMenuSubmenuServicesOpen.bind(this, true)}
                            onMouseLeave={this.handlePrimaryMenuSubmenuServicesOpen.bind(this, false)}
                            >
                            <a to={`/${lang}/services`} onClick={(e) => {
                                e.preventDefault()
                                this.handlePrimaryMenuSubmenuServicesOpen()
                            }}>
                                <SVG src="/static/images/_services.svg" />
                                {translate(lang, 'services')}
                            </a>
                            <ul>
                                <li><NavLink to={`/${lang}/services/restaurant`}>{translate(lang, 'services_restaurants')}</NavLink></li>
                                <li><NavLink to={`/${lang}/services/chef`}>{translate(lang, 'services_chefs')}</NavLink></li>
                            </ul>
                        </li>
                        }

                        <li>
                            <NavLink to={`/${lang}/contact`}>
                                <SVG src="/static/images/_contact.svg" />
                                {translate(lang, 'contact')}
                            </NavLink>
                        </li>

                        {this.props.deviceScreen === 'mobile'
                        ? <li data-section="languageSelector" className={`${this.state.languagesMenuIsOpen ? 'open' : ''}`}
                            >
                            <a onClick={(e) => {
                                e.preventDefault()
                                this.handleLanguageSelectorIsOpen(true)
                            }}>
                                <SVG src="/static/images/_language.svg" />
                                {this.langOptions[lang]}
                            </a>
                        </li>
                        : <li data-section="languageSelector" className={`${this.state.languageSelectorIsOpen ? 'open' : ''}`}
                            onMouseEnter={this.handleLanguageSelectorIsOpen.bind(this, true)}
                            onMouseLeave={this.handleLanguageSelectorIsOpen.bind(this, false)}
                            >
                            <a onClick={(e) => {
                                e.preventDefault()
                                this.handleLanguageSelectorIsOpen()
                            }}>
                                <SVG src="/static/images/_language.svg" />
                                {this.langOptions[lang]}
                            </a>
                            <ul>
                                {langList} 
                            </ul>
                        </li>
                        }
                    </ul>
                </div>
            )
        }
        if (this.props.isLogged && this.props.userType === 'restaurant') { // logged in as restaurant
            const pendingActions = this.props.user && this.props.user.main.pendingnotifications.pending
            const pendingActionsLimited = pendingActions > 99 ? '+99' : pendingActions
            const pendingMessages = this.props.user && this.props.user.main.pendingnotifications.messages
            const pendingMessagesLimited = pendingMessages > 99 ? '+99' : pendingMessages

            return (
                <div className={`primary-menu ${this.props.deviceScreen === 'mobile' ? 'mobile' : ''}`}>
                    <ul>
                        <li>
                            <NavLink to={`/${lang}/`} className={this.props.match.url === `/${lang}/` ? 'active' : 'not-home'}>
                                <SVG src="/static/images/_home.svg" />
                                {translate(lang, 'home')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/${lang}/find-chefs`}>
                                <SVG src="/static/images/_chefs.svg" />
                                {translate(lang, 'find_chefs')}
                            </NavLink>
                        </li>
                        {parseInt(this.props.user.subscription) === 1
                        && <li>
                            {pendingMessagesLimited !== 0 && parseInt(this.props.user.main.premium) === 1 ? this.pendingDot(pendingMessagesLimited) : null}
                            <NavLink to={`/${lang}/restaurant/profile/messages/${this.props.user.main.id}`}>
                                <SVG src="/static/images/_messages.svg" />
                                {translate(lang, 'messages')}
                            </NavLink>
                        </li>
                        }
                        <li>
                            {pendingActionsLimited !== 0 && parseInt(this.props.user.main.premium) === 1 ? this.pendingDot(pendingActionsLimited) : null}
                            <NavLink to={`/${lang}/restaurant/profile/offers/${this.props.user.main.id}`}>
                                <SVG src="/static/images/_offers.svg" />
                                {translate(lang, 'job_offers')}
                            </NavLink>
                        </li>
                        {parseInt(this.props.user.subscription) === 1
                        && <li>
                            <NavLink to={`/${lang}/restaurant/profile/calendar/${this.props.user.main.id}`}>
                                <SVG src="/static/images/_calendar.svg" />
                                {translate(lang, 'calendar')}
                            </NavLink>
                        </li>
                        }
                        <li className='header-btn'>
                            <Btn
                            text={translate(lang, 'post_jobs')}
                            pre="_post_offer"
                            className={this.props.deviceScreen === "mobile" ? "" : 'header-btn'}
                            callback={() => {
                                this.props.showOverlay('joboffers', {rid: -1})
                            }}
                            />
                        </li>
                    </ul>
                </div>
            )
        }
        if (this.props.isLogged && this.props.userType === 'chef') { // logged in as chef
            const pendingActions = this.props.user && this.props.user.profile.pendingnotifications.pending
            const pendingActionsLimited = pendingActions > 99 ? '+99' : pendingActions
            const pendingMessages = this.props.user && this.props.user.profile.pendingnotifications.messages
            const pendingMessagesLimited = pendingMessages > 99 ? '+99' : pendingMessages

            return (
                <div className={`primary-menu ${this.props.deviceScreen === 'mobile' ? 'mobile' : ''}`}>
                    <ul>
                        <li>
                            <NavLink to={`/${lang}/`} className={this.props.match.url === `/${lang}/` ? 'active' : 'not-home'}>
                                <SVG src="/static/images/_home.svg" />
                                {translate(lang, 'home')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/${lang}/find-restaurants`}>
                                <SVG src="/static/images/_restaurants.svg" />
                                {translate(lang, 'find_restaurants')}
                            </NavLink>
                        </li>
                        <li>
                            {pendingMessagesLimited !== 0 ? this.pendingDot(pendingMessagesLimited) : null}
                            <NavLink to={`/${lang}/chef/profile/messages`}>
                                <SVG src="/static/images/_messages.svg" />
                                {translate(lang, 'messages')}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`/${lang}/chef/profile/calendar`}>
                                <SVG src="/static/images/_calendar.svg" />
                                {translate(lang, 'calendar')}
                            </NavLink>
                        </li>
                        <li>
                            {pendingActionsLimited !== 0 ? this.pendingDot(pendingActionsLimited) : null}
                            <NavLink to={`/${lang}/chef/profile/applications`}>
                                <SVG src="/static/images/_applications.svg" />
                                {translate(lang, 'applications')}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            )
        }
    }
    renderSecondaryMenu() {
        const { lang } = this.props.match.params
        const langList = []
        const previewButtonTitle = translate(lang, 'preview')

        Object.keys(this.langOptions).forEach((key) => {
            const url = this.updateLanguage(key)
            langList.push(
                <li key={key}><a onClick={() => window.location.replace(url)}>{this.langOptions[key]}</a></li>
                )
        })
        let image = ''
        let alt = ''
        if (this.props.isLogged && this.props.user && this.props.userType === 'restaurant') {
            image = this.props.user.main && typeof this.props.user.main.images.logo === 'object' ? this.props.user.main.images.logo.sizes.square.url : '/static/images/empty_600_600.png'
            alt = this.props.user.main.name
        } else if (this.props.isLogged && this.props.user && this.props.userType === 'chef') {
            image = this.props.user.profile && typeof this.props.user.profile.images.profile === 'object' ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'
            alt = this.props.user.profile.name
        }

        if (!this.props.isLogged) { //user is NOT logged
            return null
        }
        let items = []
        if (this.props.userType === 'restaurant') {
            items.push(
                <li key="restaurants">
                    <Link to={`/${lang}/find-restaurants`}>
                        <SVG src="/static/images/_restaurants.svg" />{translate(lang, 'find_restaurants')}
                    </Link>
                </li>
            )
            items.push(
                <li key="contact">
                    <Link to={`/${lang}/contact`}>
                        <SVG src="/static/images/_contact.svg" />{translate(lang, 'contact')}
                    </Link>
                </li>
            )
            items.push(
                <li key="services">
                    <Link to={`/${lang}/services/restaurant`}>
                        <SVG src="/static/images/_services.svg" />{translate(lang, 'services')}
                    </Link>
                </li>
            )
            items.push(
                <li key="faqs">
                    <Link to={`/${lang}/faqs/restaurant`}>
                        <SVG src="/static/images/_faqs.svg" />{translate(lang, 'faqs')}
                    </Link>
                </li>
            )
            items.push(
                <li key="settings">
                    <a onClick={()=>{this.props.showOverlay('settings', this.props.user)}}>
                        <SVG src="/static/images/_settings.svg" />{translate(lang, 'settings')}
                    </a>
                </li>
            )
        } else if (this.props.userType === 'chef') {
            items.push(
                <li key="chefs">
                    <Link to={`/${lang}/find-chefs`}>
                        <SVG src="/static/images/_chefs.svg" />{translate(lang, 'find_chefs')}
                    </Link>
                </li>
            )
            items.push(
                <li key="saved">
                    <Link to={`/${lang}/chef/profile/saved`}>
                        <SVG src="/static/images/_saved_offers.svg" />{translate(lang, 'saved')}
                    </Link>
                </li>
            )
            items.push(
                <li key="contact">
                    <Link to={`/${lang}/contact`}>
                        <SVG src="/static/images/_contact.svg" />{translate(lang, 'contact')}
                    </Link>
                </li>
            )
            items.push(
                <li key="services">
                    <Link to={`/${lang}/services/chef`}>
                        <SVG src="/static/images/_services.svg" />{translate(lang, 'services')}
                    </Link>
                </li>
            )
            items.push(
                <li key="faqs">
                    <Link to={`/${lang}/faqs/chef`}>
                        <SVG src="/static/images/_faqs.svg" />{translate(lang, 'faqs')}
                    </Link>
                </li>
            )
        }
        if (this.state.languagesMenuIsOpen) {
            return (
                this.renderLanguagesMenu()
            )
        }
        if (this.state.profilesMenuIsOpen) {
            return (
                this.renderProfilesMenu()
            )
        }

        const pendingMessagesActions = this.props.isLogged && this.props.user && this.props.user.type === "restaurant" && this.props.user.main.pendingnotifications.messages + this.props.user.main.pendingnotifications.pending
        const pendingMessagesActionsLimited = pendingMessagesActions > 99 ? '+99' : pendingMessagesActions

        if (!this.state.languagesMenuIsOpen && !this.state.profilesMenuIsOpen) {
            return (
                <div
                    className="secondary-menu"
                    >
                    <ul>
                        <li className='profile'>
                            {this.props.userType === 'restaurant'
                            ? <a className={`profile-picture-wrapper profiles ${this.state.profilesMenuIsOpen ? 'profiles-open' : ''} `} onClick={(e) => {
                                e.preventDefault()
                                this.handleProfilesMenuIsOpen()
                            }}>
                                <SVG src="/static/images/arrow.svg" />
                                <div className="logo">
                                    {pendingMessagesActionsLimited !== 0 && parseInt(this.props.user.main.premium) === 1 ? this.pendingDot(pendingMessagesActionsLimited) : null}
                                    <LazyImage className='profile-picture' src={image} alt={alt} />
                                </div>
                            </a>
                            : <div className='profile-picture-wrapper'>
                                <LazyImage className='profile-picture' src={image} alt={alt} />
                            </div>
                            }
                            <div className='buttons-name'>
                                <div className='name'><span>{this.props.userType === 'chef' ? this.props.user.profile.name : this.props.user.main.name}</span></div>
                                <div className='buttons'>
                                    <Link className='edit-profile button' to={this.props.userType === 'chef' ? `/${lang}/chef/profile/home` : `/${lang}/restaurant/profile/home`}>
                                        {translate(lang, 'edit')}
                                    </Link>
                                    <Link className='preview-profile button' to={this.props.userType === 'chef' ? `/${lang}/chef/view/${this.props.user.profile.id}/${this.props.user.profile.slug}` : `/${lang}/restaurant/view/home/${this.props.user.main.id}/${this.props.user.main.slug}`}>
                                        {previewButtonTitle.length > 8 ? `${previewButtonTitle.substring(0, 8)}.` : previewButtonTitle}
                                    </Link>
                                </div>
                            </div>
                        </li>
                        {items}
                        <li data-section="languageSelector" className={this.state.languageSelectorIsOpen ? 'open' : null}>
                            <a onClick={(e) => {
                                e.preventDefault()
                                this.handleLanguagesMenuIsOpen()
                            }}>
                                <SVG src="/static/images/_language.svg" />
                                {this.langOptions[lang]}
                            </a>
                        </li>
                        <li>
                            <a onClick={this.logout.bind(this)}>
                                <SVG src="/static/images/_logout.svg" />{translate(lang, 'logout')}
                            </a>
                        </li>
                        {this.props.userType === 'restaurant' && parseInt(this.props.user.subscription) === 0
                        && <li key="premium" className='button-li'>
                            <Link className="premium button" to={`/${lang}/services/restaurant`}>
                                {translate(lang, 'upgrade_premium')}
                            </Link>
                        </li>}
                    </ul>
                </div>
            )
        }
    }
    renderServicesMenu() {
        const { lang } = this.props.match.params
        return (
            <div
                className={`services-menu ${this.state.primaryMenuSubmenuServicesOpen ? 'open' : ''}`}
                >
                <ul>
                    <li>
                        <Link to={`/${lang}/services/restaurant`}>
                            {translate(lang, 'services_restaurants')}
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${lang}/services/chef`}>
                            {translate(lang, 'services_chefs')}
                        </Link>
                    </li>
                    <li>
                        <a onClick={() => this.handlePrimaryMenuSubmenuServicesOpen(false)}>
                            {translate(lang, 'cancel')}
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
    renderLanguagesMenu() {
        const { lang } = this.props.match.params
        const langList = []
        const previewButtonTitle = translate(lang, 'preview')

        Object.keys(this.langOptions).forEach((key) => {
            const url = this.updateLanguage(key)
            langList.push(
                <li key={key}><a onClick={() => window.location.replace(url)}>{this.langOptions[key]}</a></li>
                )
        })
        let image = ''
        let alt = ''
        if (this.props.isLogged && this.props.user && this.props.userType === 'restaurant') {
            image = typeof this.props.user.main.images.logo === 'object' ? this.props.user.main.images.logo.sizes.square.url : '/static/images/empty_600_600.png'
            alt = this.props.user.main.name
        } else if (this.props.isLogged && this.props.user && this.props.userType === 'chef') {
            image = this.props.user.profile && typeof this.props.user.profile.images.profile === 'object' ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'
            alt = this.props.user.profile.name
        }

        if (this.props.isLogged) {
            return (
                <div
                    className="secondary-menu"
                    >
                    <ul>
                        <li className='profile'>
                            {this.props.userType === 'restaurant'
                            ? <a className={`profile-picture-wrapper profiles ${this.state.profilesMenuIsOpen ? 'profiles-open' : ''} `} onClick={(e) => {
                                e.preventDefault()
                                this.handleProfilesMenuIsOpen()
                            }}>
                                <SVG src="/static/images/arrow.svg" />
                                <LazyImage className='profile-picture' src={image} alt={alt} />
                            </a>
                            : <div className='profile-picture-wrapper'>
                                <LazyImage className='profile-picture' src={image} alt={alt} />
                            </div>
                            }
                            <div className='buttons-name'>
                                <div className='name'><span>{this.props.userType === 'chef' ? this.props.user.profile.name : this.props.user.main.name}</span></div>
                                <div className='buttons'>
                                    <Link className='edit-profile button' to={this.props.userType === 'chef' ? `/${lang}/chef/profile/home` : `/${lang}/restaurant/profile/home`}>
                                        {translate(lang, 'edit')}
                                    </Link>
                                    <Link className={`preview-profile button`} to={this.props.userType === 'chef' ? `/${lang}/chef/view/${this.props.user.profile.id}/${this.props.user.profile.slug}` : `/${lang}/restaurant/view/home/${this.props.user.main.id}/${this.props.user.main.slug}`}>
                                        {previewButtonTitle.length > 8 ? `${previewButtonTitle.substring(0, 8)}.` : previewButtonTitle}
                                    </Link>
                                </div>
                            </div>
                        </li>
                        {langList}
                        <li>
                            <a onClick={() => this.handleLanguagesMenuIsOpen(false)}>
                                {translate(lang, 'cancel')}
                            </a>
                        </li>
                    </ul>
                </div>
            )
        } else {
            return (
                <div
                    className={`languages-menu ${this.state.languageSelectorIsOpen ? 'open' : ''}`}
                    >
                    <ul>
                        {langList}
                        <li>
                            <a onClick={() => this.handleLanguageSelectorIsOpen(false)}>
                                {translate(lang, 'cancel')}
                            </a>
                        </li>
                    </ul>
                </div>
            )
        }
    }


    changeMainProfile(rid) {
        const { lang } = this.props.match.params
        this.setState({ loading: true })
        setMainProfile({rid: rid, lang}).then((res) => {
            if (res.data.success) {
                this.setState({profilesMenuIsOpen: false})
                this.props.setUser(res.data.data.user)
                this.props.history.push(`/${lang}/restaurant/profile/home`)
            } else {
                this.setState({loading:false})
                this.props.showOverlay('error', {message: res.data.message})
            }
        })
    }
    renderProfilesMenu() {
        const { lang } = this.props.match.params
        const profilesList = []

        this.props.user.profiles.forEach(profile => {
            const pendingMessagesActions = profile.pendingnotifications.messages + profile.pendingnotifications.pending
            const pendingMessagesActionsLimited = pendingMessagesActions > 99 ? '+99' : pendingMessagesActions
            if (parseInt(profile.main) !== 1) {
                const image = typeof profile.images.logo === 'object' ? profile.images.logo.sizes.square.url : '/static/images/empty_600_600.png'
                profilesList.push(
                    <li key={profile.id} className='other-profile'>
                        {pendingMessagesActionsLimited !== 0 && parseInt(this.props.user.main.premium) === 1 ? this.pendingDot(pendingMessagesActionsLimited) : null}
                        <a onClick={() => {this.changeMainProfile(profile.id)}}>
                            <LazyImage className='profile-picture' src={image} alt={profile.name} />
                            <div className='profile-name'>{profile.name}</div>
                        </a>
                    </li>
                )
            }
        })

        let image = ''
        let alt = ''
        if (this.props.isLogged && this.props.user && this.props.userType === 'restaurant') {
            image = typeof this.props.user.main.images.logo === 'object' ? this.props.user.main.images.logo.sizes.square.url : '/static/images/empty_600_600.png'
            alt = this.props.user.main.name
        } else if (this.props.isLogged && this.props.user && this.props.userType === 'chef') {
            image = this.props.user.profile && typeof this.props.user.profile.images.profile === 'object' ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'
            alt = this.props.user.profile.name
        }
        const previewButtonTitle = translate(lang, 'preview')
        const pendingMessagesActionsMain = this.props.isLogged && this.props.user && this.props.user.type === "restaurant" && this.props.user.main.pendingnotifications.messages + this.props.user.main.pendingnotifications.pending
        const pendingMessagesActionsMainLimited = pendingMessagesActionsMain > 99 ? '+99' : pendingMessagesActionsMain

        return (
            <div
                className="secondary-menu"
                >
                <ul>
                    <li className='profile'>
                        {this.props.userType === 'restaurant'
                            ? <a className={`profile-picture-wrapper profiles ${this.state.profilesMenuIsOpen ? 'profiles-open' : ''} `} onClick={(e) => {
                                e.preventDefault()
                                this.handleProfilesMenuIsOpen()
                            }}>
                                <SVG src="/static/images/arrow.svg" />
                                <div className="logo">
                                    {pendingMessagesActionsMainLimited !== 0 && parseInt(this.props.user.main.premium) === 1 ? this.pendingDot(pendingMessagesActionsMainLimited) : null}
                                    <LazyImage className='profile-picture' src={image} alt={alt} />
                                </div>
                            </a>
                            : <div className='profile-picture-wrapper'>
                                <LazyImage className='profile-picture' src={image} alt={alt} />
                            </div>
                            }
                        <div className='buttons-name'>
                            <div className='name'><span>{this.props.userType === 'chef' ? this.props.user.profile.name : this.props.user.main.name}</span></div>
                            <div className='buttons'>
                                <Link className='edit-profile button' to={this.props.userType === 'chef' ? `/${lang}/chef/profile/home` : `/${lang}/restaurant/profile/home`}>
                                    {translate(lang, 'edit')}
                                </Link>
                                <Link className='preview-profile button' to={this.props.userType === 'chef' ? `/${lang}/chef/view/${this.props.user.profile.id}/${this.props.user.profile.slug}` : `/${lang}/restaurant/view/home/${this.props.user.main.id}/${this.props.user.main.slug}`}>
                                    {previewButtonTitle.length > 8 ? `${previewButtonTitle.substring(0, 8)}.` : previewButtonTitle}
                                </Link>
                            </div>
                        </div>
                    </li>
                    {profilesList}
                    <li>
                        <a onClick={() => {this.props.showOverlay('restaurantabout', {})}}>
                            <SVG src="/static/images/more_icn.svg" />
                            {translate(lang, 'add_restaurant')}
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
    openUserMenu() {
        if (this.props.isLogged) {
            if (this.state.primaryMenuIsOpen) {
                this._closePrimaryMenu(() => {
                    this.setState({ secondaryMenuIsOpen: !this.state.secondaryMenuIsOpen })
                })
            } else if (this.state.notificationsIsOpen) {
                this.setState({ notificationsIsOpen: false })
                this.setState({ secondaryMenuIsOpen: !this.state.secondaryMenuIsOpen })
            } else {
                this.setState({ secondaryMenuIsOpen: !this.state.secondaryMenuIsOpen })
            }
        } else {
            if (this.props.showOverlay) {
                this.props.showOverlay('login');
            } else {
                this.props.showOverlay(null);
            }
        }
    }
    
    handlePrimaryMenuSubmenuServicesOpen(open) {
        if (this.props.deviceScreen === "mobile") {
            this.setState({
                primaryMenuSubmenuServicesOpen: open !== undefined ? open : !this.state.primaryMenuSubmenuServicesOpen,
                primaryMenuIsOpen: open !== undefined ? !open : !this.state.primaryMenuIsOpen,
                languageSelectorIsOpen: false,
                secondaryMenuIsOpen: false
            })
        } else {
            this.setState({
                primaryMenuSubmenuServicesOpen: open !== undefined ? open : !this.state.primaryMenuSubmenuServicesOpen,
                languageSelectorIsOpen: false,
                secondaryMenuIsOpen: false,
                primaryMenuIsOpen: false,
            })
        }
    }
    handleLanguageSelectorIsOpen(open) {
        if (this.props.deviceScreen === "mobile") {
            this.setState({
                languageSelectorIsOpen: open !== undefined ? open : !this.state.languageSelectorIsOpen,
                primaryMenuIsOpen: this.props.isLogged ? false : !open,
                primaryMenuSubmenuFindJobsOpen: false,
                primaryMenuSubmenuServicesOpen: false,
                secondaryMenuIsOpen: false,
            })

        } else {
            this.setState({
                languageSelectorIsOpen: open !== undefined ? open : !this.state.languageSelectorIsOpen,
                primaryMenuSubmenuFindJobsOpen: false,
                primaryMenuSubmenuServicesOpen: false,
                secondaryMenuIsOpen: false,
                primaryMenuIsOpen: false,
            })
        }
    }
    handleLanguagesMenuIsOpen(open) {
        this.setState({
            languagesMenuIsOpen: open !== undefined ? open : !this.state.languagesMenuIsOpen,
            profilesMenuIsOpen: false,
            primaryMenuSubmenuFindJobsOpen: false,
            primaryMenuSubmenuServicesOpen: false,
            secondaryMenuIsOpen: this.props.isLogged ? true : false,
        })
    }
    handleProfilesMenuIsOpen(open) {
        this.setState({
            profilesMenuIsOpen: open !== undefined ? open : !this.state.profilesMenuIsOpen,
            languagesMenuIsOpen: false,
            primaryMenuSubmenuFindJobsOpen: false,
            primaryMenuSubmenuServicesOpen: false,
            secondaryMenuIsOpen: this.props.isLogged ? true : false
        })
    }
    togglePrimaryMenu() {
        if (this.state.primaryMenuIsOpen) {
            this._closePrimaryMenu()
        } else {
            this._openPrimaryMenu()
        }
    }
    _closePrimaryMenu(callback = () => {}) {
        if (this.state.primaryMenuSubmenuServicesOpen || this.state.primaryMenuSubmenuFindJobsOpen || this.state.languageSelectorIsOpen) {
            this.setState({ primaryMenuSubmenuServicesOpen: false, primaryMenuSubmenuFindJobsOpen: false, languageSelectorIsOpen: false }, () => {
                setTimeout(() => {
                    this.setState({ primaryMenuIsOpen: false }, callback)
                }, 200)
            })
        } else {
            this.setState({ primaryMenuIsOpen: false }, callback)
        }
    }
    _openPrimaryMenu(callback = () => {}) {
        this.setState({
            primaryMenuIsOpen: true,
            languagesMenuIsOpen: false,
            primaryMenuSubmenuServicesOpen: false,
            languageSelectorIsOpen: false,
        }, callback)
    }
    logout() {
        const { lang } = this.props.match.params
        this.props.setUser(null)
        this.props.setToken(null)
        this.props.setUserType(null)
        this.props.setIsLogged(false)

        this.closeAllMenus()
        
        accessLogout(lang).then((res) => {
            localStorage.removeItem('user')
            Cookies.remove('token')
        })
        this.props.history.push('/')
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        showOverlay: state.showOverlay,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Header))
