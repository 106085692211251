import React from 'react'
import { translate } from '../../Translations/translate'
import IconButton from './IconButton'

const ProfileSection = ({ lang, section, children, title, buttonType = null, buttonCallback = () => {}, noChildrenMessage = "" }) => {

  const setLabel = () => {
    switch(buttonType) {
      case 'edit':
        return translate(lang, 'edit')
      case 'add':
        return translate(lang, 'add')
    }
  }

  return (
    <div className='component_ProfileSection' data-block={section}>
      <div className='top'>
        <h3>{title}</h3>
        {buttonType && <IconButton
          label={setLabel()}
          callback={() => buttonCallback()}
          icon={buttonType} />
        }
      </div>
     {children ? children : <div className="noChildrenMessage">{noChildrenMessage}</div> }
    </div>
  )
}

export default ProfileSection