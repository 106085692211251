import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Checkbox from '../../Components/Input/Checkbox'
import Input from '../../Components/Input/Input'
import Moment from 'moment'
import MonthView from 'react-calendar/dist/entry.nostyle'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import { extendMoment } from 'moment-range'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import {applyOffers, acceptSuggestedOffer} from '../../Services/Offers/offers'
// import {disponibility} from '../../Lists/lists'
import { translate } from '../../Translations/translate'
const moment = extendMoment(Moment);

var findIndex = require('lodash/findIndex')
var map = require('lodash/map')

class ApplyOffersOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            offers: [],
            selectedDays: [],
            errors: [],
            activeStartDate: moment().toDate(),
            value: null,
            status: 1,
            date: moment().toDate()
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay callback={this.props.data.callback} title={translate(lang, 'application_form')} className="apply-offers">
                <div className="col-group">
                    <div className="col-mb-12 q">
                        <p>{translate(lang, 'select_available_trial_dates')}</p>
                    </div>
                    <div className="col-mb-12 col-6 col-offset-3 calendar-view">
                        <MonthView
                            minDate={new Date()}
                            activeStartDate={this.state.activeStartDate}
                            value={this.state.value}
                            onChange={()=>{this.setState({value:null})}}
                            onClickDay={(date) => {
                                let newSelectedDays = this.state.selectedDays ? this.state.selectedDays : []
                                let index = findIndex(newSelectedDays, (o) => {
                                    return o.getTime() === date.getTime()
                                })
                                if (index > -1) {
                                    newSelectedDays.splice(index, 1)
                                } else if (newSelectedDays.length < 3) {
                                    newSelectedDays.push(date)
                                }
                                this.setState({ selectedDays: newSelectedDays, value: null, activeStartDate: date })
                            }}
                            locale="en-En"
                            view="month"
                            tileClassName={({date, view }) => {
                                let index = (findIndex(this.state.selectedDays, (o) => {
                                    return o.getTime() === date.getTime()
                                }) > -1)
                                return index ? 'react-calendar__tile--active' : ''
                            }}
                            />
                    </div>
                    {/* <div className="col-mb-12 q q-checks">
                        <p><strong>02.</strong> {translate(lang, 'when_available_to_start')}</p>
                    </div>
                    <div className="col-mb-12 col-6 col-offset-3 checks">
                        <Checkbox
                            label={disponibility[lang][0].label}
                            name="status1"
                            error={this.state.errors}
                            checked={this.state.status === 1}
                            onChange={(v) => {
                                this.setState({status: 1})
                            }}
                            />
                        <Checkbox
                            label={disponibility[lang][1].label}
                            name="status2"
                            error={this.state.errors}
                            checked={this.state.status === 2}
                            onChange={(v) => {
                                this.setState({status: 2})
                            }}
                            />
                        {this.state.status === 2 ? <Input
                            label={translate(lang, 'date')}
                            name="date"
                            error={this.state.errors}
                            type="date"
                            value={this.state.date}
                            onChange={(v) => {
                                this.setState({date: v})
                            }}
                            /> : null}
                        <Checkbox
                            label={disponibility[lang][2].label}
                            name="status3"
                            error={this.state.errors}
                            checked={this.state.status === 3}
                            onChange={(v) => {
                                this.setState({status: 3})
                            }}
                            />
                        <Checkbox
                            label={disponibility[lang][3].label}
                            name="status4"
                            error={this.state.errors}
                            checked={this.state.status === 4}
                            onChange={(v) => {
                                this.setState({status: 4})
                            }}
                            />
                    </div> */}
                    <div className="col-mb-12 actions multiple">
                        <div className="btns">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'send').toUpperCase()} loading={this.state.loading} className={this.state.selectedDays.length===0 ? 'disabled inverted' : null} callback={() => {
                                if (this.state.selectedDays.length > 0) {
                                    if (this.props.data.proposedChef) {
                                        this.actionAccept()
                                    } else {
                                        this.actionApply()
                                    }
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    actionApply() {
        const { lang } = this.props.match.params
        let ids = map(this.props.data.offers, (o) => {
            return o.id
        })
        let dates = []
        this.state.selectedDays.forEach((d) => {
            dates.push(moment(d).format('YYYY-MM-DD'))
        })
        this.setState({loading: true})
            applyOffers({
                offers: ids.join(','),
                dates: JSON.stringify(dates),
                disponibility: 1,
                disponibilitydate: this.state.date,
                // disponibility: this.state.status,
                // disponibilitydate: moment(this.state.date).format('YYYY-MM-DD')
                lang,
            }).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                if (this.props.data.callback) {
                    this.props.data.callback(true)
                    this.props.showOverlay('success', { message: translate(lang, 'application_sent'), refreshAfter: true })
                }
            }).catch((e) => {
                this.setState({ loading: false })
                this.props.showOverlay('error', {message: e.message})
            })
    }
    actionAccept() {
        const { lang } = this.props.match.params
        let dates = []
        this.state.selectedDays.forEach((d) => {
            dates.push(moment(d).format('YYYY-MM-DD'))
        })
        this.setState({loading: true})
            acceptSuggestedOffer({
                id: this.props.data.id,
                dates: JSON.stringify(dates),
                disponibility: 1,
                disponibilitydate: this.state.date,
                lang,
            }).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                if (this.props.data.callback) {
                    this.props.data.callback(true)
                    this.props.showOverlay('success', {message: translate(lang, 'application_sent')})
                }
            }).catch((e) => {
                this.setState({ loading: false })
                this.props.showOverlay('error', {message: e.message})
            })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ApplyOffersOverlay))
