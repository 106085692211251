import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import Select from 'react-select'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {offerGuru} from '../../Services/Offers/offers'
import {validateArr} from '../../Components/Input/validate'
import {valueByKey} from '../../Lib/valueByKey'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class JobOfferGuruOverlay extends Component {
    constructor(props) {
        super(props)
        let {guru} = this.props.data
        this.state = {
            about: valueByKey(guru, 'about', 0),
            position: valueByKey(guru, 'position', 0),
            headchef: valueByKey(guru, 'headchef', 0),
            opportunities: valueByKey(guru, 'opportunities', 0),
            thechef: valueByKey(guru, 'thechef', 0),
            foodstandards: valueByKey(guru, 'foodstandards', 0),
            staffdevelopment: valueByKey(guru, 'staffdevelopment', 0),
            healthsafety: valueByKey(guru, 'healthsafety', 0),
            management: valueByKey(guru, 'management', 0),
            other: valueByKey(guru, 'other', 0),
            errors: []
        }
    }
    componentDidMount() {
        const { lang } = this.props.match.params
        offerGuru(lang).then((res) => {
            this.setState({items: res.data.data})
        })
    }
    render() {
        const { lang } = this.props.match.params
        let className = 'guru-template'

        return (
            <Overlay
                title={translate(lang, 'guru_template')}
                className={className}
                >
                <div className="col-group form">

                    <div className="col-mb-12">
                        <h4>{translate(lang, 'help_job_description')}</h4>
                        <p>{translate(lang, 'help_job_description_text')}</p>
                    </div>

                    {this.renderContent()}

                    <div className="col-mb-12 actions multiple">
                        <div className="accessory">
                            <Btn text={translate(lang, 'back').toUpperCase()} pre="backarrow" className="outlined" callback={() => {
                                this.props.data.callback(false)
                            }} />
                        </div>
                        <div className="main">
                            <Btn text={translate(lang, 'add_to_job_description').toUpperCase()} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div>
                <div className="col-mb-12">
                    <label htmlFor="about">{translate(lang, 'restaurant')} &amp; {translate(lang, 'location')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="about"
                        multi={true}
                        removeSelected={true}
                        value={this.state.about}
                        className={this.state.errors.indexOf('about') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ about: options }) }}
                        options={this.state.items && this.state.items.about ? this.state.items.about : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="position">{translate(lang, 'position')} &amp; {translate(lang, 'team')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="position"
                        value={this.state.position}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('position') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ position: options }) }}
                        options={this.state.items && this.state.items.position ? this.state.items.position : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="headchef">{translate(lang, 'head_chef')} &amp; {translate(lang, 'menu')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="headchef"
                        value={this.state.headchef}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('headchef') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ headchef: options }) }}
                        options={this.state.items && this.state.items.headchef ? this.state.items.headchef : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="opportunities">{translate(lang, 'future_opportunities')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="opportunities"
                        value={this.state.opportunities}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('opportunities') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ opportunities: options }) }}
                        options={this.state.items && this.state.items.opportunities ? this.state.items.opportunities : []}
                        />
                </div>
                <div className="col-mb-12">
                    <h4>{translate(lang, 'the_chef').toUpperCase()}</h4>
                </div>
                <div className="col-mb-12">
                    <label htmlFor="thechef">{translate(lang, 'previous_experience')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="thechef"
                        value={this.state.thechef}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('thechef') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ thechef: options }) }}
                        options={this.state.items && this.state.items.thechef ? this.state.items.thechef : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="foodstandards">{translate(lang, 'food_standards')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="foodstandards"
                        value={this.state.foodstandards}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('foodstandards') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ foodstandards: options }) }}
                        options={this.state.items && this.state.items.foodstandards ? this.state.items.foodstandards : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="healthsafety">{translate(lang, 'health_safety')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="healthsafety"
                        value={this.state.healthsafety}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('healthsafety') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ healthsafety: options }) }}
                        options={this.state.items && this.state.items.healthsafety ? this.state.items.healthsafety : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="management">{translate(lang, 'management')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="management"
                        value={this.state.management}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('management') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ management: options }) }}
                        options={this.state.items && this.state.items.management ? this.state.items.management : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="staffdevelopment">{translate(lang, 'staff_development')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="staffdevelopment"
                        value={this.state.staffdevelopment}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('staffdevelopment') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ staffdevelopment: options }) }}
                        options={this.state.items && this.state.items.staffdevelopment ? this.state.items.staffdevelopment : []}
                        />
                </div>
                <div className="col-mb-12">
                    <label htmlFor="other">{translate(lang, 'other_duties')}</label>
                    <Select
                        placeholder={translate(lang, 'type_or_options')}
                        name="other"
                        value={this.state.other}
                        multi={true}
                        removeSelected={true}
                        className={this.state.errors.indexOf('other') !== -1 ? 'error' : null}
                        onChange={(options) => { this.setState({ other: options }) }}
                        options={this.state.items && this.state.items.other ? this.state.items.other : []}
                        />
                </div>
            </div>
        )
    }
    save() {
        let items = [
            {field: 'about'},
            {field: 'position'},
            {field: 'headchef'},
            {field: 'opportunities'},
            {field: 'thechef'},
            {field: 'foodstandards'},
            {field: 'staffdevelopment'},
            {field: 'healthsafety'},
            {field: 'management'},
            {field: 'other'},
        ];
        let errors = validateArr(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            let data = {
                about: this.state.about.map((i) => { return i.label }),
                position: this.state.position.map((i) => { return i.label }),
                headchef: this.state.headchef.map((i) => { return i.label }),
                opportunities: this.state.opportunities.map((i) => { return i.label }),
                thechef: this.state.thechef.map((i) => { return i.label }),
                foodstandards: this.state.foodstandards.map((i) => { return i.label }),
                staffdevelopment: this.state.staffdevelopment.map((i) => { return i.label }),
                healthsafety: this.state.healthsafety.map((i) => { return i.label }),
                management: this.state.management.map((i) => { return i.label }),
                other: this.state.other.map((i) => { return i.label }),
            }
            this.props.data.callback(data)
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(JobOfferGuruOverlay))
