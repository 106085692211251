import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import Btn from '../../Components/Btn/Btn'
import SVG from 'react-inlinesvg';
import Select from 'react-select'
import Stars from '../../Components/Stars/Stars'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findOption} from '../../Lib/findOption'
import {positions} from '../../Lists/lists'
import {selectOptionsForOffers, offersForUser} from '../../Lib/selectOptionsForOffers'
import {sendOffers} from '../../Services/Offers/offers'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
var map = require('lodash/map')

class ItemsSelection extends Component {
    constructor(props) {
        super(props)

        let state = {
            loading: false,
            open: false
        }

        let offers
        if (this.props.userType === 'restaurant') {
            offers = selectOptionsForOffers(offersForUser(this.props.user, true))
            if (offers.length > 0) {
                state.offers = offers
                state.offerId = offers[0].value
            }
        }

        this.state = state
    }
    render() {
        let className = 'component_ItemsSelection'
        if (this.state.open) {
            className += ' open'
        }

        let numberOfSelectedItems = this._numberOfSelectedItems()
        if (numberOfSelectedItems) {
            className += ' has-selected-items'
        }
        
        return (
            <div className={className}>
                <div className="wrapper">
                    <div className="items">
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }

    _numberOfSelectedItems() {
        let numberOfSelectedItems = 0
        if ((!this.props.isLogged || this.props.userType === 'chef') && this.props.selectedOffers) {
            numberOfSelectedItems = this.props.selectedOffers.length
        } else if (this.props.userType === 'restaurant' && this.props.selectedChefs) {
            numberOfSelectedItems = this.props.selectedChefs.length
        }
        return numberOfSelectedItems
    }
    _title() {
        const { lang } = this.props.match.params
        let numberOfSelectedItems = this._numberOfSelectedItems()
        let title = !this.props.isLogged || this.props.userType === 'chef' ? translate(lang, 'selected_offers_NUM', numberOfSelectedItems) : translate(lang, 'selected_chefs_NUM', numberOfSelectedItems)
        if (this.props.deviceScreen === 'mobile') {
            title = !this.props.isLogged || this.props.userType === 'chef' ? translate(lang, 'selected_offers_NUM_mobile', numberOfSelectedItems) : translate(lang, 'selected_chefs_NUM_mobile', numberOfSelectedItems)
        }
        return title
    }
    _idsOfSelectedItems() {
        const items = this.props.selectedOffers
        const ids = [] 
        if (items !== null && items.length > 0) {
            items.map(item => {
                ids.push(item.id)
            })
            const idsString = ids.join('&')
            return idsString
        }
    }
    
    renderContent() {
        const { lang } = this.props.match.params
        let numberOfSelectedItems = this._numberOfSelectedItems()
        let title = this._title()
        let className = numberOfSelectedItems > 0 ? 'title has-elements' : 'title'
        let selectedIds = this._idsOfSelectedItems()

        return (
            <div className="logged">
                <div className={className}>
                    <h5 onClick={()=>{this.setState({open:!this.state.open})}}>{title}{this.props.deviceScreen === 'mobile' ? <SVG src="/static/images/arrow.svg" /> : null}</h5>
                    {this.props.deviceScreen !== 'mobile' ? this.renderOfferSelector() : null}
                    {numberOfSelectedItems > 0 ? <span className="vertical-line"></span> : null}
                </div>
                {numberOfSelectedItems > 0 ? <div className="selected-items">
                    {this.props.deviceScreen === 'mobile' ? this.renderOfferSelector() : null}
                    {this.renderItems()}
                    {numberOfSelectedItems > 0 ? <div className="send"><Btn text={translate(lang, 'send').toUpperCase()} loading={this.state.loading} callback={() => {
                            this.applyToItems()
                        }}/></div> : null}
                    {!this.props.isLogged && numberOfSelectedItems > 0 ? <div className="only-cv"><Link className="link" to={`/${lang}/contact/${selectedIds}`}>{translate(lang, 'send_cv_only').toUpperCase()}</Link></div> : null}
                </div> : null}
            </div>
        )
    }
    renderOfferSelector() {
        const { lang } = this.props.match.params
        if (!this.props.isLogged || this.props.userType === 'chef') {
            return null
        } else if (this.props.userType === 'restaurant') {
            return (
                <div className="form">
                    <Select
                        placeholder={translate(lang, 'select_offer')}
                        name="offerId"
                        value={this.state.offerId}
                        onChange={(option) => { this.setState({ offerId: option ? option.value : -1 }) }}
                        options={this.state.offers}
                        />
                </div>
            )
        } else {
            return null
        }
    }
    renderItems() {
        if (!this.props.isLogged || this.props.userType === 'chef') {
            return this.renderOffers()
        } else if (this.props.userType === 'restaurant') {
            return this.renderChefs()
        } else {
            return null
        }
    }
    renderOffers() {
        let items = []
        if (this.props.selectedOffers) {
            this.props.selectedOffers.forEach((item) => {
                items.push(this.renderOffer(item))
            })
        }
        return items
    }
    renderOffer(rawItem) {
        let item = rawItem.item
        let selector = (
            <div className="select" onClick={() => {
                this.props.toggleSelectedOffer({id: item.offer.id})
                }}>
                <SVG src="/static/images/tick1.svg" />
            </div>
        )
        return (
            <div key={item.offer.id} className="item">
                <div className="restaurant-info">
                    {selector}
                    <div className="restaurant">
                        {item.restaurant.name}
                    </div>
                    {/* <Stars type="default" number="3" /> */}
                    {true ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                </div>
                <div className="offer-info">
                    {item.offer.name}
                </div>
            </div>
        )
    }
    renderChefs() {
        let items = []
        if (this.props.selectedChefs) {
            this.props.selectedChefs.forEach((item) => {
                items.push(this.renderChef(item))
            })
        }
        return items
    }
    renderChef(rawItem) {
        const { lang } = this.props.match.params
        //TODO
        let item = rawItem.item
        let selector = (
            <div className="select" onClick={() => {
                this.props.toggleSelectedChef({id: item.profile.id})
                }}>
                <SVG src="/static/images/tick1.svg" />
            </div>
        )
        return (
            <div key={item.profile.id} className="item">
                <div className="chef-info">
                    {selector}
                    <div className="chef">
                        {item.profile.name}
                    </div>
                    {true ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                </div>
                <div className="position-info">
                    {findOption(item.profile.position, positions[lang])}
                </div>
            </div>
        )
    }
    applyToItems() {
        const { lang } = this.props.match.params
        if (!this.props.isLogged) {
            this.props.showOverlay('login', { fromApplyCallback:() => {
                this.applyToItems()
            } })
        } else if (this.props.userType === 'chef' && this.props.selectedOffers) {
            this.setState({ loading: true })
            this.props.showOverlay('applyoffers', { offers: this.props.selectedOffers }, (valid) => {
                this.setState({ loading: false })
                if (valid) {
                    this.props.setSelectedOffers([])
                    this.props.showOverlay(null)
                }
            })
        } else if (this.props.userType === 'restaurant' && this.props.selectedChefs) {
            this.setState({ loading: true })
            let ids = map(this.props.selectedChefs, (o) => {
                return o.id
            })
            sendOffers({cid: ids.join(','), oid: this.state.offerId, lang}).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                //do something
                this.props.setSelectedChefs([])
                this.props.showOverlay('success', { message: translate(lang, 'offer_sent'), refreshAfter: true })
            }).catch((e) => {
                this.setState({ loading: false })
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        selectedOffers: state.selectedOffers,
        selectedChefs: state.selectedChefs,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ItemsSelection))
