import {restaurantFaqs_en} from './restaurantFaqs/restaurantFaqs_en'
import {restaurantFaqs_es} from './restaurantFaqs/restaurantFaqs_es'
import {restaurantFaqs_fr} from './restaurantFaqs/restaurantFaqs_fr'
import {restaurantFaqs_pt} from './restaurantFaqs/restaurantFaqs_pt'

export const restaurantFaqs = {
    en: restaurantFaqs_en,
    es: restaurantFaqs_es,
    fr: restaurantFaqs_fr,
    pt: restaurantFaqs_pt
}