import React, {Component} from 'react'
// import store from '../../Config/store'
import SVG from 'react-inlinesvg'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'

class UserIcon extends Component {
    render() {
        let content = null
        if (this.props.open) {
            content = <SVG src='/static/images/close_btn_top.svg' />
        } else {
            content = <SVG src='/static/images/login_icn.svg' />
            if (
                this.props.isLogged
                && this.props.userType === 'chef'
                && this.props.user.profile.images.profile
                && this.props.user.profile.images.profile.sizes.square.url !== '') {
                content = <LazyImage src={this.props.user.profile.images.profile.sizes.square.url} alt={this.props.user.profile.name} />
            } else if (
                this.props.isLogged
                && this.props.userType === 'restaurant'
                && this.props.user.main.images.logo
                && this.props.user.main.images.logo.sizes.square.url !== '') {
                content = <LazyImage src={this.props.user.main.images.logo.sizes.square.url} alt={this.props.user.main.name} />
            }
        }
        return (
            <div
                className="component_UserIcon"
                onClick={this.handleClick.bind(this)}
                >
                {content}
            </div>
        )
    }
    handleClick() {
        if (this.props.callback !== undefined) {
            this.props.callback()
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        user: state.user,
        userType: state.userType,
    }
}
export default withRouter(connect(mapStateToProps)(UserIcon))
