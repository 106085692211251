import * as types from './types'

export function setDeviceScreen(deviceScreen) {
    return {
        type: types.SET_DEVICE_SCREEN,
        deviceScreen
    }
}
export function setDeviceWidth(deviceWidth) {
    return {
        type: types.SET_DEVICE_WIDTH,
        deviceWidth
    }
}
export function setDeviceHeight(deviceHeight) {
    return {
        type: types.SET_DEVICE_HEIGHT,
        deviceHeight
    }
}
export function setLanguage(language) {
    return {
        type: types.SET_LANGUAGE,
        language
    }
}

export function showOverlay(overlay, data = {}, callback) {
    data.callback = callback
    let item = {name: overlay, data: data}
    return {
        type: types.SHOW_OVERLAY,
        item
    }
}
