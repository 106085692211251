export const memberships_pt = {
    m1: {
        title: 'MÉTODO 1',
        pricing: 'LIVRE',
        subtitle: 'Inscrição gratuita',
        items: [
            'Crie o perfil da sua empresa e anúncios gratuitamente',
            'A tecnologia LINK localizará rapidamente os Chefs mais relevantes para o seu papel',
            'Fácil de criar e compartilhar seus anúncios no social',
            'Filtros avançados para otimizar os resultados da pesquisa',
            'Selecione seu público e receba apenas os Cvs relevantes para sua empresa.',
            'Procurar por Chefs na nossa rede'
        ],
        fees: '(Taxa de recrutamento de 3% será aplicada)'
    },
    m2: {
        title: 'MÉTODO 2',
        pricing: '£600 POR ANO',
        subtitle: 'Premium',
        popular: 'MAIS POPULAR',
        items: [
            'Espaço publicitário premium',
            'E-mail e marketing de mídia social',
            'Procurar por Chefs na nossa rede',
            'Mensagem direta com nossos chefs',
            'Receba Chefs Cvs diretamente para sua caixa de entrada',
            'Controle todos os seus restaurantes e ofertas de emprego de uma plataforma fácil de usar',
            'Adicionar mais restaurantes premium'
        ],
        fees: '(+ £400 por restaurante)'
    },
}
