import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Input from '../../Components/Input/Input'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefEditInfo} from '../../Services/Chef/chef'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Select from 'react-select'
import ChefInfoForm from '../_NEW/ChefInfoForm'
import { refreshUser } from '../../Services/Access/access'
import Cookies from 'js-cookie'

class SettingsOverlay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            name: this.props.data.profile.name,
            position: this.props.data.profile.position,
            location: this.props.data.profile.location,
            dob: this.props.data.profile.dob,
            nationalities: this.props.data.profile.nationalities,
            visas: this.props.data.profile.visas,
            cuisine: this.props.data.profile.cuisine, 
            password: '',
            rpassword: '',
            phone: this.props.data.phone,
            email: this.props.data.contactemail,
            language: this.props.data.language
        }
    }
    
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay
              title={translate(lang, 'profile')}
              className={this.props.className}
              isNew
              overlayName={this.props.overlayName}
              profileNav={this.props.data.profileNav}
              subtitle={translate(lang, 'start_your_profile')}
              >
                <ChefInfoForm props={this.props} />

                {/* <div className="col-group form">
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <Input
                                    label={translate(lang, 'new_password')}
                                    name="password"
                                    error={this.state.errors}
                                    type="password"
                                    minLength={6}
                                    value={this.state.password}
                                    onChange={(v) => {
                                        this.setState({password: v})
                                    }}
                                    />
                            </div>
                            <div className="col-mb-12 col-6">
                                <Input
                                    label={translate(lang, 'repeat_new_password')}
                                    name="rpassword"
                                    error={this.state.errors}
                                    type="password"
                                    minLength={6}
                                    value={this.state.rpassword}
                                    onChange={(v) => {
                                        this.setState({rpassword: v})
                                    }}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <Input
                                    label={translate(lang, 'contact_email')}
                                    name="email"
                                    value={this.state.email}
                                    error={this.state.errors}
                                    onChange={(v) => {
                                        this.setState({email: v})
                                    }}
                                    />
                            </div>
                            <div className="col-mb-12 col-6">
                                <Input
                                    label={translate(lang, 'phone_number')}
                                    name="phone"
                                    error={this.state.errors}
                                    value={this.state.phone}
                                    onChange={(v) => {
                                        this.setState({phone: v})
                                    }}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <span>{translate(lang, 'default_language')}</span>
                        <Select
                            placeholder={this.state.language}
                            name="language-selector"
                            value={this.state.language}
                            onChange={(v) => {
                                this.setState({language: v.value})
                            }}
                            options={this.languageOptions}
                            clearable = {false}
                            deleteRemoves= {false}
                        />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div> */}
            </Overlay>
        )
    }
    save() {
        let items = [
            {field: 'email' , minLength: 4},
            {field: 'password' , minLength: 6, onlyIfNotEmpty: true},
            {field: 'rpassword' , minLength: 6, equals: 'password', onlyIfNotEmpty: true}
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })

            let data = {
                password: this.state.password,
                rpassword: this.state.rpassword,
                contactemail: this.state.email,
                phone: this.state.phone,
                language: this.state.language
            }
            // editSettings(data).then((res) => {
            //     this.setState({ loading: false })
            //     if (!res.data.success) {
            //         this.props.showOverlay('error', {message: res.data.message})
            //         return
            //     }
            //     let data = res.data.data
            //     this.props.setUser(data.user)
            //     this.props.showOverlay(null)
            // }).catch((e) => {
            //     this.props.showOverlay('error', {message: e.message})
            // })
            }
        }
    }


const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(SettingsOverlay))
