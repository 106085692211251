import React, {Component} from 'react'
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {cuisines, ratingTypes, languages} from '../../Lists/lists'
import {findOption} from '../../Lib/findOption'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
var find = require('lodash/find')

class Filter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false
        }
    }
    render() {
        const { lang } = this.props.match.params
        let options = []
        this.props.items.forEach((i) => {
            let option = this.renderOption(i)
            if (option) {
                options.push(option)
            }
        })
        if (options.length === 0) {
            return false
        }
        let visibleOptions = this.state.showMore || options.length <= 4 ? options : options.slice(0,4)
        let showMoreBtn = options.length > 4 && !this.state.showMore ? translate(lang, 'more') : translate(lang, 'less')
        return (
            <div className="component_Filter">
                <h6>{this.props.title}</h6>
                {visibleOptions}
                {options.length > 4 ? <div className="toggleMore" onClick={this.toggleMore.bind(this)}>{showMoreBtn}</div> : null}
            </div>
        )
    }
    renderOption(option) {
        let label = this.label(option)
        if (!label) {
            return false
        }
        let name = this.props.type + '_' + option.id
        let selected = find(this.props.selectedFilters, {id: option.id, type: this.props.type}) ? true : false
        let className = 'rc-checkbox'
        if (selected) {
            className += ' checked';
        }
        let image = selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'
        return (
            <div key={name} className="option">
                <label htmlFor={name} onClick={() => {
                        this.props.toggleSelectedFilter({id: option.id, type: this.props.type})
                        if (this.props.callback) {
                            this.props.callback()
                        }
                    }}>
                    <div className={className}>
                        <SVG src={image} />
                    </div>
                    <span>
                        {label}
                    </span>
                </label>
            </div>
        )
    }
    label(option) {
        const { lang } = this.props.match.params
        switch(this.props.type) {
            case 'cuisine':
                return findOption(option.id, cuisines[lang], false)
                break;
            case 'ratingtype':
                return findOption(option.id, ratingTypes[lang], false)
                break;
            case 'language':
                return findOption(option.id, languages[lang], false)
                break;
            default:
                return null
                break;
        }
    }
    toggleMore() {
        this.setState({ showMore: !this.state.showMore })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        selectedFilters: state.selectedFilters,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Filter))
