export const memberships_fr = {
    m1: {
        title: 'METHODE 1',
        pricing: 'LIBRE',
        subtitle: 'Inscription gratuite',
        items: [
            "Créez gratuitement votre profil d'entreprise et vos annonces",
            "La technologie LINK permettra de localiser rapidement les chefs les plus pertinents pour votre rôle",
            "Facile à créer et à partager vos annonces sur les réseaux sociaux",
            "Filtres avancés pour optimiser les résultats de recherche",
            "Sélectionnez votre public et recevez uniquement des CV pertinents pour votre entreprise.",
            "Rechercher des chefs dans notre réseau"
        ],
        fees: "(Des frais de recrutement de 3% seront appliqués)"
    },
    m2: {
        title: 'METHODE 2',
        pricing: '600£ PAR AN',
        subtitle: 'Premium',
        popular: 'LE PLUS POPULAIRE',
        items: [
            "Espace publicitaire premium",
            "Marketing par courrier électronique et médias sociaux",
            "Rechercher des chefs dans notre réseau",
            "Message direct avec nos chefs",
            "Recevez les Chefs Cvs directement dans votre boîte de réception",
            "Contrôlez tous vos restaurants et offres d'emploi à partir d'une plate-forme facile à utiliser",
            "Ajouter plus de Restaurants Premium"
        ],
        fees: '(+ 400£ par restaurant)'
    },
}
