export const SET_USER = 'SET_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_IS_LOGGED = 'SET_IS_LOGGED'
export const SET_USER_TYPE = 'SET_USER_TYPE'

export const SET_DEVICE_SCREEN = 'SET_DEVICE_SCREEN'
export const SET_DEVICE_WIDTH = 'SET_DEVICE_WIDTH'
export const SET_DEVICE_HEIGHT = 'SET_DEVICE_HEIGHT'
export const SHOW_OVERLAY = 'SHOW_OVERLAY'

export const SET_SELECTED_OFFERS = 'SET_SELECTED_OFFERS'
export const ADD_SELECTED_OFFER = 'ADD_SELECTED_OFFER'
export const REMOVE_SELECTED_OFFER = 'REMOVE_SELECTED_OFFER'

export const SET_SELECTED_CHEFS = 'SET_SELECTED_CHEFS'
export const ADD_SELECTED_CHEF = 'ADD_SELECTED_CHEF'
export const REMOVE_SELECTED_CHEF = 'REMOVE_SELECTED_CHEF'

export const SET_SELECTED_FILTERS = 'SET_SELECTED_FILTERS'
export const ADD_SELECTED_FILTER = 'ADD_SELECTED_FILTER'
export const REMOVE_SELECTED_FILTER = 'REMOVE_SELECTED_FILTER'

export const SET_LANGUAGE = 'SET_LANGUAGE'
