import React, {Component} from 'react'
import AuthorInfo from '../../Components/AuthorInfo/AuthorInfo'
import Btn from '../../Components/Btn/Btn'
import ChefApplication from '../../Components/ChefApplication/ChefApplication'
import ChefApplicationStatus from './Components/ChefApplicationStatus'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefToggle, listSubscribedOffers} from '../../Services/Chef/chef'
import {findOption} from '../../Lib/findOption'
import {listItems, listBtn2} from '../ChefProfile/ChefProfileTopNavMenuItems'
import {positions} from '../../Lists/lists'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import Loader from '../../Components/_NEW/Loader'


class ChefProfileApplications2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false,
            loadingSwitch: false,
            items: [],
            page: 0,
            hasMorePages: true,
            loading: true,
            firstLoad: true,
        }
    }
    _initState() {
        this.loadItems()
    }
    loadItemsFromStart() {
        this.setState({page: 0, items: [], hasMorePages: true}, () => {
            this.loadItems()
        })
    }
    loadItems(done) {
        const qty = 10
        const { lang } = this.props.match.params
        listSubscribedOffers({page: this.state.page, qty: qty, status: 2, showpaused: 1, lang}).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                const { data } = res.data
                let items = [
                    ...this.state.items,
                    ...data
                    ]
                this.setState({ items: items, hasMorePages: (res.data.data.length >= qty), loading: false, firstLoad: false })
            }
        })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }

     

    render() {
        let className = 'scene_ChefProfileApplications'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={null}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                topLeft={this.renderTopLeftContent.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        return (
            <ImageHeader
                left={this.props.user.profile.images.profile ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="image" />}
                right={this.props.user.profile.images.header ? this.props.user.profile.images.header.sizes.header.url : null}
                rightBtn={<FakeUploadBtn type="profile" name="header" />}
                alt={this.props.user.profile.name}
                />
        )
    }
    _showShare() {
        const { lang } = this.props.match.params
        let profile = this.props.user.profile
        let url = `/${lang}/chef/view/` + profile.id + '/' + profile.slug
        let image = this.props.user.profile.images.header && this.props.user.profile.images.header.sizes && this.props.user.profile.images.header.sizes.share ? this.props.user.profile.images.header.sizes.share.url : null
        this.props.showOverlay('share', {title: profile.name, url: url, image:image})
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        let items = listItems(this.props.isLogged, this.props.userType, this.state, this.props.user, lang)
        let btn2 = listBtn2(lang, this.state.profile, this.state, this._showShare.bind(this))
        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'my_profile')}</span> / {translate(lang, 'applications')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderTopLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <ImageHeader
                noCover={true}
                left={this.props.user && this.props.user.profile.images.profile ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="image" />}
                alt={this.props.user && this.props.user.profile.name}
                />
            )
        }
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        const { pending, review, accepted } = this.props.user.profile.pendingnotifications
        const totalApplications = pending + review + accepted
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.props.user.profile.name}</h3>
                        <span>{findOption(this.props.user.profile.position, positions[lang])}</span>
                        <Switch active={this.props.user.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                chefToggle(lang).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                    <div className='total_applications'>
                        <SVG src='/static/images/edit_icn.svg' />
                        <span className='applications_title'>{translate(lang, 'total_applications_NUM', totalApplications)}</span>
                    </div>
                    <div className='calendar_link'>
                        <SVG src='/static/images/_calendar.svg' />
                        <Link className='calendar_title' to={`/${lang}/chef/profile/calendar`}>{translate(lang, 'view_calendar')}</Link>
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div>
                {this.props.deviceScreen === 'mobile' && <h4>{translate(lang, 'applications')}</h4>}
                <ChefApplicationStatus profile={this.props.user} />
                {this.renderItems(<p>{translate(lang, 'no_in_review_applications')}</p>)}
                {this.state.loading && <Loader />}
            </div>
        )
    }
    renderItems(message) {
        const { lang } = this.props.match.params
        let items = []
        this.state.items.forEach((item) => {
            const wasAccepted = item.stages.find(stage => stage.status === 'Accepted')
            const wasRejected = item.stages.find(stage => stage.status === 'Rejected')
            items.push(
                <ChefApplication
                    deleteButton={true}
                    deleteButtonCallback={() => this.loadItemsFromStart()}
                    key={item.id}
                    item={item}
                    restaurant={item.restaurant}
                    offer={item.offer}
                    chef={this.props.user}
                    description={true}
                    extraContent={
                        <div className="extra-content">
                            <div>
                                <div key={item.id} className="stage">
                                {item.stages.length > 1 && !wasAccepted && !wasRejected
                                    ? <span>{item.stages.map((stage, index) => {
                                        if (index === 0) {
                                            return `${moment(stage.date).format('DD MMM YYYY')}`
                                        }
                                        return ` / ${moment(stage.date).format('DD MMM YYYY')}`
                                    })}</span>
                                    : <span>{moment(item.stages[0].date).format('DD MMM YYYY')}</span>}
                                    <Btn text={this.props.deviceScreen === 'mobile' ? translate(lang, 'waiting_response').toUpperCase() : translate(lang, 'waiting_restaurant_response').toUpperCase()} pre="tick1" className={`no-line disabled ${this.props.deviceScreen === 'mobile' && 'long-text'}`} callback={() => {
                                    }} />
                                </div>
                            </div>

                        </div>
                    }
                    alternativeShare={
                        <div className="share alternative-share">
                            {this.renderMessageButton(item.restaurant.id)}
                        </div>
                    }
                    />
            )
        })
        return items.length ? items : message
    }
    renderMessageButton(restaurantId) {
        const { lang } = this.props.match.params
        let text = this.props.deviceScreen === 'mobile' ? translate(lang, 'mess').toUpperCase() : translate(lang, 'message').toUpperCase()
        return (
            <Btn text={text} className="text" post="message_icn" callback={() => {
                this.props.showOverlay('message', {cid: this.props.user.profile.id, rid: restaurantId})
            }} />
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefProfileApplications2))
