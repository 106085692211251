export const memberships_es = {
    m1: {
        title: 'MÉTODO 1',
        pricing: 'GRATIS',
        subtitle: 'Registro gratuito',
        items: [
            'Crea tu perfil de empresa y publica ofertas gratis',
            'La tecnología LINK localizará rápidamente a los chefs más relevantes para tu oferta',
            'Tus anuncios son fáciles de crear y compartir en redes sociales',
            'Filtros avanzados para optimizar resultados de búsqueda',
            'Selecciona tu audiencia y recibe solo CVs que sean relevantes para tu negocio.',
            'Búsqueda de chefs en nuestra red'
        ],
        fees: '(Se aplicará una tarifa de contratación del 3%)'
    },
    m2: {
        title: 'MÉTODO 2',
        pricing: '£600 POR AÑO',
        subtitle: 'Premium',
        popular: 'MÁS POPULAR',
        items: [
            'Espacio publicitario premium',
            'Correo electrónico y marketing en redes sociales',
            'Búsqueda de chefs en nuestra red',
            'Mensaje directos a nuestros chefs',
            'Recibe CVs de chefs directamente en tu bandeja de entrada',
            'Controla todos tus restaurantes y ofertas de trabajo desde una plataforma fácil de usar',
            'Añadir más restaurantes premium'
        ],
        fees: '(+ £400 por restaurante)'
    },
}
