import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import SVG from 'react-inlinesvg';

const FormSelect = ({
  id,
  name,
  label,
  placeholder,
  options,
  errors,
  formError,
  showErrorMessage = true,
  multipleSelector = false,
  disableSearch = false
}) => {
  const error = errors && errors.hasOwnProperty(name) ? errors[name] : null
  const [field, meta, helpers] = useField(name)
  const [open, setOpen] = useState(false)
  const [multiSelection, setMultiSelection] = useState(field.value || [])
  const [inputValue, setInputValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([...options])

  const noEmptyValue = field.value !== "" && field.value !== -1

  const currentValue = () => {
    if (field.value && noEmptyValue && !multipleSelector) {
      const opt = options.find(o => `${o.value}` === `${field.value}`)
      if (opt.name) {
        return opt.name
      } else {
        return opt.label
      }
    } else {
      return placeholder
    }
  }

  const handleSelection = (value) => {
    if (multipleSelector) {
      const currentSelection = [...multiSelection]
      if (currentSelection.includes(`${value}`)) {
        const index = currentSelection.indexOf(`${value}`)
        currentSelection.splice(index, 1)
        setMultiSelection(currentSelection)
        helpers.setValue(currentSelection)
      } else {
        currentSelection.push(`${value}`)
        setMultiSelection(currentSelection)
        helpers.setValue(currentSelection)
      }
    } else {
      helpers.setValue(value)
    }
    setInputValue('')
    const input = document.getElementById(`inputSelect-${name}`)
    input.value = ''
  }

  useEffect(() => {
    if (inputValue !== '') {
      setOpen(true)
      const filter = options.filter(o => {
        const labelToString = `${o.label}`
        if (labelToString.toLowerCase().includes(inputValue.toLowerCase())) {
          return o
        }
      })
      setFilteredOptions(filter)
    } else {
      setOpen(false)
      setFilteredOptions(options)
    }
  }, [inputValue])

  return (
    <div style={{ position: 'relative' }} className="component_FormSelect">
      <label className="inputLabel" htmlFor={id}>
        {label}
      </label>
      <div className='selectContainer' onClick={() => setOpen(!open)}>
        <div className={`select ${error || formError ? 'error' : ''}`}>
          <input
            id={`inputSelect-${name}`}
            type="text"
            className={`${field.value && noEmptyValue ? 'option' : ''}`}
            placeholder={currentValue()}
            onChange={(e) => setInputValue(e.target.value)}
            disabled={disableSearch}
          />
          <div className="arrowContainer">
            <div className={`arrowIcon ${open ? 'open' : ''}`}>
              <SVG src="/static/images/arrow_simple.svg" />
            </div>
          </div>
        </div>
        <ul className={`options ${open ? 'open' : ''}`}>
          {filteredOptions.map(option => (
            <li key={option.value} className="option" onClick={() => handleSelection(option.value)}>
              {option.name || option.label}
            </li>
          ))}
        </ul>
        {error && showErrorMessage && <div className="errorMessage">{error}</div>}
      </div>
      {multipleSelector && multiSelection && <div className="multiSelection">
          {options.map((opt) => {
            if (multiSelection.includes(`${opt.value}`)) {
              return (
                <div className="itemSelection" onClick={() => handleSelection(opt.value)}>{opt.label}</div>
              )
            }
            return null
          })}
        </div>}
    </div>
  )

}

export default FormSelect