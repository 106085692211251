
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { translate } from "../../Translations/translate"
import { cuisines, countries, positions } from "../../Lists/lists"
import {report, block} from '../../Services/Util/util'
import {findOption} from '../../Lib/findOption'
import {readableLocation} from '../../Lib/readableLocation'
import ShareButton from "./ShareButton"
import Button from "./Button"

const ChefViewLeftColumn = ({ props, item }) => {
  const { lang } = props.match.params
  const { user, userType, isLogged } = props

  const [contactInfo, setContactInfo] = useState({ email: null, phone: null })

  const reportUser = () => {
    report({offender: item.profile.id, ischef: 1, lang}).then((res) => {
      if (res.data.success) {
          this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
      }
    })
  }

  const blockUser = () => {
    block({offender: item.profile.acc, lang}).then((res) => {
      if (res.data.success) {
          this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
      }
    })
  }

  useEffect(() => {
    if (isLogged) {
      if (userType === "chef" && user.profile.id === item.profile.id) {
          setContactInfo({ email: user.contactemail, phone: user.phone })
        } else {
          setContactInfo({ email: item.profile.contactemail, phone: item.profile.phone })
        }
      }
  }, [])

  return (
    <div className='component_ChefViewLeftColumn'>
      <div className="top">
        <div className="buttons">
            {isLogged && userType === 'restaurant' && item.profile.inapply === 0 && item.profile.invited === 0
            && <Button label={translate(lang, 'message')} onClick={() => {
                props.showOverlay('message', {rid: user.main.id, cid: item.profile.id, sender: 'restaurant' })
            } } className="outline margin-right"/>}
            {isLogged && userType === 'restaurant' && parseInt(user.main.premium) === 1
            && <Button label={translate(lang, 'send_offer')} onClick={() => {
                props.toggleSelectedChef({id: item.profile.id, item: item})}} className="blue bold margin-left"/>}
        </div>
        <ShareButton props={props} />
      </div>
      <div className="userName">
        <h3>{item.profile.name}</h3>
        <div className="basicInfo">
          <div>
              <div>{findOption(item.profile.position, positions[lang])}</div>
              {item.profile.location && typeof item.profile.location === "object" ? <div>{readableLocation(item.profile.location)}</div> : null}
          </div>
        </div>
      </div>
      <div className="userInfo">
        <div>
          {/* TODO */}
          {/* <div className="infoBlock">
            <h4>{translate(lang, 'last_login')}</h4>
            <div className="text">{moment(item.profile.updated).format('DD/MM/YYYY')}</div>
          </div> */}
          {isLogged && (item.profile.related || item.profile.inapply) ? <div>
            <div className="infoBlock">
              <h4>{translate(lang, 'email')}</h4>
              <div className="text">{contactInfo.email || translate(lang, 'no_information')}</div>
            </div>
            <div className="infoBlock">
              <h4>{translate(lang, 'phone')}</h4>
              <div className="text">{contactInfo.phone || translate(lang, 'no_information')}</div> 
            </div>
          </div>
          : null}
          {isLogged && item.profile.visas.length > 0 && <div className="infoBlock">
            <h4>{translate(lang, 'work_visas')}</h4>
            <div className="wrap">
              {item.profile.visas.map((vis) => <div className="item" key={vis}>
                <span className="text">{findOption(vis, countries[lang])}</span>
              </div>)}
            </div>
          </div>}
         {isLogged && item.profile.nationalities.length > 0 && <div className="infoBlock">
            <h4>{translate(lang, 'nationalities')}</h4>
            <div className="wrap">
              {item.profile.nationalities.map((nat) => <div className="item" key={nat}>
              <span className="text">{findOption(nat, countries[lang])}</span>
              </div>)}
            </div>
          </div>}
          {isLogged && item.profile.cuisine.length > 0 && <div className="infoBlock">
            <h4>{translate(lang, 'cuisine')}</h4>
            <div className="wrap">
              {item.profile.cuisine.map((cui) => <div className="item" key={cui}>
              <span className="text">{findOption(cui, cuisines[lang])}</span>
              </div>)}
            </div>
          </div>}
        </div>
      </div>
      <div className="moderationOptions">
        <div className="option">
          <div className="button" title={translate(lang, 'report_page')} onClick={() => reportUser()}>{translate(lang, 'report')}</div>
          <div className="description">{translate(lang, 'report_page')}</div>
        </div>
        <div className="option">
          <div className="button" title={translate(lang, 'block_this_page')} onClick={() => blockUser()}>{translate(lang, 'block_page')}</div>
          <div className="description">{translate(lang, 'block_this_page')}</div>
        </div>
      </div>
    </div>
  )
}

export default ChefViewLeftColumn