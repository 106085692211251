import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import ChatBubble from '../../Components/ChatBubble/ChatBubble'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import Textarea from '../../Components/Input/Textarea'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import LazyImage from '../../Components/LazyImage/LazyImage'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chatListConversation, chatSendMessage} from '../../Services/Chat/chat'
import {findById} from '../../Lib/findById'
import {listItems, listBtn2} from '../RestaurantProfile/RestaurantProfileTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';
import {refreshUser} from '../../Services/Access/access'
import Cookies from 'js-cookie';
// const sortBy = require('lodash/sortBy')


class RestaurantProfileMessagesDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            errors: '',
            message: '',
            rawItems: [],
            items: [],
            page: 0,
            hasMorePages: true
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        this.setState({
            profile: profile,
        }, this.loadItems.bind(this))
        this.refreshUser()
    }
    loadItems(done) {
        chatListConversation({id: this.props.match.params.mid,page: this.state.page, qty: 20}).then((res) => {
            if (this.state.loading) {
                this.setState({loading: false})
            }
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                let items = this.state.items
                items.push(...res.data.data.messages)
                // items = sortBy(items, ['created']) dont alter order sent by API
                this.setState({ data: res.data.data, items: items, hasMorePages: (res.data.data.messages.length > 0) })
            }
        })
    }

    refreshUser() {
        const { lang } = this.props.match.params
        const token = Cookies.get('token')
        if (![undefined, 'undefined'].includes(token)) {
            refreshUser({token: token, lang}).then((res) => {
                if (!res.data.success) {
                    Cookies.remove('token')
                    this.setState({loading: false})
                    console.log('ERROR: ' + res.data.message)
                    this.checkAuth()
                    return
                } else {
                    let data = res.data.data
                    this.props.setUser(data.user)
                    this.props.setUserType(data.user.type)
                    this.props.setToken(token)
                    this.props.setIsLogged(true)

                    Cookies.set('token', token, {expires: 7})
                    this.setState({loading: false})
                    this.checkAuth()
                }
            }).catch((e) => {
                Cookies.remove('token')
                this.setState({loading: false})
                this.checkAuth()
            })
        }
    }
    checkAuth() {
        if (this.props.checkAuth) {
            if (this.props.checkAuth()) {
                this.props.authSuccess()
            } else {
                console.log('Auth: no permissions')
                this.props.history.push('/')
            }
        }
    }

    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            let profile = nextProps.match.params.id ? findById(nextProps.match.params.id, nextProps.user.profiles) : nextProps.user.profiles.find((profile) => parseInt(profile.main) === 1)
            this.setState({profile: profile});
        }
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant' && parseInt(this.props.user.subscription) === 1)
    }

     

    render() {
        let className = 'scene_RestaurantProfileMessagesDetail'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.props.deviceScreen === 'mobile' && this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                topLeft={this.renderTopLeftContent.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages) {
                        this.setState({page:this.state.page+1}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        
        let back = this.state.data && this.state.data.restaurant ? `/${lang}/restaurant/profile/messages/${this.props.user.main.id}` : ''
        let items = listItems(this.state.profile, this.state, lang)
        let btn2 = listBtn2(lang, this.state.profile, this.state, this.showJobsOverlay.bind(this))

        if (this.props.deviceScreen === 'mobile') {
            return (
                <nav className='mobile'>
                    <div className="btns">
                        <Link className='back-button' to={back}>
                            <SVG src="/static/images/backarrow.svg" />
                            <span>
                                {translate(lang, 'back')}
                            </span>
                        </Link>
                    </div>
                </nav>
            )
        } else {
            return (
                <div className='top-content'>
                    <h4><span>{translate(lang, 'my_profile')} / {translate(lang, 'messages')}</span></h4>
                    <Link className='back-button' to={back}>
                        <SVG src="/static/images/backarrow.svg" />
                        <span>
                            {translate(lang, 'back')}
                        </span>
                    </Link>
                </div>
            )
        }
    }
    renderLeft() {
        
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderTopLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <ImageHeader
                noCover={true}
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
            )
        }
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        <Switch active={this.state.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        let items = []
        let i = 0
        this.state.items.forEach((m) => {
            let them = m.sender === this.props.user.id ? 'me' : 'them'
            items.push(
                <ChatBubble
                    key={i}
                    data={this.state.data}
                    text={m.message}
                    date={m.created}
                    sender={m.sender}
                    them={them}
                    deviceScreen={this.props.deviceScreen}
                    />
            )
            i++
        })
        let url = this.state.data && this.state.data.chef ? `/${lang}/chef/view/${this.state.data.chef.id}/${this.state.data.chef.slug}` : ''
        return (
            <div className='conversation'>
                <div className='messages-title'>
                    <LazyImage className='logo' src={this.state.data && this.state.data.chef && this.state.data.chef.images.profile ? this.state.data.chef.images.profile.sizes.square.url : '/static/images/empty_600_600.png'} alt={this.state.data && this.state.data.chef ? this.state.data.chef.name : ''} />
                    <Link to={url} className='sender'><h4>{this.state.data && this.state.data.chef ? this.state.data.chef.name : ''}</h4></Link>
                </div>
                <div className="items">
                    <div className='messages-container'>
                        {items}
                    </div>
                    <div className="form">
                        <Textarea
                            lang={lang}
                            ref="textarea"
                            name="message"
                            error={this.state.errors}
                            value={this.state.message}
                            minLength={4}
                            
                            onChange={(v) => {
                                this.setState({message: v})
                            }}
                            placeholder={translate(lang, 'write_new_message_here')}
                            addMediaCallback={(id, media) => {
                                this._sendMessage('<a target="_blank" href="' + media.showcase.url + `">${translate(lang, 'view_media')}</a>`, false)
                                this.props.showOverlay(null)
                            }}
                            />
                        <Btn
                            text={translate(lang, 'send').toUpperCase()}
                            className="inline to-right"
                            loadingSend={this.state.loadingSend}
                            callback={() => {
                                this._sendMessage(this.state.message, true)
                            }}
                            />
                    </div>
                </div>
            </div>
        )
    }
    _sendMessage(message, clean) {
        this.setState({loadingSend: true})
        chatSendMessage({ conversation: this.state.data.id, message: message }).then((res) => {
            this.setState({loadingSend: false})
            if (res.data.success) {
                if (clean) {
                    this.refs.textarea.clear()
                }
                this.setState({
                    items: [],
                    page: 0,
                    hasMorePages: true
                }, this.loadItems.bind(this))
            }
        })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfileMessagesDetail))
