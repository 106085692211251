import React from 'react'
import { Link } from 'react-router-dom'
import Loader from "./Loader"
import SVG from 'react-inlinesvg';

const Button = ({ label, link, submit, onClick, className = '', loading, limitLabel = false, icon }) => {

  const src = () => {
    switch(icon) {
      case 'apply':
        return '/static/images/edit_icn.svg';
    }
  }

  if (link) {
    return (
      <Link className={`component_Button ${className || 'blue'}`} to={link}>
        {loading
          ? <Loader color="white" className="button"/>
          : <span>{limitLabel && label.length > 8 ? `${label.substring(0, 8)}.` : label}</span>
        }
      </Link>
    )
  }

  return (
    <button
      type={submit ? "submit" : "button"}
      className={`component_Button ${className || 'blue'}`}
      onClick={onClick}
    >
      {loading
        ? <Loader color="white" className="button"/>
        : <div className='buttonContent'>
            {icon && <div className="pre"><SVG src={src()} /></div>}
            <span>{limitLabel && label.length > 8 ? `${label.substring(0, 8)}.` : label}</span>
          </div>
      }
    </button>
  )
  }

export default Button