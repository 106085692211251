import React, {Component} from 'react'
import SVG from 'react-inlinesvg'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {canUseDOM} from '../../Lib/canUseDOM'
import smoothscroll from 'smoothscroll-polyfill'
import {translate} from '../../Translations/translate'
import { withRouter } from 'react-router'
if (canUseDOM) {smoothscroll.polyfill();}

class TopNavMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            linkOpen: false,
            moreOpen: false,
            btn1Open: false,
            btn2Open: false
        }
    }
    render() {
        const { lang } = this.props.match.params
        let items = []
        let rawItems = this.props.items ? this.props.items : []
        rawItems.forEach((i) => {
            items.push(this.renderItem(i))
        })
        if (this.props.more) {
            this.props.deviceScreen === 'mobile'
            ? items.push(this.renderItem({url: '#more', title: translate(lang, 'moremore'), icon: 'more_icn'}))
            : items.push(this.renderItem({url: '#more', title: translate(lang, 'more'), icon: 'more_icn'}))
        }
        let className = 'component_TopNavMenu'
        if (this.state.linkOpen) {
            className += ' link-open'
        }
        if (this.state.moreOpen) {
            className += ' more-open'
        }
        if (this.state.btn1Open) {
            className += ' btn1-open'
        }
        if (this.state.btn2Open) {
            className += ' btn2-open'
        }
        return (
            <div className={className}>
                <ul>
                    {items}
                    {this.props.btn1 ? this.renderBtn(1, this.props.btn1, this.props.btn1Content) : null}
                    {this.props.btn2 ? this.renderBtn(2, this.props.btn2, this.props.btn2Content) : null}
                </ul>
                {this.props.rightBtn ? <div className="right">{this.props.rightBtn}</div> : null}
            </div>
        )
    }
    renderItem(item) {
        let isActionable = (item.url === '#link' || item.url === '#more')
        let className = null
        if (item.url === '#link') {
            className = 'link'
        } else if (item.url === '#share') {
            className = 'share'
        } else if (item.url === '#more') {
            className = 'more'
        } else if (item.url === '#save') {
            className = 'save'
        }
        let preSrc = item.icon !== '' ? '/static/images/' + item.icon + '.svg' : null
        let preIcon = preSrc && this.props.deviceScreen === 'mobile' ? <div className="pre"><SVG className={item.icon} src={preSrc} /></div> : null
        return (
            <li key={item.url} className={className}
                onMouseEnter={isActionable && this.props.deviceScreen !== 'mobile' ? this.handleActionable.bind(this, item, true) : null}
                onMouseLeave={isActionable && this.props.deviceScreen !== 'mobile' ? this.handleActionable.bind(this, item, false) : null}
                >
                <NavLink
                    to={item.url}
                    onClick={(e) => {
                        this.handleClickActionable(e, item, true)
                    }}
                    >
                    {preIcon}
                    {item.title}
                    {item.badge && item.badge > 0 ? <span className="badge">{item.badge}</span> : null}
                </NavLink>
                {this.renderContents(item)}
            </li>
        )
    }
    renderBtn(i, btn, content) {
        let className = this.props.hiddenBtn1 && i === 2 ? 'btn' + i + ' only-button' : 'btn' + i
        let item = {url: '#' + className}
        let actionClassName = className + '-action'
        let contentClassName = className + '-content'
        return (
            <li key={item.url} className={className}
                onMouseEnter={this.handleActionable.bind(this, item, true)}
                onMouseLeave={this.handleActionable.bind(this, item, false)}
                >
                {btn ? <div className={actionClassName}>{btn}</div> : null}
                {content ? <div className={contentClassName}>{content}</div> : null}
            </li>
        )
    }
    renderContents(item) {
        if (item.url === '#link') {
            return this.renderLink()
        } else if (item.url === '#more') {
            return this.renderMore()
        } else {
            return null
        }
    }
    renderLink() {
        return (
            <div className="link">
                {this.props.link}
            </div>
        )
    }
    renderMore() {
        return (
            <div className="more">
                {this.props.more}
            </div>
        )
    }
    handleActionable(item, open) {
        if (item.url === '#link') {
            this.setState({
                linkOpen: open !== undefined ? open : !this.state.linkOpen,
            })
        } else if (item.url === '#more') {
            this.setState({
                moreOpen: open !== undefined ? open : !this.state.moreOpen,
            })
        } else if (item.url === '#btn1') {
            this.setState({
                btn1Open: open !== undefined ? open : !this.state.btn1Open,
            })
        }
    }
    handleClickActionable(e, item, open) {
        if (item.url === '#share') {
            e.preventDefault()
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
            this.props.showOverlay('share')
        } else if (item.url === '#save') {
            e.preventDefault()
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
            this.props.saveCallback()
        } else if (item.url === '#more') {
            e.preventDefault()
            e.stopPropagation()
            if (this.props.deviceScreen === 'mobile') {
                this.handleActionable(item, !this.state.moreOpen)
            }
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(TopNavMenu))
