import React, { Component } from "react";
import Btn from "../../Components/Btn/Btn";
import StickyElement from "../../Components/StickyElement/StickyElement";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ActionCreators } from "../../Actions";
import { canUseDOM } from "../../Lib/canUseDOM";
import { translate } from "../../Translations/translate";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withRouter } from "react-router";
import Overlays from "./Overlays";
import Cookies from "js-cookie";
import { refreshUser } from "../../Services/Access/access";
import LinkedInTag from "react-linkedin-insight";
import "smartbanner.js/dist/smartbanner.min.js";
import "smartbanner.js/dist/smartbanner.min.css";

var debounce = require("lodash/debounce");
const ReactPixel = require("react-facebook-pixel");

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infiniteScrollLoading: false,
      mounted: false,
      loading: Cookies.get("token") !== undefined,
    };

    this.handleInfiniteScroll = debounce(
      () => this._handleInfiniteScroll(),
      500
    );
  }

  componentDidMount() {
    const { lang } = this.props.match.params;

    ReactPixel.default.init("633374518401268");
    LinkedInTag.init("3448434", "dc", false);

    const appSmartBanner = document.querySelector(".smartbanner");

    if (appSmartBanner) {
      appSmartBanner.style.position = "fixed";
    } else {
      this.forceUpdate();
    }

    const token = Cookies.get("token");

    // if we have a user token
    if (![undefined, "undefined"].includes(token)) {
      //helper variable to check if user is handled
      let userHandled = false;
      // we check if we have localStorage for user
      const userData = localStorage.getItem("user");
      if (userData) {
        // if we have user, we will the props
        const data = JSON.parse(userData);
        if (data.user) {
          this.props.setUser(data.user);
          this.props.setUserType(data.user.type);
          this.props.setToken(token);
          this.props.setIsLogged(true);
          this.setState({ loading: false });
          this.checkAuth();
          userHandled = true;
        }
      }
      // if we don't have user, we will refresh the user
      if (!userHandled) {
        // standard old process
        refreshUser({ token: token, lang })
          .then((res) => {
            if (!res.data.success) {
              Cookies.remove("token");
              this.setState({ loading: false });
              console.log("ERROR: " + res.data.message);
              this.checkAuth();
              return;
            } else {
              let data = res.data.data;
              this.props.setUser(data.user);
              this.props.setUserType(data.user.type);
              this.props.setToken(token);
              this.props.setIsLogged(true);

              Cookies.set("token", token, { expires: 7 });
              this.setState({ loading: false });
              this.checkAuth();
            }
          })
          .catch((e) => {
            Cookies.remove("token");
            this.setState({ loading: false });
            this.checkAuth();
          });
      }
    } else {
      this.setState({ loading: false });
      this.checkAuth();
    }

    this.setState({ mounted: true });
    if (this.props.onBottomReached) {
      window.addEventListener("scroll", this.handleScroll.bind(this));
      if (this.props.hasInitialCallForBottomReached) {
        this._callBottomReached();
      }
    }
  }

  componentDidUpdate() {
    const appSmartBanner = document.querySelector(".smartbanner");

    if (appSmartBanner) {
      appSmartBanner.style.position = "fixed";
    }
  }

  checkAuth() {
    if (this.props.checkAuth) {
      if (this.props.checkAuth()) {
        this.props.authSuccess();
      } else {
        console.log("Auth: no permissions");
        this.props.history.push("/");
      }
    }
  }

  render() {
    let className = this.props.className ? this.props.className : "";
    className += " main-layout";
    if (!this.state.mounted || this.state.loading) {
      className += " loading";
      return <div className={className}>{this.renderLoading()}</div>;
    }
    return (
      <div>
        <Header />
        <Overlays />
        <div className={className}>
          {this.renderHelmet()}
          {this.renderPreTopArea()}
          {this.renderTopArea()}
          <div className="container-fluid">
            <div className="col-group content-wrapper">
              {this.renderLeft()}
              <div
                className={`col-mb-12 col-8 main ${
                  this.props.darkBackground ? "dark-background" : ""
                }`}
              >
                {this.renderTopNav()}
                <div className="wrapper">{this.props.children}</div>
              </div>
              {this.renderRight()}
            </div>
          </div>
          {this.renderBottomArea()}
        </div>
        <Footer />
      </div>
    );
  }
  renderHelmet() {
    const { lang } = this.props.match.params;
    if (this.props.meta) {
      let metas = [];
      if (this.props.meta.metas) {
        this.props.meta.metas.forEach((m) => {
          if (m.name.indexOf("og:") > -1) {
            metas.push(
              <meta key={m.name} property={m.name} content={m.value} />
            );
          } else {
            metas.push(<meta key={m.name} name={m.name} content={m.value} />);
          }
        });
      }
      let title = this.props.meta.title
        ? this.props.meta.title + " | ChefsLink"
        : "ChefsLink";

      const appStoreUrl =
        lang === "en"
          ? "https://apps.apple.com/app/chefslink/id1519459963"
          : `https://apps.apple.com/${lang}/app/chefslink/id1519459963`;
      const jobPostingSchema = this.props.jobPostingSchema
        ? JSON.stringify(this.props.jobPostingSchema)
        : null;

      return (
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta
            name="description"
            content={translate(lang, "search_thousands_offer")}
          />
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
          />
          <meta
            name="og:image"
            content="https://www.chefslink.com/static/images/header_default.png"
          />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="stylesheet" href="/static/styles.css" />
          {metas}
          {/* <!-- Start SmartBanner configuration --> */}
          <meta name="smartbanner:title" content="ChefsLink" />
          <meta name="smartbanner:author" content="Chefs Link Ltd" />
          <meta
            name="smartbanner:price"
            content={translate(lang, "smart_banner_free")}
          />
          <meta
            name="smartbanner:price-suffix-apple"
            content={translate(lang, "smart_banner_apple")}
          />
          <meta
            name="smartbanner:price-suffix-google"
            content={translate(lang, "smart_banner_google")}
          />
          <meta
            name="smartbanner:icon-apple"
            content="https://www.chefslink.com/static/images/app-logo.png"
          />
          <meta
            name="smartbanner:icon-google"
            content="https://www.chefslink.com/static/images/app-logo.png"
          />
          <meta
            name="smartbanner:button"
            content={translate(lang, "smart_banner_view")}
          />
          <meta name="smartbanner:button-url-apple" content={appStoreUrl} />
          <meta
            name="smartbanner:button-url-google"
            content="https://play.google.com/store/apps/details?id=com.chefslinkapp"
          />
          <meta name="smartbanner:enabled-platforms" content="android,ios" />
          <meta name="smartbanner:close-label" content="Close" />
          {/* JOB POSTING SCHEMA */}
          {jobPostingSchema && (
            <script type="application/ld+json">{jobPostingSchema}</script>
          )}
          {/* <!-- End SmartBanner configuration --> */}
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=UA-64352142-3`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'UA-64352142-3', {
                        page_path: window.location.pathname,
                        });
                    `,
            }}
          />
          {/* LINKEDIN INSIGHT TAG ---> THIS CODE IS NOT WORKING */}
          {/* <script
                      async
                      src={`https://snap.licdn.com/li.lms-analytics/insight.min.js`}
                    />
                    <script
                    strategy="lazyOnload"
                    async
                    dangerouslySetInnerHTML={{
                        __html: `
                        _linkedin_partner_id = “3448434"; 
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function(l) { if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])}; 
                        window.lintrk.q=[]} var s = document.getElementsByTagName(“script”)[0]; 
                        var b = document.createElement(“script”); 
                        b.type = “text/javascript”;
                        b.async = true; 
                        b.src = “https://snap.licdn.com/li.lms-analytics/insight.min.js”; 
                        s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    `,
                    }}
                    />
                    <noscript 
                     dangerouslySetInnerHTML={{
                        __html: `
                        <img height=“1" width=“1” style=“display:none;” alt=“” src=“https://px.ads.linkedin.com/collect/?pid=3448434&fmt=gif”                    
                    `,
                    }}
                    />   */}
        </Helmet>
      );
    } else {
      return null;
    }
  }
  renderLoading() {
    const { lang } = this.props.match.params;
    if (canUseDOM) {
      if (
        document.querySelector("header") &&
        document.querySelector("footer")
      ) {
        let headerHeight = document.querySelector("header").offsetHeight;
        let style = { height: window.innerHeight - headerHeight };
        return (
          <div className="loading-content" style={style}>
            <Btn
              loading={true}
              className="text"
              text={translate(lang, "loading")}
            />
          </div>
        );
      }
    } else {
      return (
        <div className="loading-content">
          <Btn
            loading={true}
            className="text"
            text={translate(lang, "loading")}
          />
        </div>
      );
    }
    return null;
  }
  renderPreTopArea() {
    if (this.props.preTopArea) {
      return <div className="pre-top-area">{this.props.preTopArea()}</div>;
    }
    return null;
  }
  renderTopArea() {
    if (this.props.topArea) {
      return <div className="top-area">{this.props.topArea()}</div>;
    }
    return null;
  }
  renderBottomArea() {
    if (this.props.bottomArea) {
      return <div className="bottom-area">{this.props.bottomArea()}</div>;
    }
    return null;
  }
  renderTopNav() {
    if (this.props.topNav) {
      let className = "top-nav";
      if (this.props.topNavShadow) {
        className += " shadow";
      }
      return <div className={className}>{this.props.topNav()}</div>;
    }
    return null;
  }
  renderLeft() {
    if (this.props.left) {
      let content = null;
      if (this.props.deviceScreen === "mobile") {
        content = this.props.left();
      } else {
        content = (
          <StickyElement
            callback={(style, calculatedHeight) => {
              return <div style={style}>{this.props.left()}</div>;
            }}
          />
        );
      }
      return (
        <div
          className={`col-mb-12 col-2 sidebar-left ${
            this.props.darkBackground ? "dark-background" : ""
          }`}
        >
          <div className="top-left-content">
            {this.props.topLeft && this.props.topLeft()}
          </div>
          <div className="inner-content">{content}</div>
        </div>
      );
    }
    return null;
  }
  renderRight() {
    if (this.props.right) {
      let content = null;
      if (this.props.deviceScreen === "mobile") {
        content = this.props.right();
      } else {
        content = (
          <StickyElement
            scroll={true}
            callback={(style) => {
              return <div style={style}>{this.props.right()}</div>;
            }}
          />
        );
      }
      return (
        <div className="col-mb-12 col-2 sidebar-right">
          <div className="inner-content">{content}</div>
        </div>
      );
    }
    return null;
  }
  componentWillUnmount() {
    if (this.props.onBottomReached) {
      window.removeEventListener("scroll", this.handleScroll.bind(this));
    }
  }
  handleScroll(event) {
    this.handleInfiniteScroll();
  }
  _handleInfiniteScroll() {
    let footerHeight = 0;
    if (document.querySelector("footer")) {
      footerHeight = document.querySelector("footer").offsetHeight;
    }
    if (
      getDocHeight() <=
      getScrollXY()[1] + window.innerHeight + footerHeight
    ) {
      if (!this.state.infiniteScrollLoading) {
        this._callBottomReached();
      }
    }
  }
  _callBottomReached() {
    if (!this.props.ready) return;
    this.setState({ infiniteScrollLoading: true }, () => {
      this.props.onBottomReached(() => {
        this.setState({ infiniteScrollLoading: false });
      });
    });
  }
  updateInfiniteScrollLoading(loading) {
    this.setState({ infiniteScrollLoading: loading });
  }
  isInfiniteScrollLoading() {
    return this.state.infiniteScrollLoading;
  }
}
const mapStateToProps = (state) => {
  return {
    deviceHeight: state.deviceHeight,
    user: state.user,
  };
};
export default connect(mapStateToProps, ActionCreators, null, {
  withRef: true,
})(Main);

function getScrollXY() {
  var a = 0,
    b = 0;
  return (
    "number" === typeof window.scrollY
      ? ((b = window.scrollY), (a = window.scrollX))
      : document.body && (document.body.scrollLeft || document.body.scrollTop)
      ? ((b = document.body.scrollTop), (a = document.body.scrollLeft))
      : document.documentElement &&
        (document.documentElement.scrollLeft ||
          document.documentElement.scrollTop) &&
        ((b = document.documentElement.scrollTop),
        (a = document.documentElement.scrollLeft)),
    [a, b]
  );
}
function getDocHeight() {
  var a = document;
  return Math.max(
    a.body.scrollHeight,
    a.documentElement.scrollHeight,
    a.body.offsetHeight,
    a.documentElement.offsetHeight,
    a.body.clientHeight,
    a.documentElement.clientHeight
  );
}
