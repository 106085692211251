import React, {Component} from 'react'
import EditBtn from '../../Components/EditBtn/EditBtn'
import Education from '../../Components/Education/Education'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import WorkExperience from '../../Components/WorkExperience/WorkExperience'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefToggle} from '../../Services/Chef/chef'
import {findOption} from '../../Lib/findOption'
import {languages, levels} from '../../Lists/lists'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

import ProfileTopMenu from '../../Components/_NEW/ProfileTopMenu'
import ChefProfileLeftColumn from '../../Components/_NEW/ChefProfileLeftColumn'
import ProfileSection from '../../Components/_NEW/ProfileSection'
import Button from "../../Components/_NEW/Button"
import Loader from "../../Components/_NEW/Loader"
import IconButton from "../../Components/_NEW/IconButton"

var chunk = require('lodash/chunk')
var sortBy = require('lodash/sortBy')
var reverse = require('lodash/reverse')


class ChefProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false,
            loadingSwitch: false,
            loading: true,
        }
    }

    _initState() {
        this.setState({ profile: this.props.user.profile, loading: false })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }
    render() {
        const { lang } = this.props.match.params
        let className = 'scene_ChefProfile'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }

        return (
            <Main
                match={this.props.match}
                className={className}
                loading={this.state.loading}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                darkBackground
                >
                <div className="content">
                    {this.props.deviceScreen === 'mobile' && this.props.user ? <div>
                        <ChefProfileLeftColumn props={this.props} />
                        <ProfileTopMenu
                            props={this.props}
                            items={[
                                {url: 'about', title: translate(lang, 'about')},
                                {url: 'work-experience', title: translate(lang, 'work_experience')},
                                {url: 'education', title: translate(lang, 'education')},
                                {url: 'languages', title: translate(lang, 'languages')},
                                {url: 'referrals', title: translate(lang, 'more_info')},
                            ]}
                        />
                    </div> : null}
                    <div className="container-fluid">
                        {this.props.user && !this.state.loading ? this.renderContent() : <Loader />}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        let {images} = this.props.user.profile
        return (
            <ImageHeader
                left={images.profile && images.profile.sizes ? images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="image" />}
                right={images.header && images.header.sizes ? images.header.sizes.header.url : null}
                rightBtn={<FakeUploadBtn type="profile" name="header" />}
                alt={images.name}
            />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return (
            <ProfileTopMenu
                props={this.props}
                items={[
                    {url: 'about', title: translate(lang, 'about')},
                    {url: 'work-experience', title: translate(lang, 'work_experience')},
                    {url: 'education', title: translate(lang, 'education')},
                    {url: 'languages', title: translate(lang, 'languages')},
                    {url: 'referrals', title: translate(lang, 'more_info')},
                ]}
                buttonLabel={translate(lang, 'preview')}
                buttonLink={`/${lang}/chef/view/${this.props.user.profile.id}/${this.props.user.profile.slug}`}
            />
        )
    }
    renderLeft() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <ChefProfileLeftColumn props={this.props} />
            )
        }
    }
    renderRight() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <ItemsSelection />
            )
        }
    }
    renderSwitch() {
        const { lang } = this.props.match.params
        return (
            <Switch active={parseInt(this.props.user.profile.active)} callback={() => {
                if (!this.state.loadingSwitch) {
                    this.setState({ loadingSwitch: true })
                    chefToggle(lang).then((res) => {
                        this.setState({ loadingSwitch: false })
                        let data = res.data.data
                        this.props.setUser(data.user)
                    }).catch((e) => {
                        this.setState({ loadingSwitch: false })
                    })
                }
            }} />
        )
    }
    renderWorkExperience() {
        let items = []
        let experience = this.props.user.profile.experience
        experience = sortBy(experience, (a) => {
            return `${a.value.fromYear}-${a.value.fromMonth < 10 ? '0'+a.value.fromMonth : a.value.fromMonth}-01`
        })
        experience = reverse(experience)
        experience.forEach((item, index) => {
            items.push(this.renderWorkExperienceItem(item, index))
        })
        return items
    }
    renderWorkExperienceItem(item, index) {
        const { lang } = this.props.match.params
        return (
            <WorkExperience
                key={item.id}
                item={item}
                index={index}
                btn={
                    <IconButton icon="edit" label={translate(lang, 'edit')} callback={() => {
                        this.props.showOverlay('chefworkexperience', {
                            id: item.id,
                            value: item.value
                        })
                    }}/>
                }
                />
        )
    }
    renderEducation() {
        let items = []
        let key = 0
        let education = this.props.user.profile.education
        education = sortBy(education, (a) => {
            return `${a.value.fromYear}-${a.value.fromMonth < 10 ? '0'+a.value.fromMonth : a.value.fromMonth}-1`
        })
        education = reverse(education)
        chunk(education, 2).forEach((row) => {
            let rowItems = []
            row.forEach((item) => {
                rowItems.push(this.renderEducationItem(item))
            })
            items.push(<div key={key} className="col-group">{rowItems}</div>)
            key++
        })
        return items
    }
    renderEducationItem(item) {
        const { lang } = this.props.match.params
        return (
            <Education
                key={item.id}
                item={item}
                btn={
                    <IconButton icon="edit" label={translate(lang, 'edit')} callback={() => {
                        this.props.showOverlay('chefeducation', {
                            id: item.id,
                            value: item.value
                        })
                    }}/>
                }
                />
        )
    }
    renderLanguages() {
        let items = []
        let key = 0
        chunk(this.props.user.profile.languages, 3).forEach((row) => {
            let rowItems = []
            row.forEach((item) => {
                rowItems.push(this.renderLanguagesItem(item))
            })
            items.push(<div key={key} className="col-group">{rowItems}</div>)
            key++
        })
        return items
    }
    renderLanguagesItem(item) {
        const { lang } = this.props.match.params
        return (
            <div key={item.id} className="col-mb-6 col-4">
                <div className="item">
                    <h5><span>{findOption(item.language, languages[lang])}</span></h5>
                    <span>{findOption(item.level, levels[lang])}</span>
                    <div className="edit-btn">
                        <IconButton icon="edit" label={translate(lang, 'edit')} callback={() => {
                            this.props.showOverlay('cheflanguage', {
                                id: item.id,
                                language: item.language,
                                level: item.level
                            })
                        }}/>
                    </div>
                </div>
            </div>
        )
    }
    renderReferrals() {
        let items = []
        let key = 0
        chunk(this.props.user.profile.referrals, 2).forEach((row) => {
            let rowItems = []
            row.forEach((item) => {
                rowItems.push(this.renderReferralsItem(item))
            })
            items.push(<div key={key} className="col-group">{rowItems}</div>)
            key++
        })
        return items
    }
    renderReferralsItem(item) {
        const { lang } = this.props.match.params
        return (
            <div key={item.id} className="col-mb-12">
                <div className="item">
                    <div className="the-description" dangerouslySetInnerHTML={{__html: item.value.description}}></div>
                    <div className="edit-btn">
                        <IconButton icon="edit" label={translate(lang, 'edit')} callback={() => {
                            this.props.showOverlay('chefreferral', {
                                id: item.id,
                                value: item.value
                            })
                        }}/>
                    </div>
                </div>
            </div>
        )
    }
    renderContent() {
        const { lang } = this.props.match.params
        const { description, experience, education, languages, referrals } = this.props.user.profile
        return (
            <div>
                <ProfileSection
                    lang={lang}
                    section="status"
                    title={translate(lang, 'profile_status')}
                >
                     {/* profileSectionStatus styled in ProfileSection.less */}
                    <div className="profileSectionStatus">
                        <div className="statusTitle">{translate(lang, 'start_your_profile')}</div>
                        <Button
                            label={translate(lang, 'start')}
                            className="blue bold"
                            onClick={() => this.props.showOverlay('chefinfo', { ...this.props.user, profileNav: true })}
                        />
                        <div className="statusContainer">
                            <div className="statusItem">
                                <div className={`statusBar ${description.length > 0 ? "full" : ''}`} />
                                <div className="statusLabel">{translate(lang, 'about')}</div>
                            </div>
                            <div className="statusItem">
                                <div className={`statusBar ${experience.length > 0 ? "full" : ''}`} />
                                <div className="statusLabel">{translate(lang, 'work_experience')}</div>
                            </div>
                            <div className="statusItem">
                                <div className={`statusBar ${education.length > 0 ? "full" : ''}`} />
                                <div className="statusLabel">{translate(lang, 'education')}</div>
                            </div>
                            <div className="statusItem">
                                <div className={`statusBar ${languages.length > 0 ? "full" : ''}`} />
                                <div className="statusLabel">{translate(lang, 'languages')}</div>
                            </div>
                            <div className="statusItem">
                                <div className={`statusBar ${referrals.length > 0 ? "full" : ''}`} />
                                <div className="statusLabel">{translate(lang, 'more_info')}</div>
                            </div>
                        </div>
                    </div>
                </ProfileSection>
                <ProfileSection
                    lang={lang}
                    section="about"
                    title={translate(lang, 'about_chef')}
                    buttonType="edit"
                    buttonCallback={() => this.props.showOverlay('chefabout', {
                        description: this.props.user.profile.description,
                    })}
                    noChildrenMessage={translate(lang, 'add_description')}
                >
                    {description.length > 0 && <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>}
                </ProfileSection>
                <ProfileSection
                    lang={lang}
                    section="work-experience"
                    title={translate(lang, 'work_experience')}
                    buttonType="add"
                    buttonCallback={() => this.props.showOverlay('chefworkexperience', {
                        id: 0,
                        value: {},
                    })}
                    noChildrenMessage={translate(lang, 'add_work_experience')}
                >
                    {experience.length > 0 && this.renderWorkExperience()}
                </ProfileSection>
                <ProfileSection
                    lang={lang}
                    section="education"
                    title={translate(lang, 'education')}
                    buttonType="add"
                    buttonCallback={() => this.props.showOverlay('chefeducation', {
                        id: 0,
                        value: {},
                    })}
                    noChildrenMessage={translate(lang, 'add_education')}
                >
                    {education.length > 0 && this.renderEducation()}
                </ProfileSection>
                <ProfileSection
                    lang={lang}
                    section="languages"
                    title={translate(lang, 'languages')}
                    buttonType="add"
                    buttonCallback={() => this.props.showOverlay('cheflanguage', {
                        id: 0,
                        name: '',
                        value: '',
                    })}
                    noChildrenMessage={translate(lang, 'add_languages')}
                >
                    {languages.length > 0 && this.renderLanguages()}
                </ProfileSection>
                <ProfileSection
                    lang={lang}
                    section="referrals"
                    title={translate(lang, 'more_info')}
                    buttonType="add"
                    buttonCallback={() => this.props.showOverlay('chefreferral', {
                        id: 0,
                        value: {},
                    })}
                    noChildrenMessage={translate(lang, 'add_referrals')}
                >
                    {referrals.length > 0 && this.renderReferrals()}
                </ProfileSection>
                <div className="preview-button">
                    <Button label={translate(lang, 'preview')} link={`/${lang}/chef/view/${this.props.user.profile.id}/${this.props.user.profile.slug}`} className="blue bold"/>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefProfile))
