var sanitizeHtml = require('sanitize-html');

export function fixHtml(html) {
    html = new DOMParser().parseFromString(html, "text/html").body.innerHTML;
    html = sanitizeHtml(html, {
        allowedTags: ['strong', 'i', 'b', 'em', 'b', 'a', 'ul', 'li', 'ol', 'br', 'p'],
        allowedAttributes: {
            'a': [ 'href' ]
        },
    })
    return html;
}
