import createReducer from '../Services/General/createReducer.js'
import * as types from '../Actions/types.js'
var reject = require('lodash/reject')

export const selectedFilters = createReducer(null, {
    [types.SET_SELECTED_FILTERS](state, action) {
        state = action.selectedFilters
        return state
    },
    [types.ADD_SELECTED_FILTER](state, action) {
        if (state == null) {
            state = []
        }
        state.push(action.selectedFilter)
        return state.slice(0)
    },
    [types.REMOVE_SELECTED_FILTER](state, action) {
        if (state == null) {
            state = []
        }
        state = reject(state, {id: action.selectedFilter.id})
        return state.slice(0)
    }
})
