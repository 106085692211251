import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Chef from '../../Components/Chef/Chef'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Moment from 'moment'
import MonthView from 'react-calendar/dist/entry.nostyle'
import Switch from '../../Components/Switch/Switch'
import { connect } from 'react-redux'
import { extendMoment } from 'moment-range'
import {ActionCreators} from '../../Actions'
import {findById} from '../../Lib/findById'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle} from '../../Services/Restaurant/restaurant'
import {stagesCalendar} from '../../Services/Offers/offers'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Loader from '../../Components/_NEW/Loader'
import { applicationTypes } from "../../Lists/lists"
var find = require('lodash/find')
var findIndex = require('lodash/findIndex')
const moment = extendMoment(Moment);


class RestaurantProfileCalendar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            firstLoad: true,
            loading: true,
            items: [],
            page: 0,
            hasMorePages: true,
            activeStartDate: moment().toDate(),
            offers: [],
            dates: [],
            selectedDayEvents: [],
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        let offer = find(profile.offers, {id: this.props.match.params.oid})
        this.setState({
            profile: profile,
            offerId: this.props.match.params.oid,
            offer: offer
        }, this.loadItems.bind(this))
    }
    loadItemsFromStart() {
        this.setState({page: 0, items: []}, () => {
            this.loadItems()
        })
    }
    loadItems(done) {
        const qty = 10
        const { lang } = this.props.match.params
        stagesCalendar({rid: this.props.user.main.id, page: this.state.page, qty: qty, showpaused: 1, lang}).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                const response = res.data.data.list
                let offers = []
                let items = []
                response.forEach(offer => {
                    offers.push(offer)
                    offer.applicants.forEach(stage => {
                        items.push(stage)
                    })
                })

                let dates = []
                items.forEach((i) => {
                    i.stages.forEach((s) => {
                        dates.push(s.date)
                    })
                })

                this.setState({ items: items, dates: dates, offers: offers, hasMorePages: response.length >= qty, loading: false, firstLoad: false })
            }
        })
    }

    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            const profile = nextProps.match.params.id ? findById(nextProps.match.params.id, nextProps.user.profiles) : nextProps.user.profiles.find((profile) => parseInt(profile.main) === 1)
            this.setState({profile: profile});
        }
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant' && parseInt(this.props.user.subscription) === 1)
    }

    printHour(hour) {
        const splitHour = hour.split(':')
        if (splitHour[1] === '0'){
            return `${splitHour[0]}:00`
        }
        return `${splitHour[0]}:${splitHour[1]}`
    }


    render() {
        let className = 'scene_RestaurantProfileCalendar'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.props.deviceScreen !== 'mobile' && this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params

        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div className='top-content'>
                    <h4><span>{translate(lang, 'restaurant_profile')}</span> / {translate(lang, 'calendar')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        <Switch active={this.state.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                </div>
            )
        }
    }
    countApplicationsByStatus(status) {
        let count = 0
        this.state.items.forEach((i) => {
            if (parseInt(i.statusid) === parseInt(status)) {
                count++
            }
        })
        return count
    }
    dateClassName(date) {
        const day = date.getDate()
        if (parseInt(day) > 9 && parseInt(day) < 32) {
            return 'stage-big'
        }
        return 'stage'
    }
    handleClick(value) {
        const date = moment(value).format('YYYY-MM-DD')
        let stages = []
        if (this.state.dates.includes(date)) {
            this.state.offers.forEach(o => {
                o.applicants.forEach(a => {
                    a.stages.forEach(s => {
                        if (s.date === date) {
                            stages.push({
                                id: a.id,
                                offer: o,
                                application: a,
                                stage: s,
                            })
                        }
                    })
                })
            })
            this.setState({ selectedDayEvents: stages })
        } else {
            let stages = []
            this.setState({ selectedDayEvents: stages })
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return <Loader />
        }
        return (
            <div>
            {this.props.deviceScreen === 'mobile' && <h4>{translate(lang, 'calendar')}</h4>}
                <div className="col-group">
                    <div className="col-mb-12 col-6">
                        <MonthView
                            activeStartDate={this.state.activeStartDate}
                            onActiveDateChange={(info) => {
                                let date = info.activeStartDate
                                this.setState({
                                    activeStartDate: date,
                                }, () => {
                                    this.loadItems()
                                })
                            }}
                            locale="en-En"
                            view="month"
                            tileClassName={({date}) => 
                                this.state.dates.includes(moment(date).format('YYYY-MM-DD'))
                                ? this.dateClassName(date)
                                : ''
                            }
                            // tileDisabled={({date, view }) => {
                            //     return (findIndex(this.state.dates, (o) => {
                            //         return o === moment(date).format('YYYY-MM-DD')
                            //     }) > -1)
                            // }}
                            onClickDay={(value) => {this.handleClick(value)}}
                            />
                    </div>
                </div>
                {this.state.selectedDayEvents.length === 0 ? <div className='click-message'>({translate(lang, 'click_date')})</div> : null}
                {this.renderChefs(<p>{translate(lang, 'no_trials')}</p>)}
            </div>
        )
    }
    sortDates = () => {
        const formattedItems = (
            this.state.selectedDayEvents.map((item) => {
              const dateItems = item.stage.date.split('-')
              const intDateItems = dateItems.map(number => parseInt(number))
              const year = intDateItems[0]
              const month = intDateItems[1]
              const day = intDateItems[2]
              const start = parseInt(item.stage.start)
              const end = parseInt(item.stage.end)
      
              return ({
                id: item.id, year, month, day, start, end,
              })
            })
          )
          const sortedItems = formattedItems.sort((a, b) => {
            if (a.year - b.year !== 0) {
                return a.year - b.year
              }
              if (a.month - b.month !== 0) {
                return a.month - b.month
              }
              if (a.day - b.day !== 0) {
                return a.day - b.day
              }
              if (a.start - b.start !== 0) {
                return a.start - b.start
              }
              if (a.end - b.end !== 0) {
                return a.end - b.end
              }
              return (a, b)
            })

          const order = sortedItems.map(i => i.id)
            this.state.selectedDayEvents.sort((a, b) => {
          const A = a.id
          const B = b.id
          if (order.indexOf(A) > order.indexOf(B)) {
            return 1
          }
          return -1
        })
        return this.state.selectedDayEvents
    }
    renderChefs(message) {
        const { lang } = this.props.match.params
        let items = []
        this.sortDates().forEach((item) => {
            let chef = item.application.chef
            items.push(
                <Chef
                    key={item.application.id}
                    item={{profile: chef}}
                    hideSelect={true}
                    isApplication={true}
                    extraContent={
                        <div className='main-info no-padding-top'>
                            <div className="extra-content no-border">
                                {this.renderExtraContentForApplication(item.application, item.offer)}
                            </div>
                        </div>
                    }
                    alternativeDate={
                        <span className="alternative-date">
                            {translate(lang, 'applied_on').toUpperCase()} {moment(item.application.created).format('MMM DD, YYYY')}
                        </span>
                    }
                    />
            )
        })
        if (items.length > 0) {
            return items
        } else {
            return message
        }
    }
    renderExtraContentForApplication(item, offer) {
        const { lang } = this.props.match.params
        let content = []
        if (item.stages.length) {
            let stage = item.stages[0]
            const stageType = applicationTypes[lang].find(i => i.value === parseInt(stage.typeid))
            const dateLiteral = stageType ? translate(lang, 'trial_date').replace('(%@)', stageType.label) : translate(lang, 'date') + ":"
            let btn = <Btn text={translate(lang, 'accepted').toUpperCase()} pre="tick1" className="no-line disabled btn-accepted" callback={() => {}} />
            
            content.push(
                <div key={stage.id} className="stage">
                    <a onClick={() => { return this.props.history.push('/'), this.props.history.push(`${lang}/restaurant/profile/offers/${this.props.user.main.id}/view/${offer.id}`) }} className='offer'>
                        <span className='offer-name'>{offer.name}</span>
                    </a>
                    <div className='trial-wrapper'>
                        <div className='trial-date'>
                            <strong className='pre-date'>{dateLiteral.toUpperCase()}</strong>
                            {stage.start !== "" && stage.end !== ""
                            ? <span className='accepted-stage'><strong>{moment(stage.date).format('DD/MM/YYYY')}</strong> ({translate(lang, 'from').toLowerCase()} {this.printHour(stage.start)} {translate(lang, 'to').toLowerCase()} {this.printHour(stage.end)})</span>
                            : <span className='accepted-stage'><strong>{moment(stage.date).format('DD/MM/YYYY')}</strong> ({translate(lang, 'no_time')})</span>
                            }
                        </div>
                        {this.state.stage !== -1 ? btn : null}
                    </div>
                </div>
            )
        }
        return content
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfileCalendar))
