export const restaurantFaqs_fr = [
    {
        q: "Est-ce que votre service coûte de l'argent?",
        t: "Vous avez deux options:<br/><br/><strong>Inscrivez-vous gratuitement: </strong><br/>En tant que Restaurant, les offres d'emploi sur notre site sont gratuites et sans risque ni paiement. Ce n'est que lorsque le restaurant décide qu'il est nécessaire ou intéressé par l'un de nos chefs que le paiement est pris en compte.<br/><br/><strong>Clients Premium: </strong><br/>Des frais annuels de 600 £ vous permettront d’avoir accès à toutes les informations de contact de nos chefs en envoyant un message directement aux applications Chef directement dans votre boîte de réception avec prime de publicité et bien d’autres encore. Pour plus d'informations sur le paiement, veuillez contacter le bureau et nous vous enverrons les détails nécessaires."
    },
    {
        q: "Comment puis-je devenir un client Premium?",
        t: "Inscrivez-vous ou mettez à niveau votre compte vers Premium dans les paramètres une fois que nous avons reçu votre demande, nous activerons votre compte. Si vous faites partie d'un groupe de restaurants, veuillez noter que tous les restaurants de votre groupe doivent s'inscrire en tant que clients Premium. Pour plus d’informations sur Premium, n’hésitez pas à contacter l’un de nos conseillers."
    },
    {
        q: "Pourquoi ne puis-je pas voir tous les chefs disponibles?",
        t: "Notre système détectera uniquement les chefs pertinents pour votre restaurant en fonction des sélections que vous avez effectuées pour votre entreprise dans les paramètres. Les deux caractéristiques principales sont la langue et la nationalité. Cela vous permettra uniquement d'envoyer des offres d'emploi et de recevoir des applications Chef pertinentes pour votre restaurant et de ne pas perdre de temps en filtrant des centaines de CV qui ne sont pas pertinents pour votre restaurant."
    },
    {
        q: "Pourquoi mon profil est-il toujours en révision?",
        t: "Il peut être l'une des deux raisons: <ul><li>Nous traitons toujours votre demande; Après avoir créé votre profil, veuillez laisser au moins 24 heures avant de contacter Chefs Link. </li><li>Votre candidature est incomplète: veuillez compléter votre formulaire et le soumettre à nouveau</li></ul>"
    },
    {
        q: "Chefs Freelance?",
        t: "Seulement nos clients Premium auront accès à ces profils."
    },
    {
        q: 'Messages:',
        t: "Seulement nos clients Premium pourront envoyer un message directement à nos chefs."
    },
    {
        q: "Pouvez-vous m'aider à créer mes annonces?",
        t: "Vous pouvez utiliser notre gourou pour vous aider à créer des annonces d'emploi. Cliquez sur Publier des offres et, en bas, vous trouverez notre gourou à côté de la description de tâches. <br/>ÉTAPE 1: <br/>Sélectionnez les descriptions qui importent pour votre entreprise. <br/>ÉTAPE 2: <br/>Modifiez le texte en fonction de vos besoins en recrutement dans la description de poste et envoyez-le."
    },
    {
        q: "Avons-nous besoin de payer les chefs pour leur période d'essai?",
        t: "Il appartient aux restaurants de décider s’ils veulent ou non payer pour le poste d’essai. En règle générale, les restaurants ne paient le poste d’essai que si le chef obtient le poste et commence à travailler pour la société."
    },
    {
        q: "Que faire si le chef que vous envoyez pour un poste d'essai n'est pas le bon candidat pour le poste?",
        t: "Il appartient au restaurant de décider si vous aimez le chef ou si vous vous sentez à l'aise avec eux dans la cuisine. Si vous décidez que ce n’est pas le bon chef, nous continuerons à rechercher des chefs jusqu’à ce que nous trouvions le bon candidat pour votre restaurant."
    },
    {
        q: "Je ne veux pas qu’un certain chef voie nos offres d’emploi ou notre profil?",
        t: "Vous pouvez empêcher un chef de voir vos offres d'emploi ou votre profil en accédant à leur profil et en cliquant sur le bouton Plus de la barre de fonctions. Un menu déroulant vous donnera quelques options pour bloquer le chef."
    },
    {
        q: "Avec quels chefs travaillez-vous?",
        t: "Nous travaillons aux côtés des universités les plus prestigieuses et des chefs individuels. Si vous recherchez un type de chef spécifique, spécialisé dans certaines cuisines, veuillez nous en informer et notre équipe fera de son mieux pour que cela se produise à votre place."
    },
    {
        q: "Puis-je chercher des chefs moi-même?",
        t: "Nous encourageons tous nos restaurants à chercher leur propre chef. Vous pouvez cliquer sur Trouver des chefs dans la barre de navigation et sélectionner ceux qui intéressent votre entreprise. Ce faisant, vous aurez un choix beaucoup plus large de chefs à considérer."
    },
    {
        q: "Pouvez-vous m'envoyer plus d'informations sur le chef?",
        t: "Nous serons plus qu'heureux de le faire. Dites-nous quelles sont vos préoccupations ou les autres informations que vous souhaitez que nous vous fournissions et nous y répondrons du mieux que nous pourrons."
    },
    {
        q: "Quand le chef peut-il commencer à travailler?",
        t: "Si nous avons le bon candidat pour votre entreprise et que le chef est prêt à commencer à travailler, nous organiserons un période d’essai pour ce chef le lendemain dans votre restaurant. Nous conseillons à tous nos clients de prendre contact avec nous dès que possible afin de nous assurer de disposer de suffisamment de temps pour pourvoir ce poste avec le meilleur candidat possible."
    },
    {
        q: "Que se passera-t-il si, après quelques semaines, nous décidons que le chef n'est pas le bon candidat pour le poste ou que le chef quitte l'entreprise?",
        t: "Nous ne facturerons la société qu'après que le chef aura terminé ses 30 premiers jours au restaurant pour s'assurer que le candidat et le chef du restaurant sont satisfaits de l'accord. Si le chef décide de partir ou si le chef du restaurant estime qu’ils ne sont pas le bon candidat pour le poste au cours de la première période d’essai de 30 jours, nous annulerons ce contrat sans paiement et nous commencerons à chercher un remplaçant pour ce poste."
    },
]
