import React, {Component} from 'react'
// import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'

class DateSectionTitle extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <div className="component_DateSectionTitle">
                {this.props.title}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(DateSectionTitle))
