import {Component} from 'react'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {canUseDOM} from '../../Lib/canUseDOM'
import { withRouter } from 'react-router'
var debounce = require('lodash/debounce')

class ResponsiveUtility extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deviceScreen: props.deviceScreen
        }
        this.updateDimensions = debounce(this.updateDimensions, 100)
    }
    render() {
        return null
    }
    updateDimensions() {
        if (!canUseDOM) {
            return
        }
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let nDS = this.state.deviceScreen
        if (width < 1024) {
            nDS = 'mobile'
        } else if (width < 1200) {
            nDS = 'tablet'
        } else if (width < 1400) {
            nDS = 'desktop'
        } else {
            nDS = 'large'
        }
        if (this.state.deviceScreen !== nDS) {
            this.setState({ deviceScreen: nDS }, () => {
                this.props.setDeviceScreen(nDS)
            })
        }
        this.props.setDeviceWidth(width)
        this.props.setDeviceHeight(height)
    }
    componentWillMount() {
        this.updateDimensions();
    }
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ResponsiveUtility))
