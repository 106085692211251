import React, {Component} from 'react'
import {Async} from 'react-select'
// import {myPromise} from '../../Lib/MyPromise'
import Geocode from "react-geocode";

var myTimer;

export default class LocationInput extends Component {
    constructor(props) {
        super(props)

        Geocode.setApiKey('AIzaSyDnMH5wbKMpJWpumoqFFSZy45Pp2nsW734')

        this.state = {
            value: this.props.value,
            placeholder: this.props.value ? this.props.value.formatted_address : this.props.placeholder
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({
                value: this.props.value,
                placeholder: this.props.value ? this.props.value.formatted_address : this.props.placeholder
            })
        }
    }

    render() {
        return (
            <div>
                <label htmlFor={this.props.name}>{this.props.label}</label>
                <Async
                    placeholder={this.state.placeholder}
                    name="location"
                    value={this.state.value ? this.state.value.formatted_address : null}
                    onChange={(option) => {
                        this.setState({ value: option.value, placeholder: option.value.formatted_address }, () => {
                            this.props.onChange(option.value)
                        });
                    }}
                    loadOptions={this.loadOptions.bind(this)}
                    backspaceRemoves={false}
                    className={this.props.className}
                    cache={false}
                    autoload={false}
                    filterOptions={(options, filter, currentValues) => {
                        // Do no filtering, just return all options
                        return options;
                    }}
                    />
            </div>
        )
    }
    hasError() {
        return (this.props.error.indexOf(this.props.name) !== -1)
    }
    loadOptions(input, callback) {

        if (!input) {
            callback(null, [])
            return
        }
        clearTimeout(myTimer)

        myTimer = setTimeout(() => {
            Geocode.fromAddress(input).then(
              response => {
                  let items = []
                  response.results.forEach((r) => {
                      items.push({value: r, label: r.formatted_address})
                  })
                  callback(null, {options: items})
              },
              error => {
                callback(error, [])
              }
            );
        }, 200)

    }
};
