import React, { useState } from 'react'
import Button from './Button'
import IconButton from './IconButton'
import { translate } from '../../Translations/translate'
import {report, block} from '../../Services/Util/util'
import SVG from 'react-inlinesvg';

const RestaurantTopNavMobile = ({ props, state, sendCVCallback, screen }) => {
  const [showMore, setShowMore] = useState(false)
  const { lang } = props.match.params
  const { isLogged, userType, showOverlay, user, toggleSelectedOffer } = props
  const { id, slug, item, offer } = state

  const moreOptions = <div className={`more ${showMore ? "more-open" : ""}`}>
    <div className="report-menu">
      <h4>{translate(lang, 'block_page').toUpperCase()}</h4>
      <div className="line" onClick={() => block({offender: this.state.item.profile.acc, lang}).then((res) => {
            if (res.data.success) {
                this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
            }
        })}
      >
          <SVG src="/static/images/tick1.svg" />
          {translate(lang, 'block_this_page')}
      </div>
      <h4>{translate(lang, 'report').toUpperCase()}</h4>
      <div className="line" onClick={() => report({offender: this.state.item.profile.acc, ischef: 0, lang}).then((res) => {
            if (res.data.success) {
                this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
            }
        })}
      >
          <SVG src="/static/images/tick1.svg" />
          {translate(lang, 'report_page')}
      </div>
  </div>
  </div>

  return (
    <div className="component_RestaurantTopNavMobile">
      <div className='icons'>
        <IconButton icon='home' color="white" link={`/${lang}/restaurant/view/home/${id}/${slug}`}/>
        {
          isLogged
          && userType === "chef"
          && <IconButton icon='calendar' color="white" link={`/${lang}/restaurant/view/stage/${id}/${slug}`} />
        }
        <IconButton icon='share' color="white" callback={() => showOverlay('share')}/>
        {
          isLogged
          && <IconButton icon='more' color="white" callback={() => setShowMore(!showMore)}/>
        }
        {
          isLogged
          && !["stage", "message"].includes(screen)
          && userType !== "restaurant"
          && <IconButton icon='message' color="white" callback={() => showOverlay('message', {
            rid: item.profile.id,
            cid: user.profile.id,
            sender: 'restaurant'
          })}/>
      }
      </div>
      {
        userType !== 'restaurant'
        && offer && toggleSelectedOffer && screen === "offer"
        && item.profile.inapply === 0
        && (item.profile.invited === 0 || !item.profile.invited)
        && <Button
          label={translate(lang, 'apply').toUpperCase()}
          icon="apply"
          className='bold blue'
          onClick={() => toggleSelectedOffer({id: offer.offer.id, item: offer})}
        />
      }
      {
        !["stage", "offer"].includes(screen)
        && userType !== 'restaurant'
        && <Button
          label={translate(lang, 'send_cv').toUpperCase()}
          icon="apply"
          className='bold blue'
          onClick={sendCVCallback}
        />
      }
      {moreOptions}
    </div>
  )
}

export default RestaurantTopNavMobile