import React, {Component} from 'react'
import SVG from 'react-inlinesvg';

export default class EditBtn extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {

        let className = 'component_EditBtn'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        if (this.props.loading) {
            className += ' loading'
        }

        return (
            <div className={className} onClick={this.handleClick.bind(this)}>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        return (
            <div>
                <div className="pre"><SVG src="/static/images/edit_icn.svg" /></div>
                <div className="text">{this.props.text}</div>
            </div>
        )
    }
    //algo
    handleClick() {
        if (this.props.callback !== undefined) {
            this.props.callback()
        }
    }
};
