import React, {Component} from 'react'
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class FakeUploadBtn extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {

        let className = 'component_Btn file-upload'
        if (this.props.className) {
            className += ' ' + this.props.className
        } else {
            className += ' inverted'
        }
        if (this.props.loading) {
            className += ' loading'
        }

        return (
            <div className={className}>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <label className="w" onClick={() => {
                if (this.props.click) {
                    this.props.click()
                } else {
                    this.props.showOverlay('media', {name: this.props.name, type: this.props.type, rid: this.props.rid, oid: this.props.oid}, this.props.callback)
                }
            }}>
                <div className="pre"><SVG src='/static/images/more_icn.svg' /></div>
                <div className="text">{this.props.btnText ? this.props.btnText : translate(lang, 'upload')}</div>
            </label>
        )
    }
};


const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(FakeUploadBtn))
