import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import SVG from 'react-inlinesvg'
import UploadBtn from '../../Components/UploadBtn/UploadBtn'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {listMedia, assignOfferImage, assignProfileImage, deleteImage} from '../../Services/Media/media'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'

class MediaOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingMedia: true,
            items: [],
            activeItemId: false,
        }
    }
    componentDidMount() {
        this.fetchItems()
    }
    fetchItems() {
        const { lang } = this.props.match.params
        this.setState({loadingMedia: true})
        listMedia(lang).then((res) => {
            this.setState({loadingMedia: false})
            if (res.data.success) {
                this.setState({items: res.data.data})
            }
        })
    }
    render() {
        const { lang } = this.props.match.params
        let className = this.props.className
        className += ' media-overlay'
        return (
            <Overlay title={translate(lang, 'media')} className={className}
                rightTop={
                    <UploadBtn
                        refresh={() => {
                            this.fetchItems()
                        }}
                        />
                }
                >
                {this.renderImages()}
                <div className="bottom">
                    <Btn
                        loading={this.state.loading}
                        text={this.props.data.type !== 'offer' && this.props.data.type !== 'profile' ? translate(lang, 'choose_file') : translate(lang, 'choose_image')}
                        className={this.state.activeItemId ? '' : 'inverted'}
                        callback={this.assign.bind(this)}
                        />
                </div>
            </Overlay>
        )
    }
    renderImages() {
        const { lang } = this.props.match.params
        let items = []
        this.state.items.forEach((i) => {
            if (
                (this.props.data.type !== 'offer' && this.props.data.type !== 'profile' && i.type === 'image')
                || ((this.props.data.type === 'offer' || this.props.data.type === 'profile') && i.type !== 'image')
            ) {
                return
            }
            let className = (i.id === this.state.activeItemId) ? 'active' : null
            items.push(
                <div key={i.id} className={className} onClick={() => {
                    if (
                        (this.props.data.type !== 'offer' && this.props.data.type !== 'profile' && i.type !== 'image')
                        || ((this.props.data.type === 'offer' || this.props.data.type === 'profile') && i.type === 'image')
                    ) {
                        if (this.state.activeItemId === i.id) {
                            this.setState({activeItemId: false, activeItem: false})
                        } else {
                            this.setState({activeItemId: i.id, activeItem: i.sizes})
                        }
                    }

                }}>
                    <div className="remove" onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();
                        if (window.confirm(translate(lang, 'remove_file'))) {
                            this.removeMedia(i.id)
                        }
                    }}>
                        <SVG src="/static/images/close_btn_2.svg"/>
                    </div>
                    {i.type === 'image' ? <LazyImage src={i.sizes.square.url} alt={i.id} /> : <div className="fill-type"><span>{i.src.split('/').pop()}</span></div>}
                </div>
            )
        })
        return <div className="items-wrapper">{items}</div>
    }
    removeMedia(id) {
        const { lang } = this.props.match.params
        this.setState({loading: true})
        deleteImage(id, lang).then((res) => {
            this.setState({loading: false})
            if (res.data.success) {
                this.fetchItems()
            }
        })
    }
    assign() {
        const { lang } = this.props.match.params
        if (this.props.data.callback) {
            this.props.data.callback(this.state.activeItemId, this.state.activeItem)
        } else {
            if (this.props.data.type === 'offer') {
                this.setState({loading: true})
                assignOfferImage({key: this.props.data.name, id: this.state.activeItemId, oid: this.props.data.oid, lang}).then((res) => {
                    this.setState({loading: false})
                    if (res.data.success) {
                        //hide modal
                        this.props.data.callback(res.data.data)
                        this.props.showOverlay(null)
                    }
                })
            } else if (this.props.data.type === 'profile') {
                this.setState({loading: true})
                let data = {key: this.props.data.name, id: this.state.activeItemId, lang}
                if (this.props.data.rid) {
                    data.rid = this.props.data.rid
                }
                assignProfileImage(data).then((res) => {
                    this.setState({loading: false})
                    if (res.data.success) {
                        this.props.setUser(res.data.data.user)
                        this.props.showOverlay(null)
                    }
                })
            }
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(MediaOverlay))
