import React from 'react'
import Btn from '../../Components/Btn/Btn'
import store from '../../Config/store'
import { translate } from '../../Translations/translate'

export function listItems(profile, state, lang) {
    const nots = profile.pendingnotifications
    let items = [
        {url: `/${lang}/restaurant/profile/home/` + profile.id, title: translate(lang, 'home'), icon: '_home'},
        {url: `/${lang}/restaurant/profile/notifications/` + profile.id, title: translate(lang, 'notifications'), icon: '_notifications_2', badge: nots.notifications},
        {url: `/${lang}/restaurant/profile/offers/` + profile.id, title: translate(lang, 'job_offers'), icon: '_joboffer_apply'}
    ]
    if (parseInt(profile.premium) > 0) {
        items.push({url: `/${lang}/restaurant/profile/applicants/` + profile.id, title: translate(lang, 'applicants'), icon: '_applicants', badge: nots.offers})
        items.push({url: `/${lang}/restaurant/profile/messages/` + profile.id, title: translate(lang, 'messages'), icon: '_messages', badge: nots.messages})
    }
    // items.push({url: `/${lang}/restaurant/profile/links/` + profile.id, title: translate(lang, 'links'), icon: '_link'})
    items.push(
        {url: `/${lang}/restaurant/profile/calendar/` + profile.id, title: translate(lang, 'calendar'), icon: '_calendar'},
    )
    return items
}
export function listBtn2(lang, profile, state, callback) {
    let st = store.getState()
    return (
        <Btn
            text={translate(lang, 'post_jobs').toUpperCase()}
            pre="edit_icn"
            className={st.deviceScreen === 'mobile' ? 'outlined' : null}
            callback={callback ? callback : null}
            />
    )
}
