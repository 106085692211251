import 'url-search-params-polyfill';
import axios from 'axios'

const API_URL = 'https://www.chefslink.com/e/'
// const API_URL = 'http://192.168.1.20/'

export function apiFetch(url, data) {

    data = Object.assign({submit: 1}, data)

    if (data.lang) {
        data.language = data.lang
        delete data.lang
    }

    let formData = new FormData()
    Object.keys(data).forEach((k) => {
        let d = data[k]
        if (d === null || d === 'null') {
            d = ''
        }
        formData.append(k, d)
    })

    return axios(API_URL + url, {
            method: 'post',
            data: formData,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

}

// function sleeper(ms) {
//   return function(x) {
//     return new Promise(resolve => setTimeout(() => resolve(x), ms));
//   };
// }
