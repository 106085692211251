import React, {useEffect} from "react"
import { translate } from "../../Translations/translate"

const OverlayChefProfileNav = ({ props }) => {
  const { lang } = props.match.params

  // TODO: not working
  useEffect(() => {
    let el = document.querySelector('a[data-block="' + props.overlayName + '"]')
      if (el) {
          el.scrollIntoView({
              behavior: 'smooth',
              inline: 'start',
              block: 'start',
          })
      }
  }, [props.overlayName])

  return (
    <div className="component_OverlayChefProfileNav">
      <a
        data-block="chefinfo"
        className={`navItem ${props.overlayName === "chefinfo" ? "active" : ""}`}
        onClick={() => props.showOverlay('chefinfo', { ...props.user, profileNav: true })}>
          {translate(lang, 'profile')}
        </a>
      <a
        data-block="chefabout"
        className={`navItem ${props.overlayName === "chefabout" ? "active" : ""}`}
        onClick={() => props.showOverlay('chefabout', { description: props.user.profile.description, profileNav: true })}>
          {translate(lang, 'about')}
        </a>
      <a
        data-block="chefworkexperience"
        className={`navItem ${props.overlayName === "chefworkexperience" ? "active" : ""}`}
        onClick={() => props.showOverlay('chefworkexperience', { id: 0, value: {}, profileNav: true })}>
          {translate(lang, 'work_experience')}
        </a>
      <a
        data-block="chefeducation"
        className={`navItem ${props.overlayName === "chefeducation" ? "active" : ""}`}
        onClick={() => props.showOverlay('chefeducation', { id: 0, value: {}, profileNav: true })}>
          {translate(lang, 'education')}
        </a>
      <a
        data-block="cheflanguage"
        className={`navItem ${props.overlayName === "cheflanguage" ? "active" : ""}`}
        onClick={() => props.showOverlay('cheflanguage', { id: 0, name: '', value: '', profileNav: true })}>
          {translate(lang, 'languages')}
        </a>
      <a
        data-block="chefreferral"
        className={`navItem ${props.overlayName === "chefreferral" ? "active" : ""}`}
        onClick={() => props.showOverlay('chefreferral', { id: 0, value: {}, profileNav: true })}>
          {translate(lang, 'more_info')}
        </a>
    </div>
  )
}

export default OverlayChefProfileNav