import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import Textarea from '../../Components/Input/Textarea'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import {setMainProfile} from '../../Services/Restaurant/restaurant'
import {validate} from '../../Components/Input/validate'
import Select from 'react-select'
import { translate } from '../../Translations/translate';

class MainProfileOverlay extends Component {
    constructor(props) {
        super(props)

        let mains = []
        this.props.user.profiles.forEach((profile) => {
            mains.push({value: profile.id, label: profile.name})
        })

        this.state = {
            rememberMe: false,
            mains: mains,
            main: this.props.user.main.id,
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'switch_active_page')} className={this.props.className}>
                <div className="col-group form">
                    <div className="col-mb-12">
                        <Select
                            placeholder=""
                            name="main"
                            value={this.state.main}
                            className={this.state.errors.indexOf('main') !== -1 ? 'error' : null}
                            onChange={(option) => { this.setState({ main: option.value }) }}
                            options={this.state.mains}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        <Btn text={translate(lang, 'switch').toUpperCase()} loading={this.props.loading} callback={() => {
                            this.action()
                        }} />
                    </div>
                </div>
            </Overlay>
        )
    }
    action() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'main'},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })
            setMainProfile({rid: this.state.main, lang}).then((res) => {
                if (res.data.success) {
                    this.props.setUser(res.data.data.user)
                    this.props.showOverlay(null)
                    this.props.history.push(`/${lang}/restaurant/profile/home`)
                } else {
                    this.setState({loading:false})
                    this.props.showOverlay('error', {message: res.data.message})
                }
            })
        }

    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(MainProfileOverlay))
