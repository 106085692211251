import React, {Component} from 'react'
import FadingLine from '../../Components/FadingLine/FadingLine'
import moment from 'moment'
import {fixHtml} from '../../Lib/fixHtml'
import { translate } from '../../Translations/translate';
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'

class Education extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMore: false
        }
    }
    render() {
        const { lang } = this.props.match.params
        let className = 'component_Education col-mb-12'
        if (this.props.className) {
            className += ' ' + this.props.className
        }

        let {item} = this.props

        let period = moment(item.value.fromYear + '-' + item.value.fromMonth + '-01', 'YYYY-M-DD').format('YYYY MMM') + ' - ' + (
            (item.value.currently)
            ? translate(lang, 'currently').toLowerCase()
            : moment(item.value.toYear + '-' + item.value.toMonth + '-01', 'YYYY-M-DD').format('YYYY MMM')
        )

        return (
            <div data-index={this.props.index} className={className}>
                <div className="col-group">
                    <div className="col-mb-6">
                        <div className="info">
                            <h5>
                                <div className="title">
                                    <div className="title-text">
                                        <span>{item.value.title}</span>
                                    </div>
                                </div>
                            </h5>
                            <span><FadingLine title={period} /></span>
                        </div>
                    </div>
                    <div className="col-mb-6">
                        <div className="info center">
                            <h5>
                                <FadingLine title={item.value.center} />
                            </h5>
                            {item.value.url ? <span><FadingLine><a href={item.value.url} target="_blank">{item.value.url}</a></FadingLine></span> : null}
                        </div>
                    </div>
                </div>
                {this.props.hideDescription ? null : this.renderDescription()}
                {this.props.btn ? <div className="edit-btn">{this.props.btn}</div> : null}
            </div>
        )
    }
    renderDescription() {
        const { lang } = this.props.match.params
        let {item} = this.props
        if (strip(item.value.description) === '') {
            return null
        }

        let {description} = item.value
        let teaser = !this.state.showMore && description && description.length > 120 ? description.substring(0, 120) + '...' : description
        let content = (
            <span>
                <span dangerouslySetInnerHTML={{__html: fixHtml(teaser)}}></span>
                <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
            </span>
        )
        return (
            <div className="col-group description-wrapper">
                <div className="col-mb-12 the-description">
                    {content}
                </div>
            </div>
        )
    }
};

function strip(html)
{
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}



const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Education))