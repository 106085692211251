import React, { useState, useEffect } from 'react'
import { Formik, Form } from "formik"
import moment from 'moment'

import { translate } from '../../Translations/translate'
import ChefEducationSchema from "../../FormValidations/ChefEducationSchema"
import { chefEditEducation } from '../../Services/Chef/chef'

import FormField from './FormField'
import FormSelect from './FormSelect'
import FormCheckbox from './FormCheckbox'
import Button from './Button'
import FormTextarea from "./FormTextarea"

const ChefEducationForm = ({ props , textTranslation, textLang}) => {
  const { lang } = props.match.params
  const { data, setUser, showOverlay } = props
  const { value, profileNav } = data

  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingAddMore, setLoadingAddMore] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [reset, setReset] = useState(false)
  const [formError, setFormError] = useState(null)
  const [changedText, setChangedText] = useState(null)

  const months = [
    {value: 1, label: 'January'},
    {value: 2, label: 'February'},
    {value: 3, label: 'March'},
    {value: 4, label: 'April'},
    {value: 5, label: 'May'},
    {value: 6, label: 'June'},
    {value: 7, label: 'July'},
    {value: 8, label: 'August'},
    {value: 9, label: 'September'},
    {value: 10, label: 'October'},
    {value: 11, label: 'November'},
    {value: 12, label: 'December'},
  ]
  const currentYear = moment().year()
  const years = []
  for (var y = 0; y < 60; y++) {
      years.push({value: (currentYear-y), label: (currentYear-y)})
  }

  const handleSave = (values, currentLang, closeModal, addMore = false) => {
    if (!addMore) {
      setLoadingSave(true)
    } else {
      setLoadingAddMore(true)
    }
    const monthsValues = months.map(m => m.value)
    const yearsValues = years.map(y => y.value)

    const formData = {
      id: values.id,
      name: 'education',
      order: 0,
      language: currentLang,
      value: JSON.stringify({
          title: values.title,
          center: values.center,
          url: values.url,
          fromMonth: values.fromMonth,
          fromYear: values.fromYear,
          toMonth: values.toMonth,
          toYear: values.toYear,
          currently: values.currently,
          description: values.description,
          lang: currentLang,
          location: values.location,
          months: monthsValues.join(','),
          currentYear: values.currentYear,
          years: yearsValues.join(','),
      })
    }
    chefEditEducation(formData).then((res) => {
      setLoadingSave(false)
      if (!res.data.success) {
          setFormError(res.data.message)
          return
      } else {
          const response = res.data.data
          setUser(response.user)
          if (profileNav && !addMore) {
            showOverlay('cheflanguage', { id: 0, name: '', value: '', profileNav: true })
          } else if (addMore) {
            setLoadingAddMore(false)
            setReset(true)
          } else if (closeModal) {
            showOverlay(null)
          }
      }
    }).catch((e) => {
        setLoadingSave(false)
        setFormError(e.message)
    })
  }

  const handleDelete = () => {
    setLoadingDelete(true)
    chefEditEducation({id: data.id, action: 'delete', lang}).then((res) => {
        setLoadingDelete(false)
        if (!res.data.success) {
            setFormError(res.data.message)
            return
        }
        const response = res.data.data
        setUser(response.user)
        showOverlay(null)
    }).catch((e) => {
        setFormError(e.message)
    })
  }

  return (
    <div className="component_chefEducationForm">
      <Formik
        initialValues={{
          id: data.id ? parseInt(data.id) : 0,
          title: value.title ? value.title : '',
          center: value.center ? value.center : '',
          url: value.url ? value.url : '',
          fromMonth: value.fromMonth ? value.fromMonth : '',
          fromYear: value.fromYear ? value.fromYear : '',
          toMonth: value.toMonth ? value.toMonth : '',
          toYear: value.toYear ? value.toYear : '',
          currently: value.currently ? value.currently : false,
          description: value.description ? value.description : '',
          months: months,
          currentYear: currentYear,
          years: years
        }}
        validationSchema={ChefEducationSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSave(values, textLang, true)}
        enableReinitialize
      >{({ values, errors, handleChange, setFieldValue, resetForm, validateForm }) => {
        
        useEffect(() => {
          if (textTranslation) {
            if (changedText) {
              handleSave(values, changedText, false)
              setChangedText(null)
              setFieldValue('description', textTranslation)
            } else {
              setFieldValue('description', textTranslation)
            }
          }
        }, [textTranslation])

        useEffect(() => {
          if (reset) {
            resetForm()
            setReset(false)
          }
        }, [reset])

        return (
          <Form>
            <div className="fullWidth">
              <FormField
                id="title"
                name="title"
                label={translate(lang, 'title')}
                placeholder={translate(lang, 'placeholder_education_title')}
                value={values.title}
                onChange={handleChange}
                errors={errors}
                formError={formError}
                showErrorMessage={false}
              />
            </div>
            <div className="fullWidth">
              <div className="halfWidth marginRight">
                <FormField
                  id="center"
                  name="center"
                  label={translate(lang, 'center')}
                  placeholder={translate(lang, 'placeholder_center_name')}
                  value={values.center}
                  onChange={handleChange}
                  errors={errors}
                  formError={formError}
                  showErrorMessage={false}
                />
              </div>
              <div className="halfWidth marginLeft">
                <FormField
                  id="url"
                  name="url"
                  label={translate(lang, 'url')}
                  placeholder={translate(lang, 'placeholder_center_url')}
                  value={values.url}
                  onChange={handleChange}
                  errors={errors}
                  formError={formError}
                />
              </div>
            </div>
            <div className="fullWidth">
              <div className="halfWidth marginRight">
                <div className="halfWidth marginRight">
                  <FormSelect
                    id="fromMonth"
                    name="fromMonth"
                    label={translate(lang, 'from')}
                    options={months}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, 'placeholder_select_month')}
                    showErrorMessage={false}
                  />
                </div>
                <div className="halfWidth marginLeft">
                  <FormSelect
                    id="fromYear"
                    name="fromYear"
                    options={years}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, 'placeholder_select_year')}
                    showErrorMessage={false}
                  />
                </div>
              </div>
              <div className="halfWidth marginLeft desktop">
                <FormCheckbox
                  id="currently"
                  name="currently"
                  label={`${translate(lang, 'currently_studying')}`}
                  checked={values.currently}
                  onChange={handleChange}
                  errors={errors}
                />
              </div>
            </div>
            <div className="fullWidth">
              <div className={`halfWidth marginRight ${values.currently ? "hidden" : ""}`}>
                <div className="halfWidth marginRight">
                  <FormSelect
                    id="toMonth"
                    name="toMonth"
                    label={translate(lang, 'to')}
                    options={months}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, 'placeholder_select_month')}
                    showErrorMessage={false}
                  />
                </div>
                <div className="halfWidth marginRight marginLeft">
                  <FormSelect
                    id="toYear"
                    name="toYear"
                    options={years}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, 'placeholder_select_year')}
                    showErrorMessage={false}
                  />
                </div>
              </div>
            </div>
            <div className="fullWidth mobile">
              <FormCheckbox
                id="currently"
                name="currently"
                label={`${translate(lang, 'currently_studying')}`}
                checked={values.currently}
                onChange={handleChange}
                errors={errors}
              />
            </div>
            <div className="fullWidth textarea">
              <FormTextarea
                id="description"
                name="description"
                label={translate(lang, 'description')}
                placeholder={null}
                errors={errors}
                onChange={v => {
                  setChangedText(textLang);
                  setFieldValue('description', v);
                }}
                lang={lang}
                textTranslation={textTranslation}
                addMediaCallback={false}
                reset={reset}
              />
            </div>
            <div className={`buttonRow ${formError || (errors && Object.keys(errors).length > 0) ? "hasError" : ""}`}>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            {errors && Object.keys(errors).length > 0 && <div className="errorMessage">{translate(lang, 'error_required_fields')}</div>}
            <div className='buttons'>
              {parseInt(data.id) !== 0 && <div className="nextTo">
                <Button
                  label={translate(lang, 'delete').toUpperCase()}
                  className="red"
                  loading={loadingDelete}
                  onClick={() => handleDelete()}
                />
              </div>}
              {parseInt(data.id) === 0 && <div className="nextTo">
                  <Button
                    label={translate(lang, 'save_add_more').toUpperCase()}
                    loading={loadingAddMore}
                    onClick={() => validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0) {
                      handleSave(values, textLang, false, true)
                    }})}
                  />
                </div>}
                <Button
                  label={profileNav? translate(lang, 'save_continue').toUpperCase() : translate(lang, 'save').toUpperCase()}
                  submit
                  loading={loadingSave}
                />
            </div>
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default ChefEducationForm