import {apiFetch} from '../../Config/api'

export function chatStartConversation(data) {
    return apiFetch('messages/startconversation', data)
}

export function chatSendMessage(data) {
    return apiFetch('messages/send', data)
}

export function chatListConversation(data) {
    return apiFetch('messages/conversations/show', data)
}

export function chatListConversations(data) {
    return apiFetch('messages/conversations/list', data)
}
export function chatListAccountConversations(data) {
    return apiFetch('messages/accountconversations/list', data)
}
export function blockConversation(data) {
    return apiFetch('messages/blockconversation', data)
}
export function unblockConversation(data) {
    return apiFetch('messages/unblockconversation', data)
}
export function deleteConversation(data) {
    return apiFetch('messages/endconversation', data)
}
