import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'

class ImageHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.added = false
    }
    _handleResize() {
        if (this.props.right !== null || this.props.deviceScreen === 'mobile') {
            if (!document.querySelector('component_Overlay')) {
                if (document.querySelector('div.main')) {
                    document.querySelector('div.main').style.marginTop = 0 + 'px'
                    if (document.querySelector('div.sidebar-right')) {
                        document.querySelector('div.sidebar-right').classList.remove('no-header-image')
                        document.querySelector('div.sidebar-right').style.marginTop = 0 + 'px'
                    }
                }
            }
        } else {
            let h = document.querySelector('div.component_ImageHeader .col-mb-4.col-2').offsetWidth
            if (document.querySelector('div.main')) {
                document.querySelector('div.main').style.marginTop = -h + 'px'
            }
            if (document.querySelector('div.sidebar-right')) {
                document.querySelector('div.sidebar-right').style.marginTop = -h + 'px'
                document.querySelector('div.sidebar-right').classList.add('no-header-image')
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.deviceWidth !== prevProps.deviceWidth || this.props.user !== prevProps.user) {
            this._handleResize()
        }
    }
    componentDidMount() {
        this._handleResize();
    }
    render() {
        let noImage = (this.props.right === null)
        if (this.props.noCover) {
            return (
                <div className="component_ImageHeader no-cover">
                    <div className="profile">
                        <LazyImage src={this.props.left} alt={this.props.alt}/>
                        {this.props.leftBtn ? this.props.leftBtn : null}
                    </div>
                </div>
            )
        }
        return (
            <div className="component_ImageHeader">
                <div className="container-fluid">
                    <div className="col-group">
                        <div className="col-mb-4 col-2">
                            <LazyImage src={this.props.left} alt={this.props.alt}/>
                            {this.props.leftBtn ? this.props.leftBtn : null}
                        </div>
                        <div className={'col-mb-8 col-10' + (noImage ? ' no-image' : '')} style={noImage ? null : {backgroundImage:'url(' + this.props.right + ')'}}>
                            {this.props.rightBtn ? this.props.rightBtn : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        deviceWidth: state.deviceWidth,
        user: state.user
    }
}
export default withRouter(connect(mapStateToProps)(ImageHeader))
