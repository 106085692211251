import React, {Component} from 'react'
import FakeUploadBtn from '../FakeUploadBtn/FakeUploadBtn'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import { translate } from '../../Translations/translate';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'

class Textarea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editorState: this.props.value && this.props.value !== '' ? EditorState.createWithContent(stateFromHTML(this.props.value)) : EditorState.createEmpty(),
            limit: 490
        }
        this.setContent = this.setContent.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.enableReceiveProps) {
            if (this.props.value !== this.state.html) {
                this.setState({ editorState: nextProps.value && nextProps.value !== '' ? EditorState.createWithContent(stateFromHTML(nextProps.value)) : EditorState.createEmpty(), })
            }
            if (this.props.reset) {
                this.clear()
            }
        }
    }

    clear() {
        this.setState({editorState: EditorState.createWithContent(stateFromHTML(''))})
    }
 
    render() {
        const lang = this.props.lang
        return (
            <div data-type="textarea">
                <label htmlFor={this.props.name}>
                    {this.props.label}
                    {this.props.endLabel ? this.props.endLabel : null}
                </label>
                <Editor
                    handleReturn={this.handleReturn}
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName={this.hasError() ? 'input error' : 'input'}
                    placeholder={this.props.placeholder}
                    onEditorStateChange={(editorState) => {
                        this.handleHtml(editorState)
                    }}
                    toolbar={{
                        options: ['inline', 'list'],
                        inline: {
                            options: ['bold', 'italic', 'underline']
                        },
                        list: {
                            options: ['unordered', 'ordered']
                        },
                    }}
                    toolbarCustomButtons={this.props.addMediaCallback ? [<FakeUploadBtn btnText={translate(lang, 'add_media').toUpperCase()} className="no-line" callback={this.props.addMediaCallback} />] : null}
                    />
            </div>
        )
    }
    handleHtml(editorState) {
        const { lang } = this.props.lang
        let options = {
            defaultBlockTag: 'span',
        }
        let plainText = editorState.getCurrentContent().getPlainText()
        let html = stateToHTML(editorState.getCurrentContent(), options)
        if (isEmail(plainText) || isUrl(plainText)) {
            let re = new RegExp(isUrlRegex(), "i");
            let re2 = new RegExp(isEmailRegex(), "i");
            html = html.replace(re, translate(lang, 'no_urls_allowed'));
            html = html.replace(re2, translate(lang, 'no_emails_allowed'));
        } else {
            html = stateToHTML(editorState.getCurrentContent(), options)
        }

        this.setState({editorState: editorState, html: html})
        this.props.onChange(html)
    }
    handleReturn = (e) => {
    }
    hasError() {
        return (this.props.error.indexOf(this.props.name) !== -1)
    }
    setContent(html) {
        this.setState({
            editorState: EditorState.createWithContent(stateFromHTML(html))
        })
    }
};

function isUrlRegex() {
    let regex = "((?:(http|https|Http|Https|rtsp|Rtsp):\\/\\/(?:(?:[a-zA-Z0-9\\$\\-\\_\\.\\+\\!\\*\\'\\(\\)"
    + "\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,64}(?:\\:(?:[a-zA-Z0-9\\$\\-\\_"
    + "\\.\\+\\!\\*\\'\\(\\)\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,25})?\\@)?)?"
    + "((?:(?:[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}\\.)+"   // named host
    + "(?:"   // plus top level domain
    + "(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])"
    + "|(?:biz|b[abdefghijmnorstvwyz])"
    + "|(?:cat|com|coop|c[acdfghiklmnoruvxyz])"
    + "|d[ejkmoz]"
    + "|(?:edu|e[cegrstu])"
    + "|f[ijkmor]"
    + "|(?:gov|g[abdefghilmnpqrstuwy])"
    + "|h[kmnrtu]"
    + "|(?:info|int|i[delmnoqrst])"
    + "|(?:jobs|j[emop])"
    + "|k[eghimnrwyz]"
    + "|l[abcikrstuvy]"
    + "|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])"
    + "|(?:name|net|n[acefgilopruz])"
    + "|(?:org|om)"
    + "|(?:pro|p[aefghklmnrstwy])"
    + "|qa"
    + "|r[eouw]"
    + "|s[abcdeghijklmnortuvyz]"
    + "|(?:tel|travel|t[cdfghjklmnoprtvwz])"
    + "|u[agkmsyz]"
    + "|v[aceginu]"
    + "|w[fs]"
    + "|y[etu]"
    + "|z[amw]))"
    + "|(?:(?:25[0-5]|2[0-4]" // or ip address
    + "[0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\\.(?:25[0-5]|2[0-4][0-9]"
    + "|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1]"
    + "[0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}"
    + "|[1-9][0-9]|[0-9])))"
    + "(?:\\:\\d{1,5})?)" // plus option port number
    + "(\\/(?:(?:[a-zA-Z0-9\\;\\/\\?\\:\\@\\&\\=\\#\\~"  // plus option query params
    + "\\-\\.\\+\\!\\*\\'\\(\\)\\,\\_])|(?:\\%[a-fA-F0-9]{2}))*)?"
    + "(?:\\b|$)";
    return regex
}

function isUrl(s) {
    return new RegExp(isUrlRegex()).test(s)
}

function isEmailRegex() {
    let regex = '/[a-zA-Z0-9\\+\\.\\_\\%\\-]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+/gi'
    return regex
}

function isEmail(s) {
    return new RegExp(isEmailRegex()).test(s)
}


export default Textarea;