import React, { useEffect, useState } from 'react'
import Button from './Button'
import ShareButton from "./ShareButton"

const ProfileTopMenu = ({
  props,
  items,
  buttonLabel,
  buttonLink,
  buttonCallback,
  buttonSecondaryLabel,
  buttonSecondaryCallback,
  share
}) => {
  const [active, setActive] = useState()

  const hasButton = buttonLabel && (buttonLink || buttonCallback)
  const hasSecondaryButton = buttonSecondaryLabel && buttonSecondaryCallback

  useEffect(() => {
    if (items) {
      setActive(items[0].title)
    }
  }, [items])

  return (
    <div className={`component_ProfileTopMenu 
    ${share && (hasButton || hasSecondaryButton) 
    && !props.deviceScreen !== 'mobile' ? 'double-row' : ''}`}>
      <div className="menu">
        <ul>
          {items.map(item => {
            return (
              <li key={item.url} className={active === item.title ? 'active' : ''}>
                    <a onClick={(e) => {
                        setActive(item.title)
                        let el = document.querySelector('div[data-block="' + item.url + '"]')
                        if (el) {
                            el.scrollIntoView({
                                behavior: 'smooth'
                            })
                        }
                    }}>{item.title}</a>
                    <div className='underline' />
                </li>
            )
          })}
        </ul>
        {share && <ShareButton props={props} />}
      </div>
      <div className="buttons">
        {hasSecondaryButton
        && <Button label={buttonSecondaryLabel} onClick={buttonSecondaryCallback} className="outline"/>}
        {hasButton && <Button label={buttonLabel} link={buttonLink} onClick={buttonCallback} className="blue bold"/>}
      </div>
    </div>
  )
}

export default ProfileTopMenu