import {apiFetch} from '../../Config/api'

export function listStages(data) {
    return apiFetch('stage/get', data)
}
export function listNextStages(data) {
    return apiFetch('stage/next', data)
}
export function stageInfo(data) {
    return apiFetch('stage/info', data)
}
export function requestStage(data) {
    return apiFetch('stage/request', data)
}
export function viewAcceptedOffers(data) {
    return apiFetch('offer/stageables/list', data)
}
export function rejectStaging(data) {
    // data.status = 'reject'
    return apiFetch('stage/withdraw', data)
}
export function acceptStaging(data) {
    return apiFetch('stage/accept', data)
}
export function rescheduleStaging(data) {
    return apiFetch('stage/reschedule', data)
}
export function rejectAcceptedStage(data) {
    return apiFetch('stage/rejectAccepted', data)
}
