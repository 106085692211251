import React, {Component} from 'react'
import Stars from '../../Components/Stars/Stars'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import LazyImage from '../../Components/LazyImage/LazyImage'

class SmallOffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        const { lang } = this.props.match.params
        let { item } = this.props
        let url = `/${lang}/restaurant/view/offer/${item.restaurant.id}/${item.restaurant.slug}/${item.offer.id}/${item.offer.slug}`
        return (
            <div className="component_SmallOffer">
                <div>
                    <div className="top">
                        {this.renderTop()}
                    </div>
                    <div className="wrap">
                        <div className="graphic">
                            <LazyImage src={item.offer.images.image1 !== '' ? item.offer.images.image1.sizes.showcase.url : 'http://via.placeholder.com/400x300'} alt={item.restaurant.name}/>
                        </div>
                        <div className="info">
                            <h2>
                                <Link to={url}>{item.offer.name}</Link>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderTop() {
        const { lang } = this.props.match.params
        let { item } = this.props
        let url = `/${lang}/restaurant/view/home/${item.restaurant.id}/${item.restaurant.slug}`
        return (
            <div>
                <div className="restaurant">
                    <div className="name-text"><span><Link to={url}>{item.restaurant.name}</Link></span></div>
                </div>
                <Stars type={item.restaurant.ratingType} number={parseInt(item.restaurant.rating)} />
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        userType: state.userType,
        isLogged: state.isLogged,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(SmallOffer))
