import React, {Component} from 'react'
import EditBtn from '../../Components/EditBtn/EditBtn'
import EditableImage from '../../Components/EditableImage/EditableImage'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Offer from '../../Components/Offer/Offer'
import RestaurantInfo from '../../Components/RestaurantInfo/RestaurantInfo'
import SVG from 'react-inlinesvg';
import ShowcaseImages from '../../Components/ShowcaseImages/ShowcaseImages'
import SidebarMenu from '../../Components/SidebarMenu/SidebarMenu'
import Stars from '../../Components/Stars/Stars'
import Switch from '../../Components/Switch/Switch'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findById} from '../../Lib/findById'
import {findOption} from '../../Lib/findOption'
import {positions, days} from '../../Lists/lists'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle, listOffersRestaurant} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Loader from "../../Components/_NEW/Loader"


class RestaurantProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            activeSection: 'opening-hours',
            items: [],
            page: 0,
            hasMorePages: true,
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        this.setState({
            items: [],
            page: 0,
            hasMorePages: true,
            profile: profile,
        }, this.loadItems.bind(this))
    }
    loadItems(done) {
        const { lang } = this.props.match.params
        listOffersRestaurant({
            showpaused: 1,
            rid: this.state.profile.id,
            page: this.state.page,
            qty: 20,
            lang: lang,
        }).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                let items = this.state.items
                items.push(...res.data.data.list)
                this.setState({ items: items, hasMorePages: (res.data.data.list.length === 20), loading: false })
            }
        })
    }
    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.user && (nextProps.user !== this.props.user)) {
            this.setState({profile: this.props.match.params.id ? findById(this.props.match.params.id, nextProps.user.profiles) : nextProps.user.main, page: 0, items: []});
        }
    }

    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant')
    }

    render() {
        let className = 'scene_RestaurantProfile'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.profile) {
            let profile = this.state.profile
            meta = {
                title: profile.name,
                metas: [
                    {name: 'og:image', value: profile.images.header ? profile.images.header.sizes.share.url : null}
                ]
            }
        }
        return (
            <Main
                match={this.props.match}
                meta={meta}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages) {
                        this.setState({page:this.state.page+1}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    {this.renderContent()}
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (!this.state.profile) {
            return null
        }
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'restaurant_profile')}</span> / {translate(lang, 'home')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderSwitch() {
        const { lang } = this.props.match.params
        return (
            <Switch active={parseInt(this.state.profile.active)} callback={() => {
                if (!this.state.loadingSwitch) {
                    this.setState({ loadingSwitch: true })
                    restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                        this.setState({ loadingSwitch: false })
                        let data = res.data.data
                        this.props.setUser(data.user)
                    }).catch((e) => {
                        this.setState({ loadingSwitch: false })
                    })
                }
            }} />
        )
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        let items = [
            {url: 'about', title: translate(lang, 'about')},
            {url: 'details', title: translate(lang, 'details')},
            {url: 'job-offers', title: translate(lang, 'job_offers')},
        ]
        if (this.props.deviceScreen === 'mobile' || !this.state.profile) {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        {this.renderSwitch()}
                    </div>
                    <h5 className="clear">{translate(lang, 'info')}</h5>
                    <SidebarMenu items={items} />
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return <Loader />
        }
        let description = this.state.profile.description
        let profile = this.state.profile

        return (
            <div>
                {this.props.deviceScreen === 'mobile' ? <RestaurantInfo profile={profile} /> : null}
                {this.props.deviceScreen === 'mobile' ? <div className="switch-mobile">{this.renderSwitch()}</div> : null}
                <div className="container-fluid">
                    <div className="blocks">
                        <div className="block" data-block="about">
                            <h4>
                                <span>{translate(lang, 'about_restaurant')}</span>
                                {this.props.deviceScreen !== "mobile" && <Stars type={profile.ratingType} number={parseInt(profile.rating)} />}
                            </h4>
                            <EditBtn text={translate(lang, 'edit')} callback={()=>{
                                this.props.showOverlay('restaurantabout', {
                                    id: this.state.profile.id,
                                    name: this.state.profile.name,
                                    description: this.state.profile.description,
                                    location: this.state.profile.location,
                                    rating: this.state.profile.rating,
                                    ratingType: this.state.profile.ratingType,
                                    url: this.state.profile.url,
                                    cuisine: this.state.profile.cuisine,
                                    language: this.state.profile.language,
                                    workvisas: this.state.profile.visas,
                                    nationalities: this.state.profile.nationalities,
                                    stageinfo: this.state.profile.stageinfo,
                                    emailmessage: this.state.profile.emailmessage,
                                    contactemail: this.state.profile.contactemail
                                })
                            }} />
                            <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>
                            <ShowcaseImages
                                item1={
                                    <EditableImage
                                        src={this.state.profile.images.image1 ? this.state.profile.images.image1.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'}
                                        btn={<FakeUploadBtn type="profile" name="image1" alt={this.state.profile.name} rid={this.state.profile.id} />}
                                        />
                                }
                                item2={
                                    <EditableImage
                                        src={this.state.profile.images.image2 ? this.state.profile.images.image2.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'}
                                        btn={<FakeUploadBtn type="profile" name="image2" alt={this.state.profile.name} rid={this.state.profile.id} />}
                                        />
                                }
                                item3={
                                    <EditableImage
                                        src={this.state.profile.images.image3 ? this.state.profile.images.image3.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'}
                                        btn={<FakeUploadBtn type="profile" name="image3" alt={this.state.profile.name} rid={this.state.profile.id} />}
                                        />
                                }
                                />
                        </div>
                        {this.renderSectionsNav()}
                        {this.renderSectionsContent()}
                    </div>
                </div>
                {this.renderSectionJobOffers()}
            </div>
        )
    }
    renderSectionsNav() {
        const { lang } = this.props.match.params
        let options = [
            {type: 'opening-hours', title: translate(lang, 'opening_hours')},
            {type: 'covers', title: translate(lang, 'covers')},
            {type: 'chefs', title: translate(lang, 'chefs')},
            {type: 'contact', title: translate(lang, 'contact')},
        ]
        let items = []
        options.forEach((o) => {
            items.push(
                <li key={o.type} className={this.state.activeSection === o.type ? 'active' : null}><a onClick={(e)=>{e.preventDefault();this.setState({activeSection:o.type})}}>{o.title}</a></li>
            )
        })
        return (
            <ul className="nav">
                {items}
            </ul>
        )
    }
    renderSectionsContent() {
        switch (this.state.activeSection) {
            case 'opening-hours':
                return this.renderSectionOpeningHours()
                break;
            case 'chefs':
                return this.renderSectionChefs()
                break;
            case 'covers':
                return this.renderSectionCovers()
                break;
            case 'contact':
                return this.renderSectionContact()
                break;
            default:
                return null;
                break;
        }
    }
    renderSectionOpeningHours() {
        const { lang } = this.props.match.params
        return (
            <div className="block" data-block="details">
                <EditBtn text={translate(lang, 'edit')} callback={()=>{
                    let data = {id: null, value: {}, rid: this.state.profile.id}
                    if (this.state.profile.schedule && this.state.profile.schedule.length > 0) {
                        let schedule = this.state.profile.schedule[0]
                        data = {id: schedule.id, value: schedule.value, rid: this.state.profile.id}
                    }
                    this.props.showOverlay('restaurantopeninghours', data)
                }} />
                {!this.state.profile.schedule || this.state.profile.schedule.length === 0 ? <p>{translate(lang, 'start_editing_opening_hours')}</p> : null}
                {this.renderSchedule()}
            </div>
        )
    }
    renderSchedule() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.schedule && this.state.profile.schedule.length > 0) {
            let schedule = this.state.profile.schedule[0]
            days[lang].forEach((d) => {
                let day = d.value
                let dayLunchFrom = day + 'lunchFrom'
                let dayLunchTo = day + 'lunchTo'
                let dayDinnerFrom = day + 'dinnerFrom'
                let dayDinnerTo = day + 'dinnerTo'
                let text = ''
                if (['', null].includes(schedule.value[dayLunchFrom]) && ['', null].includes(schedule.value[dayLunchTo]) && ['', null].includes(schedule.value[dayDinnerFrom]) && ['', null].includes(schedule.value[dayDinnerTo])) {
                    text = translate(lang, 'closed').toUpperCase()
                } else {
                    let parts = []
                    if (!['', null].includes(schedule.value[dayLunchFrom])) {
                        let hours
                        if (typeof schedule.value[dayLunchFrom] === 'number') {
                            hours = `${schedule.value[dayLunchFrom]}:00h`
                        } else {
                            hours = `${schedule.value[dayLunchFrom]}h`
                        }
                        if (!['', null].includes(schedule.value[dayLunchTo])) {
                            hours += '-'
                            if (typeof schedule.value[dayLunchTo] === 'number') {
                            hours += `${schedule.value[dayLunchTo]}:00h`
                            } else {
                                hours += `${schedule.value[dayLunchTo]}h`
                            }
                        }
                        parts.push(hours)
                    }
                    if (!['', null].includes(schedule.value[dayDinnerFrom])) {
                        let hours
                        if (typeof schedule.value[dayDinnerFrom] === 'number') {
                            hours = `${schedule.value[dayDinnerFrom]}:00h`
                        } else {
                            hours = `${schedule.value[dayDinnerFrom]}h`
                        }
                        if (!['', null].includes(schedule.value[dayDinnerTo])) {
                            hours += '-'
                            if (typeof schedule.value[dayDinnerTo] === 'number') {
                            hours += `${schedule.value[dayDinnerTo]}:00h`
                            } else {
                                hours += `${schedule.value[dayDinnerTo]}h`
                            }
                        }
                        parts.push(hours)
                    }
                    text = parts.join(', ')
                }
                if (text !== '') {
                    items.push(
                        <div key={d.value} className="col-mb-12 scheduleday">
                            <strong>{d.label}</strong> {text}
                        </div>
                    )
                }
            })
        }
        return <div className="col-group items">{items}</div>
    }
    renderSectionChefs() {
        const { lang } = this.props.match.params
        return (
            <div className="block" data-block="details">
                <EditBtn text={translate(lang, 'add')} callback={()=>{
                    this.props.showOverlay('restaurantchef', {id: null, value: {}, rid: this.state.profile.id})
                }} />
                {!this.state.profile.chefs || this.state.profile.chefs.length === 0 ? <p>{translate(lang, 'start_adding_chefs')}</p> : null}
                {this.renderChefs()}
            </div>
        )
    }
    renderChefs() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.chefs && this.state.profile.chefs.length > 0) {
            this.state.profile.chefs.forEach((i) => {
                items.push(
                    <div key={i.id} className="col-mb-6 col-4">
                        <h5>{i.value.person}</h5>
                        {findOption(i.value.position, positions[lang])}
                        <EditBtn text={translate(lang, 'edit')} callback={()=>{
                            this.props.showOverlay('restaurantchef', {id: i.id, value: i.value, rid: this.state.profile.id})
                        }} />
                    </div>
                )
            })
        }
        return <div className="col-group items">{items}</div>
    }
    renderSectionCovers() {
        const { lang } = this.props.match.params
        return (
            <div className="block" data-block="details">
                <EditBtn text={translate(lang, 'add')} callback={()=>{
                    this.props.showOverlay('restaurantcover', {id: null, value: {}, rid: this.state.profile.id})
                }} />
                {!this.state.profile.covers || this.state.profile.covers.length === 0 ? <p>{translate(lang, 'start_adding_covers')}</p> : null}
                {this.renderCovers()}
            </div>
        )
    }
    renderCovers() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.covers && this.state.profile.covers.length > 0) {
            this.state.profile.covers.forEach((i) => {
                items.push(
                    <div key={i.id} className="col-mb-6 col-4">
                        <h5>{i.value.title}</h5>
                        {i.value.description}
                        <EditBtn text={translate(lang, 'edit')} callback={()=>{
                            this.props.showOverlay('restaurantcover', {id: i.id, value: i.value, rid: this.state.profile.id})
                        }} />
                    </div>
                )
            })
        }
        return <div className="col-group items">{items}</div>
    }
    renderSectionContact() {
        const { lang } = this.props.match.params
        return (
            <div className="block" data-block="details">
                <div className="col-group items">
                    <div className="col-mb-12 col-4">
                        <div className="item">
                            <h5><span>{translate(lang, 'address').toUpperCase()}</span></h5>
                            {this.renderAddresses()}
                            <EditBtn text={translate(lang, 'add_address')} callback={()=>{
                                this.props.showOverlay('restaurantaddress', {id: null, value: {}, rid: this.state.profile.id})
                            }} />
                        </div>
                    </div>
                    <div className="col-mb-12 col-4">
                        <div className="item">
                            <h5><span>{translate(lang, 'email').toUpperCase()}</span> </h5>
                            {this.renderEmails()}
                            <EditBtn text={translate(lang, 'add_email')} callback={()=>{
                                this.props.showOverlay('restaurantemail', {id: null, value: {}, rid: this.state.profile.id})
                            }} />
                        </div>
                    </div>
                    <div className="col-mb-12 col-4">
                        <div className="item">
                            <h5><span>{translate(lang, 'phone_number').toUpperCase()}</span> </h5>
                            {this.renderPhoneNumbers()}
                            <EditBtn text={translate(lang, 'add_phone_number')} callback={()=>{
                                this.props.showOverlay('restaurantphone', {id: null, value: {}, rid: this.state.profile.id})
                            }} />
                        </div>
                    </div>
                </div>
                <div className="col-group items">
                    <div className="col-mb-12 col-4">
                        <div className="item">
                            <h5><span>{translate(lang, 'social_networks').toUpperCase()}</span> </h5>
                            {this.renderSocialNetworks()}
                            <EditBtn text={translate(lang, 'add_social_network')} callback={()=>{
                                this.props.showOverlay('restaurantsocial', {id: null, value: {}, rid: this.state.profile.id})
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderSectionJobOffers() {
        const { lang } = this.props.match.params
        return (
            <div className="block job-offers" data-block="job-offers">
                <div className="container-fluid">
                    <h4>{translate(lang, 'job_offers_NUM', this.state.items.length)}</h4>
                </div>
                {this.renderOffers()}
            </div>
        )
    }
    renderAddresses() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.contact.address && this.state.profile.contact.address.length > 0) {
            let j = 0
            this.state.profile.contact.address.forEach((i) => {
                items.push(
                    <div key={j}>
                        <span>{i.value.value.formatted_address}
                        <EditBtn text={translate(lang, 'edit')} callback={()=>{
                            this.props.showOverlay('restaurantaddress', {id: i.id, value: i.value, rid: this.state.profile.id})
                        }} />
                        </span>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderEmails() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.contact.email && this.state.profile.contact.email.length > 0) {
            let j = 0
            this.state.profile.contact.email.forEach((i) => {
                items.push(
                    <div key={j}>
                        <span>{i.value.value}
                        <EditBtn text={translate(lang, 'edit')} callback={()=>{
                            this.props.showOverlay('restaurantemail', {id: i.id, value: i.value, rid: this.state.profile.id})
                        }} />
                        </span>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderPhoneNumbers() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.contact.phone && this.state.profile.contact.phone.length > 0) {
            let j = 0
            this.state.profile.contact.phone.forEach((i) => {
                items.push(
                    <div key={j}>
                        <span>{i.value.value}
                        <EditBtn text={translate(lang, 'edit')} callback={()=>{
                            this.props.showOverlay('restaurantphone', {id: i.id, value: i.value, rid: this.state.profile.id})
                        }} />
                        </span>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderSocialNetworks() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.profile.contact.social && this.state.profile.contact.social.length > 0) {
            let j = 0
            this.state.profile.contact.social.forEach((i) => {
                let svgSrc = "/static/images/" + i.value.type + "_icn.svg"
                items.push(
                    <div key={j} className="social-network">
                        <div className="pre">
                            <SVG src={svgSrc} />
                        </div>
                        <EditBtn text={translate(lang, 'edit')} callback={()=>{
                            this.props.showOverlay('restaurantsocial', {id: i.id, value: i.value, rid: this.state.profile.id})
                        }} />
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderOffers() {
        const { lang } = this.props.match.params
        let items = []
        this.state.items.forEach((item) => {
            let i = {
                restaurant: this.state.profile,
                offer: item
            }
            let url = `/${lang}/restaurant/profile/offers/` + this.state.profile.id + '/view/' + item.id
            items.push(<Offer key={item.id} url={url} item={i} />)
        })
        if (items.length === 0){
        return <div className='no-jobs'>{translate(lang, 'no_job_offers')}</div>
        } else {
            return items
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfile))
