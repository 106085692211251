import * as Yup from 'yup'
import { translate } from '../Translations/translate'

const SignupEmailSchema = (lang) => Yup.object().shape({
  email: Yup.string()
    .email(translate(lang, 'error_email_valid'))
    .required(translate(lang, 'error_email_required')),
  terms: Yup.bool()
    .oneOf([true], translate(lang, 'error_terms_required')),
})

export default SignupEmailSchema