export const restaurantFaqs_pt = [
    {
        q: 'O seu serviço custa algum dinheiro?',
        t: 'Você tem duas opções:<br/><br/><strong>Inscreva-se gratuitamente: </strong><br/>Como um serviço de publicidade em nosso site é gratuito e não há risco e nenhum pagamento é necessário. É somente quando o Restaurante decide que precisa ou está interessado em um dos nossos Chefes que o pagamento é considerado.<br/><br/><strong>Clientes Premium: </strong><br/>Uma taxa anual de 600 libras esterlinas permitirá que você tenha acesso a todas as informações de contato de nossos chefs diretamente do seu inbox premium e muito mais. Para informações de pagamento, entre em contato com o escritório e nós lhe enviaremos os detalhes necessários.'
    },
    {
        q: 'Como eu me torno um cliente Premium?',
        t: 'Inscreva-se ou atualize sua conta para Premium em Configurações assim que recebermos sua inscrição, ativaremos sua conta. Por favor, note que se você faz parte de um grupo de restaurantes, pedimos que todos os restaurantes do seu grupo se inscrevam como clientes Premium. Para mais informações sobre o Premium, não hesite em entrar em contato com um de nossos consultores.'
    },
    {
        q: 'Por que não consigo ver todos os Chefs disponíveis?',
        t: 'O nosso sistema irá detectar apenas os Chefs que são relevantes para o seu Restaurante, de acordo com as seleções que você fez para a sua empresa em Configurações. As duas principais características são idioma e nacionalidade. Ele permitirá que você apenas envie Ofertas de Emprego e receba aplicativos Chef que sejam relevantes para o seu restaurante e não perca tempo filtrando centenas de Cvs que são irrelevantes para o seu restaurante.'
    },
    {
        q: 'Por que meu perfil ainda está em revisão?',
        t: 'Pode ser uma das duas razões: <ul><li>Ainda estamos lidando com seu aplicativo; Depois de criar seu perfil, por favor, deixe pelo menos 24 horas antes de entrar em contato com o Chefs Link. </li><li>Sua inscrição está incompleta: Por favor, preencha o formulário e envie-o novamente.</li></ul>'
    },
    {
        q: 'Freelance Chefs?',
        t: 'Só nossos clientes Premium terão acesso a esses perfis'
    },
    {
        q: 'Mensagens:',
        t: 'Só nossos clientes Premium poderão enviar mensagens diretamente com nossos Chefs'
    },
    {
        q: 'Você pode me ajudar a criar meus anúncios?',
        t: 'Você pode usar o nosso Guru para ajudar a criar anúncios de emprego. Clique em Post Jobs e na parte inferior você encontrará nosso Guru ao lado da descrição do trabalho. <br/>PASSO 1: <br/>Selecione as descrições importantes para sua empresa. <br/>STEP 2: <br/>Edite o texto para corresponder às suas necessidades de recrutamento na Descrição do trabalho e envie.'
    },
    {
        q: 'Precisamos pagar aos Chefs pela mudança de julgamento?',
        t: 'Cabe aos Restaurantes decidir se querem pagar pelo turno experimental ou não. Normalmente, os restaurantes só pagam pelo turno experimental se o Chef conseguir o emprego e começar a trabalhar para a empresa.'
    },
    {
        q: 'Que se o Chef que você envia para um turno de teste não é o candidato certo para o trabalho?',
        t: 'Cabe ao restaurante decidir se você gosta do Chef ou se sente confortável com eles na cozinha. Se decidir que este não é o Chef certo, continuaremos a procurar Chefs até encontrarmos o candidato certo para o seu Restaurante.'
    },
    {
        q: 'Eu não quero um certo Chef para ver nossas ofertas de emprego ou perfil?',
        t: 'Você pode bloquear um Chef de ver suas ofertas de emprego ou perfil, indo para o seu perfil e clique no botão mais na barra de funções. Um menu drop-down lhe dará algumas opções para bloquear o Chef.'
    },
    {
        q: 'Que chefs você trabalha?',
        t: 'Eu trabalho ao lado de algumas das mais prestigiadas escolas particulares chef chefs de universidades e chefs individuais. Se você estiver procurando por um tipo específico de Chef especializado em determinadas cozinhas, informe-nos e nossa equipe fará o melhor para que isso aconteça para você.'
    },
    {
        q: 'Posso procurar os chefs pessoalmente?',
        t: 'Nós encorajamos todos os nossos Restaurantes a procurarem seus próprios Chefs também. Você pode clicar em Encontrar Chefs na barra de navegação e selecionar os Chefs que interessam à sua empresa. Ao fazer isso, você terá uma escolha muito mais ampla de Chefes a considerar.'
    },
    {
        q: 'Você pode me mandar mais informações sobre o Chef?',
        t: 'Estamos mais do que felizes em fazer isso. Por favor, deixe-nos saber quais são as suas preocupações ou quais outras informações você gostaria de fornecer e responderemos da melhor maneira possível.'
    },
    {
        q: 'Quando o Chef pode começar a trabalhar?',
        t: 'Se tivermos o candidato certo para a sua empresa e o Chef estiver pronto para começar a trabalhar, providenciaremos uma mudança de avaliação para o Chef no dia seguinte no seu Restaurante. Aconselhamos todos os nossos clientes a entrar em contato conosco o mais rápido possível para garantir que tenhamos tempo suficiente para preencher a vaga com o melhor candidato possível.'
    },
    {
        q: 'O que acontece se decidirmos depois de algumas semanas que o Chef não é o candidato certo para o emprego ou o chef deixa a empresa?',
        t: 'Nós apenas faturaremos a empresa depois que o Chef tiver completado seus primeiros 30 dias no restaurante para garantir que tanto o candidato Chef quanto o Chefe de Cozinha do Restaurante estejam satisfeitos com o acordo. Se o Chef decidir sair ou o Chef de Cozinha sentir que não é o candidato certo para o Trabalho dentro do período de teste de 30 dias, nós cancelaremos o contrato sem pagamento necessário e começaremos a procurar por um Chefe substituto para essa posição.'
    },
]
