import React, {Component} from 'react';
import Layout from '../Layout/Layout'
import createHistory from 'history/createBrowserHistory'
import store from '../../Config/store'
import { ConnectedRouter } from 'react-router-redux'
import { Provider } from 'react-redux'
import { StaticRouter } from 'react-router-dom'

// import Loadable from 'react-loadable';
//
// const AsyncLayout = Loadable({
//     loader: () => import('../Layout/Layout'),
//     loading: () => <div>Loading ChefsLink</div>,
// });

export default class AppRouter extends Component {
    render() {
        if (this.props.isServer) {
            return (
                <Provider store={store}>
                    <StaticRouter context={this.props.context} location={this.props.location}>
                        <Layout/>
                    </StaticRouter>
                </Provider>
            );
        } else {
            const history = createHistory()
            return (
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <Layout/>
                    </ConnectedRouter>
                </Provider>
            );
        }

    }
}
