import * as Yup from 'yup'
import { translate } from '../Translations/translate'

const SignupSchema = (lang) => Yup.object().shape({
  email: Yup.string()
    .email(translate(lang, 'error_email_valid'))
    .required(translate(lang, 'error_email_required')),
  type: Yup.string()
    .oneOf(['chef', 'restaurant'], translate(lang, 'error_type_profile'))
    .required(translate(lang, 'error_type_profile')),
  name: Yup.string()
    .matches(/^(?! )[A-Za-z\s]+$/, translate(lang, 'error_name_letters'))
    .min(2, translate(lang, 'error_name_length'))
    .required(translate(lang, 'error_name_required')),
  phoneNumber: Yup.string()
    .matches(/^(?! )[0-9\s]+$/, translate(lang, 'error_phone_numbers'))
    .min(4, translate(lang, 'error_phone_length'))
    .required(translate(lang, 'error_phone_required')),
  password: Yup.string()
    .min(6, translate(lang, 'error_password_length'))
    .required(translate(lang, 'error_password_required')),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], translate(lang, 'error_password_match'))
    .required(translate(lang, 'error_password_confirm'))
})

export default SignupSchema