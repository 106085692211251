import {apiFetch} from '../../Config/api'

export function listUserNotifications(data) {
    return apiFetch('notifications/user', data)
}
export function listRestaurantNotifications(data) {
    return apiFetch('notifications/profile', data)
}
export function readNotifications(data) {
    if (!data.action) {
        data.action = 'all'
    }
    return apiFetch('notifications/user', data)
}
