export const restaurantFaqs_en = [
    {
        q: 'Does your service cost any money?',
        t: 'You have two options:<br/><br/><strong>Sign up for Free:</strong><br/>As a Restaurant, advertising jobs on our website is free of charge, and no risk and no payment is needed whatsoever. It is only when the Restaurant decides that they need or is interested in one of our Chefs that payment is considered.<br/><br/><strong>Premium clients: </strong><br/>An annual fee of £600 will allow you to gain access to all our Chefs, contact information, direct message, Chef applications directly to your inbox, premium advertising and a whole lot more.<br/><br/>For payment information, please contact the office and we will send you the necessary details.'
    },
    {
        q: 'How do I become a Premium client?',
        t: 'Sign up or upgrade your account to Premium in Settings, once we received your application, we will activate your account. Please note if you are part of a Restaurant group we ask that all the restaurants in your group sign up as Premium clients. For more information on Premium, please feel free to contact one of our advisers.'
    },
    {
        q: 'Why can I not see all the Chefs available? ',
        t: 'Our system will detect only the Chefs that are relevant to your Restaurant according to the selections you made for your company in Settings. The two main features are Language and Nationality. It will enable you only to send Job Offers and receive Chef applications that are relevant to your restaurant and not waste time by filtering through hundreds of Cvs that are irrelevant to your restaurant.'
    },
    {
        q: 'Why is my profile still in Review? ',
        t: 'It can be one of two reasons. <ul><li>We are still dealing with your application; After creating your profile, please leave at least 24 hours before contacting Chefs Link </li><li>Your application is incomplete; please complete your form fully and submit it again</li></ul>'
    },
    {
        q: 'Freelance Chefs? ',
        t: 'Only our Premium clients will have access to these profiles.'
    },
    {
        q: 'Messages:',
        t: 'Only our Premium clients will be able to message directly with our Chefs.  '
    },
    {
        q: 'Can you help me create my Ads?',
        t: 'You can use our Guru to help your create job Ads. Click on Post Jobs, and at the bottom, you will find our Guru next to the Job Description.<br/>STEP 1<br/>Select the descriptions that matter to your business<br/>STEP 2<br/>Edit the text to match your recruitment needs in the Job Description and send.'
    },
    {
        q: 'Do we need to pay the Chefs for their trial shift?',
        t: 'It is up to the Restaurants to decide if they want to pay for the trial shift or not. Usually, Restaurants will only pay for the trial shift if the Chef gets the Job and starts working for the company. '
    },
    {
        q: 'What if the Chef you send for a trial shift is not the right candidate for the Job?',
        t: 'It is up to the Restaurant to decide if you like the Chef or feel comfortable with them in the kitchen. If you decide that this is not the right Chef, we will continue searching for Chefs until we find the right candidate for your Restaurant.'
    },
    {
        q: 'I don’t want a certain Chef to see our Job Offers or Profile?',
        t: 'You can block a Chef from seeing your Job Offers or Profile by going on to their profile and click on the more button in the function bar. A drop-down menu will give you a few options to block the Chef.'
    },
    {
        q: 'What Chefs do you work with?',
        t: 'We work alongside some of the most prestigious private chef schools, chef universities and individual chefs. If you are looking for a specific type of Chef who specialises in certain cuisines, please let us know, and our team will do their best to make that happen for you.'
    },
    {
        q: 'Can I look for Chefs myself?',
        t: 'We encourage all our Restaurants to look for their own Chefs as well. You can click on Find Chefs in the navigation bar and select the Chefs that interest your company.  By doing so, you will have a much wider choice of Chefs to consider.'
    },
    {
        q: 'Can you send me some more information about the Chef?',
        t: 'We will be more than happy to do that. Please let us know what your concerns are or what other information you would like us to provide you with, and we will answer them as best we can.'
    },
    {
        q: 'When can the Chef start working?',
        t: 'If we have the right candidate for your company and the Chef is ready to start working, we will arrange a trial shift for that Chef the next day at your Restaurant. We do advise all our clients to get in contact with us as soon as possible to make sure we get enough time to fill that vacancy with the best candidate possible. '
    },
    {
        q: 'What happens if we decide after a few weeks that the Chef is not the right candidate for the Job, or the Chef leaves the company?',
        t: 'We will only invoice the company after the Chef has completed his or her first 30 days in the restaurant to ensure both the candidate Chef and Restaurant Head Chef are happy with the agreement. If the Chef decides to leave or the Restaurant Head Chef feels that they are not the right candidate for the Job within the first 30 day trial period, we will then cancel that contract with no payment needed and start searching for a replacement Chef for that position. '
    },
]
