import React, { useState } from 'react'
import { Formik, Form } from "formik"

import { translate } from '../../Translations/translate'

import {positions, countries, cuisines} from '../../Lists/lists'
import ChefInfoSchema from "../../FormValidations/ChefInfoSchema"
import {chefEditInfo} from '../../Services/Chef/chef'

import FormField from './FormField'
import FormSelect from './FormSelect'
import FormSelectLocation from './FormSelectLocation'
import Button from './Button'

const ChefInfoForm = ({ props }) => {
  const { lang } = props.match.params
  const { showOverlay, data, setUser } = props
  const { profile, profileNav } = data

  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)

  const languageOptions = [
    {value: 'en', label: 'English'},
    {value: 'es', label: 'Español'},
    {value: 'fr', label: 'Français'},
    {value: 'pt', label: 'Português'}
]

  const handleSave = (values) => {
    setLoading(true)
    const formData = {
      name: values.name,
      position: values.position,
      dob: values.dob,
      location: values.location !== "" ? JSON.stringify(values.location) : "",
      nationalities: values.nationalities.join(','),
      visas: values.visas.join(','),
      cuisine: values.cuisine.join(','),
      lang: lang,
      password: values.password,
      rpassword: values.rpassword,
      contactemail: values.contactemail,
      phone: values.phone,
      language: values.language
    }
    chefEditInfo(formData).then((res) => {
      setLoading(false)
      if (!res.data.success) {
        setFormError(res.data.message)
        return
    }
    const response = res.data.data
    setUser(response.user)
    if (profileNav === true) {
      showOverlay('chefabout', { description: profile.description, profileNav: true })
    } else {
      showOverlay(null)
    }
    }).catch((e) => {
      setLoading(false)
      setFormError(e.message)
  })
  }

  return (
    <div className="component_ChefInfoForm">
      <Formik
        initialValues={{
          name: profile.name,
          position: profile.position ? parseInt(profile.position) : '',
          dob: profile.dob ? profile.dob : '',
          location: profile.location ? profile.location : '',
          nationalities: profile.nationalities ? profile.nationalities : [],
          visas: profile.visas ? profile.visas : [],
          cuisine: profile.cuisine ? profile.cuisine : [],
          lang: lang,
          password: '',
          rpassword: '',
          phone: data.phone ? data.phone : '',
          contactemail: data.contactemail,
          language: data.language ? data.language : lang,
        }}
        validationSchema={ChefInfoSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSave(values)}
      >{({ values, errors, handleChange, setFieldValue }) => {
        return(
          <Form>
            <div className='fullWidth'>
              <FormField
                id="name"
                name="name"
                label={translate(lang, 'name')}
                placeholder={translate(lang, 'name')}
                value={values.name}
                onChange={handleChange}
                errors={errors}
                formError={formError}
                showErrorMessage={false}
              />
            </div>
            <div className="fullWidth">
              <div className="halfWidth marginRight">
                <FormField
                  id="dob"
                  name="dob"
                  type="date"
                  label={translate(lang, 'date_of_birth')}
                  placeholder={translate(lang, 'date_of_birth')}
                  value={values.dob}
                  onChange={handleChange}
                  errors={errors}
                  formError={formError}
                  showErrorMessage={false}
                />
              </div>
              <div className="halfWidth marginLeft">
                <FormSelectLocation
                  id="location"
                  label={translate(lang, 'location')}
                  name="location"
                  error={errors}
                  value={values.location}
                  onChange={v => setFieldValue('location', v)}
                  labelClassName="inputLabel"
                  placeholder={translate(lang, 'placeholder_location')}
                  showErrorMessage={false}
                />
              </div>
            </div>
            <div className="fullWidth">
              <div className="halfWidth marginRight">
                <FormField
                  id="contactemail"
                  name="contactemail"
                  label={translate(lang, 'contact_email')}
                  placeholder={translate(lang, 'contact_email')}
                  value={values.contactemail}
                  onChange={handleChange}
                  errors={errors}
                  formError={formError}
                  showErrorMessage={false}
                />
              </div>
              <div className="halfWidth marginLeft">
                <FormField
                  id="phone"
                  name="phone"
                  label={translate(lang, 'phone_number')}
                  placeholder={translate(lang, 'phone_number')}
                  value={values.phone}
                  onChange={handleChange}
                  errors={errors}
                  formError={formError}
                  showErrorMessage={false}
                />
              </div>
            </div>
            <div className='fullWidth'>
              <div className='halfWidth marginRight'>
                <FormSelect
                  id="position"
                  name="position"
                  label={translate(lang, 'position')}
                  options={positions[lang]}
                  errors={errors}
                  formError={formError}
                  placeholder={translate(lang, 'placeholder_position')}
                  showErrorMessage={false}
                />
              </div>
              <div className='halfWidth marginLeft'>
                <FormSelect
                  id="cuisine"
                  name="cuisine"
                  label={translate(lang, 'cuisine')}
                  options={cuisines[lang]}
                  errors={errors}
                  formError={formError}
                  placeholder={translate(lang, 'placeholder_select_type')}
                  multipleSelector
                  showErrorMessage={false}
                />
              </div>
            </div>
            <div className="fullWidth">
                <div className="halfWidth marginRight">
                  <FormSelect
                    id="visas"
                    name="visas"
                    label={translate(lang, 'work_visas')}
                    options={countries[lang]}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, 'placeholder_select_country')}
                    multipleSelector
                    showErrorMessage={false}
                  />
                </div>
                <div className="halfWidth marginLeft">
                  <FormSelect
                    id="nationalities"
                    name="nationalities"
                    label={translate(lang, 'nationalities')}
                    options={countries[lang]}
                    errors={errors}
                    formError={formError}
                    placeholder={translate(lang, 'placeholder_select_country')}
                    multipleSelector
                    showErrorMessage={false}
                  />
                </div>
              </div>
            <div className="fullWidth">
                <div className="halfWidth marginRight">
                  <FormField
                    id="password"
                    name="password"
                    label={translate(lang, 'new_password')}
                    placeholder={translate(lang, 'new_password')}
                    value={values.password}
                    onChange={handleChange}
                    errors={errors}
                    formError={formError}
                    showErrorMessage={false}
                  />
                </div>
                <div className="halfWidth marginLeft">
                  <FormField
                    id="rpassword"
                    name="rpassword"
                    label={translate(lang, 'repeat_new_password')}
                    placeholder={translate(lang, 'repeat_new_password')}
                    value={values.rpassword}
                    onChange={handleChange}
                    errors={errors}
                    formError={formError}
                    showErrorMessage={false}
                  />
                </div>
            </div>
            <div className='fullWidth'>
              <div className="halfWidth paddingRight marginRight">
                <FormSelect
                  id="language"
                  name="language"
                  label={translate(lang, 'default_language')}
                  options={languageOptions}
                  errors={errors}
                  formError={formError}
                  placeholder={translate(lang, 'default_language')}
                  showErrorMessage={false}
                  disableSearch
                />
              </div>
            </div>
            <div className={`buttonRow ${formError || (errors && Object.keys(errors).length > 0) ? "hasError" : ""}`}>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            {errors && Object.keys(errors).length > 0 && <div className="errorMessage">{translate(lang, 'error_required_fields')}</div>}
              <Button
                label={profileNav ? translate(lang, 'save_continue').toUpperCase() : translate(lang, 'save').toUpperCase()}
                submit
                loading={loading}
              />
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default ChefInfoForm