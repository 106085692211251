export const chefFaqs_pt = [
    {
        q: 'O seu serviço custa algum dinheiro?',
        t: 'Chefs Link é um serviço gratuito para todos os nossos chefs à procura de emprego. Nenhum risco e nenhum pagamento é necessário. No entanto, pedimos a todos os nossos Chefes que respeitem os requisitos de tempo e frequência de todas as entrevistas organizadas.'
    },
    {
        q: 'Por que não consigo ver todos os trabalhos disponíveis?',
        t: 'O nosso sistema detectará apenas os restaurantes relevantes para o seu perfil. As duas principais características são idioma e nacionalidade. Isso permitirá que você envie sua inscrição apenas para os Restaurantes interessados.'
    },
    {
        q: 'Tenho que criar um perfil para encontrar um emprego?',
        t: 'Não é necessário. Você pode nos encaminhar seu currículo na seção de contato ou quando se candidatar a um emprego e um de nossos membros da equipe terá prazer em ajudá-lo. No entanto, recomendamos que você crie um perfil conosco. É um processo passo-a-passo muito simples e demora apenas alguns minutos do seu tempo. Essas informações nos fornecerão todos os detalhes necessários para ajudá-lo a encontrar o melhor emprego possível. Ao fazê-lo, permite-nos ir direto ao trabalho e reduzir o número de e-mails e chamadas frustrantes, indo e voltando normalmente durante o processo de recrutamento.'
    },
    {
        q: 'Posso postar trabalhos no seu site?',
        t: 'Você precisará criar uma página de negócios em nosso site ou se sua empresa já tiver uma página de negócios, você pode pedir ao administrador do site para fornecer seus detalhes de login.'
    },
    {
        q: 'O que acontece quando eu desativo meu perfil?',
        t: 'Permitimos aos nossos Chefes decidir quando querem que os seus perfis apareçam no nosso site. Você ainda poderá se candidatar a vagas para receber nossas ofertas de emprego semanais e usar seu perfil normalmente, mesmo que seu perfil tenha sido desativado. A única coisa que faz com que o seu perfil seja desativado restringe os restaurantes a serem capazes de ver o seu perfil ou de lhe enviar ofertas de emprego.'
    },
    {
        q: 'Por que posso apenas enviar mensagens para alguns restaurantes?',
        t: 'O nosso sistema só permite direcionar mensagens com nossos clientes premium.'
    },
    {
        q: 'Por que meu perfil ainda está em revisão?',
        t: 'Pode ser uma das duas razões: <ul><li>Ainda estamos lidando com seu aplicativo; Depois de criar seu perfil, por favor, deixe pelo menos 24 horas antes de entrar em contato com o Chefs Link.</li><li>Sua inscrição está incompleta: por favor, preencha o formulário e envie-o novamente.</li></ul>'
    },
    {
        q: 'Eu não quero que um restaurante específico veja meu perfil?',
        t: 'Você pode impedir que um restaurante veja seu perfil indo até o perfil do restaurante e clicando no botão "Mais" na barra de funções. Um menu drop-down lhe dará algumas opções para bloquear o restaurante.'
    },
    {
        q: 'Que restaurantes você trabalha?',
        t: 'Você pode encontrar todos os restaurantes com os quais trabalhamos em nossa homepage, em Ofertas de Emprego ou Perfis de Restaurantes.'
    },
    {
        q: 'Você me ajuda com acomodação?',
        t: 'Não, mas alguns restaurantes oferecem acomodação com o seu contrato.'
    },
    {
        q: 'Quanto vou ser pago?',
        t: 'Tudo dependerá da sua experiência de trabalho como Chef. Ao se juntar à nossa rede, dedicamos nosso tempo para combinar sua experiência com os melhores restaurantes possíveis em nossa rede. Nossa equipe também ficará em contato com você e gerenciará sua carreira à medida que você ganha mais experiência.'
    },
    {
        q: 'Você pode me mandar mais informações sobre o trabalho?',
        t: 'Estamos mais do que felizes em fazer isso. Por favor, deixe-nos saber quais são as suas preocupações ou quais outras informações você gostaria de nos fornecer e nós responderemos da melhor forma possível.'
    },
    {
        q: 'Eu não tenho experiência na cozinha. Isso é uma questão?',
        t: 'Lamento informá-lo, mas infelizmente não poderemos ajudá-lo neste momento. Nossos restaurantes exigem apenas chefs experientes ou qualificados.'
    },
]
