import React from 'react'
import {ActionCreators} from '../../Actions'
import { connect } from 'react-redux'
import LoginOverlay from '../Overlay/LoginOverlay'
import SignUpOverlay from '../Overlay/SignUpOverlay'
import ErrorOverlay from '../Overlay/ErrorOverlay'
import SuccessOverlay from '../Overlay/SuccessOverlay'
import ChefAboutOverlay from '../Overlay/ChefAboutOverlay'
import ChefWorkExperienceOverlay from '../Overlay/ChefWorkExperienceOverlay'
import ChefEducationOverlay from '../Overlay/ChefEducationOverlay'
import ChefLanguageOverlay from '../Overlay/ChefLanguageOverlay'
import ChefReferralOverlay from '../Overlay/ChefReferralOverlay'
import RestaurantAboutOverlay from '../Overlay/RestaurantAboutOverlay'
import RestaurantOpeningHoursOverlay from '../Overlay/RestaurantOpeningHoursOverlay'
import RestaurantChefOverlay from '../Overlay/RestaurantChefOverlay'
import RestaurantCoverOverlay from '../Overlay/RestaurantCoverOverlay'
import RestaurantAddressOverlay from '../Overlay/RestaurantAddressOverlay'
import RestaurantPhoneOverlay from '../Overlay/RestaurantPhoneOverlay'
import RestaurantSocialOverlay from '../Overlay/RestaurantSocialOverlay'
import RestaurantEmailOverlay from '../Overlay/RestaurantEmailOverlay'
import JobOfferOverlay from '../Overlay/JobOfferOverlay'
import ShareOverlay from '../Overlay/ShareOverlay'
import AcceptApplicationOverlay from '../Overlay/AcceptApplicationOverlay'
import MediaOverlay from '../Overlay/MediaOverlay'
import JobOfferGuruOverlay from '../Overlay/JobOfferGuruOverlay'
import ApplyOffersOverlay from '../Overlay/ApplyOffersOverlay'
import MessageOverlay from '../Overlay/MessageOverlay'
import AcceptStageOverlay from '../Overlay/AcceptStageOverlay'
import SettingsOverlay from '../Overlay/SettingsOverlay'
import SendCVOverlay from '../Overlay/SendCVOverlay'
import ResetPasswordOverlay from '../Overlay/ResetPasswordOverlay'
import MainProfileOverlay from '../Overlay/MainProfileOverlay'
import ChefInfoOverlay from '../Overlay/ChefInfoOverlay'


class Overlays extends React.Component {
    render() {
        if (!this.props.showOverlayWithInfo) {
            return null
        }
        let content = null
        switch(this.props.showOverlayWithInfo.name) {
            case 'login':
                content = <LoginOverlay key="login" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'signup':
                content = <SignUpOverlay key="signup" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'error':
                content = <ErrorOverlay key="error" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'success':
                content = <SuccessOverlay key="success" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'chefabout':
                content = <ChefAboutOverlay key="chefabout" data={this.props.showOverlayWithInfo.data} overlayName={this.props.showOverlayWithInfo.name}/>
                break;
            case 'settings':
                content = <SettingsOverlay key="settings" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'chefinfo':
                content = <ChefInfoOverlay key="chefinfo" data={this.props.showOverlayWithInfo.data} overlayName={this.props.showOverlayWithInfo.name}/>
                break;
            case 'chefworkexperience':
                content = <ChefWorkExperienceOverlay key="chefworkexperience" data={this.props.showOverlayWithInfo.data} overlayName={this.props.showOverlayWithInfo.name}/>
                break;
            case 'chefeducation':
                content = <ChefEducationOverlay key="chefeducation" data={this.props.showOverlayWithInfo.data} overlayName={this.props.showOverlayWithInfo.name}/>
                break;
            case 'cheflanguage':
                content = <ChefLanguageOverlay key="cheflanguage" data={this.props.showOverlayWithInfo.data} overlayName={this.props.showOverlayWithInfo.name}/>
                break;
            case 'chefreferral':
                content = <ChefReferralOverlay key="chefreferral" data={this.props.showOverlayWithInfo.data} overlayName={this.props.showOverlayWithInfo.name}/>
                break;
            case 'restaurantabout':
                content = <RestaurantAboutOverlay key="restaurantabout" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantopeninghours':
                content = <RestaurantOpeningHoursOverlay key="restaurantopeninghours" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantchef':
                content = <RestaurantChefOverlay key="restaurantchef" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantcover':
                content = <RestaurantCoverOverlay key="restaurantcover" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantaddress':
                content = <RestaurantAddressOverlay key="restaurantaddress" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantsocial':
                content = <RestaurantSocialOverlay key="restaurantsocial" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantphone':
                content = <RestaurantPhoneOverlay key="restaurantphone" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'restaurantemail':
                content = <RestaurantEmailOverlay key="restaurantemail" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'joboffers':
                content = <JobOfferOverlay key="joboffers" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'share':
                content = <ShareOverlay key="share" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'acceptapplication':
                content = <AcceptApplicationOverlay key="acceptapplication" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'media':
                content = <MediaOverlay key="media" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'jobofferguru':
                content = <JobOfferGuruOverlay key="jobofferguru" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'applyoffers':
                content = <ApplyOffersOverlay key="applyoffers" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'message':
                content = <MessageOverlay key="message" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'acceptstage':
                content = <AcceptStageOverlay key="acceptstage" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'sendcv':
                content = <SendCVOverlay key="sendcv" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'resetpassword':
                content = <ResetPasswordOverlay key="resetpassword" data={this.props.showOverlayWithInfo.data}/>
                break;
            case 'mainprofile':
                content = <MainProfileOverlay key="mainprofile" data={this.props.showOverlayWithInfo.data}/>
                break;
            default:
                content = null
                break;
        }
        return content
    }
}

const mapStateToProps = state => {
    return {
        showOverlayWithInfo: state.showOverlay
    }
}
export default connect(mapStateToProps, ActionCreators)(Overlays)