import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import Textarea from '../../Components/Input/Textarea'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import {chatStartConversation, chatSendMessage} from '../../Services/Chat/chat'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate';

class MessageOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            rememberMe: false,
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'send_message')} className={this.props.className}>
                <div className="col-group form">
                    <div className="col-mb-12">
                        <Textarea
                            lang={lang}
                            name="message"
                            error={this.state.errors}
                            value={this.state.message}
                            minLength={4}
                            onChange={(v) => {
                                this.setState({message: v})
                            }}
                            placeholder={translate(lang, 'write_new_message_here')}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        <Btn text={translate(lang, 'send').toUpperCase()} loading={this.props.loading} callback={() => {
                            this.action()
                        }} />
                    </div>
                </div>
            </Overlay>
        )
    }
    action() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'message', minLength: 4},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })
            chatStartConversation({cid: this.props.data.cid, rid: this.props.data.rid, lang}).then((res) => {
                if (res.data.success) {
                    chatSendMessage({ conversation: res.data.data.id, message: this.state.message }).then((res) => {
                        this.setState({loading:false})
                        if (res.data.success) {
                            this.setState({message: ''})
                            this.props.showOverlay('success', {message: translate(lang, 'message_sent')})
                        }
                    })
                } else {
                    this.setState({loading:false})
                    this.props.showOverlay('error', {message: res.data.message})
                }
            })
        }

    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(MessageOverlay))
