import React, {Component} from 'react'
import {listUserNotifications} from '../../Services/Notifications/notifications'
import Btn from '../../Components/Btn/Btn'
import { translate } from '../../Translations/translate'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'

class Notifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            items: [],
            page: 0,
            hasMorePages: false,
        }
        
    }
    componentDidMount() {
        this.loadItems()
    }
    loadItems(done) {
        const { lang } = this.props.match.params
        listUserNotifications({page: this.state.page, qty: 10, lang}).then((res) => {
            if (this.state.loading) {
                this.setState({loading: false})
            }
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                let items = this.state.items
                items.push(...res.data.data)
                this.setState({ items: items, page: this.state.page+1, hasMorePages: (res.data.data.length > 0) })
            }
        })
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <div className="component_Notifications">
                <div className="wrapper">
                    <div className="content">
                        {this.state.items.map((i, index) => {
                            return this.renderItem(i)
                        })}
                        {!this.state.loading && this.state.hasMorePages ? <Btn loading={this.state.loading} text={translate(lang, 'load_more_items')} callback={this.loadItems.bind(this)}/> : null}
                    </div>
                </div>
            </div>
        )
    }
    renderItem(item) {
        const { lang } = this.props.match.params
        const cleanUrl = item.payload.link.replace('https://www.chefslink.com/', `/${lang}/`)
        return (
            <div key={item.id} className="item">
                <a href={cleanUrl}>
                    <LazyImage src={item.payload.image && item.payload.image.sizes && item.payload.image.sizes.square ? item.payload.image.sizes.square.url : 'http://via.placeholder.com/40x40/eee'} alt={item.payload.title}/>
                    <span>
                        {item.payload.title}
                    </span>
                </a>
            </div>
        )
    }
};



const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Notifications))