import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Input from '../../Components/Input/Input'
import LocationInput from '../../Components/Input/LocationInput'
import Overlay from './Overlay'
import Select from 'react-select'
import Textarea from '../../Components/Input/Textarea'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefEditAbout} from '../../Services/Chef/chef'
import {positions, countries, cuisines} from '../../Lists/lists'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import { refreshUser } from '../../Services/Access/access'
import Cookies from 'js-cookie'
import ChefAboutForm from "../_NEW/ChefAboutForm"
var find = require('lodash/find')

class ChefAboutOverlay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errors: [],
            loading: (Cookies.get('token') !== undefined),
            lang: props.match.params.lang,
            changedContents: false,
        }

        this.descriptionTextarea = React.createRef()
    }

    parseDataToState(data) {

        const { lang } = this.state

        let nationalities = []
        if (data.nationalities) {
            data.nationalities.forEach((n) => {
                let item = find(countries[lang], {value: n})
                if (item) {
                    nationalities.push(item)
                }
            })
        }

        let workvisas = []
        if (data.workvisas) {
            data.workvisas.forEach((n) => {
                let item = find(countries[lang], {value: n})
                if (item) {
                    workvisas.push(item)
                }
            })
        }

        let chefCuisines = []
        if (data.cuisine) {
            data.cuisine.forEach((n) => {
                let item = find(cuisines[lang], {value: parseInt(n)})
                if (item) {
                    chefCuisines.push(item)
                }
            })
        }

        this.setState({
            name: data.name,
            description: data.description,
            position: data.position,
            location: data.location,
            dob: data.dob,
            nationalities: nationalities,
            workvisas: workvisas,
            cuisines: chefCuisines,
        })
    }

    componentDidMount() {
        this.parseDataToState(this.props.data)
        this.shouldPerformRefreshUser()
        this.setState({loading: false})
    }

    shouldPerformRefreshUser() {
        this.performRefreshUser()
    }

    languageCallback(lang) {
        const {
            changedContents,
        } = this.state

        // if (!changedContents) {
            this.setState({ lang }, () => this.shouldPerformRefreshUser())
            return true
        // } else {
        //     const hasErrors = this.validateForm(false)
        //     this.save(false, () => {
        //         this.setState({ lang }, () => this.shouldPerformRefreshUser())
        //     })
        //     return hasErrors
        // }
    }

    performRefreshUser() {
        const { lang } = this.state
        const token = Cookies.get('token')
        refreshUser({ token: token, lang }).then((res) => {
            const { user } = res.data.data
            const description = user.profile.description
            this.setState({ loading: false, description: description }, () => {
            // this.descriptionTextarea.setContent(description)
        })
        })
    }

    validateForm(updateState = true){
        let items = [
            {field: 'name' , minLength: 4},
            {field: 'about', minLength: 4},
            {field: 'position'},
        ];
        let errors = validate(items, this.state)
        if (updateState) this.setState({ errors })
        return errors.length === 0
    }

    handleChange(name, value) {
        this.setState({ [name]: value, changedContents: true })
    }

    save(closeModelAfterSave = false, callback = () => {}) {

        const { lang } = this.state

        const isValid = this.validateForm()

        if (isValid) {

            this.setState({ loading: true })

            let nationalities = []
            this.state.nationalities.forEach((n) => {
                nationalities.push(n.value)
            })
            let workvisas = []
            this.state.workvisas.forEach((n) => {
                workvisas.push(n.value)
            })
            let cuisines = []
            this.state.cuisines.forEach((n) => {
                cuisines.push(n.value)
            })

            let data = {
                name: this.state.name,
                position: this.state.position,
                description: this.state.about,
                dob: this.state.dob,
                location: JSON.stringify(this.state.location),
                nationalities: nationalities.join(','),
                visas: workvisas.join(','),
                cuisine: cuisines.join(','),
                lang: lang
            }
            chefEditAbout(data).then((res) => {
                this.setState({ loading: false, changedContents: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                } else {

                    const { data } = res.data
                    this.parseDataToState(data.user)
                    this.props.setUser(data.user)
                    if (closeModelAfterSave){
                        this.props.showOverlay(null)
                    } else {
                        if (callback) callback()
                    }
                }
            }).catch((e) => {
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }

    render() {
        let { lang } = this.props.match.params
        return (
            <Overlay
                title={translate(lang, 'about')}
                className={this.props.className}
                languageTranslator={this.props.data.description === '' || this.props.data.description === '<span><br></span>' || this.props.data.profileNav ? false : true}
                languageCallback={this.languageCallback.bind(this)}
                isNew
                overlayName={this.props.overlayName}
                profileNav={this.props.data.profileNav}
                subtitle={translate(lang, 'add_description')}
                >
                    <ChefAboutForm
                        props={this.props}
                        textTranslation={this.state.description}
                        textLang={this.state.lang}
                    />

                {/* <div className="col-group form">
                    <div className="col-mb-12">
                        <Input
                            label={translate(lang, 'name')}
                            name="name"
                            value={this.state.name}
                            error={this.state.errors}
                            onChange={v => this.handleChange('name', v)}
                            />
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <label htmlFor="type">{translate(lang, 'position')}</label>
                                <Select
                                    placeholder=""
                                    name="position"
                                    value={this.state.position}
                                    className={this.state.errors.indexOf('position') !== -1 ? 'error' : null}
                                    onChange={v => this.handleChange('position', v.value)}
                                    options={positions[lang]}
                                    />
                            </div>
                            <div className="col-mb-12 col-4">
                                <LocationInput
                                    label={translate(lang, 'location')}
                                    name="location"
                                    error={this.state.errors}
                                    value={this.state.location}
                                    onChange={v => this.handleChange('location', v)}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <Input
                                    label={translate(lang, 'date_of_birth')}
                                    name="dob"
                                    error={this.state.errors}
                                    type="date"
                                    value={this.state.dob}
                                    onChange={v => this.handleChange('dob', v)}
                                    />
                            </div>
                            <div className="col-mb-12 col-6">
                                <label htmlFor="nationalities">{translate(lang, 'nationalities')}</label>
                                <Select
                                    multi
                                    placeholder=""
                                    name="nationalities"
                                    value={this.state.nationalities}
                                    className={this.state.errors.indexOf('nationalities') !== -1 ? 'error' : null}
                                    onChange={v => this.handleChange('nationalities', v)}
                                    options={countries[lang]}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <div className="col-group no-margin">
                            <div className="col-mb-12 col-6">
                                <label htmlFor="workvisas">{translate(lang, 'work_visas')}</label>
                                <Select
                                    multi
                                    placeholder=""
                                    name="workvisas"
                                    value={this.state.workvisas}
                                    className={this.state.errors.indexOf('workvisas') !== -1 ? 'error' : null}
                                    onChange={v => this.handleChange('workvisas', v)}
                                    options={countries[lang]}
                                    />
                            </div>
                            <div className="col-mb-12 col-6">
                                <label htmlFor="cuisines">{translate(lang, 'cuisines')}</label>
                                <Select
                                    multi
                                    placeholder=""
                                    name="cuisines"
                                    value={this.state.cuisines}
                                    className={this.state.errors.indexOf('cuisines') !== -1 ? 'error' : null}
                                    onChange={v => this.handleChange('cuisines', v)}
                                    options={cuisines[lang]}
                                    />
                            </div>
                        </div>
                    </div>
                    <div className="col-mb-12">
                        <Textarea
                            ref={(ref) => this.descriptionTextarea = ref}
                            lang={lang}
                            label={translate(lang, 'about')}
                            name="about"
                            error={this.state.errors}
                            type="text"
                            value={this.state.description}
                            minLength={4}
                            onChange={v => this.handleChange('about', v)}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save(true)
                            }} />
                        </div>
                    </div>
                </div> */}
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefAboutOverlay))
