import {apiFetch} from '../../Config/api'

export function accessLogin(data) {
    return apiFetch('account/login', data)
}
export function accessSignup(data) {
    return apiFetch('account/register', data)
}
export function accessLogout(lang) {
    return apiFetch('account/logout', lang)
}
export function refreshUser(data) {
    return apiFetch('account/user', data)
}
export function resetPassword(data) {
    return apiFetch('account/resetpassword', data)
}
export function saveEmail(data) {
    return apiFetch('account/preregister', data)
}
