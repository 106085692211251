import {apiFetch} from '../../Config/api'

export function searchOffers(data) {
    return apiFetch('search/offers', data)
}
export function applyOffers(data) {
    return apiFetch('offer/subscribe/', data)
}
// export function sendOffers(data) {
//     return apiFetch('link/tochef/', data)
// }
export function sendOffers(data) {
    return apiFetch('offer/subscribe/', data)
}
export function acceptSuggestedOffer(data) {
    return apiFetch('offer/acceptsugestedoffer', data)
}
export function saveOffers(data) {
    data.saved = 1
    return apiFetch('offer/subscribe/', data)
}
export function viewOffer(data) {
    return apiFetch('api/offers/show/', data)
}
export function viewSelectedOffers(data) {
    return apiFetch('api/offers/in/', data)
}
export function updateOffer(data) {
    return apiFetch('offer/manage/edit', data)
}
export function offerGuru(data) {
    return apiFetch('offer/guru', data)
}
export function relatedOffers(data) {
    return apiFetch('offer/related', data)
}
export function toggleOffer(data) {
    return apiFetch('offer/toggle', data)
}
export function deleteOffer(data) {
    return apiFetch('offer/manage/edit', data)
}
export function deleteApplicant(data) {
    return apiFetch('offer/applicants/delete', data)
}
export function deleteApplication(data) {
    return apiFetch('offer/chef/delete', data)
}
export function stagesCalendar(data) {
    return apiFetch('offer/calendar', data)
}
