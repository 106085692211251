import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import EditBtn from '../../Components/EditBtn/EditBtn'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import ShowcaseImages from '../../Components/ShowcaseImages/ShowcaseImages'
import Switch from '../../Components/Switch/Switch'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findById} from '../../Lib/findById'
import {findOption} from '../../Lib/findOption'
import {listItems, listBtn2} from '../RestaurantProfile/RestaurantProfileTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {restaurantToggle} from '../../Services/Restaurant/restaurant'
import {terms} from '../../Lists/lists'
import {viewOffer} from '../../Services/Offers/offers'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'


class RestaurantProfileViewOffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            item: null,
            items: [],
            errors: []
        }
    }
    _initState() {
        let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
        this.setState({
            profile: profile,
        }, this.loadItems.bind(this))
    }
    loadItems() {
        const { lang, oid } = this.props.match.params
        this.setState({loading: true})
        viewOffer({id: oid, lang}).then((res) => {
            if (res.data.success) {
                this.setState({ item: res.data.data, loading: false })
            }
        })
    }
    showJobsOverlay() {
        const { lang } = this.props.match.params
        this.props.showOverlay('joboffers', {rid: this.state.profile.id}, () => {
            this.props.history.push(`/${lang}/restaurant/profile/offers/` + this.state.profile.id)
        })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'restaurant')
    }

     

    render() {
        let className = 'scene_RestaurantProfileViewOffer'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.item) {
            let offer = this.state.item.offer
            meta = {
                title: offer.name + ' | ' + this.state.profile.name,
                metas: [
                    {
                        name: 'og:image',
                        value: offer.images.image1 ? offer.images.image1.sizes.showcase.url : null
                    }
                ]
            }
        }
        return (
            <Main
                match={this.props.match}
                meta={meta}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.state.loading || this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (this.state.loading) {
            return null
        }
        return (
            <ImageHeader
                left={this.state.profile.images.logo ? this.state.profile.images.logo.sizes.square.url : '/static/images/logo_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="logo" rid={this.state.profile.id} />}
                right={this.state.profile.images.header ? this.state.profile.images.header.sizes.header.url : '/static/images/empty_600_600.png'}
                rightBtn={<FakeUploadBtn type="profile" name="header" rid={this.state.profile.id} />}
                alt={this.state.profile.name}
                />
        )
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return null
        }

        if (this.props.deviceScreen === 'mobile') {
            return (
                <nav/>
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'restaurant_profiles')}</span> / {translate(lang, 'job_description')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.state.loading) {
            return null
        }
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.state.loading) {
            return null
        }
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.state.profile.name}</h3>
                        <span>{readableLocation(this.state.profile.location)}</span>
                        <Switch active={this.state.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                restaurantToggle({rid: this.state.profile.id, lang}).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return null
        }

        let item = this.state.item
        let description = item.offer.description
        let requirements = item.offer.requirements

        return (
            <div>
                <div className="blocks">
                    <div className="block">
                        <EditBtn text={translate(lang, 'edit_offer')} callback={()=>{
                            this.props.showOverlay('joboffers', {rid: this.state.profile.id, offer: this.state.item.offer}, () => {
                                this.loadItems()
                            })
                        }} />
                    </div>
                    <div className="block" data-block="about">
                        <h4><span>{item.offer.name}</span></h4>
                        {this.renderSummary()}
                        <div className="description" dangerouslySetInnerHTML={{
                                __html: description
                            }}></div>
                        <ShowcaseImages
                            item1={
                                <div><LazyImage src={item.restaurant.images.image1 ? item.restaurant.images.image1.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'} alt={item.restaurant.name}/></div>
                            }
                            item2={
                                <div><LazyImage src={item.restaurant.images.image2 ? item.restaurant.images.image2.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'} alt={item.restaurant.name}/></div>
                            }
                            item3={
                                <div><LazyImage src={item.restaurant.images.image3 ? item.restaurant.images.image3.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'} alt={item.restaurant.name}/></div>
                            }
                            />
                        <div className="requirements">
                            <h4>{translate(lang, 'chef_requirements')}</h4>
                            <div className="description" dangerouslySetInnerHTML={{
                                    __html: requirements
                                }}></div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <span>{moment(item.offer.created).format('D MMM[.] YYYY')}</span>
                    {this.renderShareOptions()}
                </div>
            </div>
        )
    }
    renderSummary() {
        const { lang } = this.props.match.params
        let item = this.state.item
        return (
            <div className="col-group summary">
                <div className="col-mb-6 col-3">
                    <span>{translate(lang, 'term')} {findOption(item.offer.term, terms[lang])}</span>
                </div>
                <div className="col-mb-6 col-3">
                    <span>{translate(lang, 'weekly_hours')}: {item.offer.hours}</span>
                </div>
                <div className="col-mb-6 col-3">
                    <span>{translate(lang, 'holidays')} {item.offer.holidays}</span>
                </div>
                <div className="col-mb-6 col-3">
                    <span>{translate(lang, 'days_off')} {item.offer.daysoff}</span>
                </div>
            </div>
        )
    }
    renderShareOptions() {
        return (
            <div className="share">
                {this.renderShare()}
            </div>
        )
    }
    renderMessage() {
        const { lang } = this.props.match.params
        let text = this.props.deviceScreen === 'mobile' ? translate(lang, 'mess').toUpperCase() : translate(lang, 'message').toUpperCase()
        return (
            <Btn text={text} className="text" post="message_icn" callback={() => {

            }} />
        )
    }
    renderShare() {
        const { lang } = this.props.match.params
        let item = this.state.item
        let url = `/${lang}/restaurant/view/offer/` + item.restaurant.id + '/' + item.restaurant.slug + '/' + item.offer.id + '/' + item.offer.slug
        let image = this.state.item.offer.images.image1 && this.state.item.offer.images.image1.sizes && this.state.item.offer.images.image1.sizes.share ? this.state.item.offer.images.image1.sizes.share.url : null
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : translate(lang, 'share').toUpperCase()} className="text" post="share_icn" callback={() => {
                this.props.showOverlay('share', {url: url, image: image})
            }} />
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantProfileViewOffer))
