import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import MonthView from 'react-calendar/dist/entry.nostyle'
import Overlay from './Overlay'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {rescheduleStaging, rejectAcceptedStage} from '../../Services/Stages/stages'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class AcceptStageOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            item: this.props.data.item,
            message: this.props.data.item.description || '<span></span>',
            errors: [],
            value: null
        }
    }
    render() {
        const { lang } = this.props.match.params
        let title = translate(lang, 'application_form')
        return (
            <Overlay title={title} className="accept-application apply-offers">
                <div className="col-group main-content">
                    <div className="col-mb-12 q">
                        <p>{translate(lang, 'select_new_trial_date')}</p>
                    </div>
                    <div className="col-mb-12">
                        <MonthView
                            minDate={new Date()}
                            activeStartDate={null}
                            value={this.state.value}
                            onClickDay={(date) => {
                                this.setState({value: date})
                            }}
                            locale="en-En"
                            view="month"
                            />
                    </div>
                </div>
                <div className="col-group form">
                    <div className="col-mb-12 actions multiple">
                        <div className="btns">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'send').toUpperCase()} className={this.state.value !== null ? '' : 'inverted'} disabled={(this.state.stage > 0 || (this.state.stage === -1 && this.state.value !== null))} loading={this.state.loading} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    save() {
        const { lang } = this.props.match.params
        if (this.state.value !== null) {
            this.setState({ loading: true })
            let params = {
                id: this.state.item.id,
                replacedate: moment(this.state.value).format('YYYY-MM-DD'),
                description: this.state.item.description,
                start: this.state.item.start,
                end: this.state.item.end,
                type: parseInt(this.state.item.typeid),
                lang,
            }
            if (this.state.item.status === 'Accepted') {
                rejectAcceptedStage({ id: this.state.item.id})
            }
            rescheduleStaging(params).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                this.props.showOverlay(null)
                this.props.data.callback()
            })
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(AcceptStageOverlay))
