import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import DateSectionTitle from '../../Components/DateSectionTitle/DateSectionTitle'
import FakeUploadBtn from '../../Components/FakeUploadBtn/FakeUploadBtn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import InfoRow from '../../Components/InfoRow/InfoRow'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Switch from '../../Components/Switch/Switch'
import moment from 'moment'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chatListConversations, blockConversation, unblockConversation, deleteConversation} from '../../Services/Chat/chat'
import {chefToggle} from '../../Services/Chef/chef'
import {findOption} from '../../Lib/findOption'
import {listItems, listBtn2} from '../ChefProfile/ChefProfileTopNavMenuItems'
import {positions} from '../../Lists/lists'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import Loader from '../../Components/_NEW/Loader'
var groupBy = require('lodash/groupBy')


class ChefProfileMessages extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false,
            loadingSwitch: false,
            rawItems: [],
            items: [],
            page: 0,
            hasMorePages: true,
            loading: true,
            firstLoad: true,
        }
    }
    _initState() {
        this.loadItems()
    }
    loadItemsFromStart() {
        this.setState({page: 0, items: [], rawItems: [], hasMorePages: true, loading: true}, () => {
            this.loadItems()
        })
    }
    loadItems(done) {
        const qty = 10
        chatListConversations({page: this.state.page, qty: qty}).then((res) => {
            if (done !== undefined) {
                done()
            }
            if (res.data.success) {
                const { list } = res.data.data
                delete list.messages
                let its = []
                Object.keys(list).forEach((k) => {
                    its.push(list[k])
                })
                let rawItems = this.state.rawItems
                rawItems.push(...its)
                let items = groupBy(rawItems, (o) => {
                    return moment(o.messages.length > 0 ? new Date(+o.messages[0].created*1000) : o.created).format('YYYY-MM-DD')
                })
                this.setState({ items: items, rawItems: rawItems, hasMorePages: (list.length >= qty), loading: false, firstLoad: false })
            }
        })
    }
    auth() {
        return (this.props.isLogged && this.props.userType === 'chef')
    }
    block(status, itemId, blockerId) {
        if (parseInt(status) !== 0 && blockerId !== this.props.user.id) {
            blockConversation({ conversation: itemId }).then((res) => {
                if (res.data.success) {
                    this.loadItemsFromStart()
                }
            })
        }
    }
    unblock(status, itemId, blockerId) {
        if (parseInt(status) === 0 && blockerId === this.props.user.id) {
            unblockConversation({ conversation: itemId }).then((res) => {
                if (res.data.success) {
                    this.loadItemsFromStart()
                }
            })
        }
    }
    delete(itemId) {
        const { lang } = this.props.match.params
        const result = window.confirm(translate(lang, 'delete_conversation'))
        if (result) {
            deleteConversation({ conversation: itemId }).then((res) => {
                if(res.data.success) {
                    this.loadItemsFromStart()
                }
            })
        } else {
            return
        }
    }




    render() {
        let className = 'scene_ChefProfileMessages'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        return (
            <Main
                match={this.props.match}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                onBottomReached={(done) => {
                    if (this.state.hasMorePages && !this.state.firstLoad) {
                        this.setState({page:this.state.page+1, loading: true}, () => {
                            this.loadItems(done)
                        })
                    }
                }}
                checkAuth={this.auth.bind(this)}
                authSuccess={this._initState.bind(this)}
                history={this.props.history}
                ready
                >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return (
            <ImageHeader
                left={this.props.user.profile.images.profile ? this.props.user.profile.images.profile.sizes.square.url : '/static/images/empty_600_600.png'}
                leftBtn={<FakeUploadBtn type="profile" name="image" />}
                right={this.props.user.profile.images.header ? this.props.user.profile.images.header.sizes.header.url : null}
                rightBtn={<FakeUploadBtn type="profile" name="header" />}
                alt={this.props.user.profile.name}
                />
        )
    }
    _showShare() {
        const { lang } = this.props.match.params
        let profile = this.props.user.profile
        let url = `/${lang}/chef/view/` + profile.id + '/' + profile.slug
        let image = this.props.user.profile.images.header && this.props.user.profile.images.header.sizes && this.props.user.profile.images.header.sizes.share ? this.props.user.profile.images.header.sizes.share.url : null
        this.props.showOverlay('share', {title: profile.name, url: url, image:image})
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        let items = listItems(this.props.isLogged, this.props.userType, this.state, this.props.user, lang)
        let btn2 = listBtn2(lang, this.state.profile, this.state, this._showShare.bind(this))
        if (this.props.deviceScreen === 'mobile') {
            return (
                null
            )
        } else {
            return (
                <div>
                    <h4><span>{translate(lang, 'my_profile')}</span> / {translate(lang, 'messages')}</h4>
                </div>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h3>{this.props.user.profile.name}</h3>
                        <span>{findOption(this.props.user.profile.position, positions[lang])}</span>
                        <Switch active={this.props.user.profile.active} callback={() => {
                            if (!this.state.loadingSwitch) {
                                this.setState({ loadingSwitch: true })
                                chefToggle(lang).then((res) => {
                                    this.setState({ loadingSwitch: false })
                                    let data = res.data.data
                                    this.props.setUser(data.user)
                                }).catch((e) => {
                                    this.setState({ loadingSwitch: false })
                                })
                            }
                        }} />
                    </div>
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        let items = []
        let k = 0
        Object.keys(this.state.items).forEach((date) => {
            let subitems = []
            this.state.items[date].forEach((m) => {
                let url = `/${lang}/chef/profile/messages/view/` + m.id
                let lastMessage = ''
                let newMessages = 0
                if (m.messages.length > 0) {
                    lastMessage = m.messages[0].message
                    if (this.props.deviceScreen !== 'mobile' && lastMessage.length > 90) {
                        lastMessage = m.messages[0].message.substring(0, 90) + "..."
                    }
                    if (this.props.deviceScreen === 'mobile' && lastMessage.length > 35) {
                        lastMessage = m.messages[0].message.substring(0, 35) + "..."
                    }
                    m.messages.forEach(mess => {
                        if (parseInt(mess.status) === 0 && mess.sender !== this.props.user.id) {
                            newMessages++
                        }
                    })
                }
                subitems.push(
                    <InfoRow
                        key={m.id}
                        newMessages={newMessages}
                        src={m.restaurant.images.logo.sizes ? m.restaurant.images.logo.sizes.square.url : null}
                        title={'<strong>'+ m.restaurant.name +'</strong>'}
                        subtitle={lastMessage}
                        className="marked"
                        url={url}
                        contentButton={true}
                        content={
                            <div className="buttons">
                                <Btn
                                    className='no-line'
                                    text={parseInt(m.status) === 0 && m.blocker === this.props.user.id ? translate(lang, 'unblock').toUpperCase() : translate(lang, 'block').toUpperCase()}
                                    callback={parseInt(m.status) === 0 && m.blocker === this.props.user.id ? this.unblock.bind(this, m.status, m.id, m.blocker) : this.block.bind(this, m.status, m.id, m.blocker)}
                                />
                                <Btn
                                    className='no-line red'
                                    text={translate(lang, 'delete').toUpperCase()}
                                    callback={this.delete.bind(this, m.id)}
                                />
                            </div>
                        }
                        blocked={parseInt(m.status) === 0}
                        />
                )
            })
            const emptyConversation = this.state.items[date].find(item => item.messages.length === 0)
            if (!this.state.items[date].includes(emptyConversation)){
                items.push(
                    <div key={k}>
                        <DateSectionTitle title={moment(date).format('MMMM Do')} />
                        <div className="info-rows">{subitems}</div>
                    </div>
                )
                k++
            }
        })
        return (
            <div>
                <h4>{translate(lang, 'messages_overview')}</h4>
                <div className="items">
                    {items.length === 0 ? translate(lang, 'no_messages') : items}
                </div>
                {this.state.loading && <Loader />}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefProfileMessages))
