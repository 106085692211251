import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { translate } from '../../../Translations/translate'
import { connect } from 'react-redux'
import {ActionCreators} from '../../../Actions'
import { withRouter } from 'react-router'


class OfferSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

     

    render() {
        const { lang } = this.props.match.params
        let restaurant = this.props.profile
        let offer = this.props.offer
        if (offer){
            return (
                <div className="component_OfferSummary">
                    <h5><Link to={`/${lang}/restaurant/profile/home/` + restaurant.id}>{restaurant.name}</Link></h5>
                    <p><Link to={`/${lang}/restaurant/profile/offers/` + restaurant.id + '/view/' + offer.id}>{offer.name}</Link></p>
                </div>
            )
        } else {            
            return (
                <p>{translate(lang, 'offer_not_available')}</p>
            )
        }
    }
};


const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(OfferSummary))
