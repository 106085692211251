import React, {Component} from 'react'

export default class Input extends Component {
    constructor(props) {
        super(props) 
        this.state = {

        }
    }

    hasError() {
        return (this.props.error.indexOf(this.props.name) !== -1)
    }
    render() {
        const errorMessage = this.props.errorMessage && this.hasError() ? this.props.errorMessage.find(e => e.field === this.props.name).message : null

        return (
            <div>
                <label className="inputLabel" htmlFor={this.props.name}>{this.props.label}</label>
                <input
                    minLength={this.props.minLength ? this.props.minLength : 0}
                    maxLength={255}
                    className={`${this.props.className} ${this.hasError() ? 'input error' :'input'}`}
                    type={this.props.type ? this.props.type : 'text'}
                    name={this.props.name}
                    value={this.props.value ? this.props.value : ''}
                    id={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={(e)=>{
                        this.props.onChange(e.target.value)
                    }}
                    />
                    {this.hasError() && errorMessage && <div className="errorMessage">{errorMessage}</div>}
            </div>
        )
    }
};
