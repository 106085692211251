import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'
import {translate} from '../../Translations/translate'

function getMetaContent(name){
    let content = ''
    let els = document.querySelectorAll('meta')
    els.forEach((i, index) => {
        if (i.getAttribute("property") === name) {
            content = i.getAttribute('content')
        }
    })
    return content
}

class ShareOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    loadFbLoginApi() {

        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : '2013532508958118',
                cookie     : true,  // enable cookies to allow the server to access
                // the session
                xfbml      : false,  // parse social plugins on this page
                version    : 'v2.5' // use version 2.1
            });
        };
          // Load the SDK asynchronously
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }

    componentDidMount() {
        this.loadFbLoginApi();
    }

    render() {
        const { lang } = this.props.match.params
        let title = this.props.data && this.props.data.title ? this.props.data.title : document.title
        let image = this.props.data && this.props.data.image ? this.props.data.image : getMetaContent('og:image')
        let currentUrl = window.location.href.replace(window.location.origin, "")
        let url = this.props.data && this.props.data.url ? this.props.data.url : currentUrl
        let urlSearch = this.props.data && this.props.data.urlSearch ? `?${this.props.data.urlSearch}` : ''

        if (image.indexOf('http://via.placeholder.com/') > -1) {
            image = ''
        }
        if (image.indexOf('https://www.chefslink.com/e/assets/') > -1) {
            image = image.replace('https://www.chefslink.com/e/assets/', '')
        }

        let finalUrl = 'https://www.chefslink.com/share.php?' + url + (url.includes(urlSearch) ? '' : urlSearch) + '___' + encodeURI(title) + '___' + encodeURI(image)
        let finalUrlNoEncoding = 'https://www.chefslink.com/share.php?' + url + (url.includes(urlSearch) ? '' : urlSearch) + '___' + encodeURI(title) + '___' + image
        let emailUrl = 'https://www.chefslink.com/' + url.replace(/^\/+/g, '') + (url.includes(urlSearch) ? '' : urlSearch.replace(/^\/+/g, ''))

        return (
            <Overlay title={translate(lang, 'share_on')} className="share-overlay">
                <div className="col-group">
                    <div className="col-mb-12 ">
                        <div className="input">
                            <input ref={(ref) => this._input = ref} type="text" value={finalUrl} onChange={()=>{}}/>
                            <Btn text={translate(lang, 'copy_link').toUpperCase()} callback={(e) => {
                                this._input.select();
                                document.execCommand('copy');
                            }} />
                        </div>
                    </div>
                </div>
                <div className="col-group">
                    <div className="col-mb-12 btns">
                        <Btn pre="fb_icn" className="facebook" callback={() => {
                            window.FB.ui({
                                method: 'feed',
                                link: finalUrlNoEncoding,
                                caption: title
                            }, function(response){});
                        }} />
                        <Btn pre="tw_icn" className="twitter" callback={() => {
                            window.open('https://twitter.com/intent/tweet?url=' + encodeURI(finalUrl) + '&text=' + encodeURI(title))
                        }} />
                        <Btn pre="in_icn" className="linkedin" callback={() => {
                            window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURI(finalUrl) + '&title=' + encodeURI(title))
                        }} />
                        <Btn pre="mail_icn" className="mail" callback={() => {
                            window.open('mailto:?subject=' + encodeURI(title) + '&body=' + encodeURI(emailUrl))
                        }} />
                    </div>
                </div>
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ShareOverlay))
