
import React, {Component} from 'react'
import { Route, Redirect, Switch } from 'react-router'
import { CSSTransitionGroup } from 'react-transition-group' // ES6
import ChefFaqs from '../Scenes/ChefFaqs/ChefFaqs'
import ChefProfile from '../Scenes/ChefProfile/ChefProfile'
import ChefProfileApplicationsPending from '../Scenes/ChefProfileApplications/ChefProfileApplicationsPending'
import ChefProfileApplicationsReview from '../Scenes/ChefProfileApplications/ChefProfileApplicationsReview'
import ChefProfileApplicationsAccepted from '../Scenes/ChefProfileApplications/ChefProfileApplicationsAccepted'
import ChefProfileApplicationsRejected from '../Scenes/ChefProfileApplications/ChefProfileApplicationsRejected'
import ChefProfileCalendar from '../Scenes/ChefProfileCalendar/ChefProfileCalendar'
import ChefProfileMessageDetail from '../Scenes/ChefProfileMessageDetail/ChefProfileMessageDetail'
import ChefProfileMessages from '../Scenes/ChefProfileMessages/ChefProfileMessages'
import ChefProfileSaved from '../Scenes/ChefProfileSaved/ChefProfileSaved'
import ChefServices from '../Scenes/ChefServices/ChefServices'
import ChefView from '../Scenes/ChefView/ChefView'
import Contact from '../Scenes/Contact/Contact'
import FindChefs from '../Scenes/FindChefs/FindChefs'
import FindRestaurants from '../Scenes/FindRestaurants/FindRestaurants'
import Home from '../Scenes/Home/Home'
import RestaurantFaqs from '../Scenes/RestaurantFaqs/RestaurantFaqs'
import RestaurantProfile from '../Scenes/RestaurantProfile/RestaurantProfile'
import RestaurantProfileApplicantsPending from '../Scenes/RestaurantProfileApplicants/RestaurantProfileApplicantsPending'
import RestaurantProfileApplicantsReview from '../Scenes/RestaurantProfileApplicants/RestaurantProfileApplicantsReview'
import RestaurantProfileApplicantsAccepted from '../Scenes/RestaurantProfileApplicants/RestaurantProfileApplicantsAccepted'
import RestaurantProfileApplicantsRejected from '../Scenes/RestaurantProfileApplicants/RestaurantProfileApplicantsRejected'
import RestaurantProfileCalendar from '../Scenes/RestaurantProfileCalendar/RestaurantProfileCalendar'
import RestaurantProfileMessages from '../Scenes/RestaurantProfileMessages/RestaurantProfileMessages'
import RestaurantProfileMessagesDetail from '../Scenes/RestaurantProfileMessagesDetail/RestaurantProfileMessagesDetail'
import RestaurantProfileOffers from '../Scenes/RestaurantProfileOffers/RestaurantProfileOffers'
import RestaurantProfileViewOffer from '../Scenes/RestaurantProfileViewOffer/RestaurantProfileViewOffer'
import RestaurantServices from '../Scenes/RestaurantServices/RestaurantServices'
import RestaurantView from '../Scenes/RestaurantView/RestaurantView'
import RestaurantViewMessage from '../Scenes/RestaurantViewMessage/RestaurantViewMessage'
import RestaurantViewOffer from '../Scenes/RestaurantViewOffer/RestaurantViewOffer'
import RestaurantViewStage from '../Scenes/RestaurantViewStage/RestaurantViewStage'
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga'


const allowedLanguages = ['es', 'en', 'fr', 'pt']

const history = createHistory()
history.listen(location => {
	ReactGA.set({ page: location.pathname })
	ReactGA.pageview(location.pathname)
})

const ScrollToTop = (a) => {
    if (a.history.action === 'PUSH') {
        window.scrollTo(0, 0);
    }
    return null;
};

export default class Routes extends Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <CSSTransitionGroup
                transitionName="content"
                transitionEnter={true}
                transitionEnterTimeout={200}
                transitionLeave={true}
                transitionLeaveTimeout={200}
                >
                <Switch>

                        <Route path="/:lang/services">
                            <div>
                                <Route path="/:lang/services/restaurant" component={RestaurantServices}/>
                                <Route path="/:lang/services/chef" component={ChefServices}/>
                            </div>
                        </Route>

                        <Route path="/:lang/faqs">
                            <div>
                                <Route path="/:lang/faqs/restaurant" component={RestaurantFaqs}/>
                                <Route path="/:lang/faqs/chef" component={ChefFaqs}/>
                            </div>
                        </Route>

                        <Route path="/:lang/contact/:items?" component={Contact}/>
                        <Route path="/:lang/chef/view/:id/:slug" component={ChefView}/>

                        <Route path="/:lang/chef/profile/applications/in-review" component={ChefProfileApplicationsReview}/>
                        <Route path="/:lang/chef/profile/applications/accepted" component={ChefProfileApplicationsAccepted}/>
                        <Route path="/:lang/chef/profile/applications/rejected" component={ChefProfileApplicationsRejected}/>
                        <Route exact path="/:lang/chef/profile/applications/" component={ChefProfileApplicationsPending}/>
                        <Route path="/:lang/chef/profile/saved" component={ChefProfileSaved}/>
                        <Route path="/:lang/chef/profile/calendar" component={ChefProfileCalendar}/>
                        <Route path="/:lang/chef/profile/messages/view/:id" component={ChefProfileMessageDetail}/>
                        <Route path="/:lang/chef/profile/messages" component={ChefProfileMessages}/>
                        <Route path="/:lang/chef/profile/home" component={ChefProfile}/>

                        <Route path="/:lang/restaurant/profile/applicants/:id?/:oid/pending" component={RestaurantProfileApplicantsPending}/>
                        <Route path="/:lang/restaurant/profile/applicants/:id?/:oid/in-review" component={RestaurantProfileApplicantsReview}/>
                        <Route path="/:lang/restaurant/profile/applicants/:id?/:oid/accepted" component={RestaurantProfileApplicantsAccepted}/>
                        <Route path="/:lang/restaurant/profile/applicants/:id?/:oid/rejected" component={RestaurantProfileApplicantsRejected}/>

                        <Route path="/:lang/restaurant/profile/calendar/:id?" component={RestaurantProfileCalendar}/>

                        <Route path="/:lang/restaurant/profile/home/:id?" component={RestaurantProfile}/>
                        <Route path="/:lang/restaurant/profile/messages/:id?/view/:mid" component={RestaurantProfileMessagesDetail}/>
                        <Route path="/:lang/restaurant/profile/messages/:id?" component={RestaurantProfileMessages}/>
                        <Route path="/:lang/restaurant/profile/offers/:id?/view/:oid?" component={RestaurantProfileViewOffer}/>
                        <Route path="/:lang/restaurant/profile/offers/:id?" component={RestaurantProfileOffers}/>

                        <Route path="/:lang/restaurant/view/home/:id/:slug" component={RestaurantView}/>
                        <Route path="/:lang/restaurant/view/stage/:id/:slug" component={RestaurantViewStage}/>
                        <Route path="/:lang/restaurant/view/message/:id/:slug" component={RestaurantViewMessage}/>
                        <Route path="/:lang/restaurant/view/offer/:id/:slug/:jobId/:jobSlug?" component={RestaurantViewOffer}/>

                        <Route path="/:lang/find-chefs/:page?" component={FindChefs}/>
                        <Route path="/:lang/find-restaurants/:page?" component={FindRestaurants}/>
                        <Route path="/:lang/home/:page?" component={Home}/>
                        <Route path="/:lang/resetpassword" component={Home}/>
                        <Route path="/:lang/" render={(nextState) => {
                            const params = nextState.location.pathname.split('/')
                            if (params.length > 0 && allowedLanguages.indexOf(params[1]) == -1) {
                                if (nextState.location.search.includes('?login=')) {
                                    return <Redirect to={`/en/${nextState.location.search}`} />
                                } else {
                                    return <Redirect to="/en/" />
                                }
                            }
                            return <Home />
                        }} />
                        <Route path="/:lang" render={(nextState) => {
                            const params = nextState.location.pathname.split('/')
                            if (params.length > 0 && allowedLanguages.indexOf(params[1]) == -1) {
                                if (nextState.location.search.includes('?login=')) {
                                    return <Redirect to={`/en/${nextState.location.search}`} />
                                } else {
                                    return <Redirect to="/en/" />
                                }
                            }
                            return <Home />
                        }} />
                        <Route exact path="/" render={(nextState) => {
                            if (nextState.location.pathname === '/') {
                                if (nextState.location.search.includes('?login=')) {
                                    return <Redirect to={`/en/${nextState.location.search}`} />
                                } else {
                                    return <Redirect to="/en/" />
                                }
                            }
                        }} />
                        <Route exact path="/" render={(nextState) => {
                            const params = nextState.location.pathname.split('/')
                            if (params.length > 0 && allowedLanguages.indexOf(params[1]) == -1) {
                                if (nextState.location.search.includes('?login=')) {
                                    return <Redirect to={`/en/${nextState.location.search}`} />
                                } else {
                                    return <Redirect to="/en/" />
                                }
                            }
                            return null
                        }} />
                        <Route component={ScrollToTop}/>
                </Switch>
            </CSSTransitionGroup>
        )
    }
};



