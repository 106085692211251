import React, {Component} from 'react'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Offer from '../../Components/Offer/Offer'
import RestaurantInfo from '../../Components/RestaurantInfo/RestaurantInfo'
import SVG from 'react-inlinesvg';
import SidebarMenu from '../../Components/SidebarMenu/SidebarMenu'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import FadingLine from '../../Components/FadingLine/FadingLine'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {positions, days} from '../../Lists/lists'
import {findOption} from '../../Lib/findOption'
import {listItems, listItemsMore, listLink, listBtnSendCV, listBtn1} from '../RestaurantView/RestaurantViewTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {report, block} from '../../Services/Util/util'
import {viewRestaurant} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import LazyImage from '../../Components/LazyImage/LazyImage'
import Loader from "../../Components/_NEW/Loader"
import RestaurantTopNavMobile from "../../Components/_NEW/RestaurantTopNavMobile"
import Stars from '../../Components/Stars/Stars'

class RestaurantView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            item: null,
            items: [],
            activeSection: 'opening-hours',
            id: this.props.match.params.id
        }
    }



    componentDidMount() {
        const { lang } = this.props.match.params
        viewRestaurant(this.props.match.params.id, lang).then((res) => {
            if (res.data.success) {
                this.setState({ 
                    item: res.data.data,
                    items: res.data.data.profile.offers,
                    slug: res.data.data.profile.slug,
                    loading: false
                }, () => this.handleScrollToOffers())
            }
        })
    }

    handleScrollToOffers() {
        if (this.props.deviceScreen === "mobile") {
            const search = window.location.search.replace("?", "")
            const offersBlock = document.getElementById(search)
            if (search === "job-offers" && offersBlock) {
                offersBlock.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        }
    }

    render() {
        let className = 'scene_RestaurantView'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.item) {
            let profile = this.state.item.profile
            meta = {
                title: profile.name,
                metas: [
                    {name: 'og:image', value: profile.images.image1 ? profile.images.image1.sizes.share.url : null}
                ]
            }
        }
        return (
            <Main
                match={this.props.match}
                loading={this.state.loading}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                topNavShadow={true}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                bottomArea={
                    this.props.deviceScreen === "mobile" 
                    ? this.renderSectionJobOffers.bind(this) 
                    : null
                }
                meta={meta}
                >
                <div className="content">
                    {this.renderContent()}
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        const { lang } = this.props.match.params
        if (!this.state.item) {
            return null
        }
        return (
            <>
                <div className="top-mobile">
                    <span>
                        <Link to={`/${lang}/restaurant/view/home/${this.state.id}/${this.state.slug}`}>{this.state.item.profile.name}</Link>
                    </span>
                </div>
                <ImageHeader
                    left={this.state.item.profile.images.logo ? this.state.item.profile.images.logo.sizes.square.url : '/static/images/empty_600_600.png'}
                    right={this.state.item.profile.images.header ? this.state.item.profile.images.header.sizes.header.url : null}
                    alt={this.state.item.profile.name}
                />
            </>
            
        )
    }
    _sendMessage() {
        this.props.showOverlay('message', {
            rid: this.state.item.profile.id,
            cid: this.props.user.profile.id,
            sender: 'restaurant'
        })
    }
    renderTopNav() {
        const { lang } = this.props.match.params
        if (!this.state.item) {
            return null
        }

        let items = listItems(this.props.isLogged, this.props.userType, this.state, lang)
        let itemsMore = listItemsMore(this.props.isLogged, this.props.userType, this.state, {
            report: (type) => {
                report({offender: this.state.item.profile.id, ischef: 0, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            },
            block: (type) => {
                block({offender: this.state.item.profile.acc, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            }
        }, lang
        )
        let itemsLink = listLink(this.props.isLogged, this.props.userType, this.state, lang) //TODO callback
        let btn1 = listBtn1(lang, this.props.isLogged, this.props.userType, this.state, this._sendMessage.bind(this))
        let btn2 = listBtnSendCV(this.props.isLogged, this.props.userType, this.state, () => {
            if (!this.props.isLogged) {
                this.props.showOverlay('login', { fromApplyCallback:() => {
                    if (this.props.userType === 'chef') {
                        this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
                    }
                }})
            } else {
                this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
            }
        }, lang)

        if (this.props.deviceScreen === 'mobile') {
            return (
                <RestaurantTopNavMobile
                    props={this.props}
                    state={this.state}
                    sendCVCallback={() => {
                        if (!this.props.isLogged) {
                            this.props.showOverlay('login', { fromApplyCallback:() => {
                                if (this.props.userType === 'chef') {
                                    this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
                                }
                            }})
                        } else {
                            this.props.showOverlay('sendcv', {id: this.state.item.profile.id})
                        }}}
                />
            )
        } else {
            return (
                <nav>
                    <TopNavMenu
                        items={items}
                        more={itemsMore}
                        link={itemsLink}
                        hiddenBtn1={!this.props.isLogged}
                        btn1={btn1}
                        btn2={btn2}
                    />
                </nav>
            )
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        let items = [
            {url: 'about', title: translate(lang, 'about')},
            {url: 'details', title: translate(lang, 'details')},
            {url: 'job-offers', title: translate(lang, 'job_offers')},
        ]
        if (this.props.deviceScreen === 'mobile' || !this.state.item) {
            return null
        } else {
            return (
                <div>
                    <div className="author">
                        <h1>{this.state.item.profile.name}</h1>
                        <span>{readableLocation(this.state.item.profile.location)}</span>
                        {this.props.deviceScreen !== "mobile"
                        ? <FadingLine className="sidebar"><a target="_blank" href={this.state.item.profile.url}>{this.state.item.profile.url}</a></FadingLine>
                        : <span><a target="_blank" href={this.state.item.profile.url}>{this.state.item.profile.url}</a></span>
                        }
                        
                    </div>
                    <h5 className="clear">{translate(lang, 'info')}</h5>
                    <SidebarMenu items={items} />
                </div>
            )
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading || !this.state.item) {
            return <Loader />
        }
        let profile = this.state.item.profile
        let description = profile.description

        return (
            <div>
                {this.props.deviceScreen === 'mobile' ? <RestaurantInfo profile={profile} /> : null}
                <div className="container-fluid">
                    <div className="blocks">
                        <div className="block" data-block="about">
                            <h3>
                                <span>{translate(lang, 'about_restaurant')}</span>
                                {this.props.deviceScreen !== "mobile" && <Stars type={profile.ratingType} number={parseInt(profile.rating)} />}
                            </h3>
                            <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>
                            <div className="col-group">
                                <div className="col-mb-4 col-4">
                                    <LazyImage
                                        alt={profile.name}
                                        src={profile.images.image1 ? profile.images.image1.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'}
                                        />
                                </div>
                                <div className="col-mb-4 col-4">
                                    <LazyImage
                                        alt={profile.name}
                                        src={profile.images.image2 ? profile.images.image2.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'}
                                        />
                                </div>
                                <div className="col-mb-4 col-4">
                                    <LazyImage
                                        alt={profile.name}
                                        src={profile.images.image3 ? profile.images.image3.sizes.showcase.url : 'http://via.placeholder.com/400x300/ddd/fff'}
                                        />
                                </div>
                            </div>
                        </div>
                        {this.renderSectionsNav()}
                        {this.renderSectionsContent()}
                    </div>
                </div>
                {this.props.deviceScreen !== "mobile" && this.renderSectionJobOffers()}
            </div>
        )
    }
    renderSectionsNav() {
        const { lang } = this.props.match.params
        let options = [
            {type: 'opening-hours', title: translate(lang, 'opening_hours')},
            {type: 'covers', title: translate(lang, 'covers')},
            {type: 'chefs', title: translate(lang, 'chefs')},
            {type: 'contact', title: translate(lang, 'contact')},
        ]
        let items = []
        options.forEach((o) => {
            items.push(
                <li key={o.type} className={this.state.activeSection === o.type ? 'active' : null}><a href="" onClick={(e)=>{e.preventDefault();this.setState({activeSection:o.type})}}>{o.title}</a></li>
            )
        })
        return (
            <ul className="nav">
                {items}
            </ul>
        )
    }
    renderSectionsContent() {
        switch (this.state.activeSection) {
            case 'opening-hours':
                return this.renderSectionOpeningHours()
                break;
            case 'chefs':
                return this.renderSectionChefs()
                break;
            case 'covers':
                return this.renderSectionCovers()
                break;
            case 'contact':
                return this.renderSectionContact()
                break;
            default:
                return null
                break;
        }
    }
    renderSectionOpeningHours() {
        return (
            <div className="block" data-block="details">
                {this.renderSchedule()}
            </div>
        )
    }
    renderSchedule() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.item.schedule && this.state.item.schedule.length > 0) {
            let schedule = this.state.item.schedule[0]
            days[lang].forEach((d) => {
                let day = d.value
                let dayLunchFrom = day + 'lunchFrom'
                let dayLunchTo = day + 'lunchTo'
                let dayDinnerFrom = day + 'dinnerFrom'
                let dayDinnerTo = day + 'dinnerTo'
                let text = ''
                if (schedule.value[dayLunchFrom] === '' && schedule.value[dayLunchTo] === '' && schedule.value[dayDinnerFrom] === '' && schedule.value[dayDinnerTo] === '') {
                    text = translate(lang, 'closed')
                } else {
                    let parts = []
                    if (schedule.value[dayLunchFrom] !== null) {
                        let hours = schedule.value[dayLunchFrom] + 'h'
                        if (schedule.value[dayLunchTo] !== null) {
                            hours += '-' + schedule.value[dayLunchTo] + 'h'
                        }
                        parts.push(hours)
                    }
                    if (schedule.value[dayDinnerFrom] !== null) {
                        let hours = schedule.value[dayDinnerFrom] + 'h'
                        if (schedule.value[dayDinnerTo] !== null) {
                            hours += '-' + schedule.value[dayDinnerTo] + 'h'
                        }
                        parts.push(hours)
                    }
                    text = parts.join(', ')
                }
                if (text !== '') {
                    items.push(
                        <div key={d.value} className="col-mb-12 scheduleday">
                            <strong>{d.label}</strong> {text}
                        </div>
                    )
                }
            })
        }
        return <div className="col-group items">{items}</div>
    }
    renderSectionChefs() {
        return (
            <div className="block" data-block="details">
                {this.renderChefs()}
            </div>
        )
    }
    renderChefs() {
        const { lang } = this.props.match.params
        let items = []
        if (this.state.item.chefs && this.state.item.chefs.length > 0) {
            this.state.item.chefs.forEach((i) => {
                items.push(
                    <div key={i.id} className="col-mb-6 col-4">
                        <h5>{i.value.person}</h5>
                        {findOption(i.value.position, positions[lang])}
                    </div>
                )
            })
        }
        return <div className="col-group items">{items}</div>
    }
    renderSectionCovers() {
        return (
            <div className="block" data-block="details">
                {this.renderCovers()}
            </div>
        )
    }
    renderCovers() {
        let items = []
        if (this.state.item.covers && this.state.item.covers.length > 0) {
            this.state.item.covers.forEach((i) => {
                items.push(
                    <div key={i.id} className="col-mb-6 col-4">
                        <h5>{i.value.title}</h5>
                        {i.value.description}
                    </div>
                )
            })
        }
        return <div className="col-group items">{items}</div>
    }
    renderSectionContact() {
        const { lang } = this.props.match.params
        return (
            <div className="block" data-block="details">
                <div className="col-group items">
                    <div className="col-mb-12 col-4">
                        <div className="item">
                            <h5><span>{translate(lang, 'address').toUpperCase()}</span></h5>
                            {this.renderAddresses()}
                        </div>
                    </div>
                    {
                        (this.props.userType === 'chef' && parseInt(this.state.item.profile.related) !== 1)
                        || parseInt(this.state.item.profile.premium) === 0
                         ?
                        null :
                        <div className="col-mb-12 col-4">
                            <div className="item">
                                <h5><span>{translate(lang, 'email').toUpperCase()}</span> </h5>
                                {this.renderEmails()}
                            </div>
                        </div>
                    }
                    {
                        (this.props.userType === 'chef' && parseInt(this.state.item.profile.related) !== 1)
                        || parseInt(this.state.item.profile.premium) === 0 ?
                        null :
                        <div className="col-mb-12 col-4">
                            <div className="item">
                                <h5><span>{translate(lang, 'phone_number').toUpperCase()}</span> </h5>
                                {this.renderPhoneNumbers()}
                            </div>
                        </div>
                    }
                </div>
                <div className="col-group items">
                    <div className="col-mb-12 col-4">
                        <div className="item">
                            <h5><span>{translate(lang, 'social_networks').toUpperCase()}</span> </h5>
                            {this.renderSocialNetworks()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderSectionJobOffers() {
        const { lang } = this.props.match.params
        return (
            <div className="block job-offers" data-block="job-offers" id="job-offers">
                <div className="container-fluid">
                    <h4>{translate(lang, 'job_offers_NUM', this.state.items.length)}</h4>
                </div>
                {this.state.items.length === 0
                ? <span className='no-offers'>{translate(lang, 'no_active_offers')}</span>
                : this.renderOffers()
                }
            </div>
        )
    }
    renderAddresses() {
        let items = []
        if (this.state.item.contact.address && this.state.item.contact.address.length > 0) {
            let j = 0
            this.state.item.contact.address.forEach((i) => {
                items.push(
                    <div key={j}>
                        <span>{i.value.value.formatted_address}
                        </span>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderEmails() {
        let items = []
        if (this.state.item.contact.email && this.state.item.contact.email.length > 0) {
            let j = 0
            this.state.item.contact.email.forEach((i) => {
                items.push(
                    <div key={j}>
                        <span>{i.value.value}
                        </span>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderPhoneNumbers() {
        let items = []
        if (this.state.item.contact.phone && this.state.item.contact.phone.length > 0) {
            let j = 0
            this.state.item.contact.phone.forEach((i) => {
                items.push(
                    <div key={j}>
                        <span>{i.value.value}
                        </span>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderSocialNetworks() {
        let items = []
        if (this.state.item.contact.social && this.state.item.contact.social.length > 0) {
            let j = 0
            this.state.item.contact.social.forEach((i) => {
                let svgSrc = "/static/images/" + i.value.type + "_icn.svg"
                items.push(
                    <div key={j} className="social-network">
                        <a href={i.value.url} target="_blank"><div className="pre"><SVG src={svgSrc} /></div></a>
                    </div>
                )
                j++
            })
        }
        return items
    }
    renderOffers() {
        let items = []
        let cleanProfile = Object.assign({}, this.state.item.profile)
        delete cleanProfile.offers
        this.state.item.profile.offers.forEach((o) => {
            let item = {
                offer: o,
                restaurant: cleanProfile
            }
            items.push(<Offer key={o.id} item={item} />)
        })
        return items
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantView))
