import React from 'react'
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom'

const IconButton = ({
  label,
  link,
  callback = () => {},
  className = '',
  icon = "edit",
  color = ''
}) => {
  const src = () => {
    switch(icon) {
      case 'edit':
        return '/static/images/edit.svg';
      case 'add':
        return '/static/images/add.svg';
      case 'share':
        return '/static/images/share.svg';
      case 'message':
        return '/static/images/message_icn.svg';
      case 'home':
        return '/static/images/_home.svg';
      case 'calendar':
        return '/static/images/_calendar.svg';
      case 'more':
        return '/static/images/more_icn.svg';
    }
  }

  if (link) {
    return (
      <Link className={`component_IconButton ${className} ${color}`} to={link}>
        <div className="pre"><SVG src={src()} /></div>
        {label && <div className="text">{label}</div>}
      </Link>
    )
  }

    return (
      <div className={`component_IconButton ${className} ${color}`} onClick={callback}>
        <div className="pre"><SVG src={src()} /></div>
        {label && <div className="text">{label}</div>}
      </div>
    )
  }

export default IconButton