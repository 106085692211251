import {memberships_en} from './memberships/memberships_en'
import {memberships_es} from './memberships/memberships_es'
import {memberships_fr} from './memberships/memberships_fr'
import {memberships_pt} from './memberships/memberships_pt'

export const memberships = {
    en: memberships_en,
    es: memberships_es,
    fr: memberships_fr,
    pt: memberships_pt
}
