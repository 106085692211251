import React, {Component} from 'react'
import ResponsiveUtility from '../ResponsiveUtility/ResponsiveUtility'
import { connect } from 'react-redux'
import Routes from '../../Config/Routes'
import { withRouter } from 'react-router'
import {ActionCreators} from '../../Actions'
import Cookies from 'js-cookie';

class Layout extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {
        const userIsLogged = Cookies.get('token') !== undefined
        if (this.props.location.pathname === '/') {
            this.props.location.pathname = '/en/'
        }
        if (this.props.location.pathname.indexOf('resetpassword') !== -1) {
            let queryString = parse_query_string(this.props.location.search.replace('?', ''))
            this.props.showOverlay('resetpassword', {code: queryString.code})
        }
        if (this.props.location.search.includes('?login=') && !userIsLogged) {
            let email = this.props.location.search.replace('?login=', '')
            this.props.showOverlay('login', { email })
        }
    }
    render() {
        return (
            <div>
                <ResponsiveUtility />
                <Routes />
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
    }
};
function parse_query_string(query) {
  var vars = query.split("&");
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof query_string[key] === "undefined") {
      query_string[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof query_string[key] === "string") {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
      // If third or later entry with this name
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}
const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Layout))
