import {apiFetch} from '../../Config/api'

export function uploadImage(data) {
    return apiFetch('account/image', data)
}
export function listMedia(data) {
    return apiFetch('account/album', data)
}
export function deleteImage(id, lang) {
    return apiFetch('account/image/delete', {id: id, lang})
}
export function assignProfileImage(data) {
    return apiFetch('profile/image', data)
}
export function assignOfferImage(data) {
    return apiFetch('offer/image', data)
}
