import createReducer from '../Services/General/createReducer.js'
import * as types from '../Actions/types.js'
var reject = require('lodash/reject')

export const selectedOffers = createReducer(null, {
    [types.SET_SELECTED_OFFERS](state, action) {
        state = action.selectedOffers
        return state
    },
    [types.ADD_SELECTED_OFFER](state, action) {
        if (state == null) {
            state = []
        }
        state.push(action.selectedOffer)
        return state.slice(0)
    },
    [types.REMOVE_SELECTED_OFFER](state, action) {
        if (state == null) {
            state = []
        }
        state = reject(state, {id: action.selectedOffer.id})
        return state.slice(0)
    }
})
