import React from 'react'
import SVG from 'react-inlinesvg';

const Loader = ({ color = "black", className = "" }) => {

    return (
      <div className='component_Loader'>
        <div className={`loader ${className} ${color}`}><SVG src='/static/images/loading_icn.svg' /></div>
      </div>
    )
  }

export default Loader