export function selectOptionsForOffers(offers) {
    let items = []    
    offers.forEach((o) => {
        items.push({
            value: o.id,
            label: o.name,
            rid: o.rid
        })
    })
    return items
}
export function selectOptionsForProfiles(profiles) {
    let items = []
    profiles.forEach((o) => {
        if (parseInt(o.main) === 1) {
            items.push({
                value: o.id,
                label: o.name
            })
        }
    })
    return items
}
export function offersForUser(user, onlyActive = false) {
    let offers = []
    user.profiles.forEach((p) => {
        if (parseInt(p.main) === 1) {
            p.offers.forEach((o) => {
                o.rid = p.id
                if (onlyActive) {
                    if (parseInt(o.status) > 0) {
                        offers.push(o)
                    }
                } else {
                    offers.push(o)
                }
            })
        }
    })
    return offers
}
export function profilesForUser(user) {
    let profiles = []
    user.profiles.forEach((p) => {
        profiles.push(p)
    })
    return profiles
}
