import React, { useState, useEffect } from 'react'
import { Formik, Form } from "formik"
import moment from 'moment'

import { translate } from '../../Translations/translate'
import ChefReferralSchema from "../../FormValidations/ChefReferralSchema"
import {chefEditReferral} from '../../Services/Chef/chef'

import FormTextarea from './FormTextarea'
import Button from './Button'

const ChefReferralForm = ({ props, textTranslation, textLang }) => {
  const { lang } = props.match.params
  const { data, setUser, showOverlay } = props
  const { value } = data

  const [loadingSave, setLoadingSave] = useState(false)
  const [loadingAddMore, setLoadingAddMore] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [reset, setReset] = useState(false)
  const [formError, setFormError] = useState(null)
  const [changedText, setChangedText] = useState(null)

  const currentYear = moment().year()
  const years = []
  for (var y = 0; y < 60; y++) {
      years.push({value: (currentYear-y), label: (currentYear-y)})
  }

  const handleSave = (values, currentLang, closeModal, addMore = false) => {
    if (!addMore) {
      setLoadingSave(true)
    } else {
      setLoadingAddMore(true)
    }
    const formData = {
      id: values.id,
      name: 'referral',
      order: 0,
      language: currentLang,
      value: JSON.stringify({
          description: values.description,
          lang: currentLang,
      })
    }
    chefEditReferral(formData).then((res) => {
      setLoadingSave(false)
      if (!res.data.success) {
          setFormError(res.data.message)
          return
      } 
      const response = res.data.data
      setUser(response.user)
      if (addMore) {
        setLoadingAddMore(false)
        setReset(true)
      } else if (closeModal) {
        showOverlay(null)
      }
  }).catch((e) => {
      setLoadingSave(false)
      setFormError(e.message)
  })
  }

  const handleDelete = () => {
    setLoadingDelete(true)
    chefEditReferral({id: data.id, action: 'delete', lang}).then((res) => {
        setLoadingDelete(false)
        if (!res.data.success) {
            setFormError(res.data.message)
            return
        }
        const response = res.data.data
        setUser(response.user)
        showOverlay(null)
    }).catch((e) => {
      setLoadingDelete(false)
      setFormError(e.message)
    })
  }

  return (
    <div className="component_chefReferralForm">
      <Formik
        initialValues={{
            id: data.id ? parseInt(data.id) : 0,
            description: value.description || ''
        }}
        validationSchema={ChefReferralSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSave(values, textLang, true)}
        enableReinitialize
      >{({ values, errors, setFieldValue, resetForm, validateForm }) => {

        useEffect(() => {
          if (textTranslation) {
            if (changedText) {
              handleSave(values, changedText, false)
              setChangedText(null)
              setFieldValue('description', textTranslation)
            } else {
              setFieldValue('description', textTranslation)
            }
          }
        }, [textTranslation])

        useEffect(() => {
          if (reset) {
            resetForm()
            setReset(false)
          }
        }, [reset])

        return (
          <Form>
            <div className="fullWidth textarea">
              <FormTextarea
                id="description"
                name="description"
                label={translate(lang, 'description')}
                placeholder={null}
                errors={errors}
                onChange={v => {
                  setChangedText(textLang);
                  setFieldValue('description', v);
                }}
                lang={lang}
                textTranslation={textTranslation}
                addMediaCallback={false}
                reset={reset}
              />
            </div>
            <div className={`buttonRow ${formError || (errors && Object.keys(errors).length > 0) ? "hasError" : ""}`}>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            {errors && Object.keys(errors).length > 0 && <div className="errorMessage">{translate(lang, 'error_required_fields')}</div>}
            <div className='buttons'>
              {parseInt(data.id) !== 0 &&  <div className="nextTo">
                <Button
                  label={translate(lang, 'delete').toUpperCase()}
                  className="red"
                  onClick={() => handleDelete()}
                  loading={loadingDelete}
                  />
                </div>}
              {parseInt(data.id) === 0 && <div className="nextTo">
                  <Button
                    label={translate(lang, 'save_add_more').toUpperCase()}
                    loading={loadingAddMore}
                    onClick={() => validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0) {
                      handleSave(values, textLang, false, true)
                    }})}
                  />
                </div>}
                <Button
                  label={translate(lang, 'save').toUpperCase()}
                  submit
                  loading={loadingSave}
                />
            </div>
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default ChefReferralForm