import {createStore, applyMiddleware, compose} from 'redux'
import reducer from '../Reducers/'
import {createLogger} from 'redux-logger'
import thunk from 'redux-thunk'

const logger = createLogger({})

function configureStore(initialState) {
    const enhancer = compose(
        applyMiddleware(
            // logger,
            thunk
        )
    )
    return createStore(reducer, initialState, enhancer)
}

let store = configureStore({})
export default store
