import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import RelatedOffers from '../../Components/RelatedOffers/RelatedOffers'
import ShowcaseImages from '../../Components/ShowcaseImages/ShowcaseImages'
import TopNavMenu from '../../Components/TopNavMenu/TopNavMenu'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import {ActionCreators} from '../../Actions'
import {findOption} from '../../Lib/findOption'
import {listItems, listItemsMore, listLink, listBtn1, listBtn2} from '../RestaurantView/RestaurantViewTopNavMenuItems'
import {readableLocation} from '../../Lib/readableLocation'
import {report, block} from '../../Services/Util/util'
import {terms} from '../../Lists/lists'
import {viewOffer, relatedOffers} from '../../Services/Offers/offers'
import {viewRestaurant} from '../../Services/Restaurant/restaurant'
import { translate } from '../../Translations/translate'
import LazyImage from '../../Components/LazyImage/LazyImage'
import { withRouter } from 'react-router'
import Loader from "../../Components/_NEW/Loader"
import RestaurantTopNavMobile from '../../Components/_NEW/RestaurantTopNavMobile'
import JobPostingSchema from './JobPostingSchema'
var _remove = require('lodash/remove')


class RestaurantViewOffer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            item: null,
            items: [],
            relatedItems: [],
            id: this.props.match.params.id,
            errors: []
        }
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadData()
        }
        if (this.state.offer) {
            this.loadJobPostingSchema()
        }
    }

    loadData(){
        const { lang } = this.props.match.params
        this.setState({ loading: true }, () => 
        {viewRestaurant(this.props.match.params.id, lang).then((res) => {
            if (res.data.success) {
                this.setState({
                    item: res.data.data,
                    slug: res.data.data.profile.slug
                }, () => {
                    viewOffer({id: this.props.match.params.jobId, lang}).then((res) => {
                        if (res.data.success) {
                            this.setState({offer: res.data.data, loading: false})
                        } else {
                            this.setState({offer: null, loading: false})
                        }
                    })
                })
            }
        })
        relatedOffers({id: this.props.match.params.jobId, page: 0, qty: 999, lang}).then((res) => {
            if (res.data.success) {
                const { data } = res.data
                const items = []
                data.forEach(item => 
                    {if (this.props.match.params.id !== item.restaurant.id && this.props.match.params.jobId !== item.offer.id){
                        items.push(item)
                    }
                })
                this.setState({relatedItems: items})
                
            }
        })}
        )
    }

    loadJobPostingSchema() {
        const jobPostingSchema = JobPostingSchema(this.state.offer)
        if (!this.state.jobPostingSchema) {
            this.setState({ jobPostingSchema: jobPostingSchema })
        }
    }
    

    render() {
        let className = 'scene_RestaurantViewOffer'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta
        if (this.state.offer) {
            let profile = this.state.item.profile
            let offer = this.state.offer.offer
            meta = {
                title: offer.name + ' | ' + profile.name,
                metas: [
                    {
                        name: 'og:image',
                        value: this.state.offer.offer.images.image1 ? this.state.offer.offer.images.image1.sizes.share.url : null
                    }
                ]
            }
        }
        return (
            <Main
            match={this.props.match}
            loading={this.state.loading}
            className={className}
            preTopArea={this.renderPreTopArea.bind(this)}
            topArea={this.renderTopArea.bind(this)}
            topNav={this.renderTopNav.bind(this)}
            topNavShadow={true}
            left={this.renderLeft.bind(this)}
            right={this.renderRight.bind(this)}
            bottomArea={this.renderBottomArea.bind(this)}
            meta={meta}
            jobPostingSchema={this.state.jobPostingSchema}
            >
                <div className="content">
                    <div className="container-fluid">
                        {this.renderContent()}
                    </div>
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if ( this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection/>
    }
    renderTopArea() {
        const { lang } = this.props.match.params
        if (!this.state.item) {
            return null
        }

        return (
            <>
                <div className="top-mobile">
                    <span>
                        <Link to={`/${lang}/restaurant/view/home/${this.state.id}/${this.state.slug}`}>{this.state.item.profile.name}</Link>
                    </span>
                </div>
                <ImageHeader left={this.state.item.profile.images.logo
                    ? this.state.item.profile.images.logo.sizes.square.url
                    : '/static/images/empty_600_600.png'} right={this.state.item.profile.images.header ? this.state.item.profile.images.header.sizes.header.url : null}
                    alt={this.state.item.profile.name}
                />
            </>
        )
    }
    _sendMessage() {
        this.props.showOverlay('message', {
            rid: this.state.item.profile.id,
            cid: this.props.user.profile.id,
            sender: 'restaurant'
        })
    }
    _applyOffer() {
        this.props.toggleSelectedOffer({id: this.state.offer.offer.id, item: this.state.offer})

    }
    renderTopNav() {
        if (!this.state.item) {
            return null
        }
        const { lang } = this.props.match.params
        let items = listItems(this.props.isLogged, this.props.userType, this.state, lang)
        items = _remove(items, (i) => {
            return i.icon !== '_messages'
        })
        let itemsMore = listItemsMore(this.props.isLogged, this.props.userType, this.state, {
            report: (type) => {
                report({offender: this.state.item.profile.acc, ischef: 0, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            },
            block: (type) => {
                block({offender: this.state.item.profile.acc, lang}).then((res) => {
                    if (res.data.success) {
                        this.props.showOverlay('success', {message: res.data.message ? res.data.message : 'Success'})
                    }
                })
            }
        }, lang
        )
        let itemsLink = listLink(this.props.isLogged, this.props.userType, this.state, lang) //TODO callback
        let btn1 = listBtn1(lang, this.props.isLogged, this.props.userType, this.state, this._sendMessage.bind(this))
        let btn2 = listBtn2(lang, this.props.isLogged, this.props.userType, this.state, this._applyOffer.bind(this))

        if (this.props.deviceScreen === 'mobile') {
            return (
                <RestaurantTopNavMobile props={this.props} state={this.state} screen="offer"/>
            )
        } else {
            if (!this.state.offer) {
                return (<div>
                    <nav>
                        <TopNavMenu items={items} more={itemsMore} link={itemsLink}/>
                    </nav>
                    <h4>
                        <span>{this.state.item.profile.name}</span>
                        / {translate(lang, 'job_description')}</h4>
                </div>)
            } else {
                return (<div>
                    <nav>
                        <TopNavMenu
                            items={items}
                            more={itemsMore}
                            link={itemsLink}
                            hiddenBtn1={!this.props.isLogged}
                            btn1={btn1}
                            btn2={this.state.item.profile.inapply === 0 && (this.state.item.profile.invited === 0 || !this.state.item.profile.invited) ? btn2 : null}
                        />
                    </nav>
                    <h4>
                        <span>{this.state.item.profile.name}</span>
                        / {translate(lang, 'job_description')}</h4>
                </div>)
            }
        }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        }
        return this.renderLeftContent()
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (<ItemsSelection/>)
        }
        return null
    }
    renderLeftContent() {
        if (this.props.deviceScreen === 'mobile' || !this.state.item) {
            return null
        } else {
            return (<div>
                <div className="author">
                    <h1>{this.state.item.profile.name}</h1>
                    <span>{readableLocation(this.state.item.profile.location)}</span>
                </div>
            </div>)
        }
    }
    renderContent() {
        const { lang } = this.props.match.params
        if (this.state.loading) {
            return <Loader />
        } else {
            if (!this.state.offer){
                return (
                    <p>{translate(lang, 'offer_not_available')}</p>
                )
            } else {
            let item = this.state.offer
            let description = item.offer.description
            let requirements = item.offer.requirements
            return (
                <div>
                    <div className="blocks">
                        <div className="block" data-block="about">
                            <h4>
                                <span>{item.offer.name}</span>
                            </h4>
                            {this.renderSummary()}
                            <div className="description" dangerouslySetInnerHTML={{
                                    __html: description
                                }}></div>
                            <ShowcaseImages item1={<div > <LazyImage src={item.restaurant.images.image1
                                        ? item.restaurant.images.image1.sizes.showcase.url
                                        : 'http://via.placeholder.com/400x300/ddd/fff'} alt={item.restaurant.name}/></div>} item2={<div > <LazyImage src={item.restaurant.images.image2
                                        ? item.restaurant.images.image2.sizes.showcase.url
                                        : 'http://via.placeholder.com/400x300/ddd/fff'} alt={item.restaurant.name}/></div>} item3={<div > <LazyImage src={item.restaurant.images.image3
                                        ? item.restaurant.images.image3.sizes.showcase.url
                                        : 'http://via.placeholder.com/400x300/ddd/fff'} alt={item.restaurant.name}/></div>}/>
                            <div className="requirements">
                                <h4>{translate(lang, 'chef_requirements')}</h4>
                                <div className="description" dangerouslySetInnerHTML={{
                                        __html: requirements
                                    }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom">
                        <span>{moment(item.offer.updated).format('D MMM[.] YYYY')}</span>
                        {this.renderShareOptions()}
                    </div>
                </div>
                )
            }
        }
    }
    renderSummary() {
        const { lang } = this.props.match.params
        let item = this.state.offer
        return (<div className="col-group summary">
            <div className="col-mb-6 col-3">
                <span>{translate(lang, 'term')} {findOption(item.offer.term, terms[lang])}</span>
            </div>
            <div className="col-mb-6 col-3">
                <span>{translate(lang, 'w_h')} {item.offer.hours}</span>
            </div>
            <div className="col-mb-6 col-3">
                <span>{translate(lang, 'holidays')} {item.offer.holidays}</span>
            </div>
            <div className="col-mb-6 col-3">
                <span>{translate(lang, 'days_off')} {item.offer.daysoff}</span>
            </div>
        </div>)
    }
    renderShareOptions() {
        if (this.props.userType === 'chef') {
            return (<div className="share">
                {this.renderShare()}
            </div>)
        }
        return null
    }
    renderMessage() {
        const { lang } = this.props.match.params
        let text = this.props.deviceScreen === 'mobile'
            ? translate(lang, 'mess').toUpperCase()
            : translate(lang, 'message').toUpperCase()
        return (<Btn text={text} className="text" post="message_icn" callback={() => {}}/>)
    }
    renderShare() {
        const { lang } = this.props.match.params
        let item = this.state.offer
        let url = `/${lang}/restaurant/view/offer/` + item.restaurant.id + '/' + item.restaurant.slug + '/' + item.offer.id + '/' + item.offer.slug
        let image = item.offer.images.image1 && item.offer.images.image1.sizes && item.offer.images.image1.sizes.share ? item.offer.images.image1.sizes.share.url : null
        return (<Btn text={this.props.deviceScreen === 'mobile' ? null : translate(lang, 'share').toUpperCase()} className="text" post="share_icn" callback={() => {
                this.props.showOverlay('share', {
                    title: this.state.offer.name,
                    url: url,
                    image:image
                })
            }}/>)
    }
    renderLink() {
        const { lang } = this.props.match.params
        return (<Btn text={translate(lang, 'link')} className="text" post="link_icn" callback={() => {}}/>)
    }
    renderBottomArea() {
        return <RelatedOffers items={this.state.relatedItems}/>
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,
        userType: state.userType,
        user: state.user
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantViewOffer))
