import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import CallToActionBox from '../../Components/CallToActionBox/CallToActionBox'
import ContactForm from '../../Components/ContactForm/ContactForm'
import FeaturesBox from '../../Components/FeaturesBox/FeaturesBox'
import ImageHeader from '../../Components/ImageHeader/ImageHeader'
import ItemsSelection from '../../Components/ItemsSelection/ItemsSelection'
import Main from '../../Components/Layout/Main'
import Membership from '../../Components/Membership/Membership'
import SidebarMenu from '../../Components/SidebarMenu/SidebarMenu'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'


class RestaurantServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showMenu: false,
            showSelectedItems: false
        }        
    }

     
    
    featuresBoxItems() {
        const { lang } = this.props.match.params
        return([
        {
            title: translate(lang, 'create_page'),
            subtitle: translate(lang, 'create_page_title'),
            content: translate(lang, 'create_page_content'),
            icon: 'premium_icn'
        },
        {
            title: translate(lang, 'post_jobs'),
            subtitle: translate(lang, 'post_jobs_subtitle'),
            content: translate(lang, 'post_jobs_content'),
            icon: '_post_offer'
        },
        {
            title: translate(lang, 'search_chefs_title'),
            subtitle: translate(lang, 'search_chefs_subtitle'),
            content: translate(lang, 'search_chefs_content'),
            icon: 'search_icn'
        },
        {
            title: translate(lang, 'direct_message_title'),
            subtitle: translate(lang, 'direct_message_subtitle_restaurant'),
            content: translate(lang, 'direct_message_content_restaurant'),
            icon: 'message_icn'
        },
        {
            title: translate(lang, 'link_tech_title'),
            subtitle: translate(lang, 'link_tech_subtitle_restaurant'),
            content: translate(lang, 'link_tech_content_restaurant'),
            icon: '_link'
        },
        {
            title: translate(lang, 'select_audience_title'),
            subtitle: translate(lang, 'select_audience_subtitle'),
            content: translate(lang, 'select_audience_content'),
            icon: 'profile_icn'
        },
    ])
}

    render() {
        const { lang } = this.props.match.params
        let className = 'scene_RestaurantServices'
        if (this.state.showMenu) {
            className += ' open-menu'
        }
        if (this.state.showSelectedItems) {
            className += ' open-selected-items'
        }
        let meta = {
            title: translate(lang, 'restaurant_services'),
            metas: [
            ]
        }
        return (
            <Main
                match={this.props.match}
                className={className}
                preTopArea={this.renderPreTopArea.bind(this)}
                topArea={this.renderTopArea.bind(this)}
                topNav={this.renderTopNav.bind(this)}
                left={this.renderLeft.bind(this)}
                right={this.renderRight.bind(this)}
                meta={meta}
                >
                <div className="content">
                    {this.renderContent()}
                </div>
            </Main>
        )
    }
    renderPreTopArea() {
        if (this.state.loading || this.props.deviceScreen !== 'mobile') {
            return null
        }
        return <ItemsSelection />
    }
    renderTopArea() {
        const { lang } = this.props.match.params
        return (
            <ImageHeader left="/static/images/logo.png" right="/static/images/header_default.png" alt={translate(lang, 'services')} />
        )
    }
    renderTopNav() {
        // if (this.props.deviceScreen === 'mobile') {
        //     return (
        //         <nav>
        //             <div className="btns">
        //                 <div className="main-btns">
        //                 </div>
        //             </div>
        //             <div>
        //             </div>
        //         </nav>
        //     )
        // } else {
            return null
        // }
    }
    renderLeft() {
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            return this.renderLeftContent()
        }
    }
    renderRight() {
        if (this.props.deviceScreen !== 'mobile') {
            return (
                <ItemsSelection />
            )
        }
        return null
    }
    renderLeftContent() {
        const { lang } = this.props.match.params
        let items = [
            {url: 'how-do-we-work', title: translate(lang, 'how_do_we_work')},
            {url: 'a-plan-that-works', title: translate(lang, 'plan_that_works')},
            {url: 'callback', title: translate(lang, 'callback')},
            {url: 'faw', title: translate(lang, 'faq').toUpperCase()},
        ]
        return (
            <div>
                <h5 className="clear">{translate(lang, 'info')}</h5>
                <SidebarMenu items={items} />
            </div>
        )
    }
    renderContent() {
        const { lang } = this.props.match.params
        return (
            <div>
                <div className="blocks">
                    <CallToActionBox title={translate(lang, 'join_our_network_today')} btnText={translate(lang, 'sign_up').toUpperCase()} callback={() => {
                        this.props.showOverlay('signup')
                    }} />
                    <div className="block container-fluid" data-block="how-do-we-work">
                        <h1>{translate(lang, 'how_do_we_work_restaurants')}</h1>
                        <div className="description">
                            <p>{translate(lang, 'how_do_we_work_restaurants_text')}</p>
                        </div>
                    </div>
                    <div className="container-fluid featuresbox-container">
                        <FeaturesBox title={translate(lang, 'all_the_tools_you_need')} items={this.featuresBoxItems.bind(this)}/>
                    </div>
                    <div className="block container-fluid" data-block="a-plan-that-works">
                        <h2 className="title-big">{translate(lang, 'plan_that_works_business')}</h2>
                        <div className="col-group memberships">
                            <div className="col-mb-12 col-6">
                                <Membership id="1" />
                            </div>
                            <div className="col-mb-12 col-6">
                                <Membership id="2" />
                            </div>
                        </div>
                    </div>
                    <div className="block container-fluid" data-block="callback">
                        <h3>{translate(lang, 'request_callback')}</h3>
                        <ContactForm />
                    </div>
                    <div className="block container-fluid">
                        <h3>{translate(lang, 'questions')}</h3>
                        <div className="col-group">
                            <div className="col-mb-12 col-6">
                                <p>{translate(lang, 'questions_text_1')}</p>
                            </div>
                            <div className="col-mb-12 col-6">
                                <p>{translate(lang, 'questions_text_2')}</p>
                                <Btn text={translate(lang, 'faq_restaurants').toUpperCase()} className="inverted" callback={() => {
                                    this.props.history.push(`/${lang}/faqs/restaurant`)
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        isLogged: state.isLogged,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantServices))
