import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg';

export default class Stars extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        if (this.props.number === 0 || typeof this.props.number === "string") {
            return null
        }
        let type = this.props.type ? this.props.type : 'default'
        let src = '/static/images/star_' + type + '.svg'
        let stars = []
        for (let i = 0; i < this.props.number; i++) {
            stars.push(<SVG key={i} className="star" src={src} />)
        }
        // let style = {flexBasis: isNaN(this.props.number * 21) ? 21 : this.props.number * 21}
        let style = {}
        return (
            <div className={'component_Stars ' + type} style={style}>
                {stars}
            </div>
        )
    }
};
