import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import SVG from 'react-inlinesvg';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import { translate } from '../../Translations/translate';
import FakeUploadBtn from '../FakeUploadBtn/FakeUploadBtn'


const isUrlRegex = () => {
  let regex = "((?:(http|https|Http|Https|rtsp|Rtsp):\\/\\/(?:(?:[a-zA-Z0-9\\$\\-\\_\\.\\+\\!\\*\\'\\(\\)"
  + "\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,64}(?:\\:(?:[a-zA-Z0-9\\$\\-\\_"
  + "\\.\\+\\!\\*\\'\\(\\)\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,25})?\\@)?)?"
  + "((?:(?:[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}\\.)+"   // named host
  + "(?:"   // plus top level domain
  + "(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])"
  + "|(?:biz|b[abdefghijmnorstvwyz])"
  + "|(?:cat|com|coop|c[acdfghiklmnoruvxyz])"
  + "|d[ejkmoz]"
  + "|(?:edu|e[cegrstu])"
  + "|f[ijkmor]"
  + "|(?:gov|g[abdefghilmnpqrstuwy])"
  + "|h[kmnrtu]"
  + "|(?:info|int|i[delmnoqrst])"
  + "|(?:jobs|j[emop])"
  + "|k[eghimnrwyz]"
  + "|l[abcikrstuvy]"
  + "|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])"
  + "|(?:name|net|n[acefgilopruz])"
  + "|(?:org|om)"
  + "|(?:pro|p[aefghklmnrstwy])"
  + "|qa"
  + "|r[eouw]"
  + "|s[abcdeghijklmnortuvyz]"
  + "|(?:tel|travel|t[cdfghjklmnoprtvwz])"
  + "|u[agkmsyz]"
  + "|v[aceginu]"
  + "|w[fs]"
  + "|y[etu]"
  + "|z[amw]))"
  + "|(?:(?:25[0-5]|2[0-4]" // or ip address
  + "[0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\\.(?:25[0-5]|2[0-4][0-9]"
  + "|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1]"
  + "[0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}"
  + "|[1-9][0-9]|[0-9])))"
  + "(?:\\:\\d{1,5})?)" // plus option port number
  + "(\\/(?:(?:[a-zA-Z0-9\\;\\/\\?\\:\\@\\&\\=\\#\\~"  // plus option query params
  + "\\-\\.\\+\\!\\*\\'\\(\\)\\,\\_])|(?:\\%[a-fA-F0-9]{2}))*)?"
  + "(?:\\b|$)";
  return regex
}

const isUrl = (s) => {
  return new RegExp(isUrlRegex()).test(s)
}

const isEmailRegex = () => {
  let regex = '/[a-zA-Z0-9\\+\\.\\_\\%\\-]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+/gi'
  return regex
}

const isEmail = (s) => {
  return new RegExp(isEmailRegex()).test(s)
}

const FormTextarea = ({
  name,
  label,
  endLabel,
  placeholder,
  onChange,
  errors,
  lang,
  textTranslation,
  addMediaCallback,
  reset,
}) => {
  const error = errors && errors.hasOwnProperty(name) ? errors[name] : null
  const [field, meta, helpers] = useField(name)
  const [editorState, setEditorState] = useState()
  const [html, setHtml] = useState("")
  const [limit, setLimit] = useState(490)

  const handleHtml = (editorState) => {
    let options = {
        defaultBlockTag: 'span',
    }
    let plainText = editorState.getCurrentContent().getPlainText()
    let html = stateToHTML(editorState.getCurrentContent(), options)
    if (isEmail(plainText) || isUrl(plainText)) {
        let re = new RegExp(isUrlRegex(), "i");
        let re2 = new RegExp(isEmailRegex(), "i");
        html = html.replace(re, translate(lang, 'no_urls_allowed'));
        html = html.replace(re2, translate(lang, 'no_emails_allowed'));
    } else {
        html = stateToHTML(editorState.getCurrentContent(), options)
    }

    setEditorState(editorState)
    setHtml(html)
    onChange(html)
  }

  const handleReturn = (e) => {}

  const setContent = (html) => {
    setEditorState(EditorState.createWithContent(stateFromHTML(html)))
  }

  useEffect(() => {
    if (field.value && field.value !== "" && (!textTranslation || textTranslation === field.value)) {
      setContent(field.value)
    } else if (textTranslation) {
      setContent(textTranslation)
    }
  }, [textTranslation])

  useEffect(() => {
    if (reset) {
      setContent("")
    }
  }, [reset])

  useEffect(() => {
    if (field.value && field.value !== "") {
      EditorState.createWithContent(stateFromHTML(field.value))
    } else {
      EditorState.createEmpty()
    }
  }, [])

  return (
    <div style={{ position: 'relative' }} className="component_FormTextarea">
      <div data-type="textarea">
        <label className="inputLabel" htmlFor={name}>
            {label}
            {endLabel ? endLabel : null}
        </label>
        <Editor
            handleReturn={handleReturn}
            editorState={editorState}
            toolbarClassName="toolbar"
            wrapperClassName={`${error ? 'error' : ''} textarea`}
            placeholder={placeholder}
            onEditorStateChange={(editorState) => {
                handleHtml(editorState)
            }}
            toolbar={{
                options: ['inline', 'list'],
                inline: {
                    options: ['bold', 'italic', 'underline']
                },
                list: {
                    options: ['unordered', 'ordered']
                },
            }}
            toolbarCustomButtons={addMediaCallback ? [<FakeUploadBtn btnText={translate(lang, 'add_media').toUpperCase()} className="no-line" callback={addMediaCallback} />] : null}
            />
    </div>
    </div>
  )

}

export default FormTextarea