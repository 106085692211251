import createReducer from '../Services/General/createReducer.js'
import * as types from '../Actions/types.js'

export const deviceScreen = createReducer(null, {
    [types.SET_DEVICE_SCREEN](state, action) {
        state = action.deviceScreen
        return state
    }
})
export const deviceWidth = createReducer(null, {
    [types.SET_DEVICE_WIDTH](state, action) {
        state = action.deviceWidth
        return state
    }
})
export const deviceHeight = createReducer(null, {
    [types.SET_DEVICE_HEIGHT](state, action) {
        state = action.deviceHeight
        return state
    }
})
export const showOverlay = createReducer(null, {
    [types.SHOW_OVERLAY](state, action) {
        state = action.item
        return state
    }
})
export const language = createReducer(null, {
    [types.SET_LANGUAGE](state, action) {
        state = action.language
        return state
    }
})
