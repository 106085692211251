import React from 'react'
import IconButton from "./IconButton"
import { translate } from "../../Translations/translate"

// this component has no default style (it uses 'IconButton.less' styles). To modify style, add a className in props and style it in ShareButton.less file.

const ShareButton = ({
  props,
  urlSearchParam,
  className = "",
  image,
}) => {
  const { lang } = props.match.params

  const handleShare = (e) => {
    e.preventDefault()
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    props.showOverlay('share', { urlSearch: urlSearchParam || null, image: image })
  }

  return (
    <div className="component_ShareButton">
      <div className={className}>
        <IconButton
          icon="share"
          label={translate(lang, 'share')}
          callback={(e) => handleShare(e)}
        />
      </div>
    </div>
  )
}

export default ShareButton