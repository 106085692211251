import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import File from '../../Components/Input/File'
import Input from '../../Components/Input/Input'
import Select from 'react-select'
import Textarea from '../../Components/Input/Textarea'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {contact} from '../../Services/Other/other'
import {countryCodes} from '../../Lists/lists'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
import {viewSelectedOffers} from '../../Services/Offers/offers'

class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            surname: '',
            phone: '',
            phonecode: '',
            company: '',
            email: '',
            subject: '',
            enquiry: '',
            errors: [],
        }
    }

    autofillText(offers, lang) {
        if (offers) {
            const subject = offers.length > 1 ? translate(lang, 'only_cv_subject_plural') : translate(lang, 'only_cv_subject')
            const textIntro = offers.length > 1 ? translate(lang, 'only_cv_text_intro_plural') : translate(lang, 'only_cv_text_intro')
            const textMiddle = translate(lang, 'only_cv_text')

            const completeSubject = `${subject} ${offers.map((o, index) => {
                if (index === 0) {
                    return `${o.offer.id}`
                } else {
                    return ` ${o.offer.id}`
                }
            })}`
            const completeText = `${textIntro}<br/>
            ${offers.map((o, index) => {
                    if (index === 0) {
                        return `<br/>${offers.length > 1 ? `(${index + 1})` : ''} ${o.offer.name} (${o.offer.id}) ${textMiddle} ${o.restaurant.name} (${o.restaurant.id})`
                    } else {
                        return `<br/><br/>(${index + 1}) ${o.offer.name} (${o.offer.id}) ${textMiddle} ${o.restaurant.name} (${o.restaurant.id})`
                    }
                })}`
            this.setState({ subject: completeSubject, enquiry: completeText })
        }
    }

    async showAllSelectedOffers() {
        const { lang, items } = this.props.match.params
        const ids = items.split('&')
        viewSelectedOffers({ id: ids, lang }).then((res) => {
            if (res.data.success) {
                const { data } = res.data
                if (data) {
                    this.autofillText(data, lang)
                }
            } else {
                return
            }
        })
    }

    componentDidMount() {
        const { items } = this.props.match.params
        if (items) {
            this.showAllSelectedOffers()
        }
    }

    componentDidUpdate() {
    }

    render() {
        const { lang } = this.props.match.params
        return (
            <div className="form component_ContactForm">
                <div className="col-group">
                    <div className="col-mb-6 col-4">
                        <Input
                            label={translate(lang, 'name')}
                            name="name"
                            error={this.state.errors}
                            type="text"
                            value={this.state.name}
                            className={this.state.errors.indexOf('name') !== -1 ? 'error' : null}
                            minLength={4}
                            onChange={(v) => {
                                this.setState({name: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-6 col-4">
                        <Input
                            label={translate(lang, 'surname')}
                            name="surname"
                            error={this.state.errors}
                            type="text"
                            value={this.state.surname}
                            className={this.state.errors.indexOf('surname') !== -1 ? 'error' : null}
                            minLength={4}
                            onChange={(v) => {
                                this.setState({surname: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-6 col-4">
                        <Input
                            label={translate(lang, 'email')}
                            name="email"
                            error={this.state.errors}
                            type="email"
                            value={this.state.email}
                            className={this.state.errors.indexOf('email') !== -1 ? 'error' : null}
                            minLength={6}
                            onChange={(v) => {
                                this.setState({email: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-6 col-4">
                        <label htmlFor="">{translate(lang, 'phone_code_number')}</label>
                        <Select
                            placeholder={translate(lang, 'phone_code_number')}
                            name="phonecode"
                            value={this.state.phonecode}
                            className={this.state.errors.indexOf('phonecode') !== -1 ? 'error' : null}
                            onChange={(value) => { this.setState({ phonecode: value.value }) }}
                            options={countryCodes[lang]}
                            />
                    </div>
                    <div className="col-mb-6 col-4">
                        <Input
                            label={translate(lang, 'phone_number')}
                            name="phone"
                            error={this.state.errors}
                            type="text"
                            value={this.state.phone}
                            className={this.state.errors.indexOf('phone') !== -1 ? 'error' : null}
                            minLength={4}
                            onChange={(v) => {
                                this.setState({phone: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-6 col-4">
                        <Input
                            label={translate(lang, 'company')}
                            name="company"
                            error={this.state.errors}
                            type="text"
                            value={this.state.company}
                            className={this.state.errors.indexOf('company') !== -1 ? 'error' : null}
                            minLength={6}
                            onChange={(v) => {
                                this.setState({company: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-12 col-4">
                        <Input
                            label={translate(lang, 'enquiry_subject')}
                            name="subject"
                            error={this.state.errors}
                            type="text"
                            value={this.state.subject}
                            className={this.state.errors.indexOf('subject') !== -1 ? 'error' : null}
                            minLength={6}
                            onChange={(v) => {
                                this.setState({subject: v})
                            }}
                            />
                        <File
                            label={translate(lang, 'upload_cv')}
                            name="cv"
                            error={this.state.errors}
                            type="text"
                            onChange={(v) => {
                                this.setState({cv: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-12 col-8">
                        <Textarea
                            lang={lang}
                            label={translate(lang, 'your_enquiry')}
                            name="enquiry"
                            error={this.state.errors}
                            type="text"
                            value={this.state.enquiry}
                            className={this.state.errors.indexOf('enquiry') !== -1 ? 'error' : null}
                            minLength={6}
                            onChange={(v) => {
                                this.setState({enquiry: v})
                            }}
                            enableReceiveProps={true}
                            />
                    </div>
                    <div className="col-mb-12 actions right">
                        <Btn text={translate(lang, 'send').toUpperCase()} loading={this.props.loading} callback={() => {
                            this.contact()
                        }} />
                    </div>
                </div>
            </div>
        )
    }
    contact() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'name', minLength: 4},
            {field: 'surname', minLength: 4},
            {field: 'phone', minLength: 2},
            {field: 'company', minLength: 4},
            {field: 'email', minLength: 4},
            {field: 'subject', minLength: 4},
            {field: 'enquiry', minLength: 4},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })

            let data = {
                name: this.state.name,
                surname: this.state.surname,
                phone: this.state.phone,
                phonecode: this.state.phonecode,
                company: this.state.company,
                email: this.state.email,
                subject: this.state.subject,
                enquiry: this.state.enquiry,
                fileupload: this.state.cv ? 1 : 0,
                curriculum: this.state.cv,
                callback: this.props.isCallback ? 1 : 0,
                lang: lang
            }
            contact(data).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                } else {
                    this.props.showOverlay('success', {message: translate(lang, 'message_was_sent')})
                    this.setState({
                        name: '',
                        surname: '',
                        phone: '',
                        phonecode: '',
                        company: '',
                        email: '',
                        subject: '',
                        enquiry: '',
                        cv: null,
                    })
                }
            }).catch((e) => {
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ContactForm))
