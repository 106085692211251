var find = require('lodash/find')

export function findOption(id, options, defValue = '_') {
    let item = find(options, (o) => {
        return o.value == id
    })
    if (item) {
        return item.label
    } else {
        if (defValue !== false) {
            return defValue + id
        } else {
            return false
        }
    }
}
