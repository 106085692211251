import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class SuccessOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        const { lang } = this.props.match.params
        let className = 'success'
        if (this.props.className) {
            className += ' '+this.props.className
        }
        let message = this.props.data.message ? this.props.data.message : translate(lang, 'action_completed')
        return (
            <Overlay title={translate(lang, 'success')} className={className}>
                <div className="col-group">
                    <div className="col-mb-12">
                        <br/>
                        <p dangerouslySetInnerHTML={{__html:message}}></p>
                    </div>
                    <div className="col-mb-12 actions">
                        <Btn text={translate(lang, 'ok').toUpperCase()} className="success" callback={() => {
                            this.props.showOverlay(null)
                            if (this.props.data.refreshAfter && typeof window !== 'undefined') {
                                setTimeout(() => {
                                    window.location.reload()
                                }, 1000)
                            }
                        }} />
                    </div>
                </div>
            </Overlay>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(SuccessOverlay))
