import React, {Component} from 'react'
import SVG from 'react-inlinesvg'
import { translate } from '../../Translations/translate'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'

class Switch extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {

        let className = 'component_Switch'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        if (this.props.active === true || parseInt(this.props.active) === 1) {
            className += ' active'
        }
        if (this.props.loading) {
            className += ' loading'
        }
        if (this.props.inLeft) {
            className += ' in-left'
        }
        return (
            <div className={className} onClick={this.handleClick.bind(this)}>
                {this.renderContent()}
            </div>
        )
    }
    renderContent() {
        if (this.props.loading) {
            return <div className="loader"><SVG src='/static/images/loading_icn.svg' /></div>
        } else {
            return <div>{this.renderLabel('left')}<i></i>{this.renderLabel('right')}</div>
        }
    }
    renderLabel(pos) {
        const { lang } = this.props.match.params
        let left = this.props.inLeft ? true : false
        let label = <span>{this.props.active === true || parseInt(this.props.active) === 1 ? translate(lang, 'active') : translate(lang, 'inactive')}</span>
        if (pos === 'left' && left) {
            return label
        }
        if (pos === 'right' && !left) {
            return label
        }
        return null
    }
    handleClick() {
        if (this.props.callback !== undefined) {
            this.props.callback()
        }
    }
};



const mapStateToProps = state => {
    return {    
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Switch))
