export const es = {

        about: "Acerca de",
        about_chef: "Sobre el Chef",
        about_restaurant: "Sobre el Restaurante",
        about_us: "Sobre nosotros",
        accept: "Aceptar",
        accepted: "Aceptado",
        action_completed: 'Acción completada',
        active: "Activo",
        active_NUM: "Activo (%@)",
        active_offers: "Ofertas activas",
        active_offers_NUM: "Ofertas activas: %@",
        ad_manager: "Administrador de anuncios",
        add: "Añadir",
        add_address: "Añadir dirección",
        add_email: "Añadir correo electrónico",
        add_media: "Añadir fotografías",
        add_phone_number: "Añadir número de teléfono",
        add_restaurant: "Añadir restaurante",
        add_social_network: "Añadir red social",
        add_to_job_description: "Añadir a la descripción del trabajo",
        address: "Dirección",
        ads: "Anuncios",
        all_the_tools_you_need: "Todas las herramientas que necesitarás",
        already_member: "¿Ya eres usuario?",
        and: " y ",
        applic: 'Solic.',
        applicants: "Solicitantes",
        applicants_NUM: '(%@) Solicitantes',
        application_confirmation: "Confirmación de solicitud",
        application_form: "Formulario de solicitud",
        application_sent: "Solicitud enviada",
        applications: "Solicitudes",
        applications_NUM: "Solicitudes (%@)",
        applied_on: "Solicitado en",
        apply: "Solicitar",
        apply_jobs_content: 'Las ofertas de empleo publicadas en nuestro sitio web son un resumen de la información más importante y necesaria. Al solicitar la oferta de empleo, se notificará al restaurante que estás interesado en ese trabajo y recibirá una descripción completa de tu currículum.',
        apply_jobs_subtitle: 'Buscar ofertas de trabajo en nuestra red',
        apply_jobs_title: 'Solicitar puestos de trabajo',
        attach_file: "Adjuntar archivo",

        back: 'Atrás',
        back_to_active_chefs: "Volver a los chefs activos",
        back_to_all_offers: "Volver a todas las ofertas",
        back_to_lastest_offers: "Volver a las últimas ofertas",
        back_to_restaurants_list: "Volver a la lista de restaurantes",
        block: 'Bloquear',
        block_page: 'Bloquear página',
        block_this_page: 'Bloquea esta página para que no vea tu perfil',
        blog: "Blog",
        book_stage: "¡Reserva una práctica con nosotros!",
        browse: "busca",

        calendar: "Calendario",
        callback: "Contacta",
        cancel: "Cancelar",
        center: "Centro",
        chef_profile: "Currículum del Chef",
        chef_requirements: "Requisitos del chef",
        chef: "Chef",
        chef_faqs: "FAQS para chefs",
        chef_services: 'Servicios para chefs',
        chefs: "Chefs",
        chefs_active_NUM: "Chefs activos (%@)",
        choose_file: "Eliegir archivo",
        choose_image: "Elegir imagen",
        choose_profile_type: "Es esencial que no se equivoque al elegir su tipo de perfil,",
        click_date: 'Haga clic en una fecha para ver los detalles de su solicitud',
        closed: "Cerrado",
        company: "Empresa",
        contact: "Contacto",
        contact_email: "Correo electrónico de contacto",
        cookie_policy: "Política de cookies",
        copy_link: "Copiar link",
        copyright: "2018. CHEFS LINK. TODOS LOS DERECHOS RESERVADOS.",
        cover_picture: "Imagen de portada",
        covers: "Portadas",
        continue: "Continuar",
        continue_later: "Continuar más tarde",
        create_account: "Crear una cuenta",
        create_account_description_1: "Si todavía no tienes una cuenta de usuario creada por chefslink.com, utiliza esta opción para acceder al formulario de registro.",
        create_account_description_2: "Introduce tu correo electrónico y te pediremos la información esencial para crear una cuenta. Podrás completar tu perfil más adelante.",
        create_page: 'Crear una página',
        create_page_content: 'Esta información nos proporcionará todos los detalles necesarios que necesitamos para comprender los requisitos de tu empresa. Al hacerlo, nos permite ponernos manos a la obra.',
        create_page_title: 'Ayúdanos a entender tus necesidades',
        create_profile: 'Crear un perfil',
        create_profile_content: 'Esta información no solo se publicará en nuestro sitio web para que los restaurantes la vean, sino que también proporcionará a nuestro equipo toda la información necesaria para ayudarte a encontrar el mejor trabajo posible.',
        create_profile_title: 'Anuncio paso a paso en nuestro sitio web',
        create_restaurant: "Crear restaurante",
        create_your_profile: "¡Crea tu perfil y date a conocer!",
        create_your_profile_text: "Trabaja en los restaurantes más exclusivos.",
        cuisine: "Cocina",
        cuisines: "Cocinas",
        currency: "Moneda",
        currently: 'Actualmente',
        currently_studying: "Actualmente estoy estudiando",
        currently_work_here: "Actualmente trabajo aquí",
        curriculum_vitae: "Currículum vitae",

        date: "Fecha",
        date_of_birth: "Fecha de nacimiento",
        days_off: "Días libres:",
        default_email_message: "Mensaje predeterminado para los chefs",
        default_language: "Idioma predeterminado",
        delete: "Borrar",
        delete_application: '¿Quieres borrar esta solicitud?',
        delete_conversation: '¿Quieres borrar la conversación? Esta acción es irreversible',
        delete_offer: "¿Quieres borrar la oferta?",
        description: "Descripción",
        details: "Detalles",
        dinner: "Cena",
        direct_message_content_chef: 'Nuestro sistema de mensajes hace que sea más fácil chatear o compartir archivos directamente con restaurantes premium en nuestro sitio web en caso de necesitar más información sobre las ofertas de trabajo publicadas.',
        direct_message_content_restaurant: 'Como cliente Premium, puedes chatear directamente con nuestros Chefs en caso de necesitar más información para programar un turno de prueba, o simplemente ver si el chef podría estar interesado en trabajar en tu empresa.',
        direct_message_subtitle_chef: 'Necesito más información',
        direct_message_subtitle_restaurant: 'Para más información',
        direct_message_title: 'Mensaje directo',
        double_check: "Mira las novedades!",
        drop_file_here: "Arrastra tu archivo aquí o ",
        drop_us_a_line: "Escríbenos",

        edit: "Editar",
        edit_about: "Editar Acerca de",
        edit_address: "Editar dirección",
        edit_chef: "Editar Chef",
        edit_cover: "Editar portada",
        edit_education: "Editar educación",
        edit_email: "Editar correo electrónico",
        edit_language: "Editar idioma",
        edit_more_information: "Editar más información",
        edit_offer: "Editar oferta",
        edit_opening_hours: "Editar horas de apertura",
        edit_page: "Editar página",
        edit_phone_number: "Editar número de teléfono",
        edit_settings: "Editar configuración",
        edit_social_network: "Editar red social",
        edit_work_experience: "Editar experiencia de trabajo",
        education: "Educación",
        education_training: "Educación y formación",
        email: "Correo electrónico",
        email_exists: "Esta dirección de correo electrónico ya está en uso, por favor haga clic en \"Olvidé mi contraseña\" para restablecerla. Recibirá un correo electrónico para restablecer su contraseña. O puede enviarnos un correo electrónico a info@chefslink.com",
        email_placeholder: 'Introduce tu correo electrónico',
        email_reset_password: "Te hemos enviado un correo electrónico para restablecer la contraseña.",
        enquiry_subject: "Tema de consulta",
        error: "Lo sentimos, ha habido un error",
        error_request: "Hubo un error al procesar tu solicitud. Por favor, inténtalo de nuevo.",

        faq: "FAQ",
        faqs: "Preguntas frecuentes",
        faq_chefs: "Preguntas frecuentes para chefs",
        faq_restaurants: "Preguntas frecuentes para restaurantes",
        file_attached: "Archivo adjunto",
        filter_by: "Filtrar por",
        file_selected: "Has seleccionado",
        find_chefs: "Encontrar chefs",
        find_chefs_now: "Buscar chefs ahora:",
        find_jobs: "Encontrar trabajos",
        find_jobs_now: "Buscar trabajos ahora:",
        find_restaurants: 'Encontrar restaurantes',
        find_restaurants_now: "Buscar restaurantes ahora:",
        food_standards: "Normas alimentarias",
        forgot_password: "¿Olvidaste tu contraseña?",
        from: "Desde",
        future_opportunities: "Futuras oportunidades",

        get_in_touch: "Contactar",
        go: "Ir",
        go_back: "Volver",
        guru_template: "Plantilla de gurú",

        head_chef: "Jefe de cocina",
        header: 'Encabezamiento',
        headline: "Titular",
        health_safety: "Salud y seguridad",
        help_job_description: '¿Necesitas ayuda para escribir una descripción del trabajo?',
        help_job_description_text: 'Haz clic en los elementos que mejor describan tus objetivos de contratación y edita los detalles después.',
        help_recruit: "Ayúdanos a reclutar para este puesto (se aplicará una tarifa del 3%)",
        hide: "Ocultar",
        hide_menu: "Ocultar menú",
        hide_salary: "Ocultar salario",
        holidays: "Vacaciones:",
        home: "Inicio",
        how_do_we_work: "Cómo trabajamos",
        how_do_we_work_chefs: "¿Cómo trabajamos para los chefs?",
        how_do_we_work_chefs_text: "Chefs Link es un servicio gratuito para todos nuestros chefs que buscan trabajo. No es necesario ningún pago en absoluto. Tenemos acceso a una extensa red de restaurantes con las mejores oportunidades de carrera disponibles. Oportunidades que prometen impulsar tu carrera como chef, y te permitirán trabajar junto con algunos de los mejores chefs del mundo, siendo guiado por ellos. Nos dedicamos a gestionar tu carrera de chef.",
        how_do_we_work_restaurants: "¿Cómo trabajamos para restaurantes?",
        how_do_we_work_restaurants_text: "Dedicamos nuestro tiempo a encontrar los chefs más talentosos para nuestros restaurantes a través de nuestras redes, marketing y buenas relaciones con algunas de las mejores escuelas y universidades culinarias del mundo. De esta forma podemos encontrar el talento que satisfaga sus necesidades específicas. Nuestra empresa está diseñada para eliminar las molestias y frustraciones asociadas con el proceso de contratación, haciéndolo lo más fácil posible para tí y tu empresa.",

        import_template_NAME: '¿Te gustaría importar la plantilla "%@"?',
        in: "En",
        in_review: "En revisión",
        inactive: "Inactivo",
        inactive_NUM: "Inactivo (%@)",
        info: "Info:",
        invalid_email: "Correo electrónico no válido",

        job_description: "Descripción del trabajo",
        job_offers: "Ofertas de trabajo",
        job_offers_NUM: "Ofertas de trabajo (%@)",
        job_offers_you_saved: "Estas son las ofertas de trabajo que has guardado!",
        join_our_network_today: "¡Únete a nuestra red hoy!",
        join_the_community: "O puedes unirte a la comunidad y ",

        language: "Idioma",
        languages: "Idiomas",
        last_job_offers_NUM: "Últimas ofertas de trabajo (%@)",
        last_login: "Último acceso",
        latest_job_offer_alerts_content: 'Recibirás una alerta semanal por correo electrónico con las últimas ofertas de empleo que se vincularon automáticamente a tu perfil de acuerdo con la información que has proporcionado.',
        latest_job_offer_alerts_title: 'Últimas alertas de ofertas de empleo',
        latest_job_offer_alerts_subtitle: 'Ofertas relacionadas con tu perfil',
        launch_guru_template: "Lanzar la plantilla gurú",
        less: "- Menos",
        level: "Nivel",
        link: "Enlazar",
        link_now: 'Enlace ahora',
        link_offer: "Enlace a la oferta",
        link_tech_content_chef: 'El sistema de vinculación inteligente de nuestro sitio web te enlazará automáticamente con los restaurantes que te interesan. Solo te mostrará las ofertas que coincidan con tu perfil para que no tenga que perder tiempo o pasar horas buscando ofertas que sean irrelevantes para tí.',
        link_tech_content_restaurant: 'El sistema de vinculación inteligente de nuestro sitio web te enlazará automáticamente con los chefs que más te interesan a tu empresa para que no tengas que perder tiempo o pasar horas buscando entre cientos de currículums irrelevantes para tí.',
        link_tech_subtitle_chef: 'Conectándote con las personas adecuadas',
        link_tech_subtitle_restaurant: 'Conectándote con los chefs adecuados',
        link_tech_title: 'Tecnología de enlace',
        link_to_our_page: 'Conéctate a nuestra página para ver nuestras últimas ofertas de trabajo y recibe una notificación cuando publiquemos una nueva oferta de trabajo.',
        link_with_us: 'Enlaza con nosotros',
        links: "Enlaces",
        links_accepted_NUM: "Tienes %@ enlace%@ aceptado%@!",
        links_for_you: "¡Enlaces para tí!",
        links_pending_NUM: "Tienes %@ enlace%@ pendiente%@!",
        links_rejected_NUM: "Tienes %@ enlace%@ rechazado%@!",
        load_more_items: "Cargar más ítems",
        loading: 'Cargando',
        location: "Ubicación",
        log_in: "Iniciar sesión",
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        lunch: "Almuerzo",

        manage: "Gestionar",
        manage_pages: "Administrar Páginas",
        manage_your_pages: "Gestiona tus páginas",
        management: "Administración",
        me: "Yo",
        media: "Fotografías",
        member_login: "Inicio de sesión",
        membership_requested: 'Membresía requerida',
        menu: 'Menú',
        mess: "Mens.",
        message: "Mensaje",
        message_from_restaurant: "Mensaje del restaurante",
        message_sent: "Mensaje enviado",
        message_was_sent: "El mensaje fue enviado",
        messages: "Mensajes",
        messages_from: 'Mensajes de',
        messages_overview: "Resumen de tus mensajes",
        miniature: 'Miniatura',
        month: "Mes",
        more: "+ Más",
        moremore: "Más",
        more_info: "Más información",
        my_jobs_NUM: 'Mis trabajos (%@)',
        my_profile: "Mi perfil",

        name: "Nombre",
        name_placeholder: "Introduce tu nombre",
        nationalities: "Nacionalidades",
        negotiable_salary: " - Salario negociable",
        new_password: "Nueva contraseña",
        new_trial_date: "Nueva fecha:",
        no_accepted_applications: "No hay solicitudes aceptadas",
        no_accepted_links: "No hay enlaces aceptados",
        no_active_offers: "No hay ofertas activas",
        no_emails_allowed: ' -NO SE PERMITEN CORREOS ELECTRÓNICOS- ',
        no_in_review_applications: "No hay solicitudes en revisión",
        no_information: "Sin información",
        no_job_offers: 'No hay ofertas de trabajo',
        no_message: "Sin mensajes",
        no_messages: 'No hay mensajes',
        no_notifications: "No hay notificaciones",
        no_pending_applications: "No hay solicitudes pendientes",
        no_pending_links: "No hay enlaces pendientes",
        no_rejected_applications: "No hay solicitudes rechazadas",
        no_rejected_links: "No hay enlaces rechazados",
        no_results: "No hay resultados para su búsqueda",
        no_saved_offers: 'No hay ofertas guardadas',
        no_sent_applications: "No hay solicitudes enviadas",
        no_stages_available: "No hay prácticas disponibles",
        no_time: 'No se ha concretado hora',
        no_trials: "No hay solicitudes",
        no_urls_allowed: ' -NO SE PERMITEN URLS- ',
        not_available: "no disponible",
        not_logged_in: "Necesitas estar registrado para continuar. ¿No eres un miembro todavía? <strong>Crea una cuenta gratuita</strong> y ten acceso a ofertas ilimitadas, solicita y ponte en contacto directamente con restaurantes de todo el mundo.",
        notifications: "Notificaciones",

        offer_not_available: 'Esta oferta no está disponible.',
        offer_saved: 'La oferta fue guardada',
        offer_sent: 'Oferta enviada',
        offer_sent_to_client: "La oferta fue enviada al cliente",
        offers: "Ofertas",
        ok: "De acuerdo",
        only_cv_subject: 'Solicitud para oferta',
        only_cv_subject_plural: 'Solicitud para ofertas',
        only_cv_text: 'publicada por el restaurante',
        only_cv_text_intro: 'Me gustaría enviar mi CV para solicitar la siguiente oferta:',
        only_cv_text_intro_plural: 'Me gustaría enviar mi CV para solicitar las siguientes ofertas:',
        opening_hours: "Horario de apertura",
        other_duties: "Otras atribuciones",

        password: "Contraseña",
        password_changed: "Tu contraseña ha sido modificada",
        password_placeholder: 'Introduce tu contraseña',
        pause: "Pausa",
        paused: "En pausa",
        pending: "Pendiente",
        pending_applicants_NUM: "%@ aspirantes pendientes",
        person: "Persona",
        phone: "Teléfono",
        phone_code_number: "Código telefónico",
        phone_number: "Número de teléfono",
        phone_placeholder: "Introduce tu número de teléfono",
        plan_that_works: "Un plan que funciona",
        plan_that_works_business: "Un plan que funciona para tu negocio",
        position: "Posición",
        post_job_offers: "Publicar ofertas de trabajo",
        post_jobs: "Publicar trabajos",
        post_jobs_content: 'Crea tantas ofertas de trabajo en nuestro sitio web como desees. Almacena los anuncios para futuros procesos de contratación. Con la información proporcionada en los anuncios, nuestro equipo de reclutamiento comenzará a buscar chefs y te enviará posibles candidatos.',
        post_jobs_subtitle: 'Crear anuncios rápidos y fáciles en nuestro sitio web',
        premium: 'Premium',
        preview: 'Vista previa',
        preview_as_restaurant: "Vista previa como restaurante",
        previous_experience: "Experiencia previa",
        privacy_policy: "Política de privacidad",
        profile: "Perfil",
        profile_type_placeholder: "¿Eres un chef o un restaurante?",
        profile_types: "Chef o Restaurante",
        propose_new_date: "Proponer una nueva fecha",
        proposed_offer: 'Oferta propuesta',
        published_on: "Publicado en",

        questions: "¿Tienes alguna pregunta?",
        questions_text_1: "Hemos preparado una lista con las preguntas más frecuentes (FAQ) para ayudarte con cualquier duda que puedas tener. En el caso de que no encuentres la respuesta",
        questions_text_2: "no dudes en ponerte en contacto con nosotros. Estaremos encantados de ayudarte.",

        rating: "Clasificación",
        rating_type: "Tipo de calificación",
        reached_limit: "Has llegado al límite de 10 chefs",
        read_less: "- Leer menos",
        read_more: "+ Leer más",
        register_as: "Registrarse como",
        reject: "Rechazar",
        rejected: "Rechazado",
        rejected_trial_dates: "Lamentablemente el restaurante ha rechazado las fechas propuestas. Sin embargo, sugirieron otra fecha para hacer la (%@). Por favor, acepta o sugiere otra fecha disponible.",
        related_job_offers_NUM: "Ofertas de trabajo relacionadas (%@)",
        remember_me: "Recuérdame",
        remove_file: "¿Te gustaría eliminar el archivo?",
        repeat_password: "Repite la contraseña",
        repeat_password_placeholder: "Repite tu contraseña",
        repeat_new_password: "Repite la nueva contraseña",
        report: "Denunciar",
        report_page: "Denuncia esta página a Chefs Link",
        request: "Solicitud",
        request_callback: "Solicitar contacto",
        request_sent: "La solicitud fue enviada",
        reset_password: "Restablecer la contraseña",
        restaurant: 'Restaurante',
        restaurant_faqs: "FAQS para restaurantes",
        restaurant_manager_email: 'Correo electrónico para recibir solicitudes y mensajes para este restaurante (opcional)',
        restaurant_message: "Mensaje del restaurante",
        restaurant_profile: "Perfil del restaurante",
        restaurant_profiles: "Perfiles de restaurante",
        restaurant_proposed_offer: 'El restaurante ha propuesto una oferta',
        restaurant_services: 'Servicios para restaurantes',
        restaurants: 'Restaurantes',
        restaurants_list_NUM: "Lista de restaurantes (%@)",

        salary: "Salario",
        salary_from: "Salario desde",
        salary_to: "Salario hasta",
        save: "Guardar",
        save_add_more: "Guardar y añadir más",
        save_continue: "Guardar y continuar",
        saved: "Guardado",
        search_chefs_content: 'Todos los chefs en nuestra red tienen un resumen de sus currículos con solo la información más relevante de su experiencia como Chef publicado en nuestro sitio web. Selecciona los perfiles de Chefs que te interesen y envíales una oferta de trabajo.',
        search_chefs_subtitle: 'Necesito chefs',
        search_chefs_title: 'Buscar Chefs',
        search_results: "Resultados de la búsqueda",
        search_thousands_offer: 'Buscar miles de ofertas de trabajo para chefs',
        sector: "Sector",
        select_audience_content: 'Cuando te registres con nosotros, te daremos la opción de seleccionar la nacionalidad y el idioma de tu audiencia. Filtrará para que solo ciertos chefs vean y soliciten tus ofertas de trabajo y también mostrará esos perfiles en la sección "buscar chefs".',
        select_audience_subtitle: '¿A quién quieres contratar?',
        select_audience_title: 'Selecciona tu audiencia',
        select_available_trial_dates: "Selecciona hasta tres fechas en las que estés disponible",
        select_dates: "Selecciona las fechas iniciales y finales",
        select_file: "Selecciona un archivo de tu biblioteca de medios",
        select_new_trial_date: "Por favor, selecciona una nueva fecha disponible",
        select_offer: "Selecciona una oferta",
        select_status: "Selecciona un estado",
        select_type: "Seleccionar tipo",
        selected_chefs_NUM: "(%@) chefs seleccionados para esta oferta",
        selected_chefs_NUM_mobile: "Chefs seleccionados (%@)",
        selected_offers_NUM: "Has seleccionado (%@) ofertas de trabajo",
        selected_offers_NUM_mobile: "Ofertas seleccionadas (%@)",
        selected_one_file: "Has seleccionado un archivo",
        send: "Enviar",
        send_cv: "Enviar CV",
        send_cv_only: "Enviar solo CV",
        send_curriculum_vitae: "Enviar currículum vitae",
        send_job_offer: "Enviar oferta de trabajo",
        send_message: "Enviar un mensaje",
        send_message_to_restaurant: "Enviar un mensaje al restaurante",
        send_offer: "Enviar oferta",
        sent: "Enviado",
        sent_it: "Enviado",
        sent_you_message: " te envió un mensaje",
        services: "Servicios",
        services_chefs: "Servicios para chefs",
        services_restaurants: "Servicios para restaurantes",
        settings: "Ajustes",
        share: "Compartir",
        share_on: "Compartir en",
        share_profile_content: 'Puedes compartir o descargar tu perfil haciendo clic en el botón compartir. Te dará algunas opciones para descargar en formato CV o copiar la URL y enviarla como archivo adjunto en cualquier sistema de mensajería social o correo electrónico.',
        share_profile_subtitle: 'Ten siempre tu "CV" contigo.',
        share_profile_title: 'Comparte tu perfil',
        show_menu: "Mostrar menú",
        showcase: 'Muestra',
        sign_up: "Regístrate",
        signup_terms: "Crear una cuenta significa que estás de acuerdo con nuestra ",
        smart_banner_apple: " - En la App Store",
        smart_banner_free: "GRATIS",
        smart_banner_google: " - En Google Play",
        smart_banner_view: "VER",
        social_network: "Red social",
        social_networks: "Redes sociales",
        square: 'Cuadrado',
        staff_development: "Desarrollo del personal",
        stage: "Práctica",
        stage_info: "Información de la práctica",
        start_adding_chefs: "Empieza a añadir chefs",
        start_adding_covers: "Añade una portada",
        start_date: "Fecha de inicio:",
        start_editing_opening_hours: "Edita tus horas de apertura",
        status: "Estado",
        stay_up_to_date: "¡Mantente al día!",
        stay_up_to_date_profile: "¡Mantén tu perfil actualizado!",
        success: 'Éxito',
        support: "Soporte",
        surname: "Apellido",
        switch: "Cambiar",
        switch_active_page: "Cambiar página activa",

        team: 'Equipo',
        template: "Plantilla",
        term: "Término:",
        text: "Texto",
        the_chef: "El Chef",
        them: "Ellos",
        title: "Puesto de trabajo",
        to: "a",
        total_applications_NUM: "Total de solicitudes (%@)",
        trial_date: 'fecha de la (%@):',
        type: "Tipo",
        type_or_options: "Empieza a escribir o selecciona una de nuestras opciones",

        unblock: 'Desbloquear',
        upgrade_premium: "Hazte cliente Premium",
        upload: "Subir",
        upload_cv: "Subir CV",
        url: "URL",

        view_calendar: "Ver calendario",
        view_links: "Ver enlaces",
        view_media: "Ver medios",

        w_h: "S/H:",
        w_hours: "S/Horas:",
        waiting_chef_response: "Esperando la respuesta del chef",
        waiting_response: 'Esperando respuesta',
        waiting_restaurant_confirmation: "Estamos esperando la confirmación del restaurante",
        waiting_restaurant_response: 'Esperando la respuesta del restaurante',
        weekly_days_off: "Días libres semanales",
        weekly_hours: "Horas semanales",
        when_available_to_start: "En caso de ser aceptado, ¿cuándo estás disponible para empezar?",
        work_experience: "Experiencia laboral",
        work_visas: "Permisos de trabajo",
        write_new_message_here: "Escribe tu mensaje aquí",

        year: "Año",
        your_conversation: "Tu conversación",
        your_enquiry: "Tu consulta",
        your_message: "Tu mensaje",
        your_job_offers_NUM: "Tus ofertas de trabajo (%@)",


        // Form error messages: (chef profile)
        error_email_required: 'El correo electrónico es obligatorio',
        error_email_valid: 'El correo electrónico debe ser válido',
        error_password_required: 'Se requiere una contraseña',
        error_password_length: 'La contraseña debe tener al menos 6 caracteres',
        error_terms_required: 'Debe aceptar nuestras condiciones para continuar',
        error_type_profile: 'Debe seleccionar un tipo de perfil',
        error_name_letters: 'El nombre sólo puede contener letras',
        error_name_length: 'El nombre debe tener al menos 2 caracteres',
        error_name_required: 'El nombre es obligatorio',
        error_phone_numbers: 'El teléfono sólo puede contener números',
        error_phone_length: 'El número de teléfono debe tener al menos 4 caracteres',
        error_phone_required: 'El teléfono es necesario',
        error_password_match: 'Las contraseñas no coinciden',
        error_password_confirm: 'Confirme su contraseña',
        error_login_combination: 'La combinación de usuario/contraseña no existe',
        error_email_unique: 'El correo electrónico ya está en uso',
        error_required_fields: 'Debe rellenar los campos obligatorios',

        // Forms placeholders: (chef profile)
        placeholder_position: 'Selecciona tu posición',
        placeholder_company_name: 'Añade el nombre del centro',
        placeholder_company_url: 'Añade el sitio web del centro',
        placeholder_location: 'Añade una localización',
        placeholder_select_type: 'Selecciona el tipo',
        placeholder_select_month: 'Selecciona el mes',
        placeholder_select_year: 'Selecciona el año',
        placeholder_education_title: 'Añade el título educativo',
        placeholder_center_name: 'Añade el nombre del centro',
        placeholder_center_url: 'Añade el sitio web del centro',
        placeholder_select_language: 'Selecciona un idioma',
        placeholder_select_level: 'Selecciona un nivel',
        placeholder_select_country: 'Selecciona un país',

        // Chef profile literals:
        add_description: 'Añade una descripción en tu perfil, para que la gente te conozca un poco más.',
        add_work_experience: 'Añade tu experiencia laboral.',
        add_education: 'Añade tu educación.',
        add_languages: 'Añade tus idiomas.',
        add_referrals: 'Añade más información que pueda ser de interés.',
        no_chef_info: 'El chef aún no ha agregado información.',
        start_your_profile: '¡Hola! Comienza a completar tu perfil.',
        start: 'Comenzar',
        profile_status: 'Estado del perfil',
        add_work_experience_extended: "Comienza a añadir tu experiencia laboral.<br>Puedes añadir más, editar o eliminar desde tu página de perfil.",
        add_education_extended: "Comienza a añadir tu educación.<br>Puedes añadir más, editar o eliminar desde tu página de perfil.",
        add_languages_extended: "Comienza a añadir tus idiomas.<br>Puedes añadir más, editar o eliminar desde tu página de perfil.",
        add_referrals_extended: "Añade más información que pueda ser de interés.<br>Puedes añadir más, editar o eliminar desde tu página de perfil.",

}