import * as UserActions from './user.js'
import * as UtilActions from './util.js'
import * as OfferActions from './offer.js'
import * as FilterActions from './filter.js'
import * as ChefActions from './chef.js'

export const ActionCreators = Object.assign({},
    UserActions,
    UtilActions,
    OfferActions,
    FilterActions,
    ChefActions,
);
