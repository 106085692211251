import React from 'react'
import { Field } from 'formik'
import SVG from 'react-inlinesvg';

const FormCheckbox = ({ label, id, name, onChange, checked, errors, showErrorMessage = false }) => {
  const image = checked ? '/static/images/tick1.svg' : '/static/images/tick2.svg'
  const error = errors && errors.hasOwnProperty(name) ? errors[name] : null

  return (
    <div className="component_FormCheckbox" style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
        <Field
          id={id}
          type="checkbox"
          name={name}
          onChange={onChange}
          checked={checked}
          style={{
            position: "absolute",
          }}
          className="field"
        />
      <label htmlFor={id} className="checkbox">
        <div className={`rc-checkbox ${checked ? 'checked' : ''}`}><SVG src={image} /></div>
        <span className='checkboxLabel' dangerouslySetInnerHTML={{__html: label}} />
      </label>
      {error && showErrorMessage && <div className="checkboxError errorMessage">{error}</div>}
    </div>
  )
}

export default FormCheckbox