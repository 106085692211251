export const lists_pt = {

    disponibility: [
        {label: 'Disponível atualmente', value: 1},
        {label: 'Disponível a partir de', value: 2},
        {label: 'Eu não sei quando estarei disponível', value: 3},
        {label: 'Disponível para mudar', value: 4},
    ],

    currencies: [
        {label: '£', value: 1},
        {label: '€', value: 2},
        {label: '$', value: 3},
        {label: '¥', value: 4},
        {label: 'SFr', value: 5},
    ],

    positions: [
        {label: 'Chef Patrono', value: 1},
        {label: 'Chefe Executivo', value: 2},
        {label: 'Chefe de cozinha', value: 3},
        {label: 'Sous-chef executivo', value: 4},
        {label: 'Sous-chef Senior', value: 5},
        {label: 'Sous-chef', value: 6},
        {label: 'Sous-chef Junior', value: 7},
        {label: 'Chef de Partie Senior', value: 8},
        {label: 'Chef de Partie', value: 9},
        {label: 'Demi-chef', value: 10},
        {label: 'Comis Chef', value: 11},
        {label: 'Aprendiz de chef', value: 12},
        {label: 'Chef de café da manhã', value: 13},
        {label: 'Chef executivo de pastelaria', value: 14},
        {label: 'Chefe de pastelaria', value: 15},
        {label: 'Pasteleiro Sous-chef Senior', value: 16},
        {label: 'Pasteleiro Sous-chef', value: 17},
        {label: 'Pastelaria Sous-chef Junior', value: 18},
        {label: 'Pasteleiro Chef de Partie Senior', value: 19},
        {label: 'Pasteleiro Chef de Partie', value: 20},
        {label: 'Pasteleiro Demi-chef', value: 21},
        {label: 'Pastelaria Comis Chef', value: 22},
        {label: 'Chefe Padeiro', value: 23},
        {label: 'Padeiro', value: 24},
        {label: 'Sushi Chef', value: 25},
        {label: 'Chefe Consultor', value: 26},
        {label: 'Chef Freelancer', value: 27},
        {label: 'Chef de resgate', value: 28},
        {label: 'Pizza Chef ', value: 29},
        {label: 'Chef privado', value: 30},
        {label: 'Estagiário de Culinária', value: 31},
        {label: 'Estudante de Culinária ', value: 32},
        {label: 'Etapa', value: 33},
    ],

    ratings: [
        {value: 0, label: 'Ninguém'},
        {value: 1, label: '1★'},
        {value: 2, label: '2★'},
        {value: 3, label: '3★'},
        {value: 4, label: '4★'},
        {value: 5, label: '5★'}
    ],

    ratingTypes: [
        {label: 'Ninguém', value: undefined, max: 0},
        {label: 'Estrelas do Hotel',value: 'default', max: 5},
        {label: 'Michelin',value: 'michelin', max: 3},
        {label: 'Roseta',value: 'rosette', max: 5},
        {label: 'Chapéu de Chef',value: 'chefhat', max: 3},
        {label: 'Sóis Repsol',value: 'repsolsuns', max: 5},
    ],

    sectors: [
        {label: 'Restaurante', value: 1},
        {label: 'Hotel', value: 2},
        {label: 'Gastropub', value: 3},
        {label: 'Pub Bars', value: 4},
        {label: 'Refeições', value: 5},
        {label: 'Cruzeiro', value: 6},
        {label: 'Villas & Resorts ', value: 7},
    ],

    levels: [
        {label: 'Proficiência de trabalho limitada', value: 1},
        {label: 'Profissional trabalhando com competência', value: 2},
        {label: 'Proficiência nativa ou bilíngüe', value: 3},
    ],

    applicationTypes: [
        { value: 1, label: 'Prova' },
        { value: 2, label: 'Entrevista' },
        { value: 3, label: 'Chamada' },
    ],

    languages: [
    {
        "value": "ab",
        "label": "Abkhaz"
    },
    {
        "value": "aa",
        "label": "Afar"
    },
    {
        "value": "af",
        "label": "Afrikaans"
    },
    {
        "value": "ak",
        "label": "Akan"
    },
    {
        "value": "sq",
        "label": "Albanian"
    },
    {
        "value": "am",
        "label": "Amharic"
    },
    {
        "value": "ar",
        "label": "Arabic"
    },
    {
        "value": "an",
        "label": "Aragonese"
    },
    {
        "value": "hy",
        "label": "Armenian"
    },
    {
        "value": "as",
        "label": "Assamese"
    },
    {
        "value": "av",
        "label": "Avaric"
    },
    {
        "value": "ae",
        "label": "Avestan"
    },
    {
        "value": "ay",
        "label": "Aymara"
    },
    {
        "value": "az",
        "label": "Azerbaijani"
    },
    {
        "value": "bm",
        "label": "Bambara"
    },
    {
        "value": "ba",
        "label": "Bashkir"
    },
    {
        "value": "eu",
        "label": "Basque"
    },
    {
        "value": "be",
        "label": "Belarusian"
    },
    {
        "value": "bn",
        "label": "Bengali"
    },
    {
        "value": "bh",
        "label": "Bihari"
    },
    {
        "value": "bi",
        "label": "Bislama"
    },
    {
        "value": "bs",
        "label": "Bosnian"
    },
    {
        "value": "br",
        "label": "Breton"
    },
    {
        "value": "bg",
        "label": "Bulgarian"
    },
    {
        "value": "my",
        "label": "Burmese"
    },
    {
        "value": "ca",
        "label": "Catalan"
    },
    {
        "value": "ch",
        "label": "Chamorro"
    },
    {
        "value": "ce",
        "label": "Chechen"
    },
    {
        "value": "ny",
        "label": "Chichewa"
    },
    {
        "value": "zh",
        "label": "Chinese"
    },
    {
        "value": "cv",
        "label": "Chuvash"
    },
    {
        "value": "kw",
        "label": "Cornish"
    },
    {
        "value": "co",
        "label": "Corsican"
    },
    {
        "value": "cr",
        "label": "Cree"
    },
    {
        "value": "hr",
        "label": "Croatian"
    },
    {
        "value": "cs",
        "label": "Czech"
    },
    {
        "value": "da",
        "label": "Danish"
    },
    {
        "value": "dv",
        "label": "Divehi"
    },
    {
        "value": "nl",
        "label": "Dutch"
    },
    {
        "value": "dz",
        "label": "Dzongkha"
    },
    {
        "value": "en",
        "label": "English"
    },
    {
        "value": "eo",
        "label": "Esperanto"
    },
    {
        "value": "et",
        "label": "Estonian"
    },
    {
        "value": "ee",
        "label": "Ewe"
    },
    {
        "value": "fo",
        "label": "Faroese"
    },
    {
        "value": "fj",
        "label": "Fijian"
    },
    {
        "value": "fi",
        "label": "Finnish"
    },
    {
        "value": "fr",
        "label": "French"
    },
    {
        "value": "ff",
        "label": "Fula"
    },
    {
        "value": "gl",
        "label": "Galician"
    },
    {
        "value": "ka",
        "label": "Georgian"
    },
    {
        "value": "de",
        "label": "German"
    },
    {
        "value": "el",
        "label": "Greek"
    },
    {
        "value": "gn",
        "label": "GuaranÃ­"
    },
    {
        "value": "gu",
        "label": "Gujarati"
    },
    {
        "value": "ht",
        "label": "Haitian"
    },
    {
        "value": "ha",
        "label": "Hausa"
    },
    {
        "value": "he",
        "label": "Hebrew"
    },
    {
        "value": "hz",
        "label": "Herero"
    },
    {
        "value": "hi",
        "label": "Hindi"
    },
    {
        "value": "ho",
        "label": "Hiri Motu"
    },
    {
        "value": "hu",
        "label": "Hungarian"
    },
    {
        "value": "ia",
        "label": "Interlingua"
    },
    {
        "value": "id",
        "label": "Indonesian"
    },
    {
        "value": "ie",
        "label": "Interlingue"
    },
    {
        "value": "ga",
        "label": "Irish"
    },
    {
        "value": "ig",
        "label": "Igbo"
    },
    {
        "value": "ik",
        "label": "Inupiaq"
    },
    {
        "value": "io",
        "label": "Ido"
    },
    {
        "value": "is",
        "label": "Icelandic"
    },
    {
        "value": "it",
        "label": "Italian"
    },
    {
        "value": "iu",
        "label": "Inuktitut"
    },
    {
        "value": "ja",
        "label": "Japanese"
    },
    {
        "value": "jv",
        "label": "Javanese"
    },
    {
        "value": "kl",
        "label": "Kalaallisut"
    },
    {
        "value": "kn",
        "label": "Kannada"
    },
    {
        "value": "kr",
        "label": "Kanuri"
    },
    {
        "value": "ks",
        "label": "Kashmiri"
    },
    {
        "value": "kk",
        "label": "Kazakh"
    },
    {
        "value": "km",
        "label": "Khmer"
    },
    {
        "value": "ki",
        "label": "Kikuyu"
    },
    {
        "value": "rw",
        "label": "Kinyarwanda"
    },
    {
        "value": "ky",
        "label": "Kyrgyz"
    },
    {
        "value": "kv",
        "label": "Komi"
    },
    {
        "value": "kg",
        "label": "Kongo"
    },
    {
        "value": "ko",
        "label": "Korean"
    },
    {
        "value": "ku",
        "label": "Kurdish"
    },
    {
        "value": "kj",
        "label": "Kwanyama"
    },
    {
        "value": "la",
        "label": "Latin"
    },
    {
        "value": "lb",
        "label": "Luxembourgish"
    },
    {
        "value": "lg",
        "label": "Ganda"
    },
    {
        "value": "li",
        "label": "Limburgish"
    },
    {
        "value": "ln",
        "label": "Lingala"
    },
    {
        "value": "lo",
        "label": "Lao"
    },
    {
        "value": "lt",
        "label": "Lithuanian"
    },
    {
        "value": "lu",
        "label": "Luba-Katanga"
    },
    {
        "value": "lv",
        "label": "Latvian"
    },
    {
        "value": "gv",
        "label": "Manx"
    },
    {
        "value": "mk",
        "label": "Macedonian"
    },
    {
        "value": "mg",
        "label": "Malagasy"
    },
    {
        "value": "ms",
        "label": "Malay"
    },
    {
        "value": "ml",
        "label": "Malayalam"
    },
    {
        "value": "mt",
        "label": "Maltese"
    },
    {
        "value": "mi",
        "label": "MÄori"
    },
    {
        "value": "mr",
        "label": "Marathi"
    },
    {
        "value": "mh",
        "label": "Marshallese"
    },
    {
        "value": "mn",
        "label": "Mongolian"
    },
    {
        "value": "na",
        "label": "Nauru"
    },
    {
        "value": "nv",
        "label": "Navajo"
    },
    {
        "value": "nb",
        "label": "Norwegian"
    },
    {
        "value": "nd",
        "label": "North Ndebele"
    },
    {
        "value": "ne",
        "label": "Nepali"
    },
    {
        "value": "ng",
        "label": "Ndonga"
    },
    {
        "value": "nn",
        "label": "Norwegian Nynorsk"
    },
    {
        "value": "no",
        "label": "Norwegian"
    },
    {
        "value": "ii",
        "label": "Nuosu"
    },
    {
        "value": "nr",
        "label": "South Ndebele"
    },
    {
        "value": "oc",
        "label": "Occitan"
    },
    {
        "value": "oj",
        "label": "Ojibwe"
    },
    {
        "value": "cu",
        "label": "Slavic"
    },
    {
        "value": "om",
        "label": "Oromo"
    },
    {
        "value": "or",
        "label": "Oriya"
    },
    {
        "value": "os",
        "label": "Ossetian"
    },
    {
        "value": "pa",
        "label": "Punjabi"
    },
    {
        "value": "pi",
        "label": "PÄli"
    },
    {
        "value": "fa",
        "label": "Persian"
    },
    {
        "value": "pl",
        "label": "Polish"
    },
    {
        "value": "ps",
        "label": "Pashto"
    },
    {
        "value": "pt",
        "label": "Portuguese"
    },
    {
        "value": "qu",
        "label": "Quechua"
    },
    {
        "value": "rm",
        "label": "Romansh"
    },
    {
        "value": "rn",
        "label": "Kirundi"
    },
    {
        "value": "ro",
        "label": "Romanian"
    },
    {
        "value": "ru",
        "label": "Russian"
    },
    {
        "value": "sa",
        "label": "Sanskrit"
    },
    {
        "value": "sc",
        "label": "Sardinian"
    },
    {
        "value": "sd",
        "label": "Sindhi"
    },
    {
        "value": "se",
        "label": "Northern Sami"
    },
    {
        "value": "sm",
        "label": "Samoan"
    },
    {
        "value": "sg",
        "label": "Sango"
    },
    {
        "value": "sr",
        "label": "Serbian"
    },
    {
        "value": "gd",
        "label": "Gaelic"
    },
    {
        "value": "sn",
        "label": "Shona"
    },
    {
        "value": "si",
        "label": "Sinhala"
    },
    {
        "value": "sk",
        "label": "Slovak"
    },
    {
        "value": "sl",
        "label": "Slovene"
    },
    {
        "value": "so",
        "label": "Somali"
    },
    {
        "value": "st",
        "label": "Southern Sotho"
    },
    {
        "value": "azb",
        "label": "South Azerbaijani"
    },
    {
        "value": "es",
        "label": "Spanish"
    },
    {
        "value": "su",
        "label": "Sundanese"
    },
    {
        "value": "sw",
        "label": "Swahili"
    },
    {
        "value": "ss",
        "label": "Swati"
    },
    {
        "value": "sv",
        "label": "Swedish"
    },
    {
        "value": "ta",
        "label": "Tamil"
    },
    {
        "value": "te",
        "label": "Telugu"
    },
    {
        "value": "tg",
        "label": "Tajik"
    },
    {
        "value": "th",
        "label": "Thai"
    },
    {
        "value": "ti",
        "label": "Tigrinya"
    },
    {
        "value": "bo",
        "label": "Tibetan"
    },
    {
        "value": "tk",
        "label": "Turkmen"
    },
    {
        "value": "tl",
        "label": "Tagalog"
    },
    {
        "value": "tn",
        "label": "Tswana"
    },
    {
        "value": "to",
        "label": "Tonga"
    },
    {
        "value": "tr",
        "label": "Turkish"
    },
    {
        "value": "ts",
        "label": "Tsonga"
    },
    {
        "value": "tt",
        "label": "Tatar"
    },
    {
        "value": "tw",
        "label": "Twi"
    },
    {
        "value": "ty",
        "label": "Tahitian"
    },
    {
        "value": "ug",
        "label": "Uyghur"
    },
    {
        "value": "uk",
        "label": "Ukrainian"
    },
    {
        "value": "ur",
        "label": "Urdu"
    },
    {
        "value": "uz",
        "label": "Uzbek"
    },
    {
        "value": "ve",
        "label": "Venda"
    },
    {
        "value": "vi",
        "label": "Vietnamese"
    },
    {
        "value": "vo",
        "label": "VolapÃ¼k"
    },
    {
        "value": "wa",
        "label": "Walloon"
    },
    {
        "value": "cy",
        "label": "Welsh"
    },
    {
        "value": "wo",
        "label": "Wolof"
    },
    {
        "value": "fy",
        "label": "Western Frisian"
    },
    {
        "value": "xh",
        "label": "Xhosa"
    },
    {
        "value": "yi",
        "label": "Yiddish"
    },
    {
        "value": "yo",
        "label": "Yoruba"
    },
    {
        "value": "za",
        "label": "Zhuang"
    },
    {
        "value": "zu",
        "label": "Zulu"
    }
    ],

    cuisines: [
        {label: 'Britânico', value: 1},
        {label: 'Espanhol', value: 2},
        {label: 'Francês', value: 3},
        {label: 'Italiano', value: 4},
        {label: 'Nórdico', value: 5},
        {label: 'Japonês', value: 6},
        {label: 'Mexicano', value: 7},
        {label: 'Mediterrâneo', value: 8},
        {label: 'Indiano', value: 9},
        {label: 'Chinês', value: 10},
        {label: 'Tailandês', value: 11},
        {label: 'Grego', value: 12},
        {label: 'Asiático', value: 13},
        {label: 'Alemão', value: 14},
        {label: 'Português', value: 15},
        {label: 'Peruano', value: 16},
        {label: 'Café da manhã', value: 17},
        {label: 'Gastro Pub', value: 18},
        {label: 'Pub & Bar', value: 19},
        {label: 'Coreano', value: 20},
        {label: 'Turco', value: 21},
        {label: 'Peruano', value: 22},
        {label: 'Português', value: 23},
        {label: 'Europeu', value: 24},
        {label: 'Fusão', value: 25},
        {label: 'Filipino', value: 26},
        {label: 'Sri Lank', value: 27},
        {label: 'Judaico', value: 28},
        {label: 'Cajun', value: 29},
        {label: 'Malaio', value: 30},
        {label: 'Nepales', value: 31},
        {label: 'Africa', value: 32},
        {label: 'Cingapuriano', value: 33},
        {label: 'Russo', value: 34},
        {label: 'Alemão', value: 35},
        {label: 'Indonésia', value: 36},
        {label: 'Brasileiro', value: 37},
        {label: 'Marroquino', value: 38},
        {label: 'Mediterrâneo', value: 39},
        {label: 'Irlandês', value: 40},
        {label: 'Comida rápida', value: 41},
        {label: 'Haute', value: 42},
        {label: 'Iraniano', value: 43},
        {label: 'Polonês', value: 44},
        {label: 'Cubano', value: 45},
        {label: 'Havaí', value: 46},
        {label: 'Vietnamita', value: 47},
        {label: 'Armênio', value: 48},
        {label: 'Oriente médio', value: 49},
        {label: 'Curdo', value: 50},
        {label: 'Zanzibar', value: 51},
        {label: 'Tibetano', value: 52},
        {label: 'Caribe', value: 53},
        {label: 'Canadense', value: 54},
        {label: 'Americano', value: 55},
        {label: 'Argentino', value: 56},

    ],

    socialNetworks: [
        {label: 'Facebook',value: 'fb'},
        {label: 'Twitter',value: 'tw'},
        {label: 'LinkedIn',value: 'in'},
        {label: 'Instagram',value: 'ig'},
    ],

    salaryTypes: [
        {label: 'Por ano',value: 'year'},
        {label: 'Por hora',value: 'hour'},
    ],

    terms: [
        {label: 'Tempo total',value: 'full-time'},
        {label: 'Meio período',value: 'part-time'},
        {label: 'Chef de resgate',value: 'reliefchef'},
    ],

    shifts: [
        {label: '1 turno', value: 1},
        {label: '2 turnos', value: 2},
        {label: '3 turnos', value: 3},
        {label: '4 turnos', value: 4},
        {label: '5 turnos', value: 5},
        {label: '6 turnos', value: 6},
        {label: '7 turnos', value: 7},
        {label: '8 turnos', value: 8},
    ],

    days: [
        {value: 1, label: 'Segunda'},
        {value: 2, label: 'Terça'},
        {value: 3, label: 'Quarta'},
        {value: 4, label: 'Quinta'},
        {value: 5, label: 'Sexta'},
        {value: 6, label: 'Sábado'},
        {value: 7, label: 'Domingo'},
    ],

    hours: [
        {value: 1, label: '01:00'},
        {value: 2, label: '02:00'},
        {value: 3, label: '03:00'},
        {value: 4, label: '04:00'},
        {value: 5, label: '05:00'},
        {value: 6, label: '06:00'},
        {value: 7, label: '07:00'},
        {value: 8, label: '08:00'},
        {value: 9, label: '09:00'},
        {value: 10, label: '10:00'},
        {value: 11, label: '11:00'},
        {value: 12, label: '12:00'},
        {value: 13, label: '13:00'},
        {value: 14, label: '14:00'},
        {value: 15, label: '15:00'},
        {value: 16, label: '16:00'},
        {value: 17, label: '17:00'},
        {value: 18, label: '18:00'},
        {value: 19, label: '19:00'},
        {value: 20, label: '20:00'},
        {value: 21, label: '21:00'},
        {value: 22, label: '22:00'},
        {value: 23, label: '23:00'},
        {value: 0, label: '00:00'},
    ],

    countryCodes: [
        {label: '(+376) ANDORRA',value: '376'},
        {label: '(+971) UNITED ARAB EMIRATES',value: '971'},
        {label: '(+93) AFGHANISTAN',value: '93'},
        {label: '(+1268) ANTIGUA AND BARBUDA',value: '1268'},
        {label: '(+1264) ANGUILLA',value: '1264'},
        {label: '(+355) ALBANIA',value: '355'},
        {label: '(+374) ARMENIA',value: '374'},
        {label: '(+599) NETHERLANDS ANTILLES',value: '599'},
        {label: '(+244) ANGOLA',value: '244'},
        {label: '(+672) ANTARCTICA',value: '672'},
        {label: '(+54) ARGENTINA',value: '54'},
        {label: '(+1684) AMERICAN SAMOA',value: '1684'},
        {label: '(+43) AUSTRIA',value: '43'},
        {label: '(+61) AUSTRALIA',value: '61'},
        {label: '(+297) ARUBA',value: '297'},
        {label: '(+994) AZERBAIJAN',value: '994'},
        {label: '(+387) BOSNIA AND HERZEGOVINA',value: '387'},
        {label: '(+1246) BARBADOS',value: '1246'},
        {label: '(+880) BANGLADESH',value: '880'},
        {label: '(+32) BELGIUM',value: '32'},
        {label: '(+226) BURKINA FASO',value: '226'},
        {label: '(+359) BULGARIA',value: '359'},
        {label: '(+973) BAHRAIN',value: '973'},
        {label: '(+257) BURUNDI',value: '257'},
        {label: '(+229) BENIN',value: '229'},
        {label: '(+590) SAINT BARTHELEMY',value: '590'},
        {label: '(+1441) BERMUDA',value: '1441'},
        {label: '(+673) BRUNEI DARUSSALAM',value: '673'},
        {label: '(+591) BOLIVIA',value: '591'},
        {label: '(+55) BRAZIL',value: '55'},
        {label: '(+1242) BAHAMAS',value: '1242'},
        {label: '(+975) BHUTAN',value: '975'},
        {label: '(+267) BOTSWANA',value: '267'},
        {label: '(+375) BELARUS',value: '375'},
        {label: '(+501) BELIZE',value: '501'},
        {label: '(+1) CANADA',value: '1'},
        {label: '(+61) COCOS (KEELING) ISLANDS',value: '61'},
        {label: '(+243) CONGO, THE DEMOCRATIC REPUBLIC OF THE',value: '243'},
        {label: '(+236) CENTRAL AFRICAN REPUBLIC',value: '236'},
        {label: '(+242) CONGO',value: '242'},
        {label: '(+41) SWITZERLAND',value: '41'},
        {label: '(+225) COTE D IVOIRE',value: '225'},
        {label: '(+682) COOK ISLANDS',value: '682'},
        {label: '(+56) CHILE',value: '56'},
        {label: '(+237) CAMEROON',value: '237'},
        {label: '(+86) CHINA',value: '86'},
        {label: '(+57) COLOMBIA',value: '57'},
        {label: '(+506) COSTA RICA',value: '506'},
        {label: '(+53) CUBA',value: '53'},
        {label: '(+238) CAPE VERDE',value: '238'},
        {label: '(+61) CHRISTMAS ISLAND',value: '61'},
        {label: '(+357) CYPRUS',value: '357'},
        {label: '(+420) CZECH REPUBLIC',value: '420'},
        {label: '(+49) GERMANY',value: '49'},
        {label: '(+253) DJIBOUTI',value: '253'},
        {label: '(+45) DENMARK',value: '45'},
        {label: '(+1767) DOMINICA',value: '1767'},
        {label: '(+1809) DOMINICAN REPUBLIC',value: '1809'},
        {label: '(+213) ALGERIA',value: '213'},
        {label: '(+593) ECUADOR',value: '593'},
        {label: '(+372) ESTONIA',value: '372'},
        {label: '(+20) EGYPT',value: '20'},
        {label: '(+291) ERITREA',value: '291'},
        {label: '(+34) SPAIN',value: '34'},
        {label: '(+251) ETHIOPIA',value: '251'},
        {label: '(+358) FINLAND',value: '358'},
        {label: '(+679) FIJI',value: '679'},
        {label: '(+500) FALKLAND ISLANDS (MALVINAS)',value: '500'},
        {label: '(+691) MICRONESIA, FEDERATED STATES OF',value: '691'},
        {label: '(+298) FAROE ISLANDS',value: '298'},
        {label: '(+33) FRANCE',value: '33'},
        {label: '(+241) GABON',value: '241'},
        {label: '(+44) UNITED KINGDOM',value: '44'},
        {label: '(+1473) GRENADA',value: '1473'},
        {label: '(+995) GEORGIA',value: '995'},
        {label: '(+233) GHANA',value: '233'},
        {label: '(+350) GIBRALTAR',value: '350'},
        {label: '(+299) GREENLAND',value: '299'},
        {label: '(+220) GAMBIA',value: '220'},
        {label: '(+224) GUINEA',value: '224'},
        {label: '(+240) EQUATORIAL GUINEA',value: '240'},
        {label: '(+30) GREECE',value: '30'},
        {label: '(+502) GUATEMALA',value: '502'},
        {label: '(+1671) GUAM',value: '1671'},
        {label: '(+245) GUINEA-BISSAU',value: '245'},
        {label: '(+592) GUYANA',value: '592'},
        {label: '(+852) HONG KONG',value: '852'},
        {label: '(+504) HONDURAS',value: '504'},
        {label: '(+385) CROATIA',value: '385'},
        {label: '(+509) HAITI',value: '509'},
        {label: '(+36) HUNGARY',value: '36'},
        {label: '(+62) INDONESIA',value: '62'},
        {label: '(+353) IRELAND',value: '353'},
        {label: '(+972) ISRAEL',value: '972'},
        {label: '(+44) ISLE OF MAN',value: '44'},
        {label: '(+91) INDIA',value: '91'},
        {label: '(+964) IRAQ',value: '964'},
        {label: '(+98) IRAN, ISLAMIC REPUBLIC OF',value: '98'},
        {label: '(+354) ICELAND',value: '354'},
        {label: '(+39) ITALY',value: '39'},
        {label: '(+1876) JAMAICA',value: '1876'},
        {label: '(+962) JORDAN',value: '962'},
        {label: '(+81) JAPAN',value: '81'},
        {label: '(+254) KENYA',value: '254'},
        {label: '(+996) KYRGYZSTAN',value: '996'},
        {label: '(+855) CAMBODIA',value: '855'},
        {label: '(+686) KIRIBATI',value: '686'},
        {label: '(+269) COMOROS',value: '269'},
        {label: '(+1869) SAINT KITTS AND NEVIS',value: '1869'},
        {label: '(+850) KOREA DEMOCRATIC PEOPLES REPUBLIC OF',value: '850'},
        {label: '(+82) KOREA REPUBLIC OF',value: '82'},
        {label: '(+965) KUWAIT',value: '965'},
        {label: '(+1345) CAYMAN ISLANDS',value: '1345'},
        {label: '(+7) KAZAKSTAN',value: '7'},
        {label: '(+856) LAO PEOPLES DEMOCRATIC REPUBLIC',value: '856'},
        {label: '(+961) LEBANON',value: '961'},
        {label: '(+1758) SAINT LUCIA',value: '1758'},
        {label: '(+423) LIECHTENSTEIN',value: '423'},
        {label: '(+94) SRI LANKA',value: '94'},
        {label: '(+231) LIBERIA',value: '231'},
        {label: '(+266) LESOTHO',value: '266'},
        {label: '(+370) LITHUANIA',value: '370'},
        {label: '(+352) LUXEMBOURG',value: '352'},
        {label: '(+371) LATVIA',value: '371'},
        {label: '(+218) LIBYAN ARAB JAMAHIRIYA',value: '218'},
        {label: '(+212) MOROCCO',value: '212'},
        {label: '(+377) MONACO',value: '377'},
        {label: '(+373) MOLDOVA, REPUBLIC OF',value: '373'},
        {label: '(+382) MONTENEGRO',value: '382'},
        {label: '(+1599) SAINT MARTIN',value: '1599'},
        {label: '(+261) MADAGASCAR',value: '261'},
        {label: '(+692) MARSHALL ISLANDS',value: '692'},
        {label: '(+389) MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',value: '389'},
        {label: '(+223) MALI',value: '223'},
        {label: '(+95) MYANMAR',value: '95'},
        {label: '(+976) MONGOLIA',value: '976'},
        {label: '(+853) MACAU',value: '853'},
        {label: '(+1670) NORTHERN MARIANA ISLANDS',value: '1670'},
        {label: '(+222) MAURITANIA',value: '222'},
        {label: '(+1664) MONTSERRAT',value: '1664'},
        {label: '(+356) MALTA',value: '356'},
        {label: '(+230) MAURITIUS',value: '230'},
        {label: '(+960) MALDIVES',value: '960'},
        {label: '(+265) MALAWI',value: '265'},
        {label: '(+52) MEXICO',value: '52'},
        {label: '(+60) MALAYSIA',value: '60'},
        {label: '(+258) MOZAMBIQUE',value: '258'},
        {label: '(+264) NAMIBIA',value: '264'},
        {label: '(+687) NEW CALEDONIA',value: '687'},
        {label: '(+227) NIGER',value: '227'},
        {label: '(+234) NIGERIA',value: '234'},
        {label: '(+505) NICARAGUA',value: '505'},
        {label: '(+31) NETHERLANDS',value: '31'},
        {label: '(+47) NORWAY',value: '47'},
        {label: '(+977) NEPAL',value: '977'},
        {label: '(+674) NAURU',value: '674'},
        {label: '(+683) NIUE',value: '683'},
        {label: '(+64) NEW ZEALAND',value: '64'},
        {label: '(+968) OMAN',value: '968'},
        {label: '(+507) PANAMA',value: '507'},
        {label: '(+51) PERU',value: '51'},
        {label: '(+689) FRENCH POLYNESIA',value: '689'},
        {label: '(+675) PAPUA NEW GUINEA',value: '675'},
        {label: '(+63) PHILIPPINES',value: '63'},
        {label: '(+92) PAKISTAN',value: '92'},
        {label: '(+48) POLAND',value: '48'},
        {label: '(+508) SAINT PIERRE AND MIQUELON',value: '508'},
        {label: '(+870) PITCAIRN',value: '870'},
        {label: '(+1) PUERTO RICO',value: '1'},
        {label: '(+351) PORTUGAL',value: '351'},
        {label: '(+680) PALAU',value: '680'},
        {label: '(+595) PARAGUAY',value: '595'},
        {label: '(+974) QATAR',value: '974'},
        {label: '(+40) ROMANIA',value: '40'},
        {label: '(+381) SERBIA',value: '381'},
        {label: '(+7) RUSSIAN FEDERATION',value: '7'},
        {label: '(+250) RWANDA',value: '250'},
        {label: '(+966) SAUDI ARABIA',value: '966'},
        {label: '(+677) SOLOMON ISLANDS',value: '677'},
        {label: '(+248) SEYCHELLES',value: '248'},
        {label: '(+249) SUDAN',value: '249'},
        {label: '(+46) SWEDEN',value: '46'},
        {label: '(+65) SINGAPORE',value: '65'},
        {label: '(+290) SAINT HELENA',value: '290'},
        {label: '(+386) SLOVENIA',value: '386'},
        {label: '(+421) SLOVAKIA',value: '421'},
        {label: '(+232) SIERRA LEONE',value: '232'},
        {label: '(+378) SAN MARINO',value: '378'},
        {label: '(+221) SENEGAL',value: '221'},
        {label: '(+252) SOMALIA',value: '252'},
        {label: '(+597) SURINAME',value: '597'},
        {label: '(+239) SAO TOME AND PRINCIPE',value: '239'},
        {label: '(+503) EL SALVADOR',value: '503'},
        {label: '(+963) SYRIAN ARAB REPUBLIC',value: '963'},
        {label: '(+268) SWAZILAND',value: '268'},
        {label: '(+1649) TURKS AND CAICOS ISLANDS',value: '1649'},
        {label: '(+235) CHAD',value: '235'},
        {label: '(+228) TOGO',value: '228'},
        {label: '(+66) THAILAND',value: '66'},
        {label: '(+992) TAJIKISTAN',value: '992'},
        {label: '(+690) TOKELAU',value: '690'},
        {label: '(+670) TIMOR-LESTE',value: '670'},
        {label: '(+993) TURKMENISTAN',value: '993'},
        {label: '(+216) TUNISIA',value: '216'},
        {label: '(+676) TONGA',value: '676'},
        {label: '(+90) TURKEY',value: '90'},
        {label: '(+1868) TRINIDAD AND TOBAGO',value: '1868'},
        {label: '(+688) TUVALU',value: '688'},
        {label: '(+886) TAIWAN, PROVINCE OF CHINA',value: '886'},
        {label: '(+255) TANZANIA, UNITED REPUBLIC OF',value: '255'},
        {label: '(+380) UKRAINE',value: '380'},
        {label: '(+256) UGANDA',value: '256'},
        {label: '(+1) UNITED STATES',value: '1'},
        {label: '(+598) URUGUAY',value: '598'},
        {label: '(+998) UZBEKISTAN',value: '998'},
        {label: '(+39) HOLY SEE (VATICAN CITY STATE)',value: '39'},
        {label: '(+1784) SAINT VINCENT AND THE GRENADINES',value: '1784'},
        {label: '(+58) VENEZUELA',value: '58'},
        {label: '(+1284) VIRGIN ISLANDS, BRITISH',value: '1284'},
        {label: '(+1340) VIRGIN ISLANDS, U.S.',value: '1340'},
        {label: '(+84) VIET NAM',value: '84'},
        {label: '(+678) VANUATU',value: '678'},
        {label: '(+681) WALLIS AND FUTUNA',value: '681'},
        {label: '(+685) SAMOA',value: '685'},
        {label: '(+381) KOSOVO',value: '381'},
        {label: '(+967) YEMEN',value: '967'},
        {label: '(+262) MAYOTTE',value: '262'},
        {label: '(+27) SOUTH AFRICA',value: '27'},
        {label: '(+260) ZAMBIA',value: '260'},
        {label: '(+26) ZIMBABWE',value: '263'}
    ],

    countries: [
        {"label": "Afghanistan", "value": "AF"},
        {"label": "land Islands", "value": "AX"},
        {"label": "Albania", "value": "AL"},
        {"label": "Algeria", "value": "DZ"},
        {"label": "American Samoa", "value": "AS"},
        {"label": "Andorra", "value": "AD"},
        {"label": "Angola", "value": "AO"},
        {"label": "Anguilla", "value": "AI"},
        {"label": "Antarctica", "value": "AQ"},
        {"label": "Antigua and Barbuda", "value": "AG"},
        {"label": "Argentina", "value": "AR"},
        {"label": "Armenia", "value": "AM"},
        {"label": "Aruba", "value": "AW"},
        {"label": "Australia", "value": "AU"},
        {"label": "Austria", "value": "AT"},
        {"label": "Azerbaijan", "value": "AZ"},
        {"label": "Bahamas", "value": "BS"},
        {"label": "Bahrain", "value": "BH"},
        {"label": "Bangladesh", "value": "BD"},
        {"label": "Barbados", "value": "BB"},
        {"label": "Belarus", "value": "BY"},
        {"label": "Belgium", "value": "BE"},
        {"label": "Belize", "value": "BZ"},
        {"label": "Benin", "value": "BJ"},
        {"label": "Bermuda", "value": "BM"},
        {"label": "Bhutan", "value": "BT"},
        {"label": "Bolivia", "value": "BO"},
        {"label": "Bosnia and Herzegovina", "value": "BA"},
        {"label": "Botswana", "value": "BW"},
        {"label": "Bouvet Island", "value": "BV"},
        {"label": "Brazil", "value": "BR"},
        {"label": "British Indian Ocean Territory", "value": "IO"},
        {"label": "Brunei Darussalam", "value": "BN"},
        {"label": "Bulgaria", "value": "BG"},
        {"label": "Burkina Faso", "value": "BF"},
        {"label": "Burundi", "value": "BI"},
        {"label": "Cambodia", "value": "KH"},
        {"label": "Cameroon", "value": "CM"},
        {"label": "Canada", "value": "CA"},
        {"label": "Cape Verde", "value": "CV"},
        {"label": "Cayman Islands", "value": "KY"},
        {"label": "Central African Republic", "value": "CF"},
        {"label": "Chad", "value": "TD"},
        {"label": "Chile", "value": "CL"},
        {"label": "China", "value": "CN"},
        {"label": "Christmas Island", "value": "CX"},
        {"label": "Cocos (Keeling) Islands", "value": "CC"},
        {"label": "Colombia", "value": "CO"},
        {"label": "Comoros", "value": "KM"},
        {"label": "Congo", "value": "CG"},
        {"label": "Congo, The Democratic Republic of the", "value": "CD"},
        {"label": "Cook Islands", "value": "CK"},
        {"label": "Costa Rica", "value": "CR"},
        {"label": "Cote D'Ivoire", "value": "CI"},
        {"label": "Croatia", "value": "HR"},
        {"label": "Cuba", "value": "CU"},
        {"label": "Cyprus", "value": "CY"},
        {"label": "Czech Republic", "value": "CZ"},
        {"label": "Denmark", "value": "DK"},
        {"label": "Djibouti", "value": "DJ"},
        {"label": "Dominica", "value": "DM"},
        {"label": "Dominican Republic", "value": "DO"},
        {"label": "Ecuador", "value": "EC"},
        {"label": "Egypt", "value": "EG"},
        {"label": "El Salvador", "value": "SV"},
        {"label": "Equatorial Guinea", "value": "GQ"},
        {"label": "Eritrea", "value": "ER"},
        {"label": "Estonia", "value": "EE"},
        {"label": "Ethiopia", "value": "ET"},
        {"label": "European Union", "value": "EUROPE"},
        {"label": "Falkland Islands (Malvinas)", "value": "FK"},
        {"label": "Faroe Islands", "value": "FO"},
        {"label": "Fiji", "value": "FJ"},
        {"label": "Finland", "value": "FI"},
        {"label": "France", "value": "FR"},
        {"label": "French Guiana", "value": "GF"},
        {"label": "French Polynesia", "value": "PF"},
        {"label": "French Southern Territories", "value": "TF"},
        {"label": "Gabon", "value": "GA"},
        {"label": "Gambia", "value": "GM"},
        {"label": "Georgia", "value": "GE"},
        {"label": "Germany", "value": "DE"},
        {"label": "Ghana", "value": "GH"},
        {"label": "Gibraltar", "value": "GI"},
        {"label": "Greece", "value": "GR"},
        {"label": "Greenland", "value": "GL"},
        {"label": "Grenada", "value": "GD"},
        {"label": "Guadeloupe", "value": "GP"},
        {"label": "Guam", "value": "GU"},
        {"label": "Guatemala", "value": "GT"},
        {"label": "Guernsey", "value": "GG"},
        {"label": "Guinea", "value": "GN"},
        {"label": "Guinea-Bissau", "value": "GW"},
        {"label": "Guyana", "value": "GY"},
        {"label": "Haiti", "value": "HT"},
        {"label": "Heard Island and Mcdonald Islands", "value": "HM"},
        {"label": "Holy See (Vatican City State)", "value": "VA"},
        {"label": "Honduras", "value": "HN"},
        {"label": "Hong Kong", "value": "HK"},
        {"label": "Hungary", "value": "HU"},
        {"label": "Iceland", "value": "IS"},
        {"label": "India", "value": "IN"},
        {"label": "Indonesia", "value": "ID"},
        {"label": "Iran, Islamic Republic Of", "value": "IR"},
        {"label": "Iraq", "value": "IQ"},
        {"label": "Ireland", "value": "IE"},
        {"label": "Isle of Man", "value": "IM"},
        {"label": "Israel", "value": "IL"},
        {"label": "Italy", "value": "IT"},
        {"label": "Jamaica", "value": "JM"},
        {"label": "Japan", "value": "JP"},
        {"label": "Jersey", "value": "JE"},
        {"label": "Jordan", "value": "JO"},
        {"label": "Kazakhstan", "value": "KZ"},
        {"label": "Kenya", "value": "KE"},
        {"label": "Kiribati", "value": "KI"},
        {"label": "Korea, Democratic People's Republic of", "value": "KP"},
        {"label": "Korea, Republic of", "value": "KR"},
        {"label": "Kuwait", "value": "KW"},
        {"label": "Kyrgyzstan", "value": "KG"},
        {"label": "Lao People'S Democratic Republic", "value": "LA"},
        {"label": "Latvia", "value": "LV"},
        {"label": "Lebanon", "value": "LB"},
        {"label": "Lesotho", "value": "LS"},
        {"label": "Liberia", "value": "LR"},
        {"label": "Libyan Arab Jamahiriya", "value": "LY"},
        {"label": "Liechtenstein", "value": "LI"},
        {"label": "Lithuania", "value": "LT"},
        {"label": "Luxembourg", "value": "LU"},
        {"label": "Macao", "value": "MO"},
        {"label": "Macedonia, The Former Yugoslav Republic of", "value": "MK"},
        {"label": "Madagascar", "value": "MG"},
        {"label": "Malawi", "value": "MW"},
        {"label": "Malaysia", "value": "MY"},
        {"label": "Maldives", "value": "MV"},
        {"label": "Mali", "value": "ML"},
        {"label": "Malta", "value": "MT"},
        {"label": "Marshall Islands", "value": "MH"},
        {"label": "Martinique", "value": "MQ"},
        {"label": "Mauritania", "value": "MR"},
        {"label": "Mauritius", "value": "MU"},
        {"label": "Mayotte", "value": "YT"},
        {"label": "Mexico", "value": "MX"},
        {"label": "Micronesia, Federated States of", "value": "FM"},
        {"label": "Moldova, Republic of", "value": "MD"},
        {"label": "Monaco", "value": "MC"},
        {"label": "Mongolia", "value": "MN"},
        {"label": "Montenegro", "value": "ME"},
        {"label": "Montserrat", "value": "MS"},
        {"label": "Morocco", "value": "MA"},
        {"label": "Mozambique", "value": "MZ"},
        {"label": "Myanmar", "value": "MM"},
        {"label": "Namibia", "value": "NA"},
        {"label": "Nauru", "value": "NR"},
        {"label": "Nepal", "value": "NP"},
        {"label": "Netherlands", "value": "NL"},
        {"label": "Netherlands Antilles", "value": "AN"},
        {"label": "New Caledonia", "value": "NC"},
        {"label": "New Zealand", "value": "NZ"},
        {"label": "Nicaragua", "value": "NI"},
        {"label": "Niger", "value": "NE"},
        {"label": "Nigeria", "value": "NG"},
        {"label": "Niue", "value": "NU"},
        {"label": "Norfolk Island", "value": "NF"},
        {"label": "Northern Mariana Islands", "value": "MP"},
        {"label": "Norway", "value": "NO"},
        {"label": "Oman", "value": "OM"},
        {"label": "Pakistan", "value": "PK"},
        {"label": "Palau", "value": "PW"},
        {"label": "Palestinian Territory, Occupied", "value": "PS"},
        {"label": "Panama", "value": "PA"},
        {"label": "Papua New Guinea", "value": "PG"},
        {"label": "Paraguay", "value": "PY"},
        {"label": "Peru", "value": "PE"},
        {"label": "Philippines", "value": "PH"},
        {"label": "Pitcairn", "value": "PN"},
        {"label": "Poland", "value": "PL"},
        {"label": "Portugal", "value": "PT"},
        {"label": "Puerto Rico", "value": "PR"},
        {"label": "Qatar", "value": "QA"},
        {"label": "Reunion", "value": "RE"},
        {"label": "Romania", "value": "RO"},
        {"label": "Russian Federation", "value": "RU"},
        {"label": "RWANDA", "value": "RW"},
        {"label": "Saint Helena", "value": "SH"},
        {"label": "Saint Kitts and Nevis", "value": "KN"},
        {"label": "Saint Lucia", "value": "LC"},
        {"label": "Saint Pierre and Miquelon", "value": "PM"},
        {"label": "Saint Vincent and the Grenadines", "value": "VC"},
        {"label": "Samoa", "value": "WS"},
        {"label": "San Marino", "value": "SM"},
        {"label": "Sao Tome and Principe", "value": "ST"},
        {"label": "Saudi Arabia", "value": "SA"},
        {"label": "Senegal", "value": "SN"},
        {"label": "Serbia", "value": "RS"},
        {"label": "Seychelles", "value": "SC"},
        {"label": "Sierra Leone", "value": "SL"},
        {"label": "Singapore", "value": "SG"},
        {"label": "Slovakia", "value": "SK"},
        {"label": "Slovenia", "value": "SI"},
        {"label": "Solomon Islands", "value": "SB"},
        {"label": "Somalia", "value": "SO"},
        {"label": "South Africa", "value": "ZA"},
        {"label": "South Georgia and the South Sandwich Islands", "value": "GS"},
        {"label": "Spain", "value": "ES"},
        {"label": "Sri Lanka", "value": "LK"},
        {"label": "Sudan", "value": "SD"},
        {"label": "Suriname", "value": "SR"},
        {"label": "Svalbard and Jan Mayen", "value": "SJ"},
        {"label": "Swaziland", "value": "SZ"},
        {"label": "Sweden", "value": "SE"},
        {"label": "Switzerland", "value": "CH"},
        {"label": "Syrian Arab Republic", "value": "SY"},
        {"label": "Taiwan, Province of China", "value": "TW"},
        {"label": "Tajikistan", "value": "TJ"},
        {"label": "Tanzania, United Republic of", "value": "TZ"},
        {"label": "Thailand", "value": "TH"},
        {"label": "Timor-Leste", "value": "TL"},
        {"label": "Togo", "value": "TG"},
        {"label": "Tokelau", "value": "TK"},
        {"label": "Tonga", "value": "TO"},
        {"label": "Trinidad and Tobago", "value": "TT"},
        {"label": "Tunisia", "value": "TN"},
        {"label": "Turkey", "value": "TR"},
        {"label": "Turkmenistan", "value": "TM"},
        {"label": "Turks and Caicos Islands", "value": "TC"},
        {"label": "Tuvalu", "value": "TV"},
        {"label": "Uganda", "value": "UG"},
        {"label": "Ukraine", "value": "UA"},
        {"label": "United Arab Emirates", "value": "AE"},
        {"label": "United Kingdom", "value": "GB"},
        {"label": "United States", "value": "US"},
        {"label": "United States Minor Outlying Islands", "value": "UM"},
        {"label": "Uruguay", "value": "UY"},
        {"label": "Uzbekistan", "value": "UZ"},
        {"label": "Vanuatu", "value": "VU"},
        {"label": "Venezuela", "value": "VE"},
        {"label": "Viet Nam", "value": "VN"},
        {"label": "Virgin Islands, British", "value": "VG"},
        {"label": "Virgin Islands, U.S.", "value": "VI"},
        {"label": "Wallis and Futuna", "value": "WF"},
        {"label": "Western Sahara", "value": "EH"},
        {"label": "Yemen", "value": "YE"},
        {"label": "Zambia", "value": "ZM"},
        {"label": "Zimbabwe", "value": "ZW"}
    ]

}