import React, { useEffect, useState } from 'react'
import { Formik, Form } from "formik"

import { translate } from '../../Translations/translate'
import ChefAboutSchema from "../../FormValidations/ChefAboutSchema"
import {chefEditDescription} from '../../Services/Chef/chef'

import FormTextarea from './FormTextarea'
import Button from './Button'

const ChefAboutForm = ({ props, textTranslation, textLang }) => {
  const { lang } = props.match.params
  const { data, setUser, showOverlay } = props

  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)
  const [changedText, setChangedText] = useState(null)

  const handleSave = (values, currentLang, closeModal) => {
    setLoading(true)
    const formData = {
      description: values.description,
      lang: currentLang,
    }
    chefEditDescription(formData).then((res) => {
      setLoading(false)
      if (!res.data.success) {
        setFormError(res.data.message)
        return
    } 
    const response = res.data.data
    setUser(response.user)
    if (data.profileNav) {
      showOverlay('chefworkexperience', { id: 0, value: {}, profileNav: true })
    } else if (closeModal) {
      showOverlay(null)
    }
    }).catch((e) => {
      setLoading(false)
      setFormError(e.message)
  })
  }

  return (
    <div className="component_chefAboutForm">
      <Formik
        initialValues={{
            description: data.description,
        }}
        validationSchema={ChefAboutSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSave(values, textLang, true)}
        enableReinitialize
      >{({ values, errors, setFieldValue }) => {

        useEffect(() => {
          if (textTranslation) {
            if (changedText) {
              handleSave(values, changedText, false)
              setChangedText(null)
              setFieldValue('description', textTranslation)
            } else {
              setFieldValue('description', textTranslation)
            }
          }
        }, [textTranslation])

        return (
          <Form>
            <div className="fullWidth textarea">
              <FormTextarea
                id="description"
                name="description"
                label={translate(lang, 'description')}
                placeholder={null}
                errors={errors}
                onChange={v => {
                  setChangedText(textLang);
                  setFieldValue('description', v);
                }}
                lang={lang}
                textTranslation={textTranslation}
                addMediaCallback={false}
              />
            </div>
            <div className={`buttonRow ${formError || (errors && Object.keys(errors).length > 0) ? "hasError" : ""}`}>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            {errors && Object.keys(errors).length > 0 && <div className="errorMessage">{translate(lang, 'error_required_fields')}</div>}
              <Button
                label={data.profileNav ? translate(lang, 'save_continue').toUpperCase() : translate(lang, 'save').toUpperCase()}
                submit
                loading={loading}
              />
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default ChefAboutForm