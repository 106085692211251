import * as types from './types'
var find = require('lodash/find')

export function setSelectedChefs(selectedChefs) {
    return {
        type: types.SET_SELECTED_CHEFS,
        selectedChefs
    }
}
export function addSelectedChef(selectedChef) {
    return {
        type: types.ADD_SELECTED_CHEF,
        selectedChef
    }
}
export function removeSelectedChef(selectedChef) {
    return {
        type: types.REMOVE_SELECTED_CHEF,
        selectedChef
    }
}
export function toggleSelectedChef(selectedChef) {
    return (dispatch, getState) => {
        if (find(getState().selectedChefs, {id: selectedChef.id})) {
            dispatch(removeSelectedChef(selectedChef))
        } else {
            dispatch(addSelectedChef(selectedChef))
        }
    }
}
