import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {memberships} from '../../Lists/memberships'
import {requestSubscription} from '../../Services/User/User'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'

class Membership extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }
    render() {
        const { lang } = this.props.match.params
        let membership = memberships[lang]['m' + this.props.id]
        return (
            <div className="component_Membership">
                <div>
                    <div className="top">
                        <div>
                            <div className="title">
                                {membership.title}
                            </div>
                            <span>{membership.pricing}</span>
                        </div>
                    </div>
                    <div className="wrap">
                        <div className="info">
                            <h4>
                                {membership.subtitle}
                                {membership.popular ? <span className="popular">{membership.popular}</span> : null}
                            </h4>
                            <ul>
                                {membership.items.map((item, index) => {
                                    return (<li key={index}>{item}</li>)
                                })}
                            </ul>
                            {membership.fees !== '' ? <span className="fees">{membership.fees}</span> : null}
                        </div>
                        {this.renderBtn()}
                    </div>
                </div>
            </div>
        )
    }
    renderBtn() {
        const { lang } = this.props.match.params
        if (!this.props.isLogged) {
            return (
                <div className="bottom">
                    <Btn
                        text={translate(lang, 'log_in').toUpperCase()}
                        callback={() => {
                            this.props.showOverlay('login')
                        }}
                        />
                </div>
            )
        } else if (parseInt(this.props.user.subscription_status) === 1 || this.props.id === this.props.user.subscription) {
            return null
        } else if (this.props.userType === 'restaurant') {
            return (
                <div className="bottom">
                    <Btn
                        text={translate(lang, 'request').toUpperCase()}
                        callback={() => {
                            this.setState({loading: true})
                            requestSubscription({type: this.props.id, lang}).then((res) => {
                                this.setState({loading: false})
                                if (!res.data.success) {
                                    this.props.showOverlay('error', {message: res.data.message})
                                    return
                                } else {
                                    this.props.showOverlay('success', {message: translate(lang, 'membership_requested')})
                                }
                            })
                        }}
                        />
                </div>
            )
        }
    }
};

const mapStateToProps = state => {
    return {
        user: state.user,
        deviceScreen: state.deviceScreen,
        selectedOffers: state.selectedOffers,
        isLogged: state.isLogged,
        userType: state.userType,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Membership))
