import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'

export default class CallToActionBoxSub extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    render() {
        return (
            <div className="component_CallToActionBoxSub">
                <h4>{this.props.title}</h4>
                {this.props.text ? <p dangerouslySetInnerHTML={{__html: this.props.text }}></p> : null}
                <div className="actions">
                    <Btn text={this.props.btnText} callback={this.props.callback} />
                    <div className="btn-sub">
                        {this.props.text2}
                        <span onClick={this.props.callback2}>{this.props.btnText2}</span>
                    </div>
                </div>
            </div>
        )
    }
};
