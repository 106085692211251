import React, { useState } from 'react'
import { Formik, Form } from "formik"
import Cookies from "js-cookie";

import { translate } from '../../Translations/translate'
import SignupSchema from '../../FormValidations/SignupSchema'
import { accessSignup } from "../../Services/Access/access";
import { uploadImage } from "../../Services/Media/media";

import FormField from './FormField'
import Btn from '../Btn/Btn'
import FormDropFile from './FormDropFile';
import FormSelect from "./FormSelect";

const SignupForm = ({ props }) => {
  const { lang } = props.match.params
  const { showOverlay, setUser, setUserType, setToken, setIsLogged, history } = props

  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)
  const [file, setFile] = useState(false)

  const handleFormError = (error) => {
    if (error.includes('The email field must contain a unique value.')) {
      setFormError(translate(lang, 'error_email_unique'))
    } else {
      setFormError(error)
    }
  }

  const handleSignup = (values) => {
    setLoading(true)
    const formValues = {
      ...values,
      lang,
    }
    accessSignup(formValues)
      .then((res) => {
        setLoading(false)
        if (!res.data.success) {
          console.log('ERROR: ' + res.data.message)
          handleFormError(res.data.message)
          return
        }
        let data = res.data.data;

        if (file) {
          uploadImage({ image: file, type: "curriculum", lang })
            .then((res) => {
              setLoading(false)
              this.proceed(data);
            })
            .catch((e) => {
              setLoading(false)
              Proceed(data);
            });
        } else {
          Proceed(data);
        }
      })
      .catch((e) => {
        console.log('ERROR: ' + e.message)
        handleFormError(e.message)
      });
  }

  const Proceed = (data) => {
    setUser(data.user);
    setUserType(data.user.type);
    setToken(data.user.session);
    setIsLogged(true);

    Cookies.set("token", data.user.session, {expires: 7});

    showOverlay(null);
    if (props.data.fromApplyCallback) {
      props.data.fromApplyCallback(data.user);
    } else {
      if (data.user.type === "chef") {
        history.push(`/${lang}/chef/profile/home`);
      } else if (data.user.type === "restaurant") {
        history.push(`/${lang}/restaurant/profile/home`);
      }
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: props.data.savedEmail || '',
          type: '',
          name: '',
          phoneNumber: '',
          password: '',
          rpassword: '',
        }}
        validationSchema={SignupSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSignup(values)}
      >{({ values, errors, handleSubmit, handleChange }) => {
        return(
          <Form>
            <FormField
              id="email"
              type="email"
              name="email"
              label={translate(lang, 'email')}
              placeholder={translate(lang, 'email_placeholder')}
              value={props.data.savedEmail || values.email}
              onChange={handleChange}
              errors={errors}
              className="input-email"
              disabled
            />
            <FormSelect
              id="type"
              name="type"
              label={translate(lang, "register_as").toUpperCase()}
              placeholder={translate(lang, 'profile_type_placeholder')}
              options={[
                { name: translate(lang, "chef"), value: "chef"},
                { name: translate(lang, "restaurant"), value: "restaurant"},
              ]}
              errors={errors}
              formError={formError}
              disableSearch
            />
            <div className="profileType">
              <p>
                {translate(lang, "choose_profile_type")}{" "}
                <span className="bold">
                  {translate(lang, "profile_types")}
                </span>
              </p>
            </div>
            <FormField
              id="name"
              type="text"
              name="name"
              label={translate(lang, 'name')}
              placeholder={translate(lang, 'name_placeholder')}
              value={values.name}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <FormField
              id="phoneNumber"
              type="tel"
              name="phoneNumber"
              label={translate(lang, 'phone')}
              placeholder={translate(lang, 'phone_placeholder')}
              value={values.phoneNumber}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <FormField
              id="password"
              type="password"
              name="password"
              label={translate(lang, 'password')}
              placeholder={translate(lang, 'password_placeholder')}
              value={values.password}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <FormField
              id="rpassword"
              type="password"
              name="rpassword"
              label={translate(lang, 'repeat_password')}
              placeholder={translate(lang, 'repeat_password_placeholder')}
              value={values.rpassword}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <FormDropFile
              label={translate(lang, "upload_cv").toUpperCase()}
              lang={lang}
              setFileCallback={(e) => setFile(e)}
            />
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            <div>
              <Btn
              text={translate(lang, "create_account").toUpperCase()}
              loading={loading}
              callback={() => handleSubmit()}
              />
            </div>
          </Form>
        )}}
      </Formik>
    </div>
  )
}

export default SignupForm