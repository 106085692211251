import * as Yup from 'yup'
import { translate } from '../Translations/translate'

const ChefInfoSchema = (lang) => Yup.object().shape({
  name: Yup.string().min(2).required(),
  contactemail: Yup.string().email().required(),
  phone: Yup.string().matches(/^(?! )[0-9\s]+$/).min(4).required(),
  position: Yup.string().required(),
  password: Yup.string()
    .min(6),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')])
})

export default ChefInfoSchema