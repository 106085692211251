export const chefFaqs_fr = [
    {
        q: "Est-ce que votre service coûte de l'argent?",
        t: "Chefs Link est un service gratuit pour tous nos chefs à la recherche d’emplois. Aucun risque et aucun paiement n'est nécessaire que ce soit. Cependant, nous demandons à tous nos chefs de respecter le calendrier et les exigences de toutes les entrevues organisées."
    },
    {
        q: "Pourquoi ne puis-je pas voir tous les emplois disponibles?",
        t: "Notre système ne détectera que les restaurants qui correspondent à votre profil. Les deux caractéristiques principales sont la langue et la nationalité. Cela vous permettra d'envoyer votre candidature uniquement aux restaurants intéressés par votre profil et ne perdra pas votre temps à soumettre votre candidature à des restaurants qui ne vous contacteront jamais."
    },
    {
        q: "Dois-je créer un profil pour trouver un emploi?",
        t: "Ce n'est pas nécessaire. Vous pouvez nous envoyer votre CV dans la section Contact ou lorsque vous postulez pour un emploi. Un membre de notre équipe se fera un plaisir de vous aider. Cependant, nous vous recommandons fortement de créer un profil avec nous. C'est un processus très simple, étape par étape, qui ne vous prendra que quelques minutes. Ces informations nous fourniront tous les détails nécessaires pour vous aider à trouver le meilleur emploi possible. Cela nous permet de nous mettre directement au travail et de réduire le nombre de courriers électroniques frustrants et d’appels renvoyés et rediffusés généralement rencontrés au cours du processus de recrutement."
    },
    {
        q: "Puis-je afficher des offres sur votre site web?",
        t: "Vous devrez créer une page Business sur notre site Web ou si votre entreprise en a déjà une, vous pouvez demander à l’administrateur du site Web de vous fournir vos informations de connexion."
    },
    {
        q: "Que se passe-t-il quand je désactive mon profil?",
        t: "Nous permettons à nos chefs de décider quand ils veulent que leurs profils apparaissent sur notre site Web. Vous pourrez toujours postuler à des offres d'emploi, recevoir nos offres d'emploi hebdomadaires et utiliser votre profil normalement, même si votre profil a été désactivé. Seule la désactivation de votre profil empêche les restaurants de voir votre profil ou de vous envoyer des offres d'emploi."
    },
    {
        q: "Pourquoi puis-je envoyer un message uniquement à certains restaurants?",
        t: "Notre système vous permet uniquement d'envoyer un message directement à nos clients premium."
    },
    {
        q: "Pourquoi mon profil est-il toujours en révision?",
        t: "Il peut être l'une des deux raisons: <ul><li>Nous traitons toujours votre demande; Après avoir créé votre profil, veuillez laisser au moins 24 heures avant de contacter Chefs Link</li><li>Votre demande est incomplète. veuillez compléter votre formulaire et le soumettre à nouveau</li></ul>"
    },
    {
        q: "Je ne veux pas qu’un restaurant en particulier voir mon profil?",
        t: "Vous pouvez empêcher un restaurant de voir votre profil en accédant à son profil et en cliquant sur le bouton Plus de la barre de fonctions. Un menu déroulant vous donnera quelques options pour bloquer le restaurant."
    },
    {
        q: "Quels sont les restaurants avec lesquels vous travaillez?",
        t: "Vous pouvez trouver tous les restaurants avec lesquels nous travaillons sur notre page d'accueil sous Offres d'emploi ou Profils de restaurants."
    },
    {
        q: "Est-ce que vous m'aidez avec le logement?",
        t: "Non, mais certains restaurants proposent un hébergement avec votre contrat."
    },
    {
        q: "Combien vais-je être payé?",
        t: "Tout dépendra de votre expérience de chef. En rejoignant notre réseau, nous consacrons notre temps à adapter votre expérience aux meilleurs restaurants possibles de notre réseau. Notre équipe restera également en contact avec vous et gérera votre carrière à mesure que vous gagnerez en expérience."
    },
    {
        q: "Pouvez-vous m'envoyer plus d'informations sur le travail?",
        t: "Nous serons plus qu'heureux de le faire. Veuillez nous faire part de vos préoccupations ou des autres informations que vous souhaiteriez que nous vous fournissions et nous y répondrons du mieux que nous pouvons."
    },
    {
        q: "J’ai aucune expérience en cuisine. Est-ce un problème?",
        t: "Nous sommes désolés de vous informer, mais nous ne pourrons malheureusement pas vous aider pour le moment. Nos restaurants ne demandent que des chefs expérimentés ou qualifiés."
    },
]
