import {combineReducers} from 'redux'
import { routerReducer } from 'react-router-redux'
import * as userReducer from './user'
import * as utilReducer from './util'
import * as offerReducer from './offer'
import * as filterReducer from './filter'
import * as chefReducer from './chef'

let reducers = Object.assign(
    userReducer,
    utilReducer,
    offerReducer,
    filterReducer,
    chefReducer,
)

export default combineReducers({
    ...reducers,
    routing: routerReducer
})
