import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import Input from '../../Components/Input/Input'
import {validate} from '../../Components/Input/validate'
import {restaurantEditCover} from '../../Services/Restaurant/restaurant'
import {translate} from '../../Translations/translate'
import { withRouter } from 'react-router'

class RestaurantCoverOverlay extends Component {
    constructor(props) {
        super(props)
        let value = this.props.data.value
        this.state = {
            id: this.props.data.id,
            title: value.title ? value.title : '',
            description: value.description ? value.description : '',
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'edit_cover')} className={this.props.className}>
                <div className="col-group form">
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'title')}
                            name="title"
                            error={this.state.errors}
                            value={this.state.title}
                            onChange={(v) => {
                                this.setState({title: v})
                            }}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <Input
                            label={translate(lang, 'description')}
                            name="description"
                            error={this.state.errors}
                            value={this.state.description}
                            onChange={(v) => {
                                this.setState({description: v})
                            }}
                            />
                    </div>

                    <div className="col-mb-12 actions multiple">
                        {parseInt(this.state.id) !== 0 ? <div className="accessory">
                            <Btn text={translate(lang, 'delete').toUpperCase()} loading={this.state.loadingError} className="delete" callback={() => {
                                this.delete()
                            }} />
                        </div> : null}
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div>
            </Overlay>
        )
    }
    save() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'title'},
            {field: 'description'},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })
            //SAVE
            let data = {
                rid: this.props.data.rid,
                id: this.state.id,
                name: 'cover',
                order: 0,
                value: JSON.stringify({
                    title: this.state.title,
                    description: this.state.description,
                }),
                lang: lang
            }
            restaurantEditCover(data).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                let data = res.data.data
                this.props.setUser(data.user)
                this.props.showOverlay(null)
            }).catch((e) => {
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }
    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        restaurantEditCover({rid: this.props.data.rid, id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            let data = res.data.data
            this.props.setUser(data.user)
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(RestaurantCoverOverlay))
