import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import SVG from 'react-inlinesvg';
import Stars from '../../Components/Stars/Stars'
import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import moment from 'moment'
import {deleteOffer} from '../../Services/Offers/offers'
import {listApplicantsCounter} from '../../Services/Restaurant/restaurant'
import {toggleOffer} from '../../Services/Offers/offers'
import {readableLocation} from '../../Lib/readableLocation'
import {fixHtml} from '../../Lib/fixHtml'
import { translate } from '../../Translations/translate'
import Switch from '../../Components/Switch/Switch'
import {findById} from '../../Lib/findById'
import LazyImage from '../../Components/LazyImage/LazyImage'

var find = require('lodash/find')

class OfferApplications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            showMore: false,
            savedOffers: [],
            profile: {},
            items: [],
            loadingSwitch: false,
            offer: this.props.item.offer,
            offerStatus: this.props.item.offer.status,
            applicationsCounter: {
                pending: 0,
                review: 0,
                accepted: 0,
                total: 0
            }  
        }
    }

    componentDidMount() {
      let profile = this.props.match.params.id ? findById(this.props.match.params.id, this.props.user.profiles) : this.props.user.main
      this.setState({
        profile: profile,
    })
    if (parseInt(this.props.user.subscription) !== 0){
        this.setState({
          profile: profile,
      }, this.loadItems.bind(this))
        this.loadItems()
    }
    }
    loadItems() {
      listApplicantsCounter({oid: this.props.offerId, rid: this.state.profile.id }).then((res) => {
        if (this.state.loading) {
            this.setState({loading: false})
        }
        if (res.data.success) {
            const { pending, review, accepted, rejected } = res.data.data 
            const total = pending + review + accepted

            this.setState({
                applicationsCounter: {
                    pending,
                    review,
                    accepted,
                    total
                }
            })
        }
    })
    }

    toggleOfferSwitch(offerData, url, image) {
        toggleOffer(offerData).then((res) => {
            if (!res.data.success) {
            this.props.showOverlay('error', {message: res.data.message})
            }
            if (res.data.success) {
                this.props.loadItemsCallback()
                if (offerData.action === 1) {
                    this.props.showOverlay('share', {title: this.props.item.offer.name, url: url, image: image})
                }
            }
        })
    }
    
    render() {
        const { lang } = this.props.match.params
        let { item } = this.props
        let image = item.offer.images.image1 ? item.offer.images.image1.sizes.share.url : 'http://via.placeholder.com/240x460/eee/fff'
        let url = this.props.url ? this.props.url : `/${lang}/restaurant/view/offer/${item.restaurant.id}/${item.restaurant.slug}/${item.offer.id}/${item.offer.slug}`

        return (
            <div className="component_OfferApplications">
                <div>
                    <div className="top">
                        {this.renderTop()}
                    </div>
                    <div className="wrap">
                        <div className="graphic">
                            {this.renderPremiumDesktop()}
                            <div className="logo">
                                <LazyImage src={item.restaurant.images.logo ? item.restaurant.images.logo.sizes.square.url : '/static/images/logo.png'} alt={item.restaurant.name}/>
                            </div>
                            <div className="image" style={{backgroundImage: 'url(' + image + ')'}}></div>
                        </div>
                        <div className="info">
                            <h2>
                                <Link to={url}>{item.offer.name}</Link>
                            </h2>
                            <p>{this.renderDescription()}</p>
                            <div className="offer-content">
                            {parseInt(this.props.user.subscription) !== 0
                              && <Link className="applicants" to={`/${lang}/restaurant/profile/applicants/${item.restaurant.id}/${item.offer.id}/pending`}>
                                <div>
                                  <span className='total'><stong>{translate(lang, 'applications_NUM', this.state.applicationsCounter.total)}</stong></span>
                                  <span>{translate(lang, 'pending')} ({this.state.applicationsCounter.pending}) - {translate(lang, 'in_review')} ({this.state.applicationsCounter.review}) - {translate(lang, 'accepted')} ({this.state.applicationsCounter.accepted})</span>
                                </div>
                                <SVG className='arrow' src="/static/images/backarrow.svg" />
                              </Link>}
                              <div className='offer-actions'>
                                <div className='switch'>
                                <Switch active={this.state.offerStatus} callback={() => {
                                    let action = parseInt(this.state.offerStatus) === 0 ? 1 : 0
                                    this.toggleOfferSwitch({ oid: this.state.offer.id, action: action, lang }, url, image)
                                }} />
                                {parseInt(item.offer.approvedp) === 0 && <span className='in-review'>({translate(lang, 'in_review')})</span>}
                                </div>
                                <div className='delete'>
                                <Btn className="inverted" text={translate(lang, 'delete').toUpperCase()} callback={() => {
                                    const result = window.confirm(translate(lang, 'delete_offer'))
                                    if (result) {
                                        deleteOffer({id: item.offer.id, action: "delete", lang}).then((res) => {
                                            if (res.data.success) {
                                                this.props.loadItemsCallback()
                                            }
                                        })
                                    } else {
                                        return
                                    }
                                }} />
                                </div>
                              </div>
                            </div>
                        </div>
                        <div className="bottom">
                            <span>{moment(item.offer.updated).format(this.props.deviceScreen === 'mobile' ? 'D MMM' : 'D MMM[.] YYYY')}</span>
                            {this.renderShareOptions()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderDescription() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let {description} = item.offer
        let teaser = !this.state.showMore && description.length > 100 ? description.substring(0, 100) + '...' : description
        return (
            <span>
                <span dangerouslySetInnerHTML={{__html: fixHtml(teaser)}}></span>
                <span>&nbsp;<span key="readMoreBtn" className="read-more" onClick={() => {this.setState({showMore: !this.state.showMore})}}>{this.state.showMore ? translate(lang, 'read_less') : translate(lang, 'read_more')}</span></span><br/>
            </span>
        )
    }
    renderShareOptions() {
        return (
            <div className="share">
                {this.renderShare()}
            </div>
        )
    }
    renderTop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let selected = find(this.props.selectedOffers, {id: item.offer.id}) ? true : false
        let url = `/${lang}/restaurant/view/home/${item.restaurant.id}/${item.restaurant.slug}`
        let application = this.props.application ? true : false
        if (this.props.deviceScreen === 'mobile') {
            let select = (
                <div className="select" onClick={() => {
                    this.props.toggleSelectedOffer({id: item.offer.id, item: item})
                }}>
                    <SVG src={selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'} />
                </div>
            )
            return (
                <div>
                    <div className="restaurant">
                        <Link to={url}>{item.restaurant.name}</Link>
                    </div>
                    <Stars type={item.restaurant.ratingType} number={parseInt(item.restaurant.rating)} />
                    {item.restaurant.premium === "1" ? <SVG className="isPremium" src="/static/images/premium_icn.svg" /> : null}
                    {!this.props.isLogged || this.props.userType === 'chef' ? select : null}
                </div>
            )
        } else {
            let select = (
                <div className="select" onClick={() => {
                    this.props.toggleSelectedOffer({id: item.offer.id, item: item})
                }}>
                    <SVG src={selected ? '/static/images/tick1.svg' : '/static/images/tick2.svg'} />
                    <span className="text">{translate (lang, 'apply')}</span>
                </div>
            )
            return (
                <div>
                    <div className="restaurant">
                        <div className="name-text"><span><Link to={url}>{item.restaurant.name}</Link></span></div>
                        {(!this.props.isLogged || this.props.userType === 'chef') && !application ? select : null}
                    </div>
                    <Stars type={item.restaurant.ratingType} number={parseInt(item.restaurant.rating)} />
                    <span className="location">{readableLocation(item.restaurant.location)}</span>
                </div>
            )
        }
    }
    renderPremiumDesktop() {
        const { lang } = this.props.match.params
        let {item} = this.props
        if (this.props.deviceScreen === 'mobile') {
            return null
        } else {
            let isPremium = null
            if (item.restaurant.premium === "1") {
                isPremium = (
                    <div><SVG className="isPremium" src="/static/images/premium_icn.svg" /><span className="text"><span>{translate (lang, 'premium').toUpperCase()}</span></span></div>
                )
            }
            return (
                <div className="premium">
                    {isPremium}
                </div>
            )
        }
    }
    renderShare() {
        const { lang } = this.props.match.params
        let {item} = this.props
        let url = `/${lang}/restaurant/view/offer/${item.restaurant.id}/${item.restaurant.slug}/${item.offer.id}/${item.offer.slug}`
        let image = item.offer.images.image1 && item.offer.images.image1.sizes && item.offer.images.image1.sizes.share ? item.offer.images.image1.sizes.share.url : 'http://via.placeholder.com/240x460/eee/fff'
        // let icon = this.props.deviceScreen === 'mobile' ? null : 'share_icn'
        let icon = 'share_icn'
        return (
            <Btn text={this.props.deviceScreen === "mobile" ? null : translate(lang, 'share').toUpperCase()} className="text" post={icon} callback={() => {
                this.props.showOverlay('share', {title: this.props.item.offer.name, url: url, image: image})
            }} />
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        selectedOffers: state.selectedOffers,
        userType: state.userType,
        isLogged: state.isLogged,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(OfferApplications))
