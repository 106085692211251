import React, {Component} from 'react'
// import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
// import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import { withRouter } from 'react-router'
import LanguageTranslator from '../LanguageTranslator/LanguageTranslator'
import OverlayLanguageTranslator from "../_NEW/OverlayLanguageTranslator"
import { translate } from '../../Translations/translate'
import OverlayChefProfileNav from '../_NEW/OverlayChefProfileNav'
class Overlay extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    escFunction(event){
        if (event.keyCode === 27) {
            if (this.props.callback) {
                this.props.callback(false)
            }
            // this.props.showOverlay(null)
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction.bind(this), false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction.bind(this), false);
    }

    handleGoBack(name) {
        const navListOverlayProps = [
            {name: "chefinfo", overlay: 'chefinfo', data: { ...this.props.user, profileNav: true }},
            {name: "chefabout", overlay: 'chefabout', data: { description: this.props.user.profile.description, profileNav: true }},
            {name: "chefworkexperience", overlay: 'chefworkexperience', data: { id: 0, value: {}, profileNav: true }},
            {name: "chefeducation", overlay: 'chefeducation', data: { id: 0, value: {}, profileNav: true }},
            {name: "cheflanguage", overlay: 'cheflanguage', data: { id: 0, name: '', value: '', profileNav: true }},
            {name: "chefreferral", overlay: 'chefreferral', data: { id: 0, value: {}, profileNav: true }},
        ]
        const index = navListOverlayProps.indexOf(navListOverlayProps.find(i => i.name === name))
        return this.props.showOverlay(navListOverlayProps[index - 1].overlay, navListOverlayProps[index - 1].data)
    }

    render() {
        const { lang } = this.props.match.params
        let className = this.props.isNew ? 'component_Overlay_new' : 'component_Overlay'
        if (this.props.className) {
            className += ' ' + this.props.className
        }
        return (
            <div
                className={className}
                >
                <div className="scroll" onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        if (this.props.callback) {
                            this.props.callback(false)
                        }
                        // this.props.showOverlay(null)
                    }
                }}>
                    <div className="wrapper">
                        {this.props.isNew ? // NEW DESIGN
                            <div className='head'>
                                <div className='navRow'>
                                    <div className='left'>{this.props.profileNav && <div className='navRowDesktop'>
                                            <OverlayChefProfileNav props={this.props} />
                                        </div>}
                                    </div>
                                    <div className='right'>
                                        {this.props.languageTranslator && this.props.deviceScreen !== 'mobile' ? <OverlayLanguageTranslator props={this.props} languageCallback={this.props.languageCallback} /> : ''}
                                        <a onClick={() => { this.props.showOverlay(null)}}>
                                            <SVG src="/static/images/aspa.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div className='navRowMobile'>
                                    <OverlayChefProfileNav props={this.props} />
                                </div>
                                <div className='title'>{this.props.title}</div>
                                {this.props.profileNav && this.props.subtitle && <div className='subtitle' dangerouslySetInnerHTML={{__html: this.props.subtitle}} />}
                            </div>
                         : 
                            <div className="title">
                                <h4 dangerouslySetInnerHTML={{__html: this.props.title}}></h4>
                                <div>
                                    {this.props.languageTranslator && this.props.deviceScreen !== 'mobile' ? <LanguageTranslator languageCallback={this.props.languageCallback} /> : ''}
                                    {this.props.rightTop ? this.props.rightTop : null}
                                    <div className="close" onClick={() => {
                                        if (this.props.callback) {
                                            this.props.callback(false)
                                        }
                                        this.props.showOverlay(null)
                                    }}>
                                        <SVG src="/static/images/close_btn_2.svg" />
                                    </div>
                                </div>
                            </div>
                        }
                        {this.props.languageTranslator && this.props.deviceScreen === 'mobile'
                        ? <>{this.props.isNew
                            ? <OverlayLanguageTranslator props={this.props} languageCallback={this.props.languageCallback} />
                            : <LanguageTranslator languageCallback={this.props.languageCallback} />
                        }</>
                        : ''}
                        <div className="content">{this.props.children}</div>
                        {this.props.isNew && this.props.profileNav && <div className={`bottom ${this.props.overlayName === "chefinfo" ? "alignEnd" : ""}`}>
                            {this.props.title !== "Profile" && <a onClick={() => this.handleGoBack(this.props.overlayName)}>{translate(lang, 'go_back')}</a>}
                            <a onClick={() => this.props.showOverlay(null)}>{translate(lang, 'continue_later')}</a>
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
        user: state.user,
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(Overlay))
