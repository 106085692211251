import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg';
import { translate } from '../../Translations/translate'

const FormDropFile = ({ lang, label, setFileCallback }) => {
  const [file, setFile] = useState(false)

  const onDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files.length > 0) {
      setFile(e.dataTransfer.files[0]);
    }
  }

  const handleBrowse = () => {
    let input = document.createElement("input");
    input.type = "file";

    input.onchange = (e) => {
      let file = e.target.files[0];
      if (file) {
        setFile(file);
      }
    };

    input.click();
  }

  useEffect(() => {
    setFileCallback(file)
  }, [file])

  return (
    <div>
      <label className="inputLabel">{label}</label>
      <div>
        {!file ? (
          <div className="container_dropFile">
            <div
              className="drag-area"
              onDragOver={(e) =>
                e.preventDefault() && e.stopPropagation()
              }
              onDrop={(e) => onDrop(e)}
            >
              <div className="drag-area-content">
                <span>
                  {translate(lang, "drop_file_here")}{" "}
                  <a
                    className="browse"
                    onClick={() => handleBrowse()}
                  >
                    {translate(lang, "browse")}
                  </a>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="container_dropFile">
            <div className="drag-area-content-loaded">
              <div className="file">
                <p className="file-message">
                  {`${translate(lang, "file_selected")} ${file.name} `}
                  <a className="cleanFile" onClick={() => setFile(false)}>
                      <SVG src="/static/images/aspa.svg" />
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FormDropFile