import React, {Component} from 'react'
import SVG from 'react-inlinesvg';
// import Btn from '../../Components/Btn/Btn'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class FeaturesBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedIndex: 0
        }
    }
    render() {
        return (
            <div className="component_FeaturesBox">
                <h2>{this.props.title}</h2>
                <div className="col-group">
                    <div className="col-mb-12 col-4">
                        {this.renderMenu()}
                    </div>
                    {this.props.deviceScreen !== 'mobile' ? <div className="col-mb-12 col-8">
                        {this.renderInfo()}
                    </div> : null}
                </div>
            </div>
        )
    }
    renderMenu() {
        let items = []
        let i = 0
        const theItems = this.props.items()
        theItems.forEach((item) => {
            items.push(this.renderMenuItem(item, i))
            i++
        })
        return (
            <ul>
                {items}
            </ul>
        )
    }
    renderMenuItem(item, i) {
        let className = this.state.selectedIndex === i ? 'label active' : 'label'
        let src = '/static/images/' + item.icon + '.svg'
        return (
            <li key={i} data-id={i} onClick={(e) => {
                this.setState({ selectedIndex: i })
                }}>
                <div className={className} data-icon={item.icon}>
                    <SVG src={src} /><span className="text">{item.title}</span>
                    {this.props.deviceScreen === 'mobile'?<i><SVG src="/static/images/arrow.svg" /></i>:null}
                </div>
                {this.props.deviceScreen === 'mobile' && this.state.selectedIndex === i ? this.renderInfo() : null}
                </li>
        )
    }
    renderInfo() {
        const theItems = this.props.items()
        return (
            <div className="info">
                <h4>{theItems[this.state.selectedIndex].subtitle}</h4>
                <p>{theItems[this.state.selectedIndex].content}</p>
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,
    }
}
export default withRouter(connect(mapStateToProps)(FeaturesBox))
