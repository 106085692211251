import ChefLanguageForm from '../_NEW/ChefLanguageForm'
import React, {Component} from 'react'
import Btn from '../../Components/Btn/Btn'
import Overlay from './Overlay'
import Select from 'react-select'
import { connect } from 'react-redux'
import {ActionCreators} from '../../Actions'
import {chefEditLanguage} from '../../Services/Chef/chef'
import {languages, levels} from '../../Lists/lists'
import {validate} from '../../Components/Input/validate'
import { translate } from '../../Translations/translate'
import { withRouter } from 'react-router'
class ChefLanguageOverlay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.data.id,
            language: this.props.data.language,
            level: this.props.data.level,
            errors: []
        }
    }
    render() {
        const { lang } = this.props.match.params
        return (
            <Overlay title={translate(lang, 'language')} className={this.props.className}
            isNew
            overlayName={this.props.overlayName}
            profileNav={this.props.data.profileNav}
            subtitle={translate(lang, 'add_languages_extended')}
            >
                <ChefLanguageForm props={this.props} />
                
                {/* <div className="col-group form">
                    <div className="col-mb-12 col-6">
                        <label htmlFor="type">{translate(lang, 'language')}</label>
                        <Select
                            placeholder=""
                            name="language"
                            value={this.state.language}
                            className={this.state.errors.indexOf('language') !== -1 ? 'error' : null}
                            onChange={(option) => { this.setState({ language: option ? option.value : -1 }) }}
                            options={languages[lang]}
                            />
                    </div>
                    <div className="col-mb-12 col-6">
                        <label htmlFor="type">{translate(lang, 'level')}</label>
                        <Select
                            placeholder=""
                            name="level"
                            value={this.state.level}
                            className={this.state.errors.indexOf('level') !== -1 ? 'error' : null}
                            onChange={(option) => { this.setState({ level: option ? option.value : -1 }) }}
                            options={levels[lang]}
                            />
                    </div>
                    <div className="col-mb-12 actions multiple">
                        {parseInt(this.state.id) !== 0 ? <div className="accessory">
                            <Btn text={translate(lang, 'delete').toUpperCase()}  loading={this.state.loadingError} className="delete" callback={() => {
                                this.delete()
                            }} />
                        </div> : null}
                        <div className="main">
                            <Btn text={translate(lang, 'cancel').toUpperCase()} className="inverted" callback={() => {
                                this.props.showOverlay(null)
                            }} />
                            <Btn text={translate(lang, 'save').toUpperCase()} loading={this.state.loading} callback={() => {
                                this.save()
                            }} />
                        </div>
                    </div>
                </div> */}
            </Overlay>
        )
    }
    save() {
        const { lang } = this.props.match.params
        let items = [
            {field: 'language'},
            {field: 'level'},
        ];
        let errors = validate(items, this.state)
        this.setState({ errors: errors })
        if (errors.length === 0) {
            this.setState({ loading: true })
            //SAVE
            let data = {
                id: this.state.id,
                name: this.state.language,
                value: this.state.level,
                lang: lang
            }
            chefEditLanguage(data).then((res) => {
                this.setState({ loading: false })
                if (!res.data.success) {
                    this.props.showOverlay('error', {message: res.data.message})
                    return
                }
                let data = res.data.data
                this.props.setUser(data.user)
                this.props.showOverlay(null)
            }).catch((e) => {
                this.props.showOverlay('error', {message: e.message})
            })
        }
    }
    delete() {
        const { lang } = this.props.match.params
        this.setState({ loadingDelete: true })
        chefEditLanguage({id: this.state.id, action: 'delete', lang}).then((res) => {
            this.setState({ loadingDelete: false })
            if (!res.data.success) {
                this.props.showOverlay('error', {message: res.data.message})
                return
            }
            let data = res.data.data
            this.props.setUser(data.user)
            this.props.showOverlay(null)
        }).catch((e) => {
            this.props.showOverlay('error', {message: e.message})
        })
    }
};

const mapStateToProps = state => {
    return {
        deviceScreen: state.deviceScreen,        
    }
}
export default withRouter(connect(mapStateToProps, ActionCreators)(ChefLanguageOverlay))
