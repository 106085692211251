import React from 'react'
import Btn from '../../Components/Btn/Btn'
import {translate} from '../../Translations/translate'

export function listItems(isLogged, userType, state, user, lang) {
    const nots = user.profile.pendingnotifications
    let items = [
        {url: `/${lang}/chef/profile/home`, title: translate(lang, 'home'), icon: '_home'},
        {url: `/${lang}/chef/profile/calendar`, title: translate(lang, 'calendar'), icon: '_calendar'},
        {url: `/${lang}/chef/profile/notifications`, title: translate(lang, 'notifications'), icon: '_notifications_2', badge: nots.notifications},
        // {url: `/${lang}/chef/profile/links`, title: translate(lang, 'links'), icon: '_link'},
        {url: `/${lang}/chef/profile/applications`, title: translate(lang, 'applications'), icon: '_applicants', badge: nots.offers},
        {url: `/${lang}/chef/profile/messages`, title: translate(lang, 'messages'), icon: '_messages', badge: nots.messages},
        {url: `/${lang}/chef/profile/saved`, title: translate(lang, 'saved'), icon: '_save'},
    ]
    return items
}

export function listBtn2(lang, profile, state, callback) {
    return (
        <Btn text={translate(lang, 'share').toUpperCase()} className="text" post="share_icn" callback={callback} />
    )
}
