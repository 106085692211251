var find = require('lodash/find')

export function findById(id, options) {
    let item = find(options, (option) => {
        return parseInt(id) === parseInt(option.id)
    })
    if (item) {
        return item
    } else {
        return null
    }
}
