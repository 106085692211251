import * as types from './types'
var find = require('lodash/find')

export function setSelectedOffers(selectedOffers) {
    return {
        type: types.SET_SELECTED_OFFERS,
        selectedOffers
    }
}
export function addSelectedOffer(selectedOffer) {
    return {
        type: types.ADD_SELECTED_OFFER,
        selectedOffer
    }
}
export function removeSelectedOffer(selectedOffer) {
    return {
        type: types.REMOVE_SELECTED_OFFER,
        selectedOffer
    }
}
export function toggleSelectedOffer(selectedOffer) {
    return (dispatch, getState) => {
        if (find(getState().selectedOffers, {id: selectedOffer.id})) {
            dispatch(removeSelectedOffer(selectedOffer))
        } else {
            dispatch(addSelectedOffer(selectedOffer))
        }
    }
}
