import React from 'react'
import SVG from 'react-inlinesvg';
import Btn from '../../Components/Btn/Btn'
import { translate } from '../../Translations/translate';

export function listItems(isLogged, userType, state, lang) {
    let items = [
        {url: `/${lang}/restaurant/view/home/` + state.id + '/' + state.slug, title: translate(lang, 'home'), icon: '_home'},
    ]
    if (isLogged && userType === 'chef') {
        items.push(
            // {url: `/${lang}/restaurant/view/message/` + state.id + '/' + state.slug, title: translate(lang, 'message'), icon: '_messages'},
            {url: `/${lang}/restaurant/view/stage/` + state.id + '/' + state.slug, title: translate(lang, 'stage'), icon: '_calendar'},
        )
    }
    items.push({url: '#share', title: translate(lang, 'share'), icon: 'share_icn'})
    return items
}
export function listItemsMore(isLogged, userType, state, callbacks, lang) {
    if (!isLogged) {
        return null
    }
    return (
        <div className="report-menu">
            <h4>{translate(lang, 'block_page').toUpperCase()}</h4>
            <div className="line" onClick={callbacks && callbacks.block ? callbacks.block.bind(this, 'block') : null}>
                <SVG src="/static/images/tick1.svg" />
                {translate(lang, 'block_this_page')}
            </div>
            <h4>{translate(lang, 'report').toUpperCase()}</h4>
            <div className="line" onClick={callbacks && callbacks.report ? callbacks.report.bind(this, 'report') : null}>
                <SVG src="/static/images/tick1.svg" />
                {translate(lang, 'report_page')}
            </div>
        </div>
    )
}

export function listLink(isLogged, userType, state, callback, lang) {
    if (!isLogged) {
        return null
    }
    return (
        <div>
            <h3><span>{translate(lang, 'link_with_us')}</span></h3>
            <p>{translate(lang, 'link_to_our_page')}</p>
            <Btn
                text={translate(lang, 'link_now').toUpperCase()}
                callback={callback ? callback : null}
                />
        </div>
    )
}

export function listBtn1(lang, isLogged, userType, state, callback) {
    if (!isLogged || userType === 'restaurant') {
        return null
    }
    return (
        <div>
            <Btn pre="message_icn" text={translate(lang, 'message').toUpperCase()} className="outlined" callback={callback ? callback : null} />
        </div>
    )
}

export function listBtn2(lang, isLogged, userType, state, callback) {
    if (isLogged && userType === 'restaurant') {
        return null
    }
    return (
        <div>
            <Btn
                text={translate(lang, 'apply').toUpperCase()}
                pre="edit_icn"
                callback={callback ? callback : null}
                />
        </div>
    )
}

export function listBtnSendCV(isLogged, userType, state, callback, lang) {
    if (isLogged && userType === 'restaurant') {
        return null
    }
    return (
        <div>
            <Btn
                text={translate(lang, 'send_cv').toUpperCase()}
                pre="_joboffer_publish"
                callback={callback ? callback : null}
                />
        </div>
    )
}
