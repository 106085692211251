import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ActionCreators } from "../../Actions";
import { translate } from "../../Translations/translate";
import SVG from 'react-inlinesvg';
import SignupForm from "../_NEW/SignupForm";
class SignUpOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    document.querySelector("body").style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.querySelector("body").style.overflow = "auto";
  }

  render() {
    const { lang } = this.props.match.params;

    return (
      <div className='component_Login_Signup_Overlay'>
        <div className='container'>
            <div className='buttonsRow'>
                <div className='left'>
                <a onClick={() => {this.props.showOverlay("login")}}>
                    <SVG src="/static/images/arrow_simple.svg" />
                    <span className="go-back">{translate(lang, "go_back").toUpperCase()}</span>
                </a>
                </div>
                <div className='right'>
                    <a onClick={() => { this.props.showOverlay(null)}}>
                        <SVG src="/static/images/aspa.svg" />
                    </a>
                </div>
            </div>
            <div className='formsContainer'>
                <div className="signupDescription">
                  <h4 className="title">
                    {translate(lang, "create_account").toUpperCase()}
                  </h4>
                  <div className="description">
                      <p>{translate(lang, "create_account_description_1")}</p>
                      <p>{translate(lang, "create_account_description_2")}</p>
                  </div>
                </div>
                <div className="signupForm">
                  <SignupForm props={this.props} />
                </div>
            </div>
        </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deviceScreen: state.deviceScreen,
  };
};
export default withRouter(
  connect(mapStateToProps, ActionCreators)(SignUpOverlay)
);
