import React, { useState } from 'react'
import { Formik, Form } from "formik"
import ReactDOMServer from 'react-dom/server'

import { translate } from '../../Translations/translate'
import SignupEmailSchema from '../../FormValidations/SignupEmailSchema'
import {saveEmail} from '../../Services/Access/access'

import FormField from './FormField'
import Btn from '../Btn/Btn'
import FormCheckbox from './FormCheckbox'

const SignupEmailForm = ({ props }) => {
  const { lang } = props.match.params
  const { showOverlay, history, setSelectedOffers } = props

  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState(null)

  const cookies = ReactDOMServer.renderToString(<a className="iubenda-nostyle iubenda-embed no-brand" href="https://www.iubenda.com/privacy-policy/35255572/cookie-policy">{translate(lang, 'cookie_policy')}</a>)
  const privacy = ReactDOMServer.renderToString(<a className="iubenda-nostyle iubenda-embed no-brand" href="https://www.iubenda.com/privacy-policy/35255572">{translate(lang, 'privacy_policy')}</a>)

  const handleFormError = (error) => {
    if (error.includes('The email field must contain a unique value.')) {
      setFormError(translate(lang, 'error_email_unique'))
    } else {
      setFormError(error)
    }
  }

  const handleSignupEmail = (values) => {
    setLoading(true)
    saveEmail({
      email: values.email,
      submit: 1,
    }).then((res) => {
        setLoading(false)
        if (!res.data.success) {
            console.log('ERROR: ' + res.data.message)
            handleFormError(res.data.message)
            return
        }
        showOverlay('signup', { savedEmail: values.email, fromApplyCallback: (user) => {
            setSelectedOffers([])
            if (user.type === 'chef') {
                history.push(`/${lang}/chef/profile/home`)
            } else if (user.type === 'restaurant') {
                history.push(`/${lang}/restaurant/profile/home`)
            }
        }})
    })
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          terms: false,
        }}
        validationSchema={SignupEmailSchema(lang)}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={(values) => handleSignupEmail(values)}
      >{({ values, errors, handleSubmit, handleChange }) => (
          <Form>
            <FormField
              id="email"
              type="email"
              name="email"
              label={translate(lang, 'email')}
              placeholder={translate(lang, 'email_placeholder')}
              value={values.email}
              onChange={handleChange}
              errors={errors}
              formError={formError}
            />
            <div className="signupTerms">
              <FormCheckbox
                id="terms"
                name="terms"
                label={`${translate(lang, 'signup_terms')}${cookies}${translate(lang, 'and')}${privacy}`}
                checked={values.terms}
                onChange={handleChange}
                errors={errors}
              />
            </div>
            {formError && <div className='errorMessage' dangerouslySetInnerHTML={{__html: formError}} />}
            <div className={`actions multiple loginButton ${(!values.email || !values.terms) ? 'disabled' : ''}`}>
              <Btn
              className={(!values.email || !values.terms) ? "outlined" : ""}
              text={translate(lang, 'continue').toUpperCase()}
              loading={loading}
              callback={() => handleSubmit()}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default SignupEmailForm